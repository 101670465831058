import {
    BrowserRouter as Router,
    Route,
    Link
  } from 'react-router-dom';
  import React, { Component } from 'react';
  import Tabs from 'react-responsive-tabs';
  
  import BottomDrivers from './listor/bottomdrivers';
  import TopDrivers from './listor/topdrivers';
  import RookieDrivers from './listor/rookiedrivers';
  
  class Leaderboard extends Component {
    login() {
      this.props.auth.login();
    }
    render() {
      const { isAuthenticated } = this.props.auth;
      const ordersTabs = [
        { name: 'Topplistan', content: <TopDrivers /> },
        { name: '"Håll koll på"-listan', content: <BottomDrivers /> },
        { name: 'Rookielistan', content: <RookieDrivers /> },
      ];
      function getTabs() {
        return ordersTabs.map(ordertab => ({
          tabClassName: 'tab', // Optional
          panelClassName: 'panel', // Optional
          title: ordertab.name,
          getContent: () => ordertab.content,
        }));
      }
      return (
        <div className="container">
          {
            isAuthenticated() && (
              <div>
                <h3>Leaderboards</h3>
  
                <Tabs items={getTabs()} />
              </div>
            )
          }
          {
            !isAuthenticated() && (
              <h4>
                You're not logged in! Click {' '}
                <a
                  style={{ cursor: 'pointer' }}
                  onClick={this.login.bind(this)}
                >
                  Login
                  </a>
                {' '}to continue.
                </h4>
            )
          }
        </div>
      );
    }
  }
  
  export default Leaderboard;
  
  