import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import React, { Component } from 'react';
import { DateRange, defaultRanges } from 'react-date-range';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import FileSaver from 'file-saver';

class LinasRapporter extends Component {

  handleSelection(Chart) {
    if (Chart.chart.getSelection()[0]) {
      var date = this.state.rows[Chart.chart.getSelection()[0]['row'] + 1][0];
      this.props.history.push('/delivery?merchant=LM&date=' + date);
    }
  }

  componentWillMount() {
    let rows = [];

    localStorage.setItem('clientName', 'Linas Matkasse');

    rows.push(['Datum', "Precision %", "Antal"]);

    this.state = {
      'rangePicker': {},
      'rangePickerMobile': {},
      'linked': {},
      'datePicker': null,
      'datePickerInternational': null,
      // 'firstDayOfWeek' : null,
      'predefined': {},
    }

    this.setState({ rows: rows });
    return { rows: rows };
  }

  getReport() {
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch('https://api.gordondelivery.com/api/linasmatkasse/report/' + this.state.rangePicker['startDate'].format('YYYY-MM-DD').toString() + "/" + this.state.rangePicker['endDate'].format('YYYY-MM-DD').toString(), {
        headers: {
          'Authorization': 'Bearer ' + access_token,
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
        responseType: 'blob'
      }).then(response => response.blob())
        .then(blob => FileSaver.saveAs(blob, 'Gordon_LinasMatkasse_' + this.state.rangePicker['startDate'].format('YYYY-MM-DD').toString() + "_" + this.state.rangePicker['endDate'].format('YYYY-MM-DD').toString() + '.xlsx')

        ).catch((error) => {
          console.error(error);
        });

    } else {
      console.error("401 Unauthorized");
    }
  }
  componentDidMount() {
  }

  handleSelect(range) {
    console.log(range);
    // An object with two keys,
    // 'startDate' and 'endDate' which are Momentjs objects.
  }

  handleChange(which, payload) {
    this.setState({
      [which]: payload
    });
  }

  render() {

    const {
      rangePicker,
      rangePickerMobile,
      linked,
      datePicker,
      predefined,
      datePickerInternational
    } = this.state;

    return (
      <div className="container">

        <div>
          <DateRange
            lang='en'
            linkedCalendars={true}
            ranges={defaultRanges}
            format="YYYY-MM-DD"
            firstDayOfWeek={1}
            onInit={this.handleChange.bind(this, 'rangePicker')}
            onChange={this.handleChange.bind(this, 'rangePicker')}
            date={now => { return now.add(-4, 'days') }}
          />
        </div>
        <br />
        <Button bsStyle="primary" className="btn-margin" onClick={this.getReport.bind(this)}>Skapa rapport</Button>
        {/* <div>
            <input
              type='text'
              readOnly
              value={ rangePicker['startDate'] && rangePicker['startDate'].format('YYYY-MM-DD').toString() }
            />
            <input
              type='text'
              readOnly
              value={ rangePicker['endDate'] && rangePicker['endDate'].format('YYYY-MM-DD').toString() }
            />
          </div> */}


      </div>

    );
  }
}

export default LinasRapporter;

