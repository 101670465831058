import React, { Component } from 'react';
import {
    Button,
    OverlayTrigger,
    Tooltip,
} from 'react-bootstrap';
import moment from "moment";

import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';



class GordonTotal extends Component {

    componentWillMount() {
        this.setState({ color: '#fe0400' });
        this.setState({ antal: 0 });
        this.setState({ rows: [] });
        this.setState({ sum: {} });
        this.setState({ datum: '' });
        this.setState({ stats: { precision: 0 } });
        return {};
    }

    componentDidMount() {

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            var color = '#fe0400';

            var url = process.env.REACT_APP_GORDON_API_URL + '/order/statsallyesterday';
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    var stats = responseJson;

                    var totalprecisionlastweekurl = process.env.REACT_APP_GORDON_API_URL + '/order/totalprecisionlastweek';
                    fetch(totalprecisionlastweekurl, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + access_token,
                        },
                    })
                        .then((response) => response.json())
                        .then((rj) => {
                            var precisionlastweek = rj;

                            var dataJson = [];
                            for (let j = 0; j < precisionlastweek.length; j++) {

                                dataJson.push({ "Name" : precisionlastweek[j].Day,
                                "Precision": Math.round(precisionlastweek[j].LeveransPrecision),
                                 "Antal": precisionlastweek[j].Antal })
                            }

                            this.setState({ rows: dataJson });

                            var url = process.env.REACT_APP_GORDON_API_URL + '/order/summaryprecisionlastweek';
                            fetch(url, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    Authorization: 'Bearer ' + access_token,
                                },
                            })
                                .then((response) => response.json())
                                .then((responseJson) => {
                                    var summaryprecisionlastweek = responseJson;

                                    let dataJson2 = [];
                                    for (let j = 0; j < summaryprecisionlastweek.length; j++) {

                                        let item = {"Name" : summaryprecisionlastweek[j].Week,
                                            "Precision": Math.round(summaryprecisionlastweek[j].LeveransPrecision),
                                            "Antal": summaryprecisionlastweek[j].Antal
                                        };

                                        if (summaryprecisionlastweek[j].averagerating != null) {
                                            item.Rating = summaryprecisionlastweek[j].averagerating;
                                        }

                                        dataJson2.push(item);

                                    }

                                    for (let i = 0; i < dataJson2.length; i++) {
                                        dataJson2[i].Name = 'Vecka ' + moment(dataJson2[i].Name, "YYYY-MM-DD").week();
                                        dataJson2[i].Precision = Math.round(dataJson2[i].Precision * 1) / 1;
                                        dataJson2[i].Rating = Math.round(dataJson2[i].Rating * 10) / 10;
                                    }
                                    this.setState({ sum: dataJson2[0] });

                                    if (!stats.precision) {
                                        stats.precision = 0
                                    }
                                    if (stats.precision > 90) {
                                        color = '#228B22';
                                    }
                                    else if (stats.precision > 70) {
                                        color = '#FFA500';
                                    }

                                    this.setState({ color: color, stats: stats });
                                    this.setState({ antal: (stats.antal == null ? 0 : stats.antal) });

                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            console.error("401 Unauthorized");
        }
    }

    render() {

        let rader = this.state.rows;
        let optionItems = rader.map((f) =>
            <div className="precisionitem col-sm-2 col-lg-2 col-md-2">

                <div className="caption">
                    <p className="precisionitemlargetext"><strong>{f.Precision}%</strong></p>
                    <p className="precisionitemmediumtext">{f.Antal} ordrar</p>
                    <p>{f.Name}</p>
                </div>
            </div>
        );

        const tooltip = (
            <Tooltip id="tooltip">
                <strong>{this.state.sum != null ? this.state.sum.AntalRating : ''} st</strong>
            </Tooltip>
        );


        return (
            <div className="reddiv">
                <div className="dashboardleft">

                    <h5>Leveransprecision igår - {this.state.datum}</h5>
                    <div className="circleDiv">
                        <CircularProgressbar percentage={this.state.stats.precision} text={`${this.state.stats.precision}%`}
                            background
                            backgroundPadding={6}
                            styles={{
                                background: {
                                    fill: this.state.color,
                                },
                                text: {
                                    fill: '#fff',
                                },
                                path: {
                                    stroke: '#fff',
                                },
                                trail: { stroke: 'transparent' },
                            }}
                        />
                    </div>

                </div>

                <div className="container precisioncontainer">
                    <div className="row">

                        <div className="precisionitemweek col-sm-2 col-lg-2 col-md-2">
                            <div className="caption">
                                <p className="precisionitemlargetext"><strong>{this.state.sum != null ? this.state.sum.Precision : '-'}%
                                <OverlayTrigger placement="right" overlay={tooltip}>
                                        <span>{this.state.sum != null ? this.state.sum.Rating : '-'}/5</span>
                                    </OverlayTrigger>
                                </strong></p>
                                <p className="precisionitemmediumtext">{this.state.sum != null ? this.state.sum.Antal : '-'} ordrar</p>
                                <p>{this.state.sum != null ? this.state.sum.Name : ''}</p>
                            </div>
                        </div>

                        {optionItems}
                    </div>
                </div>

                <div className="dashboardtopright">
                    <p><strong>Totalt antal: {this.state.antal} st</strong></p>
                </div>

                <div className="dashboardtoprightbelow">
                    <Button href="precision"
                        bsStyle="primary"
                        className="btn-margin"
                    >
                        Mer...
</Button>
                </div>
            </div>
        );
    }
}

export default GordonTotal;