import React, { Component } from 'react';
import { Button, Badge } from 'react-bootstrap';

class ShortCuts extends Component {

    componentWillMount() {
        this.setState({ count: 0 });
    }

    render() {


        return (
            <div className="panel panel-default">

                <div className="panel-heading">Genvägar:</div>
                <div className="panel-body">
                    <div className="row overview">
                        <div className="col-md-3 user-pad text-center">
                            <Button className="btn-margin" bsStyle="danger" href="latestart"><i className="fas fa-clock-o "></i>&nbsp;&nbsp;Sen till pass&nbsp;&nbsp;
                            {
                                    this.state.count > 0 && (
                                        <Badge variant="warning"> {this.state.count}</Badge>
                                    )}</Button>
                        </div>
                        <div className="col-md-3 user-pad text-center">
                            <Button className="btn-margin" bsStyle="info" href="eventlog"><i className="fas fa-info-circle"></i>  Händelselogg</Button>
                        </div>
                        <div className="col-md-3 user-pad text-center">
                            <Button className="btn-margin" bsStyle="warning" href="fleetmanagement"><i className="fas fa-unlock"></i>  Fordonshantering</Button>
                        </div>
                        <div className="col-md-3 user-pad text-center">
                            <Button className="btn-margin" bsStyle="success" href="drivers"><i className="fas fa-users"></i>  Drivers</Button>
                        </div>
                    </div>
                </div>
            </div >
        );
    }
}

export default ShortCuts;