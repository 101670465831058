import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Gauge from 'react-svg-gauge';

import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
class IcaStop extends Component {

    componentWillMount() {
        this.setState({ color: '#fe0400' });
        this.setState({ Datum: '' });
        this.setState({ Antal: 0 });
        this.setState({ LeveransPrecision: 0 });
    }

    componentDidMount() {

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            var url = process.env.REACT_APP_GORDON_API_URL + '/order/stats?merchant=' + 'ICA Supermarket STOP Täby';
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    var summary = responseJson;

                    var color = '#fe0400';
                    if (summary.precision > 90) {
                        color = '#228B22';
                    }
                    else if (summary.precision > 70) {
                        color = '#FFA500';
                    }

                    this.setState({ color: color });

                    this.setState({ Datum: summary.datum });
                    this.setState({ Antal: summary.antal });
                    this.setState({ LeveransPrecision: summary.precision != null ? summary.precision : 0 });

                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            console.error("401 Unauthorized");
        }
    }

    _buildLinkHref() {
        return '/delivery?merchant=ICAT&date=' + this.state.Datum;
    }

    render() {

        return (
            <div>
                <div className="dashboardleft">
                    <h5>ICA Supermarket STOP Täby - {this.state.Datum}</h5>
                   
                   <div className="smallcircleDiv">
         <CircularProgressbar percentage={this.state.LeveransPrecision} 
                       text={`${this.state.LeveransPrecision}%`}  
                        strokeWidth={5}
                       // You can override styles either by specifying this "styles" prop,
                       // or by overriding the default CSS here:
                       // https://github.com/iqnivek/react-circular-progressbar/blob/master/src/styles.css
                       styles={{
                         // Customize the root svg element
                         root: {},
                         // Customize the path, i.e. the part that's "complete"
                         path: {
                           // Tweak path color:
                           stroke: this.state.color,
                           // Tweak path to use flat or rounded ends:
                           strokeLinecap: 'butt',
                           // Tweak transition animation:
                           transition: 'stroke-dashoffset 0.5s ease 0s',
                         },
                         // Customize the circle behind the path
                         trail: {
                           // Tweak the trail color:
                           stroke: '#d6d6d6',
                         },
                         // Customize the text
                         text: {
                           // Tweak text color:
                           fill: this.state.color,
                           // Tweak text size:
                           fontSize: '30px',
                         },
                       }}
                     />
                   </div>
                   <div className="dashboardright">
                   <Button href={this._buildLinkHref()}
                       bsStyle="primary"
                       className="btn-margin"
                   >
                       Detaljer...
</Button>



               </div>
               <div className="dashboardtoprightsmall">
                   <p><strong>{this.state.Antal} st</strong></p>
               </div>
               </div>
            </div>
        );
    }
}

export default IcaStop;