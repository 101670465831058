import ReactDOM from 'react-dom';
import React from 'react';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import { Router, Route } from 'react-router-dom';
import history from './history';
import Auth from './Auth/Auth';
import App from './App';

const auth = new Auth();

ReactDOM.render(
  <Router history={history}>
    <Route path="/" render={(props) => <App auth={auth} {...props} /> } />
  </Router>,
  document.getElementById('root')
);

registerServiceWorker();
