import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import _ from "lodash";
import { SyncLoader } from "react-spinners";
import matchSorter from "match-sorter";
import { Button, Modal, FormControl } from "react-bootstrap";

var teams;

class Gordoneers extends Component {
  login() {
    this.props.auth.login();
  }

  componentWillMount() {
    this.setState({
      showModal: false
    });
    this.setState({ gordoneerapp: false });
    this.setState({ rows: [] });
    this.setState({ originalrows: [] });
    this.setState({
      isLoading: true
    });
    this.toggleGordoneerChange = this.toggleGordoneerChange.bind(this);

    this.createDriver = this.createDriver.bind(this);
  }

  open() {
    this.setState({
      showModal: true
    });
  }
  cancel() {
    this.setState({
      showModal: false,
      gordoneerapp: false
    });
  }
  componentDidMount() {
    this.getGordoneers();
  }
  hasUpperCase(str) {
    return str.toLowerCase() != str;
  }
  hasLowerCase(str) {
    return str.toUpperCase() != str;
  }
  hasNumber(myString) {
    return /\d/.test(myString);
  }
  hasAlphabetical(myString) {
    if (myString.match(/[a-z]/i)) {
      return true;
    } else {
      return false;
    }
  }
  toggleGordoneerChange() {
    this.setState({
      gordoneerapp: !this.state.gordoneerapp
    });
  }

  getGordoneers() {
    let rows = [];

    var access_token = localStorage.getItem("access_token");
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + "/users", {
        method: "get",
        headers: {
          Authorization: "Bearer " + access_token,
          "Content-Type": "application/json"
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          var numberOfRows = responseJson.length;
          for (let i = 0; i < numberOfRows; i++) {
            var row = {
              id: responseJson[i]._id,
              name: responseJson[i].fullName,
              avatar: responseJson[i].photo,
              status: responseJson[i].status
            };
            var phone = responseJson[i].phone;
            if (phone != "") {
              if (phone[0] == "0") {
                phone = phone.substring(1, phone.length);
              }
              phone = phone.replace(new RegExp(" ", "g"), "");
              phone = phone.replace(new RegExp("-", "g"), "");
              phone = phone.replace(new RegExp("/", "g"), "");

              if (phone[0] != "+") {
                phone = "+46" + phone;
              }
            }
            row.phone = phone;

            if (responseJson[i].rating != null) {
              row.average_rating =
                Math.round(
                  responseJson[i].rating.average_rating * 100
                ) / 100;
            } else {
              row.average_rating = 0;
            }
            // var prec = teams[row.name];

            // if (prec == null) {
            //   prec = 0;
            // }

            // row.leveransprecision = Math.round(prec * 100) / 100;

            // var precCurrentMonth = currentmonth[row.name];

            // if (precCurrentMonth == null) {
            //   precCurrentMonth = 0;
            // }
            // row.leveransprecisioncurrentmonth =
            //   Math.round(precCurrentMonth * 100) / 100;

            // var precPreviousMonth = previousmonth[row.name];

            // if (precPreviousMonth == null) {
            //   precPreviousMonth = 0;
            // }
            // row.leveransprecisionpreviousmonth =
            //   Math.round(precPreviousMonth * 100) / 100;

            rows.push(row);
          }
          this.rowsCount = rows.length;
          let originalRows = rows;
          let rowss = originalRows.slice(0);
          this.state.rows = rowss;
          this.state.originalRows = originalRows;
          this.setState({ rows: rows });
          this.setState({ originalRows: originalRows });

          this.setState({
            isLoading: false
          });
        })
        .catch(error => {
          console.error(error);

          this.setState({
            isLoading: false
          });
        });

    } else {
      console.error("401 Unauthorized");
    }
  }
  createDriver() {
    if (
      this.state.gordoneerapp &&
      this.password.value != null &&
      (this.password.value.length <= 8 ||
        !this.hasNumber(this.password.value) ||
        !this.hasAlphabetical(this.password.value) ||
        !this.hasUpperCase(this.password.value) ||
        !this.hasLowerCase(this.password.value))
    ) {
      alert(
        "Lösenordet måste innehålla både små och stora bokstäver, och siffror, och vara minst 8 tecken långt."
      );
      return;
    }
    if (
      this.fullName.value.length == 0 ||
      this.email.value.length == 0 ||
      this.phone.value.length == 0 ||
      this.timezynkId.value.length == 0
    ) {
      alert(
        "Namn, e-post, telefonnummer och TimeZynk-ID måste anges!"
      );
      return;
    }
    if (this.email.value.indexOf("@") < 0) {
      alert("Det måste vara en giltig e-post adress!");
      return;
    }
    var phone = this.phone.value;
    if (phone != "") {
      if (phone[0] == "0") {
        phone = phone.substring(1, phone.length);
      }
      phone = phone.replace(new RegExp(" ", "g"), "");
      phone = phone.replace(new RegExp("-", "g"), "");
      phone = phone.replace(new RegExp("/", "g"), "");

      if (phone[0] != "+") {
        phone = "+46" + phone;
      }
    }
    var postbody = {
      fullName: this.fullName.value,
      email: this.email.value,
      phone: phone,
      // photo: this.photo.value,
      // bringgId: this.bringgId.value,
      hubspotId: this.timezynkId.value,
      timezynkId: this.timezynkId.value,
      status: "Active",
      password: this.password.value,
      gordoneerapp: this.state.gordoneerapp
    };
    var access_token = localStorage.getItem("access_token");
    if (access_token != null) {
      fetch(process.env.REACT_APP_GORDON_API_URL + "/driver", {
        method: "POST",
        body: JSON.stringify(postbody),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token
        }
      })
        .then(response => {
          var res = response;
          this.setState({
            showModal: false
          });
          this.setState({
            gordoneerapp: false
          });
          this.getGordoneers();
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }
  

  hideModal() {
    this.setState({ show: false });
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    const passworddisabled = !this.state.gordoneerapp;

    const columns = [
      {
        Header: "ID",
        accessor: "id",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: [ "id"] }),
        filterAll: true,
        Cell: row =><a href={"/driver?id=" + row.value}>View</a>
      },
      {
        Header: "Name",
        accessor: "name",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["name"] }),
        filterAll: true
      },
      {
        Header: "Phone",
        accessor: "phone",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["phone"] }),
        filterAll: true
      }
    ];

    var divStyle = {
      display: this.state.isLoading ? "block" : "none"
    };

    return (
      <div className="container">
        {isAuthenticated() && (
          <div>
            <h3>Drivers</h3>
            <div className="cover" style={divStyle}>
              <div className="covercontent">
                <SyncLoader color={"#174b5F"} loading={this.state.isLoading} />
              </div>
            </div>

            <div className="gordoneer">
              {/* <Button
                bsStyle="primary"
                className="btn-margin btn-padding"
                onClick={this.open.bind(this)}
              >
                Lägg till Gordoneer...
              </Button> */}

              <br />
              <br />
              <ReactTable
                filterable
                data={this.state.rows}
                defaultSorted={[
                  {
                    id: "name",
                    desc: false
                  }
                ]}
                defaultFilterMethod={(filter, row) =>
                  String(row[filter.id]) === filter.value
                }
                columns={columns}
                pageSize={this.state.pageSize}
                defaultPageSize={50}
              />
              <Modal
                bsSize="large"
                show={this.state.showModal}
                onHide={this.cancel.bind(this)}
                dimmer={"blurring"}
              >
                <Modal.Header>
                  <Modal.Title>Ny Driver</Modal.Title>{" "}
                </Modal.Header>
                <Modal.Body>
                  <p>Namn (för- och efternamn) *:</p>
                  <FormControl
                    inputRef={ref => {
                      this.fullName = ref;
                    }}
                    placeholder="Namn"
                  />
                  <br />
                  <p>E-post *:</p>
                  <FormControl
                    inputRef={ref => {
                      this.email = ref;
                    }}
                    placeholder="E-post"
                  />
                  <br />
                  <p>Mobiltelefon *:</p>
                  <FormControl
                    inputRef={ref => {
                      this.phone = ref;
                    }}
                    placeholder="Ange mobiltelefon med +46"
                  />
                  <br />
                  {/* <p>Hubspot ID:</p>
            <FormControl type="number" inputRef={
              (ref) => {
                this.hubspotId = ref
              }
            } placeholder='Hubspot ID' />
            <br /> */}
                  {/* <p>BringgId ID:</p>
                  <FormControl
                    type="number"
                    inputRef={ref => {
                      this.bringgId = ref;
                    }}
                    placeholder="Bringg ID"
                  />
                  <br /> */}
                  <p>TimeZynk ID *:</p>
                  <img src="images/timezynk1.png" width="500" />
                  <FormControl
                    inputRef={ref => {
                      this.timezynkId = ref;
                    }}
                    placeholder="TimeZynk ID"
                  />
                  <br />
               
                  {/* <p>Bild url:</p>
                  <FormControl
                    type="number"
                    inputRef={ref => {
                      this.photo = ref;
                    }}
                    placeholder="Url till bild"
                  />
                  <br /> */}
                  <p>
                    <input
                      type="checkbox"
                      checked={this.state.gordoneerapp}
                      onChange={this.toggleGordoneerChange}
                    />
                    &nbsp;&nbsp;
                    <span onClick={this.toggleGordoneerChange}>
                      Lägg till Lås-app
                    </span>
                  </p>
                  <br />
                  <p>Ange lösenord:</p>
                  <FormControl
                    type="password"
                    disabled={passworddisabled}
                    inputRef={ref => {
                      this.password = ref;
                    }}
                    placeholder="Lösenordet måste innehålla både små och stora bokstäver, och siffror, och vara minst 8 tecken långt"
                  />
                </Modal.Body>
                <Modal.Footer>
                  <Button negative onClick={this.cancel.bind(this)}>
                    Avbryt
                  </Button>
                  <Button
                    positive
                    bsStyle="default"
                    onClick={this.createDriver.bind(this)}
                  >
                    Spara
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        )}
        {!isAuthenticated() && (
          <h4>
            You're not logged in! Click{" "}
            <a style={{ cursor: "pointer" }} onClick={this.login.bind(this)}>
              Login
            </a>{" "}
            to continue.
          </h4>
        )}
      </div>
    );
  }
}

export default Gordoneers;
