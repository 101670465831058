import React, { Component } from 'react';
import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

class Fleet extends Component {

    componentWillMount() {
        this.setState({ color: '#fe0400' });
        this.setState({ onthemove: 0 });
        this.setState({ count: 0 });
        this.setState({ outofservice: 0 });
        this.setState({ efficency: 0 });
        return {};
    }

    componentDidMount() {

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            var url = process.env.REACT_APP_GORDON_API_URL + '/metroteclog/activefleet';
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    var stats = responseJson;
                    var active = stats.count;
                    this.state.count = stats.count;
                    this.setState({ count: stats.count });

                    var url = process.env.REACT_APP_GORDON_API_URL + '/vehicle/onthemove';
                    fetch(url, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + access_token,
                        },
                    })
                        .then((response) => response.json())
                        .then((responseJson) => {
                            var stats = responseJson;
                            var onthemove = stats.count;
                            this.state.onthemove = stats.count;
                            this.setState({ onthemove: stats.count });


                            var url = process.env.REACT_APP_GORDON_API_URL + '/order/vehiclesoutofservice';
                            fetch(url, {
                                method: 'GET',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    Authorization: 'Bearer ' + access_token,
                                },
                            })
                                .then((response) => response.json())
                                .then((responseJson) => {
                                    var stats = responseJson;
                                    this.state.outofservice = stats.count;
                                    this.setState({ outofservice: stats.count });

                                    this.setState({ efficency: Math.round((onthemove / active) * 100) });

                                })
                                .catch((error) => {
                                    console.error(error);
                                });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            console.error("401 Unauthorized");
        }
    }

    render() {
        return (
            <div>

                <h5>Gordon Fleet</h5>

                <div className="row overview">
                    <div className="col-md-4 user-pad text-center fleettopright">
                        <CircularProgressbar percentage={25} text={`${25}%`}

                        />
                        <p>Nyttjandegrad (april)</p>
                    </div>

                    <div className="col-md-4 user-pad text-center fleettopright">
                        <CircularProgressbar percentage={this.state.efficency} text={`${this.state.efficency}%`}

                        />
                        <p>Aktuell nyttjandegrad</p>
                    </div>
                </div>



            </div>
        );
    }
}

export default Fleet;