import {
    BrowserRouter as Router,
    Route,
    Link
} from 'react-router-dom';
import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/sv';

class TotalComplaintsLastMonth extends Component {

    componentWillMount() {

        this.setState({ period: '' });

        this.setState({ reklamationer: '' });
        return { reklamationer: '', period: '' };
    }

    componentDidMount() {
        moment.locale('sv');

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            fetch(process.env.REACT_APP_GORDON_API_URL + '/complaints/allprevious/', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => response.json())
                .then((dataJson) => {

                    this.state.reklamationer = dataJson.length;
                    this.setState({ reklamationer: dataJson.length });

                    var period = this.capitalizeFirstLetter(moment().add(-1, 'M').format("MMMM YYYY"));
                    this.state.period = period;
                    this.setState({ period: period });

                })
                .catch((error) => {
                    console.error(error);
                });


        } else {
            console.error("401 Unauthorized");
        }
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    render() {

        return (
            <div>
                <div className="dashboardreklamationleft">
                    <img src="images/complaint.png" width="80" />
                </div>
                <div className="dashboardreklamationerright">
                    <span><strong>Reklamationer</strong></span>
                    <h3>{this.state.reklamationer} st</h3>
                    {this.state.period}
                </div>
            </div>

        );
    }
}

export default TotalComplaintsLastMonth;