import React from 'react';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import Switch from 'react-switch';

import { getStatusColor } from '../common/getStatusColor'

import './search.css';


class Search extends React.Component {
    constructor(props) {
        super(props);

        this.state = {

            searchString: '',
            searchResult: [],
            pages: '',
            activePage: '',
            totalHits: 0,
            pageSize: 20,
            orderBy: '',
            advancedSearch: false,
            loading: false,
            displayMaps: false,

        }

        this.renderResult = this.renderResult.bind(this);
        this.setPageSize = this.setPageSize.bind(this);
        this.find = this.find.bind(this);
        this.setSearchString = this.setSearchString.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
        this.renderPagination = this.renderPagination.bind(this);
        this.setActivePage = this.setActivePage.bind(this)
        this.toggleMaps = this.toggleMaps.bind(this);
    }

    componentDidMount() {
        let locationState = { ...this.props.location.state };

        if (locationState) {
            this.setState({
                ...this.state,
                searchResult: locationState.result || [],
                searchString: locationState.searchString || '',
                totalHits: locationState.total || '',
                activePage: locationState.page || 1,
                pageSize: locationState.pageSize || 20,
                loading: locationState.loading || false
            });
        }
    }


    find(searchString, page, pageSize, showFirstPage) {

        this.setState({ ...this.state, loading: true });

        let access_token = localStorage.getItem('access_token');

        fetch(process.env.REACT_APP_GORDON_API_URL + "/order/searchString", {
            method: 'POST',
            body: JSON.stringify({
                'searchString': searchString,
                'page': page,
                'pageSize': pageSize
            }),
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + access_token,
            },
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.status === "ok") {
                    if (page > Math.ceil(result.total / result.pageSize)) {
                        page = Math.ceil(result.total / result.pageSize);
                    }
                    if (showFirstPage !== undefined) {
                        page = 1;
                    }

                    this.props.history.replace({
                        pathname: '/search',
                        state: {
                            result: result.orders,
                            searchString: searchString,
                            total: result.total,
                            page: page,
                            pageSize: pageSize,
                            loading: false
                        }
                    });
                }

            })
            .catch((err) => {
                this.setState({ loading: false })
                console.log(err)
            })
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (JSON.stringify(nextProps.location.state) !== JSON.stringify(this.props.location.state) || (JSON.stringify(nextState) !== JSON.stringify(this.state))) {
            return true;
        } else {
            return false;
        }
    }


    componentWillReceiveProps(nextProps) {
        let locationState = { ...nextProps.location.state };

        if (JSON.stringify(this.props.location.state) !== JSON.stringify(nextProps.location.state) || this.state.loading !== locationState.loading) {
            this.setState({
                ...this.state,
                searchResult: locationState.result || [],
                searchString: locationState.searchString || '',
                totalHits: locationState.total || 0,
                pageSize: locationState.pageSize || 20,
                activePage: locationState.page || 1,
                loading: locationState.loading

            });
        }
    }


    setPageSize(size) {
        let page = this.state.activePage;

        if (page > Math.ceil(this.state.totalHits / size)) {
            page = Math.ceil(this.state.totalHits / size)
        }

        this.find(this.state.searchString, page, size);
    }

    setActivePage(page) {
        this.setState({ ...this.state, activePage: page }, () => {
            this.find(this.state.searchString, page, this.state.pageSize)

        });
    }

    setSearchString(e) {
        this.setState({ ...this.state, searchString: e.target.value });
    }

    toggleMaps() {
        this.setState({ ...this.state, displayMaps: !this.state.displayMaps });
    }

    handleKeyPress(e) {
        if (e.key === 'Enter') {
            this.find(this.state.searchString, this.state.activePage, this.state.pageSize, true);
        }
    }
    

    renderResult() {
        let { searchResult } = this.state;
        let displayResult = [];

        if (searchResult.length < 1) {
            return (<div style={{ marginBottom: "20px" }}></div>)
        }

        displayResult = searchResult.map((o) => {
            let orderInfo = o && o.orderInfo;
            let latitude = o.deliveryAddress && o.deliveryAddress.latitude;
            let longitude = o.deliveryAddress && o.deliveryAddress.longitude;
            let imgUrl = '';


            if (latitude && longitude) {
                imgUrl = "https://maps.googleapis.com/maps/api/staticmap?center=" + latitude + "," + longitude + "&markers=color:0x174b5f%7Clabel:G%7C" + latitude + "," + longitude + "&zoom=14&size=400x120&key=" + process.env.REACT_APP_GOOGLE_MAPS_STATIC_API_KEY;
            } else if (o.deliveryAddress && (o.deliveryAddress.streetAddress && o.deliveryAddress.city)) {
                let address = o.deliveryAddress.streetAddress + "+" + o.deliveryAddress.city
                imgUrl = "https://maps.googleapis.com/maps/api/staticmap?center=" + address + "&markers=color:0x174b5f%7Clabel:G%7C" + address + "&zoom=14&size=400x120&key=" + process.env.REACT_APP_GOOGLE_MAPS_STATIC_API_KEY;
            }


            return (
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3" style={{ margin: "15px 0", color: "#373a47", height: "auto" }}>
                    <div className="search-result-card" style={{ padding: "5px" }}>
                        <div style={{ margin: "5px 0" }}>
                            <span className="label label-default" style={{ backgroundColor: "#0b252e", fontSize: "12px", margin: "0 2.5px" }} >
                                {new moment(o.deliveryDate).format('YYYY-MM-DD')}
                            </span>
                            <span style={{ fontSize: "12px", margin: "0 2.5px", backgroundColor: getStatusColor(orderInfo.status) }} className="label label-default">{orderInfo.status}</span>
                        </div>

                        {this.state.displayMaps ? (
                            <div style={{ width: '100%', height: 'auto', position: "relative" }}>
                                <img style={{ width: "inherit", height: "inherit" }} src={imgUrl} />
                            </div>
                        ) : ''}


                        <div style={{ padding: "5px 5px", height: "110px" }}>

                            <div style={{ fontWeight: "bold", color: "#174b5f" }}>
                                {o.deliveryAddress.fullName ? o.deliveryAddress.fullName : ''}
                            </div>
                            <div style={{ fontSize: "12px" }}>
                                {o.deliveryAddress.streetAddress ? o.deliveryAddress.streetAddress : ''}
                            </div>
                            {o.deliveryAddress.streetAddress2 && (
                                <div style={{ fontSize: "12px" }}>
                                    {o.deliveryAddress.streetAddress2}
                                </div>
                            )}
                            <div style={{ fontSize: "12px" }}>
                                {o.deliveryAddress.postalCode && o.deliveryAddress.city ? o.deliveryAddress.postalCode + ', ' + o.deliveryAddress.city : ''}
                            </div>
                            <div style={{ paddingTop: "5px" }}>
                                <Link to={'/orderdetails?id=' + o.internal_id} >visa order</Link>
                            </div>
                            <div style={{ paddingTop: "5px" }}>
                                <Link to={'/routedorders?routename=' + o.orderInfo.routeName} >visa rutt</Link>
                            </div>

                        </div>
                        <div style={{ padding: "5px", width: "100%", height: "45px" }} >
                            <div className="pull-right" style={{ fontWeight: "bold", color: "#ob252e" }}>{o.pickupInfo.contactInfo.name}</div>
                            <div className="pull-left">
                                <div>Extern ref: </div>

                            </div>
                            <div style={{ width: "100%", clear: "both" }}>
                                {o.external_ref}
                            </div>
                        </div>
                    </div>
                </div>)
        });

        return displayResult;
    }

    renderPagination() {
        let totalPages = Math.ceil(this.state.totalHits / this.state.pageSize);
        let activePage = this.state.activePage;
        let maxPages = 5;

        let items = [];
        let startPage;
        let endPage;

        if (activePage < 1) {
            activePage = 1;
        } else if (activePage > totalPages) {
            activePage = totalPages;
        }

        if (totalPages <= maxPages) {
            startPage = 1;
            endPage = totalPages
        } else {
            // total pages more than max so calculate start and end pages
            let maxPagesBeforeCurrentPage = Math.floor(maxPages / 2);
            let maxPagesAfterCurrentPage = Math.ceil(maxPages / 2) - 1;
            if (activePage <= maxPagesBeforeCurrentPage) {
                // current page near the start
                startPage = 1;
                endPage = maxPages;
            } else if (activePage + maxPagesAfterCurrentPage >= totalPages) {
                // current page near the end
                startPage = totalPages - maxPages + 1;
                endPage = totalPages;
            } else {
                // current page somewhere in the middle
                startPage = activePage - maxPagesBeforeCurrentPage;
                endPage = activePage + maxPagesAfterCurrentPage;
            }
        }
        // calculate start and end item indexes
        let startIndex = (activePage - 1) * this.state.pageSize;
        let endIndex = Math.min(startIndex + this.state.pageSize - 1, totalPages - 1);

        // create an array of pages to ng-repeat in the pager control
        let pages = Array.from(Array((endPage + 1) - startPage).keys()).map(i => startPage + i);


        return (
            <nav aria-label="Page navigation" >
                <ul className="pagination" style={{ display: "inline", cursor: 'pointer' }}>
                    <li onClick={activePage > 1 ? () => this.setActivePage(1) : null}>
                        <a aria-label="Previous" ><span aria-hidden="true">&laquo;</span></a>
                    </li>

                    {pages.map((p) => <li key={p} onClick={() => this.setActivePage(p)} className={p === this.state.activePage ? 'active' : ''}><a>{p}</a></li>)}

                    <li onClick={activePage < totalPages ? () => this.setActivePage(totalPages) : null}>
                        <a aria-label="Next" ><span aria-hidden="true">&raquo;</span></a>
                    </li>
                </ul>
            </nav>)
    }



    render() {
        const { isAuthenticated } = this.props.auth;

        if (!isAuthenticated()) {
            this.props.history.push('/signin')
        }
        return (
            <div className="container">
                <div className="row">
                    <div className="col-xs-12" id="searchInput" style={{ marginTop: "100px", marginBottom: "100px" }}>
                        <div style={{ border: "1px solid grey", width: "350px", margin: "auto", borderRadius: "15px" }}>
                            <input id="searchInput" type="text" value={this.state.searchString} onKeyDown={this.handleKeyPress} onChange={this.setSearchString} placeholder="Sök order..." style={{ margin: "0 10px", width: "calc(100% - 45px)", border: "none", fontSize: "16px" }} />
                            <span onClick={() => { this.find(this.state.searchString, this.state.activePage, this.state.pageSize, true) }}>

                                {this.state.loading ? <i className="fas fa-sync fa-spin"></i> : <i className="fas fa-search" style={{ width: "10px", textAlign: "right" }}></i>}

                            </span>
                        </div>

                        <div style={{ width: "350px", margin: "10px auto", padding: "5px 10px", height: "29px" }}>
                            {this.state.searchResult.length > 0 ? 'Sökningen genererade  totalt ' + this.state.totalHits + ' träffar' : <span> </span>}
                        </div>
                    </div>
                </div>

                <div className="row">

                    <div className="col-xs-12">


                        <div style={{ paddingBottom: "10px", marginBottom: "20px", borderBottom: "1px solid #373a47" }}>
                            <div style={{ display: "inline", marginBottom: "5px" }}>


                                {this.renderPagination()}

                                <DropdownButton
                                    style={{ margin: "0 5px", borderRadius: "5px" }}
                                    title={this.state.pageSize}
                                    key={'i'}
                                    id={`dropdown-basic-i`}
                                    name="pagnationSize"
                                >
                                    <MenuItem onSelect={this.setPageSize} eventKey={20} active={this.state.pagnationSize === 20 ? true : false}>20</MenuItem>
                                    <MenuItem onSelect={this.setPageSize} eventKey={40} active={this.state.pagnationSize === 40 ? true : false}>40</MenuItem>
                                    <MenuItem onSelect={this.setPageSize} eventKey={60} active={this.state.pagnationSize === 60 ? true : false}>60</MenuItem>
                                </DropdownButton>
                                <label style={{ marginRight: "10px", fontWeight: "normal" }}> per sida</label>
                            </div>
                            <label style={{ fontWeight: "normal" }}>
                                <Switch
                                    onChange={this.toggleMaps}
                                    checked={this.state.displayMaps}
                                    className="react-switch"
                                />
                                <span>Show mini-maps</span>
                            </label>
                        </div>
                    </div>

                    {this.renderResult()}

                </div>
            </div>)
    }
}


export default GoogleApiWrapper({
    apiKey: 'AIzaSyBTbP0T250T18ulE63WcbgXumSU8SNZpPQ'
})(Search);