import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import React, { Component } from 'react';
import { Panel, ControlLabel, Glyphicon } from 'react-bootstrap';
import './myprofile.css';

class MyProfile extends Component {
  login() {
    this.props.auth.login();
  }
  componentWillMount() {
    this.setState({ profile: {} });
    const { userProfile, getProfile } = this.props.auth;
    if (!userProfile) {
      getProfile((err, profile) => {
        this.setState({ profile });
      });
    } else {
      this.setState({ profile: userProfile });
    }
  }
  render() {
    const { isAuthenticated } = this.props.auth;
    const { profile } = this.state;
    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>{profile.name}</h3>
              <Panel header="Profil">
                <img src={profile.picture} alt="profile" />
                <div>
                  <ControlLabel>Nickname:</ControlLabel>
                  <h3>{profile.nickname}</h3>
                </div>
                <pre>{JSON.stringify(profile, null, 2)}</pre>
              </Panel>
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default MyProfile;

