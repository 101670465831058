import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Form from "react-jsonschema-form";

const queryString = require('query-string');

class FleetInformation extends Component {

  componentWillMount() {
  
  }

  render() {
    
    const schema = {
      title: "Fordonsinformation",
      type: "object",
        properties: {
          matarstallning: {
            title: "Mätarställning",
            type: "number",
          }
        },
    };

    const log = (type) => console.log.bind(console, type);

    return (
      <div className="container">
             <div>
              
              <Form schema={schema}
               liveValidate={true}
                onChange={log("changed")}
                onSubmit={log("submitted")}
                onError={log("errors")} />
            </div>
    
      </div>

    );
  }
}

export default FleetInformation;

