
import { sendRequest } from './apiHelpers';


const API_ENDPOINT = process.env.REACT_APP_GORDON_API_URL;

/**
 * 
 * @param {String} internal_id   From Order.internal_id
 * @param {Function} callback   Invoked with (err, resault)
 */

export function gerOrderById(internal_id, callback) {
    
    if (!internal_id) {
        return callback('Missing required param (internal_id)');
    }

    let url = API_ENDPOINT + '/route/orders/' + internal_id;
    let options = {
        method: 'GET'
    }

    sendRequest(url, options, callback);
}

/**
 * 
 * @param {String} internal_id  from Order.internal_id
 * @param {Function} callback   Invoked with (err, result)
 */

export function getOrderLog(internal_id, callback) {

    if(!internal_id){
        return callback('Missing required param (internal_id)');
    }

    let url = API_ENDPOINT + '/route/orderlog/' + internal_id;
    let options = {
        method: 'GET'
    }

    sendRequest(url, options, callback);
}

