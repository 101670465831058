import React, { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button, Modal, FormControl } from "react-bootstrap";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from "react-places-autocomplete";
import DatePicker from "react-16-bootstrap-date-picker";
import TimePicker from "react-bootstrap-time-picker";
import moment from "moment";
import "moment/locale/sv";
import { Menu, Item, Separator, Submenu, MenuProvider } from "react-contexify";
import Switch from "react-switch";
import Workbook from 'react-excel-workbook';
import "react-contexify/dist/ReactContexify.min.css";
const queryString = require("query-string");

class RoutedOrders extends Component {
  constructor() {
    super();
    this.state = {
      geocodeResults: null
    };
    this.handleTimeChangeStart = this.handleTimeChangeStart.bind(this);
    this.handleTimeChangeEnd = this.handleTimeChangeEnd.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.renderGeocodeFailure = this.renderGeocodeFailure.bind(this);
    this.renderGeocodeSuccess = this.renderGeocodeSuccess.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.addItem = this.addItem.bind(this);
  }
  login() {
    this.props.auth.login();
  }
  handleTimeChangeStart(starttime) {
    console.log(starttime); // <- prints "3600" if "01:00" is picked
    this.setState({ starttime: starttime });
  }
  handleTimeChangeEnd(endtime) {
    console.log(endtime); // <- prints "3600" if "01:00" is picked
    this.setState({ endtime: endtime });
  }
  componentWillMount() {
    this.setState({ starttime: 54000 });
    this.setState({ endtime: 79200 });
    this.setState({ cartItems: [] });
    this.setState({ data: [] });
    this.setState({ group: "" });
    this.setState({
      formattedValue: moment()
        .add(1, "days")
        .format("YYYY-MM-DD")
    });
    this.setState({ value: new Date(moment().add(1, "days")).toISOString() });
    this.setState({
      showError: false,
      showErrorModal: false,
      showOrderModal: false,
      showRedelivery: false
    });
    this.setState({
      isFakturerbar: true,
      dispatchDirect: false
    });
    this.setState({
      routename: "",
      sheetname: ""
    });
    return { data: [] };
  }

  addItem() {
    var exists = false;

    for (let i = 0; i < this.state.cartItems.length; i++) {
      if (
        this.state.cartItems[i].value === this.state.kolli
      ) {
        exists = true;
        this.state.cartItems[i].count = this.state.cartItems[i].count + this.state.count;
      }
    }
    if (!exists) {
      this.state.cartItems.unshift({
        index: this.state.cartItems.length + 1,
        value: this.state.kolli,
        count: this.state.count,
      });
    }
    this.setState({ cartItems: this.state.cartItems, count: null, kolli: null });
  }

  removeItem(itemIndex) {
    var itemToRemove = this.state.cartItems[itemIndex];

    if (itemToRemove.count > 1) {
      itemToRemove.count = itemToRemove.count - 1;
    } else {
      this.state.cartItems.splice(itemIndex, 1);
    }
    this.setState({ cartItems: this.state.cartItems });
  }

  getOrders(merchant) {
    var parsed = queryString.parse(this.props.location.search);
    if (parsed.routename != null) {
      var routename = parsed.routename;
      var sheetname = routename;
      if (sheetname.length > 30) {
        sheetname = sheetname.substring(0, 30);
      }

      var access_token = localStorage.getItem("access_token");
      if (access_token != null) {
        this.setState({
          routename: routename,
          sheetname: sheetname
        });
        fetch(
          process.env.REACT_APP_GORDON_API_URL +
          "/route/orders?route=" +
          routename +
          "&merchant=" +
          merchant,
          {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + access_token
            }
          }
        )
          .then(response => response.json())
          .then(dataJson => {
            var data = dataJson;
            for (let i = 0; i < data.length; i++) {
              data[i].OrderId = data[i].external_ref;

              data[i].CustomerName = data[i].deliveryAddress.fullName;
              data[i].CustomerPhone = data[i].deliveryContactInformation != null ? data[i].deliveryContactInformation.mobilePhone : '';

              data[i].Address = data[i].deliveryAddress.streetAddress + (data[i].deliveryAddress.postalCode != null ? ", " + data[i].deliveryAddress.postalCode + " " + data[i].deliveryAddress.city : "");
              var timewindow =
                moment(data[i].orderInfo.noEarlierThan).format("HH:mm") +
                " - " +
                moment(data[i].orderInfo.noLaterThan).format("HH:mm");
              data[i].TimeWindow = timewindow;
              data[i].StopNumber = data[i].orderInfo.stopNumber;
              data[i].Merchant = data[i].pickupInfo.contactInfo.name;
              data[i].PromisedTime = moment(
                data[i].orderInfo.promisedTime
              ).format("HH:mm");
              data[i].TimeDelivered =
                data[i].orderInfo.timeDelivered != null
                  ? moment(data[i].orderInfo.timeDelivered).format("HH:mm")
                  : "";
              data[i].TimeArrived =
                data[i].orderInfo.timeArrived != null
                  ? moment(data[i].orderInfo.timeArrived).format("HH:mm")
                  : "";
              data[i].Driver = data[i].orderInfo.driver;
              data[i].Status = data[i].orderInfo.status;
              if (data[i].Status == "Done") {
                data[i].Status = "Levererad";
              }
            }

            this.setState({ data: data });
          })
          .catch(error => {
            console.error(error);
          });
      } else {
        console.error("401 Unauthorized");
      }
    }
  }

  componentDidMount() {
    var group = localStorage.getItem("access_group");
    this.setState({
      group
    });
    this.getOrders(group);
  }
  handleDateChange(value, formattedValue) {
    this.setState({
      value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
    });
  }
  editOrder(row) {
    this.setState({
      showOrderModal: true
    });
  }
  sendToBringg(row) {
    this.setState({
      isLoading: true
    });

    var access_token = localStorage.getItem("access_token");
    if (access_token != null) {
      fetch(
        process.env.REACT_APP_GORDON_API_URL +
        "/planning/dispatchorder/" +
        row.external_ref +
        "/" +
        row.Merchant,
        {
          method: "get",
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => response.json())
        .then(responseJson => {
          this.getOrders(this.state.group);
        })
        .catch(error => {
          console.error(error);
          this.setState({
            isLoading: false
          });
        });
    } else {
      console.error("401 Unauthorized");
      this.setState({
        isLoading: false
      });
    }
  }

  detailsFormatter(cell, row) {
    var url = "/orderdetails?id=" + row.internal_id;
    return (
      <div>
        <MenuProvider data={{ rowInfo: row }} id="menu_id">
          <span>
            <a href={url}>{cell}</a>
          </span>{" "}
        </MenuProvider>
      </div>
    );
  }

  dispatchFormatter(cell, row) {
    var disable =
      row.Status === "Levererad" || row.Status === "Kund var inte hemma";
    if (this.state.group == "Administrator") {
      return (
        <Button
          bsStyle="primary"
          className="btn-margin eventbtn"
          disabled={disable}
          onClick={this.sendToBringg.bind(this, row)}
        >
          Skicka till Bringg...
        </Button>
      );
    } else {
      return null;
    }
  }

  editFormatter(cell, row) {
    if (this.state.group == "Administrator") {
      return (
        <Button
          bsStyle="primary"
          className="btn-margin eventbtn"
          onClick={this.editOrder.bind(this, row)}
        >
          Öppna...
        </Button>
      );
    } else {
      return null;
    }
  }
  renderGeocodeFailure(err) {
    return (
      <div className="alert alert-danger" role="alert">
        <strong>Oops, något gick fel!</strong> Kunde inte hitta adressen, försök
        igen!
      </div>
    );
  }

  renderGeocodeSuccess(lat, lng) { }
  handleSelect(address) {
    this.setState({
      searchaddress: address,
      isLoading: true
    });

    geocodeByAddress(address)
      .then(results => {
        if (results != null && results[0]) {
          var lat = results[0].geometry.location.lat();
          var lng = results[0].geometry.location.lng();
          var street_number = null;
          var route = null;
          var zipcode = null;
          var city = null;

          results[0].address_components.forEach(component => {
            if (component.types != null && component.types.length > 0) {
              if (component.types[0] == "street_number") {
                street_number = component.long_name;
              }
              if (component.types[0] == "route") {
                route = component.long_name;
              }
              if (component.types[0] == "postal_town") {
                city = component.long_name;
              }
              if (component.types[0] == "postal_code") {
                zipcode = component.long_name;
              }
            }
          });
          this.setState({
            geocodeResults: this.renderGeocodeSuccess(lat, lng),
            errormessage: null,
            isLoading: false,
            latitude: lat,
            longitude: lng,
            customerzipcode: zipcode,
            customercity: city,
            customeraddress: route + " " + street_number
          });
        }
      })
      .catch(error => {
        console.log("Oh no!", error);
        this.setState({
          geocodeResults: this.renderGeocodeFailure(error),
          isLoading: false
        });
      });
  }

  handleChange(address) {
    this.setState({
      searchaddress: address,
      geocodeResults: null
    });
  }
  nameChanged(evt) {
    const customername = evt.target.value;
    this.setState({ customername });
  }
  phoneChanged(evt) {
    const customerphone = evt.target.value;
    this.setState({ customerphone });
  }
  addressChanged(evt) {
    const customeraddress = evt.target.value;
    this.setState({ customeraddress });
  }
  zipcodeChanged(evt) {
    const customerzipcode = evt.target.value;
    this.setState({ customerzipcode });
  }
  cityChanged(evt) {
    const customercity = evt.target.value;
    this.setState({ customercity });
  }

  emailChanged(evt) {
    const customeremail = evt.target.value;
    this.setState({ customeremail });
  }
  latitudeChanged(evt) {
    const latitude = evt.target.value;
    this.setState({ latitude });
  }
  longitudeChanged(evt) {
    const longitude = evt.target.value;
    this.setState({ longitude });
  }
  entrycodeChanged(evt) {
    const customerentrycode = evt.target.value;
    this.setState({ customerentrycode });
  }
  instructionsChanged(evt) {
    const instructions = evt.target.value;
    this.setState({ instructions });
  }
  priceChanged(evt) {
    const price = evt.target.value;
    this.setState({ price });
  }
  countChanged(evt) {
    const count = evt.target.value;
    this.setState({ count });
  }
  kolliChanged(evt) {
    const kolli = evt.target.value;
    this.setState({ kolli });
  }
  cancelRedelivery() {
    this.setState({
      selectedorder: null,
      external_ref: null,
      customername: null,
      customerphone: null,
      customeremail: null,
      customerentrycode: null,
      instructions: null,
      price: null,
      deliveryGroupZone: null,
      searchaddress: null,
      merchant: null,
      isFakturerbar: true,
      customeraddress: null,
      customerzipcode: null,
      customercity: null,
      latitude: null,
      longitude: null,
      starttime: 54000,
      endtime: 79200,
      formattedValue: moment()
        .add(1, "days")
        .format("YYYY-MM-DD"),
      value: new Date(moment().add(1, "days")).toISOString()
    });
    this.setState({
      showRedelivery: false
    });
  }
  createRedelivery() {
    var updatebody = {
      id: this.state.selectedorder,
      external_ref: this.state.external_ref,
      customername: this.state.customername,
      customerphone: this.state.customerphone,
      customeremail: this.state.customeremail,
      customeraddress: this.state.customeraddress,
      customerzipcode: this.state.customerzipcode,
      customercity: this.state.customercity,
      customerentrycode: this.state.customerentrycode,
      instructions: this.state.instructions,
      price: this.state.isFakturerbar ? this.state.price : 0,
      deliverydate: moment(this.state.formattedValue),
      starttime: this.state.starttime,
      endtime: this.state.endtime,
      merchant: this.state.merchant,
      latitude: this.state.latitude,
      longitude: this.state.longitude,
      dispatch: this.state.dispatchDirect,
      shouldInvoice : this.state.isFakturerbar 
    };

    var items = [];
    if (this.state.cartItems != null && this.state.cartItems.length > 0) {
      this.state.cartItems.forEach(cartItem => {
        if (cartItem.orderitem != null) {
          items.push(cartItem.orderitem);
        }
        else {
          items.push({ name: cartItem.value, numberOfItems: cartItem.count });
        }
      });
    }
    updatebody.items = items;

    var access_token = localStorage.getItem("access_token");
    if (access_token != null) {
      fetch(process.env.REACT_APP_GORDON_API_URL + "/planning/redelivery/", {
        method: "POST",
        body: JSON.stringify(updatebody),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token
        }
      })
        .then(response => {
          this.setState({
            showRedelivery: false
          });
          this.setState({
            selectedorder: null,
            external_ref: null,
            customername: null,
            customerphone: null,
            customeremail: null,
            customerentrycode: null,
            instructions: null,
            price: null,
            deliveryGroupZone: null,
            searchaddress: null,
            merchant: null,
            customeraddress: null,
            customerzipcode: null,
            customercity: null,
            latitude: null,
            longitude: null,
            isFakturerbar: true,
            dispatchDirect: false,
            starttime: 54000,
            endtime: 79200,
            formattedValue: moment()
              .add(1, "days")
              .format("YYYY-MM-DD"),
            value: new Date(moment().add(1, "days")).toISOString()
          });
          this.getOrders(this.state.group);
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }
  updateOrder() { }
  cancelOrder() {
    this.setState({
      searchaddress: null,
      customername: null,
      customerphone: null,
      customeraddress: null,
      customerzipcode: null,
      customercity: null,
      customerentrycode: null,
      latitude: null,
      longitude: null,
      customeremail: null,
      errormessage: null
    });
    this.setState({
      showOrderModal: false
    });
  }
  cancelError() {
    this.setState({
      showErrorModal: false
    });
  }

  onClick({ event, props }) {
    var row = props.rowInfo;
    this.setState({
      selectedorder: row._id,
      external_ref: row.external_ref,
      customername: row.CustomerName,
      customerphone:
        row.deliveryContactInformation != null
          ? row.deliveryContactInformation.mobilePhone
          : null,
      customeremail:
        row.deliveryContactInformation != null
          ? row.deliveryContactInformation.email
          : null,
      customerentrycode: row.deliveryAddress.entryCode,
      instructions: row.deliveryInstructions,
      price: row.orderInfo.price,
      deliveryGroupZone:
        row.validation != null
          ? row.validation.deliveryGroup + (row.validation.zone != null ? " / " + row.validation.zone : "")
          : "",
      searchaddress:
        row.Address +
        (row.deliveryAddress != null && row.deliveryAddress.postalCode != null
          ? ", " +
          row.deliveryAddress.postalCode +
          " " +
          row.deliveryAddress.city
          : ""),
      merchant: row.pickupInfo.contactInfo.name,
      customeraddress: row.deliveryAddress.streetAddress,
      customerzipcode: row.deliveryAddress.postalCode,
      customercity: row.deliveryAddress.city,
      latitude: row.deliveryAddress.latitude,
      longitude: row.deliveryAddress.longitude
    });

    var cartItems = [];
    for (let index = 0; index < row.items.length; index++) {
      const element = row.items[index];
      cartItems.push({
        index: cartItems.length,
        value: element.name,
        count: element.numberOfItems,
        orderitem: element
      });
    }
    this.setState({ cartItems: cartItems });
    this.setState({
      showRedelivery: true
    });
  }
  toggleFakturerbar = () => {
    this.setState({
      isFakturerbar: !this.state.isFakturerbar
    });
  };

  toggleDirect = () => {
    this.setState({
      dispatchDirect: !this.state.dispatchDirect,
      showErrorModal: !this.state.dispatchDirect
    });

  };

  render() {
    const { isAuthenticated } = this.props.auth;

    function trClassFormat(rowData, rIndex) {
      if (rowData != null && rowData.Status === "Levererad") {
        return { backgroundColor: "#cdf8d4" };
      } else if (rowData != null && rowData.Status === "Kund var inte hemma") {
        return { backgroundColor: "#f8f5cd" };
      } else if (rowData != null && rowData.Status === "Kom inte in i port") {
        return { backgroundColor: "#f8cdcd" };
      } else if (rowData != null && rowData.Status === "Kund avbokade") {
        return { backgroundColor: "#d9cdf8" };
      } else if (rowData != null && rowData.Status === "Tekniskt fel") {
        return { backgroundColor: "#000000", color: "#ffffff" };
      } else {
        return { backgroundColor: "#ffffff" };
      }
    }

    const cssClasses = {
      root: "form-group",
      input: "Demo__search-input",
      autocompleteContainer: "Demo__autocomplete-container"
    };
    const AutocompleteItem = ({ formattedSuggestion }) => (
      <div className="Demo__suggestion-item">
        <i className="fa fa-map-marker Demo__suggestion-icon" />
        <strong>{formattedSuggestion.mainText}</strong>{" "}
        <small className="text-muted">
          {formattedSuggestion.secondaryText}
        </small>
      </div>
    );

    const inputProps = {
      type: "text",
      value: this.state.searchaddress,
      onChange: this.handleChange,
      autoFocus: true,
      placeholder: "Ange leveransadress...",
      name: "Demo__input",
      id: "my-input-id"
    };

    const options = {
      componentRestrictions: { country: "se" }
    };

    var disableprice = !this.state.isFakturerbar;

    const RedeliveryMenu = () => (
      <Menu id="menu_id">
        <Item onClick={this.onClick.bind(this)}>Skapa omleverans...</Item>
      </Menu>
    );

    return (
      <div className="container">
        {isAuthenticated() && (
          <div>
            <RedeliveryMenu />
            <h3>{this.state.routename}</h3>
            <div className="divbreak">
              <br />
              <Workbook filename="route.xlsx" element={<button className="btn btn-primary">Exportera till Excel</button>}>
                <Workbook.Sheet data={() => this.state.data} name={this.state.sheetname}>
                  <Workbook.Column label="Merchant" value="Merchant" />
                  <Workbook.Column label="StopNumber" value="StopNumber" />
                  <Workbook.Column label="OrderId" value="OrderId" />
                  <Workbook.Column label="CustomerName" value="CustomerName" />
                  <Workbook.Column label="CustomerPhone" value="CustomerPhone" />
                  <Workbook.Column label="Address" value="Address" />
                  <Workbook.Column label="TimeWindow" value="TimeWindow" />
                  <Workbook.Column label="PromisedTime" value="PromisedTime" />
                  <Workbook.Column label="TimeArrived" value="TimeArrived" />
                  <Workbook.Column label="TimeDelivered" value="TimeDelivered" />
                  <Workbook.Column label="Driver" value="Driver" />
                  <Workbook.Column label="Status" value="Status" />
                </Workbook.Sheet>
              </Workbook>
              <br />
              <BootstrapTable
                data={this.state.data}
                editable={false}
                striped={true}
                hover={true}
                trStyle={trClassFormat}
              >
                <TableHeaderColumn
                  width="120"
                  editable={false}
                  dataField="Merchant"
                >
                  Butik/Kund
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="80"
                  editable={false}
                  dataField="StopNumber"
                >
                  Sekvens
                </TableHeaderColumn>
                <TableHeaderColumn
                  width="120"
                  editable={false}
                  dataFormat={this.detailsFormatter.bind(this)}
                  dataField="OrderId"
                  isKey
                >
                  Order ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="CustomerName"
                  editable={false}
                  width="150"
                  columnTitle
                >
                  Kund
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="Address"
                  editable={false}
                  width="250"
                  columnTitle
                >
                  Adress
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="TimeWindow"
                  editable={false}
                  width="100"
                  columnTitle
                >
                  Leveransfönster
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="PromisedTime"
                  width="80"
                  editable={false}
                  columnTitle
                >
                  Beräknad leveranstid
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="TimeArrived"
                  width="80"
                  editable={false}
                  columnTitle
                >
                  Ankommit
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="TimeDelivered"
                  width="80"
                  editable={false}
                  columnTitle
                >
                  Levererad
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="Driver"
                  width="150"
                  editable={false}
                  columnTitle
                >
                  Förare
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="internal_id"
                  width="0"
                  editable={false}
                  columnTitle
                >
                  ID
                </TableHeaderColumn>
                <TableHeaderColumn
                  dataField="Status"
                  width="150"
                  editable={false}
                  columnTitle
                >
                  Status
                </TableHeaderColumn>

                <TableHeaderColumn
                  width="150"
                  dataField="_id"
                  dataFormat={this.dispatchFormatter.bind(this)}
                >
                  Dispatch
                </TableHeaderColumn>
              </BootstrapTable>
            </div>

            <Modal
              bsSize="small"
              show={this.state.showErrorModal}
              onHide={this.cancelError.bind(this)}
            >
              <Modal.Header>
                <Modal.Title> OBS! Skapa omleverans direkt </Modal.Title>{" "}
              </Modal.Header>
              <Modal.Body>
                <p>
                  Kontrollera att du lagt in korrekt leveransdatum och leveransintervall.<br />
                </p>
                <p>
                  När du klickar på 'Skapa' kommer det att skapas en omleverans som skickas direkt till Bringg. Ordern läggs i Planning så den behöver tilldelas korrekt förare.
                </p>
                <p>
                  <br />
                  Glöm inte att sätta ett pris på ordern.
                </p>
              </Modal.Body>{" "}
              <Modal.Footer>
                <Button onClick={this.cancelError.bind(this)}> OK </Button>
              </Modal.Footer>{" "}
            </Modal>

            <Modal
              bsSize="large"
              show={this.state.showRedelivery}
              dimmer={"blurring"}
            >
              <Modal.Header>
                <Modal.Title>
                  Order-ID: {this.state.external_ref} - Skapa omleverans
                </Modal.Title>{" "}
              </Modal.Header>
              <Modal.Body>
                <div className="row overview">
                  <div className="col-md-4 user-pad text-left">
                    <p>Nytt leveransdatum:</p>
                    <DatePicker
                      autoFocus={false}
                      minDate={moment().format("YYYY-MM-DD")}
                      showTodayButton={true}
                      todayButtonLabel="Idag"
                      id="example-datepicker"
                      value={this.state.value}
                      dateFormat="YYYY-MM-DD"
                      weekStartsOn={1}
                      onChange={this.handleDateChange.bind(this)}
                      monthLabels={[
                        "Januari",
                        "Februari",
                        "Mars",
                        "April",
                        "Maj",
                        "Juni",
                        "Juli",
                        "Augusti",
                        "September",
                        "Oktober",
                        "November",
                        "December"
                      ]}
                      dayLabels={[
                        "Sön",
                        "Mån",
                        "Tis",
                        "Ons",
                        "Tors",
                        "Fre",
                        "Lör"
                      ]}
                    />
                  </div>
                  <div className="col-md-4 user-pad text-left">
                    <p>Leveransfönster start:</p>
                    <TimePicker
                      start="00:00"
                      end="23:59"
                      step={60}
                      format={24}
                      initialValue="00:00"
                      format={24}
                      value={this.state.starttime}
                      onChange={this.handleTimeChangeStart}
                    />
                  </div>
                  <div className="col-md-4 user-pad text-left">
                    <p>Leveransfönster slut:</p>
                    <TimePicker
                      start="00:00"
                      end="23:59"
                      step={60}
                      format={24}
                      initialValue="23:00"
                      value={this.state.endtime}
                      onChange={this.handleTimeChangeEnd}
                    />
                  </div>
                </div>
                <br />
                <div className="row overview">
                  <div className="col-md-4 user-pad text-left">
                    <p>Kundnamn:</p>
                    <FormControl
                      onInput={this.nameChanged.bind(this)}
                      value={this.state.customername}
                      placeholder="Kundens namn"
                    />
                  </div>
                  <div className="col-md-4 user-pad text-left">
                    <p>Telefonnummer:</p>
                    <FormControl
                      onInput={this.phoneChanged.bind(this)}
                      value={this.state.customerphone}
                      placeholder="Kundens telefonnummer"
                    />
                  </div>
                  <div className="col-md-4 user-pad text-left">
                    <p>E-post:</p>
                    <FormControl
                      onInput={this.emailChanged.bind(this)}
                      value={this.state.customeremail}
                      placeholder="E-post"
                    />
                  </div>
                </div>
                <br />

                <div className="row overview">
                  <div className="col-md-8 user-pad text-left">
                    Leveransadress:{" "}
                    <PlacesAutocomplete
                      onSelect={this.handleSelect}
                      autocompleteItem={AutocompleteItem}
                      onEnterKeyDown={this.handleSelect}
                      classNames={cssClasses}
                      inputProps={inputProps}
                      options={options}
                      googleLogo={false}
                    />
                    {this.state.isLoading ? (
                      <div>
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw Demo__spinner" />
                      </div>
                    ) : null}
                    {!this.state.isLoading && this.state.geocodeResults ? (
                      <div className="geocoding-results">
                        {this.state.geocodeResults}
                      </div>
                    ) : null}
                  </div>
                  <div className="col-md-4 user-pad text-left">
                    <p>Butik/Kund:</p>
                    <p>
                      <strong>{this.state.merchant}</strong>
                    </p>
                  </div>
                </div>

                <br />
                <div className="row overview">
                  <div className="col-md-4 user-pad text-left">
                    <p>Portkod:</p>
                    <FormControl
                      onInput={this.entrycodeChanged.bind(this)}
                      value={this.state.customerentrycode}
                      placeholder="Portkod"
                    />
                  </div>
                  <div className="col-md-4 user-pad text-left">
                    <p>Instruktion till föraren:</p>
                    <FormControl
                      onInput={this.instructionsChanged.bind(this)}
                      value={this.state.instructions}
                      placeholder="Instuktion till föraren"
                    />
                  </div>
                  <div className="col-md-4 user-pad text-left">
                    <p>Leveransgrupp / Zon:</p>
                    <p>
                      <strong>{this.state.deliveryGroupZone}</strong>
                    </p>
                  </div>
                </div>
                <br />
                <div className="row overview">
                  <div className="col-md-4 user-pad text-left" />
                </div>

                <br />
                <div className="row overview">
                  <div className="col-md-4 user-pad text-left">
                    <p>
                      <strong>Pris:</strong>
                    </p>
                    <FormControl
                      type="number"
                      disabled={disableprice}
                      onInput={this.priceChanged.bind(this)}
                      value={this.state.price}
                      placeholder="Pris ex moms"
                    />
                  </div>
                  <div className="col-md-4 user-pad text-left">
                    <p>Fakturerbar:</p>
                    <Switch
                      onChange={this.toggleFakturerbar}
                      checked={this.state.isFakturerbar}
                      id="normal-switch"
                    />
                  </div>
                </div>
                <br />

                <br />
                <div className="panel panel-default">
                  <div className="panel-heading">Kollin:</div>
                  <div className="panel-body">
                    <ul className="list-group">
                      {this.state.cartItems.map((item, i) => (
                        <li className="list-group-item text-right">
                          <div>
                            <span className="pull-left">
                              <strong className="">
                                {item.count} st {item.value}
                              </strong>{" "}

                            </span>{" "}
                            _
                            <button
                              type="button"
                              className="close"
                              onClick={this.removeItem.bind(this, item.index)}
                            >
                              <span className="fa fa-times" />
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>
                    <p><strong>Lägg till kolli:</strong>(OBS! Kollin man lägger till manuellt kommer inte ha etikett och kommer inte gå att scanna.)</p>
                    <div className="row overview">
                      <div className="col-md-4 user-pad text-left">
                        <p>Antal:</p>
                        <FormControl
                          type="number"
                          onInput={this.countChanged.bind(this)}
                          value={this.state.count}
                          placeholder="Antal kollin"
                        />
                      </div>
                      <div className="col-md-4 user-pad text-left">
                        <p>Kasse:</p>
                        <FormControl
                          onInput={this.kolliChanged.bind(this)}
                          value={this.state.kolli}
                          placeholder="Typ av kasse"
                        />
                      </div>
                      <div className="col-md-4 user-pad text-left">
                        <p> </p>
                        <Button
                          positive
                          bsStyle="success"
                          onClick={this.addItem}
                        >
                          Lägg till
                </Button>
                      </div>
                    </div>


                  </div>

                </div>
                <div className="row overview">
                  <div className="col-md-4 user-pad text-left">
                    <p>Skicka ordern till Bringg direkt för omleverans samma kväll:</p>
                    <Switch
                      onChange={this.toggleDirect}
                      checked={this.state.dispatchDirect}
                      id="normal-switch"
                    />
                  </div>
                </div>
                <br />
                <br />
              </Modal.Body>
              <Modal.Footer>
                <Button negative onClick={this.cancelRedelivery.bind(this)}>
                  Avbryt
                </Button>
                <Button
                  positive
                  bsStyle="success"
                  onClick={this.createRedelivery.bind(this)}
                >
                  Skapa
                </Button>

              </Modal.Footer>
            </Modal>

            <Modal
              bsSize="large"
              show={this.state.showOrderModal}
              onHide={this.cancelOrder.bind(this)}
              dimmer={"blurring"}
            >
              <Modal.Header>
                <Modal.Title>Uppdatera order</Modal.Title>{" "}
              </Modal.Header>
              <Modal.Body>
                <div className="row overview">
                  <div className="col-md-4 user-pad text-left">
                    <p>Kundnamn:</p>
                    <FormControl
                      onInput={this.nameChanged.bind(this)}
                      value={this.state.customername}
                      placeholder="Kundens namn"
                    />
                  </div>
                  <div className="col-md-4 user-pad text-left">
                    <p>Telefonnummer:</p>
                    <FormControl
                      onInput={this.phoneChanged.bind(this)}
                      value={this.state.customerphone}
                      placeholder="Kundens telefonnummer"
                    />
                  </div>
                  <div className="col-md-4 user-pad text-left">
                    <p>E-post:</p>
                    <FormControl
                      onInput={this.emailChanged.bind(this)}
                      value={this.state.customeremail}
                      placeholder="E-post"
                    />
                  </div>
                </div>
                <br />

                <div className="row overview">
                  <div className="col-md-8 user-pad text-left">
                    Leveransadress:{" "}
                    <PlacesAutocomplete
                      onSelect={this.handleSelect}
                      autocompleteItem={AutocompleteItem}
                      onEnterKeyDown={this.handleSelect}
                      classNames={cssClasses}
                      inputProps={inputProps}
                      options={options}
                      googleLogo={false}
                    />
                    {this.state.isLoading ? (
                      <div>
                        <i className="fa fa-spinner fa-pulse fa-3x fa-fw Demo__spinner" />
                      </div>
                    ) : null}
                    {!this.state.isLoading && this.state.geocodeResults ? (
                      <div className="geocoding-results">
                        {this.state.geocodeResults}
                      </div>
                    ) : null}
                  </div>
                </div>

                {/* <div className="row overview">
                                        <div className="col-md-4 user-pad text-left">
                                            <p>Gatuadress:</p>
                                            <FormControl onInput={this.addressChanged.bind(this)} value={this.state.customeraddress}
                                                placeholder='Gatuadress' />
                                        </div>
                                        <div className="col-md-4 user-pad text-left">
                                            <p>Postnummer:</p>
                                            <FormControl type="number" onInput={this.zipcodeChanged.bind(this)} value={this.state.customerzipcode}
                                                placeholder='Postnummer' />
                                        </div>
                                        <div className="col-md-4 user-pad text-left">
                                            <p>Ort:</p>
                                            <FormControl onInput={this.cityChanged.bind(this)} value={this.state.customercity}
                                                placeholder='Ort' />
                                        </div>
                                    </div> */}
                <br />
                <div className="row overview">
                  <div className="col-md-4 user-pad text-left">
                    <p>Latitude:</p>
                    <FormControl
                      type="number"
                      onInput={this.latitudeChanged.bind(this)}
                      value={this.state.latitude}
                      placeholder="Latitude"
                    />
                  </div>
                  <div className="col-md-4 user-pad text-left">
                    <p>Longitude:</p>
                    <FormControl
                      type="number"
                      onInput={this.longitudeChanged.bind(this)}
                      value={this.state.longitude}
                      placeholder="Longitude"
                    />
                  </div>
                  <div className="col-md-4 user-pad text-left" />
                </div>
                <br />
                <div className="row overview">
                  <div className="col-md-4 user-pad text-left">
                    <p>Portkod:</p>
                    <FormControl
                      onInput={this.entrycodeChanged.bind(this)}
                      value={this.state.customerentrycode}
                      placeholder="Portkod"
                    />
                  </div>
                  <div className="col-md-4 user-pad text-left" />
                  <div className="col-md-4 user-pad text-left">
                    {this.state.errormessage != null && (
                      <div>
                        <p>
                          <i
                            className="fas fa-exclamation-triangle"
                            style={{ color: "red" }}
                          />
                        </p>
                        <p>{this.state.errormessage}</p>
                      </div>
                    )}
                  </div>
                </div>

                <br />
              </Modal.Body>
              <Modal.Footer>
                <Button negative onClick={this.cancelOrder.bind(this)}>
                  Avbryt
                </Button>
                <Button
                  positive
                  bsStyle="success"
                  onClick={this.updateOrder.bind(this)}
                >
                  Uppdatera
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        )}
        {!isAuthenticated() && (
          <h4>
            You're not logged in! Click{" "}
            <a style={{ cursor: "pointer" }} onClick={this.login.bind(this)}>
              Login
            </a>{" "}
            to continue.
          </h4>
        )}
      </div>
    );
  }
}

export default RoutedOrders;
