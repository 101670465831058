import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

const queryString = require('query-string');

class FleetInfoDamage extends Component {

  componentWillMount() {
  
    this.setState({ data: [] });
  }

  componentDidMount() {

    var regnr = localStorage.getItem('regnr');
    this.setState({ regnr: regnr });

    var user = localStorage.getItem('user');
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + '/photolog/' + regnr.toUpperCase(), {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((dataJson) => {

    
          this.setState({ data: dataJson });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }

  rowClick(row) {

    

  }
  render() {
    const data = this.state.data;

    var options = {
      onRowClick: this.rowClick.bind(this)
    }

    return (
      <div className="container">
             <div>
              <h3>Skador</h3>
              {/* <div className="imageGalleryTable" >
                <BootstrapTable width="200" data={data} options={options} striped={true} hover={true} >
                <TableHeaderColumn width="200" dataField='name'>Gordoneer</TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField='action'>Action</TableHeaderColumn>
                  <TableHeaderColumn width="200" isKey dataField='registration'>Registreringsnummer</TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField='created_at'>Datum</TableHeaderColumn>
                </BootstrapTable>
              </div>
             */}
            </div>
    
      </div>

    );
  }
}

export default FleetInfoDamage;

