import React from 'react';
import queryString from "query-string";
import _sortBy from 'lodash/sortBy';
import _get from 'lodash/get';
import moment from 'moment';
import { SSL_OP_DONT_INSERT_EMPTY_FRAGMENTS } from 'constants';


class OrderList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            orders: [],
            routename: ''
        }

        this.renderTable = this.renderTable.bind(this);
        this.renderTotals = this.renderTotals.bind(this);
    }

    renderTotals(orders) {
        let rows = [];

        let articles = [];
        let totalCount = 0;

        orders.filter(o => o.items && o.items[0].code && !o.items[0].code.startsWith(o.external_ref)).forEach(order => {

            order.items.forEach(i => {
                let index = articles.findIndex(a => a.name === i.name);


                if (index < 0) {
                    articles.push({
                        name: i.name,
                        client: order.pickupInfo.contactInfo.name,
                        count: i.numberOfItems
                    })

                } else {
                    articles[index].count = parseInt(articles[index].count) + parseInt(i.numberOfItems);

                }
                totalCount = totalCount + i.numberOfItems;

            });
        });

        _sortBy(articles, ['client', 'count']).forEach(a => {

            rows = rows.concat([
                <tr>
                    <td>{a.name}</td>
                    <td>{a.client}</td>
                    <td>{a.count}</td>
                </tr>
            ]);
        });

        rows = rows.concat([
            <tr style={{ fontWeight: 'bold' }}>
                <td>Sum</td>
                <td></td>
                <td>{totalCount}</td>
            </tr>
        ])

        return rows;
    }


    renderTable(orders) {
        let rows = [];
        let _totalItems = 0;

        orders = orders.filter(o => o.items && ((o.items[0].code && o.items[0].code.startsWith(o.external_ref)) || !o.items[0].code))
        orders = _sortBy(orders, [(o) => _get(o, 'pickupInfo.contactInfo.name'), (o) => o.items.length]);

        orders.forEach(order => {
            let address = '';

            if (order.items) {
                _totalItems += order.items.length;
            }
            if (order.deliveryAddress.streetAddress) {
                address = address + order.deliveryAddress.streetAddress + ', ';
            }
            if (order.deliveryAddress.postalCode) {
                address = address + order.deliveryAddress.postalCode + ', ';
            }
            if (order.deliveryAddress.city) {
                address = address + order.deliveryAddress.city;
            }

            // <td> {address} </td>
            //<td> {order.orderInfo.stopNumber}</td>
            rows = rows.concat([
                <tr>
                    <td> {order.pickupInfo.contactInfo.name}</td>
                    <td> {order.deliveryAddress.fullName}</td>
                    <td>
                        <ul>
                            {order.items.map(i => <li style={{ listStyleType: "none" }}>{i.numberOfItems + ' x ' + i.name}</li>)}
                        </ul>
                    </td>
                    <td>{order.items ? order.items.length : 0} </td>
                </tr>
            ])
        })

        rows = rows.concat([
            <tr style={{ fontWeight: 'bold' }}>
                <td>Sum</td>
                <td></td>
                <td></td>
                <td>{_totalItems}</td>
            </tr>
        ])

        return rows;

    }

    componentDidMount() {
        console.log('mounting orderlsit')

        let parsed;
        let route = this.props.route;
        let routename;
        let access_token = localStorage.getItem("access_token");


        if (this.props.location) {
            parsed = queryString.parse(this.props.location.search);
            routename = parsed.routename;
        } else if (route) {
            routename = route.name;
        }

        if (!routename) {
            return;
        }

        if (access_token != null) {
            fetch(
                process.env.REACT_APP_GORDON_API_URL +
                "/route/orders?route=" +
                routename + "&merchant=Administrator",
                {
                    method: "GET",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + access_token
                    }
                }
            )
                .then(response => response.json())
                .then(data => data.sort((a, b) => b.orderInfo.stopNumber - a.orderInfo.stopNumber))
                .then(dataJson => {
                    this.setState({ ...this.state, orders: dataJson, routename: routename, route: route || '' })


                }).catch(err => console.log(err))
        }
    }




    render() {
        let { orders, routename, route } = this.state;
        let totaltAntalKollin = 0

        let routeInfo = function () {
            if (!route) {
                return
            }

            return (
                <div style={{ fontSize: '14px', margin: '10px' }}>
                    <div>Start time: {moment(route.promisedStartTime).format('HH:mm')}</div>
                    <div>End time: {moment(route.promisedEndTime).format('HH:mm')}</div>
                </div>
            )
        }()

        orders.map(o => {
            if (!o.items) {
                return
            }
            totaltAntalKollin = totaltAntalKollin + o.items.length;
        })

        //<h5>{routename}</h5>
        return (
            <div className="container" style={{ margin: "0 auto", fontSize: "12px", pageBreakAfter: "always" }}>
                <h3>{routename}</h3>

                <h4>Driver: {orders[0] ? orders[0].orderInfo.driver : ''} </h4>

                {routeInfo}
                <div style={{ fontSize: "14px", fontWeight: "bold", marginBottom: "20px" }}>
                    Total number of boxes: {totaltAntalKollin}
                </div>
                <div style={{}}>

                    <h4>Not customer-unique</h4>
                    <table className="table tabel-striped" style={{ marginTop: '30px' }}>
                        <thead>
                            <tr style={{ borderBottom: '1px solid lightgrey' }}>
                                <th style={{ border: "0" }}>Item</th>
                                <th style={{ border: "0" }}>Delivery Group</th>
                                <th style={{ border: "0" }}>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTotals(orders)}
                        </tbody>
                    </table>



                    <h4>Customer-unique</h4>
                    <table className="table tabel-striped">
                        <thead>
                            <tr style={{ borderBottom: '1px solid lightgrey' }}>
                                <th style={{ border: "0" }}>Delivery Group</th>
                                <th style={{ border: "0" }}>Customer name</th>
                                <th style={{ border: "0" }}>Item</th>
                                <th style={{ border: "0" }}>Quantity</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTable(orders)}
                        </tbody>
                    </table>

                </div>
            </div>)
    }
}


export default OrderList;