import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import React, { Component } from 'react';
import BigCalendar from 'react-big-calendar'
import moment from "moment";
import 'react-big-calendar/lib/css/react-big-calendar.css'
moment.locale('sv');
BigCalendar.momentLocalizer(moment); 

class OrderSchedule extends Component {
  login() {
    this.props.auth.login();
  }

  componentWillMount() {

    var events = [];
    this.setState({ events: events });
  }

  componentDidMount() {

    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + '/order/externalgrouped', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((dataJson) => {

          var events = [];

          for (let i = 0; i < dataJson.length; i++) {
            events.push({id: i, title : dataJson[i]._id.customer + "  - " + dataJson[i].count + " st", allDay: false, 
            start: new Date(dataJson[i]._id.year, dataJson[i]._id.month - 1, dataJson[i]._id.day,8), end: new Date(dataJson[i]._id.year, dataJson[i]._id.month - 1, dataJson[i]._id.day,12)});
          }

          this.state.events = events;
          this.setState({ events: events });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    const events = this.state.events;
    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Orderöversikt</h3>
              <div className="invoiceDiv" >
                <BigCalendar
                  events={events}
                  views={['week']}
                  defaultView="week"
                  showMultiDayTimes
                  defaultDate={new Date(moment())}
                  messages={{
                    previous: '<< Förra veckan',
                    next: 'Nästa vecka >>',
                    today: 'Idag',
                  }}
                />
              </div>
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default OrderSchedule;