import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Workbook from 'react-excel-workbook';
import _ from "lodash";
import { DateRange } from 'react-date-range';
import { format, addDays } from 'date-fns';
import moment from 'moment';
import { Modal } from 'react-bootstrap';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

class Invoicing extends Component {
  login() {
    this.props.auth.login();
  }

  componentWillMount() {

    this.state = {
      dateRange: {
        selection: {
          startDate: addDays(new Date(), 0),
          endDate: new Date(),
          key: 'selection',
        },
      }
    };
    this.setState({
      dateRange: {
        selection: {
          startDate: addDays(new Date(), 0),
          endDate: addDays(new Date(), 0),
          key: 'selection',
        },
      }
    });
    this.fetchData = this.fetchData.bind(this);
    this.setState({ rows: [] });
    this.setState({ isLoading: false });
    this.setState({ originalrows: [] });
  }
  componentDidMount() {
    this.getOrders(this.state.dateRange.selection.startDate, this.state.dateRange.selection.endDate);
  }


  getOrders(startDate, endDate) {

    
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {
      this.setState({ isLoading: true });
      fetch(process.env.REACT_APP_GORDON_API_URL + '/order/external/' + moment(startDate).format("YYYY-MM-DD") + '/' + moment(endDate).format("YYYY-MM-DD"), {
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + access_token,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((dataJson) => {

          var data = [];

          for (let i = 0; i < dataJson.length; i++) {
            if (!dataJson[i].pickupInfo.contactInfo.name.startsWith('Coop') && !dataJson[i].pickupInfo.contactInfo.name.startsWith('Stora Coop')) {

              var status = dataJson[i].orderInfo.status;
              if (dataJson[i].orderInfo.status != null && dataJson[i].orderInfo.status == 'Exkludera, hjälpte Gordoneer') {
                status = "Done";
              }
              else if (dataJson[i].orderInfo.status != null && dataJson[i].orderInfo.status == 'Exkludera, Gods ej klar för lastning') {
                status = "Done - Gods var ej klart vid lastning";
              }
              data.push({
                createddate: moment(dataJson[i].created_at).format("YYYY-MM-DD HH:mm"),
                deliverydate: moment(dataJson[i].deliveryDate).format("YYYY-MM-DD"),
                deliverydatedate: moment(dataJson[i].deliveryDate),
                customer: dataJson[i].pickupInfo.contactInfo.name,
                ordernumber: dataJson[i].external_ref,
                price: dataJson[i].orderInfo.price,
                status: status,
                distance: dataJson[i].orderInfo.distanceinmeter != null ? Math.round(dataJson[i].orderInfo.distanceinmeter / 1000 * 100) / 100 : '',
                endcustomer: dataJson[i].deliveryAddress.fullName,
                title: dataJson[i].orderInfo.title
              });
            }
          }

          var result = data;
          // for (var i = 0; i < data.length; i++) {
          //   if (moment().add(-7, 'days').startOf('day').isBefore(moment(data[i].deliverydate)) &&
          //     moment(data[i].deliverydate).isBefore(moment().endOf('day'))) {
          //     result.push(data[i]);
          //   }
          // }

          this.setState({ isLoading: false });
          this.state.rows = result;
          this.setState({ rows: result });
          this.state.originalrows = data;
          this.setState({ originalrows: data });

        })
        .catch((error) => {
          console.error(error);
        });

    } else {
      console.error("401 Unauthorized");
    }
  }
  fetchData(state, instance) {

    let filteredData = this.state.originalrows;
    if (state.filtered.length) {
      filteredData = state.filtered.reduce((filteredSoFar, nextFilter) => {
        return filteredSoFar.filter(row => {
          return (row[nextFilter.id] + "").includes(nextFilter.value);
        });
      }, filteredData);
    }
    // You can also use the sorting in your request, but again, you are responsible for applying it.
    const sortedData = _.orderBy(
      filteredData,
      state.sorted.map(sort => {
        return row => {
          if (row[sort.id] === null || row[sort.id] === undefined) {
            return -Infinity;
          }
          return typeof row[sort.id] === "string"
            ? row[sort.id].toLowerCase()
            : row[sort.id];
        };
      }),
      state.sorted.map(d => (d.desc ? "desc" : "asc"))
    );

    var result = [];
    for (var i = 0; i < sortedData.length; i++) {

      if (moment(this.state.dateRange.selection.startDate).startOf('day').isBefore(moment(sortedData[i].deliverydatedate)) &&
        moment(sortedData[i].deliverydatedate).isBefore(moment(this.state.dateRange.selection.endDate).endOf('day'))) {
        result.push(sortedData[i]);
      }
    }

    let rows = result;
    this.state.rows = result;
    this.setState({ rows: result });
  }


  handleRangeChange(which, payload) {
    console.log(which, payload);
    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });

    this.getOrders(payload.selection.startDate, payload.selection.endDate);

    // var result = [];
    // if (payload.selection != null) {
    //   for (var i = 0; i < this.state.originalrows.length; i++) {
    //     if (moment(payload.selection.startDate).startOf('day').isBefore(moment(this.state.originalrows[i].deliverydatedate)) &&
    //       moment(this.state.originalrows[i].deliverydatedate).isBefore(moment(payload.selection.endDate).endOf('day'))) {
    //       result.push(this.state.originalrows[i]);
    //     }

    //   }
    // }
    // this.setState({
    //   rows:
    //     result
    //   ,
    // });
  }
  handleChange(which, payload) {
    this.setState({
      [which]: payload
    });
  }
  formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return format(date, 'YYYY-MM-DD');
  }

  render() {
    const columns = [{
      Header: 'Leveransdatum',
      accessor: 'deliverydate'
    }, {
      Header: 'Ordernummer',
      accessor: 'ordernumber'
    }, {
      Header: 'Butik',
      accessor: 'customer'
    }, {
      Header: 'Slutkund',
      accessor: 'endcustomer'
    }, {
      Header: 'Avstånd (km)',
      accessor: 'distance'
    }, {
      Header: 'Pris ex moms',
      accessor: 'price'
    }, {
      Header: 'Status',
      accessor: 'status'
    }]

    const {
      rangePicker,
      rangePickerMobile,
      linked,
      datePicker,
      predefined,
      datePickerInternational
    } = this.state;

    const isLoading = this.state.isLoading;
    const { isAuthenticated } = this.props.auth;
    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Fakturaunderlag</h3>
              <DateRange
                onChange={this.handleRangeChange.bind(this, 'dateRange')}
                moveRangeOnFirstSelection={false}
                dateDisplayFormat="YYYY-MM-DD"
                ranges={[this.state.dateRange.selection]}
                className={'PreviewArea'}
              />

              <br />

              <Workbook filename="invoicing.xlsx" element={<button disabled={isLoading} className="btn btn-primary">{isLoading ? 'Hämtar ordar...' : 'Exportera till Excel'}</button>}>
                <Workbook.Sheet data={() => this.state.rows} name="Fakturaunderlag">
                  <Workbook.Column label="Leveransdatum" value="deliverydate" />
                  <Workbook.Column label="Ordernummer" value="ordernumber" />
                  <Workbook.Column label="Butik" value="customer" />
                  <Workbook.Column label="Slutkund" value="endcustomer" />
                  <Workbook.Column label="Avstånd (km)" value="distance" />
                  <Workbook.Column label="Pris ex moms" value="price" />
                  <Workbook.Column label="Titel" value="title" />
                  <Workbook.Column label="Status" value="status" />
                </Workbook.Sheet>
              </Workbook>
              <br />
              <ReactTable
                filterable={true}
                defaultSorted={[{
                  id: 'deliverydate',
                  desc: true,
                }]}
                data={this.state.rows}
                onFetchData={this.fetchData}
                columns={columns}
                defaultPageSize={100}
                showPageSizeOptions={true}
                pageSizeOptions={[20, 50, 100, 200, 500, 1000]} />

              <Modal
                {...this.props}
                show={this.state.isLoading}
                dialogClassName="custom-modal"
              >

                <Modal.Body>
                  <h4>Hämtar ordrar...</h4>
                </Modal.Body>

              </Modal>

            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
            </h4>
          )
        }
      </div>

    );
  }
}

export default Invoicing;

