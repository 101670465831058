import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import "react-image-gallery/styles/css/image-gallery.css";
import moment from 'moment';

const queryString = require('query-string');

class FleetInfoPhoto extends Component {

  componentWillMount() {

    this.setState({ images: [] });
    this.setState({ data: [] });

    this.setState({ selectedRow: "" });
  }

  componentDidMount() {

    var regnr = localStorage.getItem('regnr');
    this.setState({ regnr: regnr });

    var user = localStorage.getItem('user');
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + '/photolog/' + regnr.toUpperCase(), {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((dataJson) => {

          var images = [];
          var d = dataJson[0].photo;
          for (let i = 0; i < d.length; i++) {
            images.push({ original: d[i].imageUrl, thumbnail: d[i].imageUrl, description: d[i].description })
          }
          this.setState({ images: images });
          this.setState({ selectedRow: "Driver: " + dataJson[0].name + " - Action: " + dataJson[0].action + " - Datum: " + dataJson[0].created_at });
          dataJson.forEach(row => {
            row.created_at = moment(row.created_at).format('YYYY-MM-DD HH:mm:ss');
            if (row.action == 'GENERATE_PIN') {
              row.action = "Hämtat pinkod";
            } else if (row.action == 'LOCK') {
              row.action = "Låst fordon";
            } else if (row.action == 'UNLOCK') {
              row.action = "Låste upp fordon";
            }

          });

          const sorter = (a, b) => {
            if (a.created_at < b.created_at) return 1;
            if (a.created_at > b.created_at) return -1;
            return 0;
          };

          dataJson = dataJson.sort(sorter);

          this.setState({ data: dataJson });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }

  rowClick(row) {

    var action = row.action;
    var name = row.name;
    var date = row.created_at;

    var images = [];
    var d = row.photo;
    for (let i = 0; i < d.length; i++) {
      images.push({ original: d[i].imageUrl, thumbnail: d[i].imageUrl, description: d[i].description })
    }
    this.setState({ images: images });
    this.setState({ selectedRow: "Gordoneer: " + name + " - Action: " + action + " - Datum: " + date });
  }
  render() {

    const images = this.state.images;
    const data = this.state.data;

    var options = {
      onRowClick: this.rowClick.bind(this)
    }

    return (
      <div className="container">
        <div>
          <h3>Fotobesiktning</h3>
          <div className="imageGalleryTable" >
            <BootstrapTable width="200" data={data} options={options} striped={true} hover={true} >
              <TableHeaderColumn width="200" isKey dataField='name'>Driver</TableHeaderColumn>
              <TableHeaderColumn width="200" dataField='action'>Action</TableHeaderColumn>
              <TableHeaderColumn width="200" dataField='created_at'>Datum</TableHeaderColumn>
            </BootstrapTable>
          </div>
          <br />
          <hr />

          <div className="imageGallery"  >
            <h4>{this.state.selectedRow}</h4>
            <ImageGallery thumbnailPosition="top" items={images} />
          </div>
        </div>

      </div>

    );
  }
}

export default FleetInfoPhoto;

