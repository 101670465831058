
const getStatusColor = function getStatusColor(statusString) {


    let status = statusString || '';
    let statusLowerCase = status.toLowerCase()

    let colorCode;

    switch (statusLowerCase) {
        case "done":
            colorCode = "#42b555";
            break;

        case "exkludera, hjälpte Gordoneer":
            colorCode = "#42b555";
            break;

        case "kund avbokade":
            colorCode = "#ffb680";
            break;

        case "kund var inte hemma":
            colorCode = "#f6c953";
            break;

        case "kom inte in i port":
            colorCode = "#f6c953";
            break;

        case "avbruten leverans pga gods saknas":
            colorCode = "#f54040"
            break;
        case "cancelled":
            colorCode = "#f54040"
            break;
        case "deleted":
            colorCode = "#f54040"
            break;
        case "gods saknas vid lastning":
            colorCode = "#f54040"
            break;
        case "tekniskt fel":
            colorCode = "#f54040"
            break;
        default:
            colorCode = '#b7cad0'; // default
            break;
    }

    return colorCode;
};


export { getStatusColor };