import React from 'react';
import { Route } from 'react-router-dom';
import Home from './scripts/home';
import Gordoneers from './scripts/gordoneers';
import Gordoneer from './scripts/gordoneer';
import Orders from './scripts/orders';
import AssignedOrders from './scripts/assignedorders';
import Fleet from './scripts/fleet';
import FleetInfo from './scripts/fleetinfo';
import FleetAdmin from './scripts/fleetadmin';
import FleetManagement from './scripts/fleetmanagement';
import Settings from './scripts/settings';
import TempLogs from './scripts/templogs';
import MyProfile from './scripts/myprofile';
import LinasMatkasse from './scripts/linasmatkasse';
import SvartaLadan from './scripts/svartaladan';
import Axfood from './scripts/axfood';
import Wrapsons from './scripts/wrapsons';
import TimeZynk from './scripts/timezynk';
import Delivery from './scripts/delivery';
import Leaderboard from './scripts/leaderboard';
import Ekonomi from './scripts/ekonomi';
import Invoicing from './scripts/invoicing';
import CoopInvoicing from './scripts/coopinvoicing';
import OrderSchedule from './scripts/orderschedule';
import DashBoard from './scripts/dashboard';
import Eventlog from './scripts/eventlog';
import Precision from './scripts/precision';
import LateStart from './scripts/latestart';
import Coop from './scripts/coop';
import Ica from './scripts/ica';
import Blomsterlandet from './scripts/blomsterlandet';
import Paradiset from './scripts/paradiset';
import Lundbergs from './scripts/lundbergs';
import Followup from './scripts/followup';
import Fleetlog from './scripts/fleetlog';
import MonthStats from './scripts/monthstats';
import WeeklyStats from './scripts/weeklystats';
import Vrp from './scripts/vrp';
import ZipCodes from './scripts/zipcodes';
import Matkasse from './scripts/matkasse';
import CustomerNotes from './scripts/customernotes';
import OrderDetails from './scripts/orderdetails';
import Sms from './scripts/sms';
import Planned from './scripts/orders/planned';
import Unplanned from './scripts/orders/unplanned';
import Routes from './scripts/orders/routes';
import DeliveryOrders from './scripts/orders/deliveryorders';
import RoutedOrders from './scripts/orders/routedorders';
import Result from './scripts/orders/result';
import Documents from './scripts/orders/documents';
import Email from './scripts/orders/email';
import Import from './scripts/orders/import';
import SearchResults from './scripts/searchResults';
import Search from './scripts/search';
import Notification from './scripts/notification';
import Orderlist from './scripts/orderList';
import Dailyloadlist from './scripts/dailyloadlist';
import OrderImport from './scripts/orders/orderimport';




export const makeMainRoutes = (props) => {
    let auth = props.auth;
    return (
        <div className=''>
            <Route path="/home" render={(props) => <Home auth={auth} {...props} />} />
            {/* <Route path="/heart" render={(props) => <GordonHeart auth={auth} {...props} />} /> */}
            <Route path="/orders" render={(props) => <Orders auth={auth} {...props} />} />
            <Route path="/assignedorders" render={(props) => <AssignedOrders auth={auth} {...props} />} />
            <Route path="/drivers" render={(props) => <Gordoneers auth={auth} {...props} />} />
            <Route path="/driver" render={(props) => <Gordoneer auth={auth} {...props} />} />
            <Route path="/fleet" render={(props) => <Fleet auth={auth} {...props} />} />
            <Route path="/fleetinfo" render={(props) => <FleetInfo auth={auth} {...props} />} />
            <Route path="/fleetadmin" render={(props) => <FleetAdmin auth={auth} {...props} />} />
            <Route path="/fleetmanagement" render={(props) => <FleetManagement auth={auth} {...props} />} />
            <Route path="/templogs" render={(props) => <TempLogs auth={auth} {...props} />} />
            <Route path="/settings" render={(props) => <Settings auth={auth} {...props} />} />
            <Route path="/myprofile" render={(props) => <MyProfile auth={auth} {...props} />} />
            <Route path="/linasmatkasse" render={(props) => <LinasMatkasse auth={auth} {...props} />} />
            <Route path="/svartaladan" render={(props) => <SvartaLadan auth={auth} {...props} />} />
            <Route path="/invoicing" render={(props) => <Invoicing auth={auth} {...props} />} />
            <Route path="/axfood" render={(props) => <Axfood auth={auth} {...props} />} />
            <Route path="/wrapsons" render={(props) => <Wrapsons auth={auth} {...props} />} />
            <Route path="/timezynk" render={(props) => <TimeZynk auth={auth} {...props} />} />
            <Route path="/delivery" render={(props) => <Delivery auth={auth} {...props} />} />
            <Route path="/leaderboard" render={(props) => <Leaderboard auth={auth} {...props} />} />
            <Route path="/ekonomi" render={(props) => <Ekonomi auth={auth} {...props} />} />
            <Route path="/orderschedule" render={(props) => <OrderSchedule auth={auth} {...props} />} />
            <Route path="/eventlog" render={(props) => <Eventlog auth={auth} {...props} />} />
            <Route path="/dashboard" render={(props) => <DashBoard auth={auth} {...props} />} />
            <Route path="/precision" render={(props) => <Precision auth={auth} {...props} />} />
            <Route path="/latestart" render={(props) => <LateStart auth={auth} {...props} />} />
            <Route path="/coop" render={(props) => <Coop auth={auth} {...props} />} />
            <Route path="/ica" render={(props) => <Ica auth={auth} {...props} />} />
            <Route path="/blomsterlandet" render={(props) => <Blomsterlandet auth={auth} {...props} />} />
            <Route path="/paradiset" render={(props) => <Paradiset auth={auth} {...props} />} />
            <Route path="/lundbergs" render={(props) => <Lundbergs auth={auth} {...props} />} />
            <Route path="/followup" render={(props) => <Followup auth={auth} {...props} />} />
            <Route path="/fleetlog" render={(props) => <Fleetlog auth={auth} {...props} />} />
            <Route path="/monthstats" render={(props) => <MonthStats auth={auth} {...props} />} />
            <Route path="/weeklystats" render={(props) => <WeeklyStats auth={auth} {...props} />} />
            <Route path="/planned" render={(props) => <Planned auth={auth} {...props} />} />
            <Route path="/unplanned" render={(props) => <Unplanned auth={auth} {...props} />} />
            <Route path="/zipcodes" render={(props) => <ZipCodes auth={auth} {...props} />} />
            <Route path="/deliveryorders" render={(props) => <DeliveryOrders auth={auth} {...props} />} />
            <Route path="/routedorders" render={(props) => <RoutedOrders auth={auth} {...props} />} />
            <Route path="/result" render={(props) => <Result auth={auth} {...props} />} />
            <Route path="/documents" render={(props) => <Documents auth={auth} {...props} />} />
            <Route path="/email" render={(props) => <Email auth={auth} {...props} />} />
            <Route path="/import" render={(props) => <Import auth={auth} {...props} />} />
            <Route path="/routes" render={(props) => <Routes auth={auth} {...props} />} />
            <Route path="/vrp" render={(props) => <Vrp auth={auth} {...props} />} />
            <Route path="/matkasse" render={(props) => <Matkasse auth={auth} {...props} />} />
            <Route path="/customernotes" render={(props) => <CustomerNotes auth={auth} {...props} />} />
            <Route path="/sms" render={(props) => <Sms auth={auth} {...props} />} />
            <Route path="/orderdetails" render={(props) => <OrderDetails auth={auth} {...props} />} />
            <Route path="/searchResults" render={(props) => <SearchResults auth={auth} {...props} />} />
            <Route path="/search" render={(props) => <Search auth={auth} {...props} />} />
            <Route path="/coopinvoicing" render={(props) => <CoopInvoicing auth={auth} {...props} />} />
            <Route path="/notification" render={(props) => <Notification auth={auth} {...props} />} />
            <Route path="/orderlist" render={(props => <Orderlist {...props} />)} />
            <Route path="/dailyloadlist" render={(props) => (<Dailyloadlist {...props} />)} />
            <Route path="/orderimport" render={(props) => (<OrderImport auth={auth} {...props} />)} />
        </div>
    );
}
