import React, { Component } from 'react';
import ClientComment from './clientcomment';

class LinasNoteringar extends Component {

  render() {
    return (
      <div className="container">
        <div className="commentPadding">
          <ClientComment />
        </div>
      </div>
    );
  }
}

export default LinasNoteringar;

