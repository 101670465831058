import React, { Component } from 'react';
import Tabs from 'react-responsive-tabs';
import AxfoodWillysAlvsjo from './axfoodwillysalvsjo';
import AxfoodHemkopDjur from './axfoodhemkopdjur';
import AxfoodWillysUmea from './axfoodwillysumea';
import AxfoodWillysGavle from './axfoodwillysgavle';
import AxfoodWillysVaxjo from './axfoodwillysvaxjo';
import AxfoodWillysJonkoping from './axfoodwillysjonkoping';
import AxfoodWillysLanna from './axfoodwillyslanna';
import AxfoodHemkopNacka from './axfoodhemkopnacka';
import AxfoodWillysUppsala from './axfoodwillysuppsala';
import AxfoodHemkopLinkoping from './axfoodhemkoplinkoping';
import AxfoodWillysNorrkoping from './axfoodwillysnorrkoping';
import AxfoodWillysFalun from './axfoodwillysfalun';
import AxfoodWillysEskilstuna from './axfoodwillyseskilstuna';
import AxfoodWillysVasteras from './axfoodwillysvasteras';

class Axfood extends Component {
  login() {
    this.props.auth.login();
  }

  componentWillMount() {

  }
  componentDidMount() {

  }

  render() {
    const { isAuthenticated } = this.props.auth;

    const ordersTabs = [
      { name: 'Willys Älvsjö', content: <AxfoodWillysAlvsjo /> },
      { name: 'Willys Umeå', content: <AxfoodWillysUmea /> },
      { name: 'Willys Växjö', content: <AxfoodWillysVaxjo /> },
      { name: 'Willys Gävle', content: <AxfoodWillysGavle /> },
      { name: 'Willys Jönköping', content: <AxfoodWillysJonkoping /> },
      { name: 'Willys Länna', content: <AxfoodWillysLanna /> },
      { name: 'Willys Uppsala', content: <AxfoodWillysUppsala /> },
      { name: 'Willys Falun', content: <AxfoodWillysFalun /> },
      { name: 'Willys Eskilstuna', content: <AxfoodWillysEskilstuna /> },
      { name: 'Willys Västerås', content: <AxfoodWillysVasteras /> },
      { name: 'Willys Norrköping', content: <AxfoodWillysNorrkoping /> },
      { name: 'Hemköp Nacka', content: <AxfoodHemkopNacka /> },
      { name: 'Hemköp Linköping', content: <AxfoodHemkopLinkoping /> },
      { name: 'Hemköp Djurgårdstaden', content: <AxfoodHemkopDjur /> }
    ];

    function getTabs() {
      return ordersTabs.map(ordertab => ({
        tabClassName: 'tab', // Optional
        panelClassName: 'panel', // Optional
        title: ordertab.name,
        getContent: () => ordertab.content,
      }));
    }

    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <Tabs items={getTabs()} />
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default Axfood;

