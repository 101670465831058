import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
import PropTypes from "prop-types";

class BlHaggvik extends Component {
  static contextTypes = {
    router: PropTypes.object
  }
  handleSelection(Chart) {
    if (Chart.chart.getSelection()[0]) {
      var date = this.state.rows[Chart.chart.getSelection()[0]['row'] + 1][0];
      this.context.router.history.push('/delivery?merchant=BLHA&date=' + date);
    }
  }

  componentWillMount() {

    let rows = [];
    rows.push(['Datum', "Precision %", "Antal"]);

    this.setState({ rows: rows });
    return { rows: rows };
  }
  componentDidMount() {

    let rows = this.state.rows;
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      var url = process.env.REACT_APP_GORDON_API_URL + '/order/statsovertime?merchant=Blomsterlandet Häggvik';

      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var summary = responseJson;
          var numberOfRows = summary.length;
          for (let i = 0; i < numberOfRows; i++) {

            rows.push([summary[i].datum, summary[i].precision, summary[i].antal]);
          }

          this.setState({ rows: rows });
        })
        .catch((error) => {
          console.error(error);
        });

    } else {
      console.error("401 Unauthorized");
    }
  }

  render() {
    return (
      <div className="container">
        <div className={'my-pretty-chart-container'}>
          <Chart
            chartType="ComboChart"
            data={this.state.rows}
            options={{
              title: 'Blomsterlandet Häggvik - Leveransprecision',
              vAxes: [{
                title: 'Procent %', viewWindow: {
                  min: 0,
                  max: 100
                }
              },
              {
                title: 'Antal', viewWindow: {
                  min: 0,
                  max: 50
                }
              }
              ],
              hAxis: { title: 'Datum' }, seriesType: 'bars',
              series: { 1: { type: 'line', targetAxisIndex: 1 } }
            }}
            graph_id="ComboChart2"
            chartEvents={[{
              eventName: 'select',
              callback: this.handleSelection.bind(this)
            }]}
            width="100%"
            height="500px"
            legend_toggle
          />
        </div>
      </div>

    );
  }
}

export default BlHaggvik;

