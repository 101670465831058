import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Workbook from 'react-excel-workbook';
import _ from "lodash";
import { DateRange } from 'react-date-range';
import { format, addDays } from 'date-fns';
import moment from 'moment';
import { Button, Modal, FormControl } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import Select from 'react-select';
import Switch from "react-switch";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

var clientsList = [];
var categoryList = [];
class Eventlog extends Component {
  constructor(props) {
    super(props);
    this.createComplaint = this.createComplaint.bind(this);
    this.cancel = this.cancel.bind(this);
    this.open = this.open.bind(this);
  }
  login() {
    this.props.auth.login();
  }

  componentWillMount() {

    var user = localStorage.getItem('userProfileName');

    this.setState({
      author: user
    });

    this.setState({
      showModal: false
    });

    this.state = {
      driverinformed: false
    };
    this.setState({
      comment: null,
      vehicle: null,
      routename: null,
      stopnumber: null,
      endcustomer: null,
      cost: null,
      time: null,
      drivercomment: null
    });
    this.setState({ driverinformed: false });

    this.state = {
      visibleForClient: false,
    };
    this.setState({ visibleForClient: false });
    this.state = {
      isPoint: false,
    };
    this.setState({ isPoint: false });
    this.state = {
      gordonIsResponsible: true,
    };
    this.setState({ gordonIsResponsible: true });

    this.setState({ clients: [] });
    this.setState({ categories: [] });
    this.setState({ gordoneers: [] });
    this.setState({ formattedValue: null });
    this.setState({ user: '' });

    this.state = {
      dateRange: {
        selection: {
          startDate: addDays(new Date(), -7),
          endDate: addDays(new Date(), 7),
          key: 'selection',
        },
      }
    };

    this.setState({
      dateRange: {
        selection: {
          startDate: addDays(new Date(), -7),
          endDate: addDays(new Date(), 7),
          key: 'selection',
        },
      }
    });
    this.fetchData = this.fetchData.bind(this);
    this.setState({ rows: [] });
    this.setState({ originalrows: [] });
  }
  toggleResponsibleChange = () => {
    this.setState({
      gordonIsResponsible: !this.state.gordonIsResponsible,
    });
  }
  toggleVisibleChange = () => {
    this.setState({
      visibleForClient: !this.state.visibleForClient,
    });
  }
  toggleInformedChange = () => {
    this.setState({
      driverinformed: !this.state.driverinformed,
    });
  }

  toggleIsPointChange = () => {
    this.setState({
      isPoint: !this.state.isPoint,
    });
  }

  componentDidMount() {
    this.getEvents(this.state.dateRange.selection.startDate, this.state.dateRange.selection.endDate);
  }

  getEvents(startDate, endDate) {
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + '/complaints/daterange/' + moment(startDate).format("YYYY-MM-DD") + '/' + moment(endDate).format("YYYY-MM-DD"), {
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + access_token,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((responseJson) => {

          for (var i = 0; i < responseJson.length; i++) {
            responseJson[i].created_at = moment(responseJson[i].created_at).format("YYYY-MM-DD HH:mm");
            responseJson[i].updated_at = moment(responseJson[i].updated_at).format("YYYY-MM-DD HH:mm");
            responseJson[i].deliverydate = moment(responseJson[i].deliverydate).format("YYYY-MM-DD");
            responseJson[i].isPoint = responseJson[i].isPoint ? 'Ja' : '';
            responseJson[i].gordonIsResponsible = responseJson[i].gordonIsResponsible ? 'Ja' : '';
          }

          // var result = [];
          // for (var i = 0; i < responseJson.length; i++) {
          //   if (moment().add(-7, 'days').startOf('day').isBefore(moment(responseJson[i].deliverydate)) &&
          //     moment(responseJson[i].deliverydate).isBefore(moment().endOf('day'))) {
          //     result.push(responseJson[i]);
          //   }
          // }
          let rows = responseJson;

          var url = process.env.REACT_APP_GORDON_API_URL + "/complaints/complaintcategories";

          fetch(url, {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + access_token,
            },
          })
            .then((resp) => resp.json())
            .then((dataJson) => {
              var categories = dataJson;
              categoryList = [];
              for (let i = 0; i < categories.length; i++) {
                categoryList.push({ value: categories[i].description, label: categories[i].description });
              }

              const sorter = (a, b) => {
                if (a.label > b.label) return 1;
                if (a.label < b.label) return -1;
                return 0;
              };

              categoryList = categoryList.sort(sorter);

              this.state.categories = categoryList;
              this.setState({ categories: categoryList });
              var url = process.env.REACT_APP_GORDON_API_URL + "/client/active";

              fetch(url, {
                method: 'GET',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + access_token,
                },
              })
                .then((resp) => resp.json())
                .then((dataJson) => {
                  var clients = dataJson;
                  clientsList = [];
                  for (let i = 0; i < clients.length; i++) {
                    clientsList.push({ value: clients[i].name, label: clients[i].name });
                  }

                  const sorter = (a, b) => {
                    if (a.label > b.label) return 1;
                    if (a.label < b.label) return -1;
                    return 0;
                  };

                  clientsList = clientsList.sort(sorter);

                  this.state.clients = clientsList;
                  this.setState({ clients: clientsList });
                  var url = process.env.REACT_APP_GORDON_API_URL + "/users/";

                  fetch(url, {
                    method: 'GET',
                    headers: {
                      'Accept': 'application/json',
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + access_token,
                    },
                  })
                    .then((resp) => resp.json())
                    .then((dataJson) => {
                      var gordoneers = dataJson;
                      var gordoneerList = [];
                      for (let i = 0; i < gordoneers.length; i++) {
                        gordoneerList.push({ value: gordoneers[i].fullName, label: gordoneers[i].fullName });
                      }
                      const sorter = (a, b) => {
                        if (a.label > b.label) return 1;
                        if (a.label < b.label) return -1;
                        return 0;
                      };

                      gordoneerList = gordoneerList.sort(sorter);

                      this.state.gordoneers = gordoneerList;
                      this.setState({ gordoneers: gordoneerList });
                    })
                    .catch((error) => {
                      console.error(error);
                    });
                })
                .catch((error) => {
                  console.error(error);
                });

            })
            .catch((error) => {
              console.error(error);
            });

          this.state.rows = rows;
          this.setState({ rows: rows });
          this.state.originalrows = responseJson;
          this.setState({ originalrows: responseJson });

        })
        .catch((error) => {
          console.error(error);
        });

    } else {
      console.error("401 Unauthorized");
    }
  }
  fetchData(state, instance) {
    let filteredData = this.state.originalrows;
    if (state.filtered.length) {
      filteredData = state.filtered.reduce((filteredSoFar, nextFilter) => {
        return filteredSoFar.filter(row => {
          return (row[nextFilter.id] + "").includes(nextFilter.value);
        });
      }, filteredData);
    }
    // You can also use the sorting in your request, but again, you are responsible for applying it.
    const sortedData = _.orderBy(
      filteredData,
      state.sorted.map(sort => {
        return row => {
          if (row[sort.id] === null || row[sort.id] === undefined) {
            return -Infinity;
          }
          return typeof row[sort.id] === "string"
            ? row[sort.id].toLowerCase()
            : row[sort.id];
        };
      }),
      state.sorted.map(d => (d.desc ? "desc" : "asc"))
    );

    var result = [];
    for (var i = 0; i < sortedData.length; i++) {
      if (moment().add(-7, 'days').isBefore(moment(sortedData[i].deliverydate)) &&
        moment(sortedData[i].deliverydate).isBefore(moment())) {
        result.push(sortedData[i]);
      }
    }
    let rows = sortedData;
    this.state.rows = rows;
    this.setState({ rows: rows });
  }


  handleRangeChange(which, payload) {
    console.log(which, payload);
    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });

    this.getEvents(payload.selection.startDate, payload.selection.endDate);

    // var result = [];
    // if (payload.selection != null) {
    //   for (var i = 0; i < this.state.originalrows.length; i++) {
    //     if (moment(payload.selection.startDate).startOf('day').isBefore(moment(this.state.originalrows[i].deliverydate)) &&
    //       moment(this.state.originalrows[i].deliverydate).isBefore(moment(payload.selection.endDate).endOf('day'))) {
    //       result.push(this.state.originalrows[i]);
    //     }

    //   }
    // }
    // this.setState({
    //   rows:
    //     result
    //   ,
    // });
  }
  handleChange(which, payload) {
    this.setState({
      [which]: payload
    });
  }
  formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return format(date, 'YYYY-MM-DD');
  }


  getInitialState() {
    var value = new Date().toISOString();
    return {
      value: value
    }
  }
  handleDateChange(value, formattedValue) {
    this.setState({
      value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z" 
      formattedValue: formattedValue // Formatted String, ex: "11/19/2016" 
    });

  }
  openevent(row) {
    this.setState({
      selectedGordoneer: row.original.drivername,
      formattedValue: row.original.deliverydate,
      value: new Date(row.original.deliverydate).toISOString(),
      selectedClient: row.original.client,
      selectedCategory: row.original.category,
      driverinformed: row.original.driverinformed,
      gordonIsResponsible: row.original.gordonIsResponsible == "Ja" ? true : row.original.gordonIsResponsible == "Nej" ? false : row.original.gordonIsResponsible,
      visibleForClient: row.original.visibleForClient,
      isPoint: row.original.isPoint,
      comment: row.original.comment,
      vehicle: row.original.vehicle,
      routename: row.original.routename,
      stopnumber: row.original.stopnumber,
      endcustomer: row.original.endcustomer,
      cost: row.original.cost,
      time: row.original.time,
      drivercomment: row.original.drivercomment,
      selectedId: row.original._id
    });
    this.setState({
      showModal: true
    });

  }
  open() {
    this.setState({
      showModal: true
    });
  }

  delete() {
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {
      var url = process.env.REACT_APP_GORDON_API_URL + '/complaints/delete/' + this.state.selectedId;
      fetch(url, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => {

          this.setState({
            comment: null,
            vehicle: null,
            routename: null,
            stopnumber: null,
            endcustomer: null,
            cost: null,
            time: null,
            drivercomment: null
          });
          this.setState({ driverinformed: false });
          this.setState({ visibleForClient: false });
          this.setState({ isPoint: false });
          this.setState({ gordonIsResponsible: true });
          this.setState({ value: null });
          this.setState({ formattedValue: null });
          this.setState({ selectedCategory: null });
          this.setState({ selectedClient: null });
          this.setState({ selectedGordoneer: null });
          this.setState({ selectedId: null });
          this.setState({
            showModal: false
          });

          this.getEvents(this.state.dateRange.selection.startDate, this.state.dateRange.selection.endDate);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }

  cancel() {
    this.setState({
      comment: null,
      vehicle: null,
      routename: null,
      stopnumber: null,
      endcustomer: null,
      cost: null,
      time: null,
      drivercomment: null
    });
    this.setState({ driverinformed: false });
    this.setState({ visibleForClient: false });
    this.setState({ isPoint: false });
    this.setState({ gordonIsResponsible: true });
    this.setState({ value: null });
    this.setState({ formattedValue: null });
    this.setState({ selectedCategory: null });
    this.setState({ selectedClient: null });
    this.setState({ selectedGordoneer: null });
    this.setState({ selectedId: null });
    this.setState({
      showModal: false
    });
  }

  updateComplaint() {
    var updatebody = {
      'id': this.state.selectedId,
      'drivername': this.state.selectedGordoneer,
      'deliverydate': this.state.formattedValue,
      'client': this.state.selectedClient,
      'category': this.state.selectedCategory,
      'comment': this.state.comment,
      'vehicle': this.state.vehicle,
      'routename': this.state.routename,
      'stopnumber': this.state.stopnumber == "" ? null : this.state.stopnumber,
      'endcustomer': this.state.endcustomer,
      'cost': this.state.cost == "" ? null : this.state.cost,
      'time': this.state.time == "" ? null : this.state.time,
      'driverinformed': this.state.driverinformed,
      'drivercomment': this.state.drivercomment,
      'gordonIsResponsible': this.state.gordonIsResponsible,
      'visibleForClient': this.state.visibleForClient,
      'isPoint': this.state.isPoint
    };

    // var updatebody = '{"id" : "' + selectedId + '", "' + cellName + '": "' + cellValue + '", "updated_by" : "' + this.state.author + '"}';

    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + '/complaints/update', {
        method: 'PUT',
        body: JSON.stringify(updatebody),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => {

          this.setState({
            showModal: false
          });
          this.setState({ driverinformed: false });
          this.setState({ visibleForClient: false });
          this.setState({ isPoint: false });
          this.setState({ gordonIsResponsible: true });
          this.setState({ value: null });
          this.setState({ formattedValue: null });
          this.setState({ selectedCategory: null });
          this.setState({ selectedClient: null });
          this.setState({ selectedGordoneer: null });
          this.setState({ selectedId: null });
          this.setState({
            comment: null,
            vehicle: null,
            routename: null,
            stopnumber: null,
            endcustomer: null,
            cost: null,
            time: null,
            drivercomment: null
          });
          this.getEvents(this.state.dateRange.selection.startDate, this.state.dateRange.selection.endDate);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }
  createComplaint() {

    if (this.state.selectedId != null) {
      this.updateComplaint();
    }
    else {

      var postbody = {
        'drivername': this.state.selectedGordoneer,
        'deliverydate': this.state.formattedValue,
        'client': this.state.selectedClient,
        'category': this.state.selectedCategory,
        'comment': this.state.comment,
        'vehicle': this.state.vehicle,
        'routename': this.state.routename,
        'stopnumber': this.state.stopnumber,
        'endcustomer': this.state.endcustomer,
        'cost': this.state.cost,
        'time': this.state.time,
        'driverinformed': this.state.driverinformed,
        'drivercomment': this.state.drivercomment,
        'gordonIsResponsible': this.state.gordonIsResponsible,
        'visibleForClient': this.state.visibleForClient,
        'isPoint': this.state.isPoint,
        'created_by': this.state.author
      };
      var access_token = localStorage.getItem('access_token');
      if (access_token != null) {

        fetch(process.env.REACT_APP_GORDON_API_URL + '/complaints', {
          method: 'POST',
          body: JSON.stringify(postbody),
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + access_token,
          },
        })
          .then((response) => {

            this.setState({
              showModal: false
            });
            this.setState({ driverinformed: false });
            this.setState({ visibleForClient: false });
            this.setState({ isPoint: false });
            this.setState({ gordonIsResponsible: true });
            this.setState({ value: null });
            this.setState({ formattedValue: null });
            this.setState({ selectedCategory: null });
            this.setState({ selectedClient: null });
            this.setState({ selectedGordoneer: null });
            this.setState({ selectedId: null });
            this.setState({
              comment: null,
              vehicle: null,
              routename: null,
              stopnumber: null,
              endcustomer: null,
              cost: null,
              time: null,
              drivercomment: null
            });
            this.getEvents(this.state.dateRange.selection.startDate, this.state.dateRange.selection.endDate);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.error("401 Unauthorized");
      }
    }
  }

  onClientSelect(client) {
    if (client != null) {
      this.setState({
        selectedClient: client.label
      });
    }
    else {
      this.setState({
        selectedClient: null
      })
    }
  }
  commentChanged(evt) {
    const comment = evt.target.value;
    this.setState({ comment });
  }
  vehicleChanged(evt) {
    const vehicle = evt.target.value;
    this.setState({ vehicle });
  }
  routenameChanged(evt) {
    const routename = evt.target.value;
    this.setState({ routename });
  }

  stopnumberChanged(evt) {
    const stopnumber = evt.target.value;
    this.setState({ stopnumber });
  }
  endcustomerChanged(evt) {
    const endcustomer = evt.target.value;
    this.setState({ endcustomer });
  }
  costChanged(evt) {
    const cost = evt.target.value;
    this.setState({ cost });
  }
  timeChanged(evt) {
    const time = evt.target.value;
    this.setState({ time });
  }
  drivercommentChanged(evt) {
    const drivercomment = evt.target.value;
    this.setState({ drivercomment });
  }

  onCategorySelect(category) {
    if (category != null) {
      this.setState({
        selectedCategory: category.label
      });
    }
    else {
      this.setState({
        selectedCategory: null
      })
    }
  }

  onGordoneerSelect(gordoneer) {
    if (gordoneer != null) {
      this.setState({
        selectedGordoneer: gordoneer.label
      });
    }
    else {
      this.setState({
        selectedGordoneer: null
      })
    }
  }

  render() {


    const { formattedValue } = this.state;

    const isEnabled = formattedValue != null && this.state.selectedCategory != null && this.state.selectedGordoneer && this.state.selectedClient != null;


    var { categories, clients, gordoneers } = this.state;
    if (categories == null) {
      categories = [];
    }
    if (gordoneers == null) {
      gordoneers = [];
    }
    if (clients == null) {
      clients = [];
    }

    const columns = [{
      Header: 'Gordoneer',
      accessor: 'drivername'
    }, {
      Header: 'Butik',
      accessor: 'client'
    }, {
      Header: 'Händelsedatum',
      accessor: 'deliverydate'
    }, {
      Header: 'Orsak',
      accessor: 'category'
    }, {
      Header: 'Beskrivning',
      accessor: 'comment'
    }, {
      Header: 'Tid',
      accessor: 'time'
    }, {
      Header: 'Point',
      accessor: 'isPoint'
    }, {
      Header: 'Gordon ansvarig?',
      accessor: 'gordonIsResponsible'
    }, {
      Header: 'Kostnad',
      accessor: 'cost'

    }, {
      Header: 'Skapad av',
      accessor: 'created_by'
    }, {
      Header: 'Skapad',
      accessor: 'created_at'
    }, {
      Header: 'Uppdaterad av',
      accessor: 'updated_by'
    }, {
      Header: 'Uppdaterad',
      accessor: 'updated_at'
    },
    {
      Header: "Visa",
      accessor: '_id',
      Cell: row => (

        <Button
          bsStyle="primary"
          className="btn-margin eventbtn"
          onClick={this.openevent.bind(this, row)}>Öppna...</Button>
      )
    }]

    const { isAuthenticated } = this.props.auth;
    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Händelselogg</h3>
              <DateRange
                onChange={this.handleRangeChange.bind(this, 'dateRange')}
                moveRangeOnFirstSelection={false}
                dateDisplayFormat="YYYY-MM-DD"
                ranges={[this.state.dateRange.selection]}
                className={'PreviewArea'}
              />

              <br />

              <Button
                bsStyle="primary"
                className="btn-margin btn-padding"
                onClick={this.open.bind(this)}>Lägg till...</Button>
              <br />
              <br />
              <Workbook filename="handelselogg.xlsx" element={<button className="btn btn-primary">Exportera till Excel</button>}>
                <Workbook.Sheet data={() => this.state.rows} name="Händelselogg">
                  <Workbook.Column label="Gordoneer" value="drivername" />
                  <Workbook.Column label="Butik" value="client" />
                  <Workbook.Column label="Händelsedatum" value="deliverydate" />
                  <Workbook.Column label="Orsak" value="category" />
                  <Workbook.Column label="Beskrivning" value="comment" />
                  <Workbook.Column label="Rutt" value="routename" />
                  <Workbook.Column label="Tid" value="time" />
                  <Workbook.Column label="Point" value="isPoint" />
                  <Workbook.Column label="Gordon ansvarig" value="gordonIsResponsible" />
                  <Workbook.Column label="Kostnad" value="cost" />
                  <Workbook.Column label="Skapad" value="created_at" />
                </Workbook.Sheet>
              </Workbook>
              <br />
              <ReactTable
                filterable={true}
                data={this.state.rows}
                defaultSorted={[{
                  id: 'deliverydate',
                  desc: true,
                }]}
                onFetchData={this.fetchData}
                columns={columns}
                defaultPageSize={50} />


              <Modal bsSize="large" show={
                this.state.showModal
              }
                onHide={
                  this.cancel.bind(this)
                } dimmer={"blurring"}>
                <Modal.Header >
                  <Modal.Title>{this.state.selectedId != null ? 'Uppdatera händelse' : 'Lägg till händelse'}</Modal.Title> </Modal.Header >
                <Modal.Body  >
                  <table id="reklamationerTable">
                    <tr>
                      <td>
                        <div className="datePicker">
                          <p>Leveransdag: *</p>
                          <DatePicker autoFocus={false} showTodayButton={true} todayButtonLabel="Idag" id="example-datepicker" value={this.state.value} dateFormat='YYYY-MM-DD' weekStartsOn={1} onChange={this.handleDateChange.bind(this)}
                            monthLabels={['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']}
                            dayLabels={['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör']} />
                        </div>
                        <br />  <br />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div className="datePicker paddingmore">
                          <p>Gordoneer: *</p>

                          <div className="dropdownEventSearch">

                            <Select placeholder="Välj Gordoneer"
                              name="ddSelectGordoneer"
                              onChange={this.onGordoneerSelect.bind(this)}
                              options={gordoneers}
                            />
                          </div>

                        </div>

                      </td>
                      <td>
                        <div className="datePicker paddingmore">
                          <p>Butik/Kund: *</p>


                          <div className="dropdownEventSearch">

                            <Select placeholder="Välj kund/butik"
                              name="ddSelectClient"
                              onChange={this.onClientSelect.bind(this)}
                              options={clients}
                            />
                          </div>
                        </div>
                      </td>
                      <td>
                        <div className="datePicker paddingmore">
                          <p>Orsak: *</p>

                          <div className="dropdownEventSearch">

                            <Select placeholder="Välj orsak"
                              name="ddSelectKategori"
                              onChange={this.onCategorySelect.bind(this)}
                              options={categories}
                            />
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>

                  <br />
                  <div className="row overview">
                    <div className="col-md-4 user-pad text-left">
                      <p>Tid i min ('Sen till pass', 'Gods ej klar' etc):</p>
                      <FormControl type="number" onInput={this.timeChanged.bind(this)} value={this.state.time}
                        placeholder='Tid i minuter' />
                    </div>
                    <div className="col-md-4 user-pad text-left">
                      <p>Fordon:</p>
                      <FormControl onInput={this.vehicleChanged.bind(this)} value={this.state.vehicle}
                        placeholder='t ex registreringsnummer' />
                    </div>
                    <div className="col-md-4 user-pad text-left">
                      <p>Namn på rutt,zon, etc:</p>
                      <FormControl onInput={this.routenameChanged.bind(this)} value={this.state.routename}
                        placeholder='Ruttnamn' />
                    </div>
                  </div>
                  <br />
                  <div className="row overview">
                    <div className="col-md-4 user-pad text-left">
                      <p>Kostnad för Gordon Delivery:</p>
                      <FormControl type="number" onInput={this.costChanged.bind(this)} value={this.state.cost}
                        placeholder='Kostnad i kr' />
                    </div>
                    <div className="col-md-4 user-pad text-left">
                      <p>Ordernr/Stoppnr:</p>
                      <FormControl type="number" onInput={this.stopnumberChanged.bind(this)} value={this.state.stopnumber}
                        placeholder='Ordernr/Stoppnr' />
                    </div>
                    <div className="col-md-4 user-pad text-left">
                      <p>Slutkundens namn:</p>
                      <FormControl onInput={this.endcustomerChanged.bind(this)} value={this.state.endcustomer}
                        placeholder='Slutkund/Stoppnumer' />
                    </div>
                  </div>

                  <br />
                  <p>Beskrivning:</p>
                  <FormControl onInput={this.commentChanged.bind(this)} value={this.state.comment}
                    placeholder="Beskrivning av händelsen" />
                  <br />
                  <div className="row overview">
                    <div className="col-md-4 user-pad text-left">
                      <p>Klassa som Prick (Point System):</p>
                      <Switch
                        onChange={this.toggleIsPointChange}
                        checked={this.state.isPoint}
                        id="normal-switch"
                      />
                    </div>
                    <div className="col-md-4 user-pad text-left">
                      <p>Är Gordon ansvarig för händelsen?:</p>
                      <Switch
                        onChange={this.toggleResponsibleChange}
                        checked={this.state.gordonIsResponsible}
                        id="normal-switch"
                      />
                    </div>
                    <div className="col-md-4 user-pad text-left">
                      <p>Synlig för kund:</p>
                      <Switch
                        onChange={this.toggleVisibleChange}
                        checked={this.state.visibleForClient}
                        id="normal-switch"
                      />
                    </div>
                  </div>
                  <br />
                  <p>Informerat Gordoneer:</p>
                  <Switch
                    onChange={this.toggleInformedChange}
                    checked={this.state.driverinformed}
                    id="normal-switch"
                  />
                  <br />
                  <p>Kommentar från Gordoneer:</p>
                  <FormControl onInput={this.drivercommentChanged.bind(this)} value={this.state.drivercomment}
                    placeholder="Kommentar från Gordoneer:" />

                  <br />

                </Modal.Body>
                <Modal.Footer>
                  {
                    this.state.selectedId != null && (
                      <Button bsStyle="danger" className="pull-left" onClick={this.delete.bind(this)}>Radera</Button>
                    )}
                  <Button negative onClick={this.cancel.bind(this)}>Avbryt</Button>
                  <Button positive
                    bsStyle="success"
                    onClick={this.createComplaint.bind(this)} disabled={!isEnabled} >{this.state.selectedId != null ? 'Uppdatera' : 'Spara'}</Button>
                </Modal.Footer>
              </Modal>

            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default Eventlog;

