import React, { Component } from "react";
import { Route } from 'react-router-dom';
import "./App.css";
import "./react-grid-layout/styles.css";
import "./react-resizable/styles.css";
import SideBar from "./common/sidebar";
import Profile from "./scripts/profile";
import { SignIn } from './scripts/signin';
import Header from './scripts/Header';
import Callback from './Callback/Callback';
import { makeMainRoutes } from './routes';
import moment from "moment-timezone";
import CacheBuster from './CacheBuster';

import ReactTooltip from "react-tooltip";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";




moment.tz.setDefault("Europe/Stockholm");

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      url: '/home',
      group: '',
      notifications: [],
    }

    this.timer = null;

    this.login = this.login.bind(this);
    // this.fetchNotifications = this.fetchNotifications.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    // this.loggedIn = this.loggedIn.bind(this)
    // this.loggedOut = this.loggedOut.bind(this);
    // this.signOffNote = this.signOffNote.bind(this);

  }
  /*
    signOffNote(note){
      let access_token = localStorage.getItem('access_token');
      console.log(note)
  
      fetch(process.env.REACT_APP_GORDON_API_URL+ '/notification/remove', {
          method: 'POST',
          body: JSON.stringify(note),
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + access_token,
            },
      }).then(() => {
          this.fetchNotifications();
      }).catch((err) => {
        console.log(err);
      })
  }*/

  /*fetchNotifications() {
    if (!this.timer) {
      this.timer = setInterval(this.fetchNotifications, 15000)
    }

    let access_token = localStorage.getItem('access_token');

    fetch(process.env.REACT_APP_GORDON_API_URL + '/notification', {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
    })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === 'ok') {
          this.setState({ ...this.state, notifications: result.data })
        }

      })
      .catch((err) => {
        console.log(err);
      });
  }

  loggedIn() {
    console.log('user signed in')
    this.fetchNotifications();
  }

  loggedOut(){
    if(this.timer){
      clearInterval(this.timer);
    }
    this.setState({...this.state, notifications: []})
    console.log('user logged out')

  }*/

  componentDidMount() {
    //this.fetchNotifications();
    //this.props.auth.on('login', this.loggedIn);
    //this.props.auth.on('logout', this.loggedOut);
    // notification.init(this.props.auth);
  }

  componentWillUnmount() {
    /*if (this.timer) {
      clearInterval(this.timer);
    }

    this.props.auth.removeListener('login', this.loggedIn);
    this.props.auth.removeListener('logout', this.loggedOut);*/

    // notification.stop();
  }


  goTo(route) {
    this.props.history.replace(`/${route}`);
  }

  login() {
    if (!this.props.auth.isAuthenticated()) {
      return this.props.auth.login();
    }
    this.props.auth.logout();

  }

  handleAuthentication = (nextState, replace) => {
    if (/access_token|id_token|error/.test(nextState.location.hash)) {
      this.props.auth.handleAuthentication();
    }
  }

  notify = () =>
    toast.info("Hej! Ha en fin dag!", {
      position: toast.POSITION.TOP_CENTER
    });

  render() {
    const { isAuthenticated, login, logout } = this.props.auth;

    let trusted = isAuthenticated();

    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }
          if (!trusted) {
            return (
              <div >
                <Header login={login} logout={logout} isAuthenticated={isAuthenticated}  />
                <SignIn login={login} />
                <Route path="/callback" render={(props) => {
                  this.handleAuthentication(props);
                  return <Callback {...props} />
                }} />
              </div>
            )
          }

          return (
            <div >
              <SideBar {...this.props} />
              <Header login={login} logout={logout} isAuthenticated={isAuthenticated}  {...this.props} />
              {makeMainRoutes(this.props)}
              <ToastContainer />
            </div>
          );
        }}
      </CacheBuster>
    );
  }
}

export default App;
