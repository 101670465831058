import React, { Component } from 'react';
import { Map, Polyline, Marker, GoogleApiWrapper, InfoWindow } from 'google-maps-react';
import myData from './vrp.json';
import CheckBox from './checkbox';

var randomColor = require('randomcolor');
const decodePolyline = require('decode-google-map-polyline');
/*global google*/

class Vrp extends Component {

  login() {
    this.props.auth.login();
  }

  componentWillMount() {
    this.setState({ stats: {} });
    this.setState({ routes: [] });
    this.setState({ routeinfo: [] });
    this.setState({ markers: [] });

    this.setState({ isallchecked: true });
  }

  componentDidMount() {

    var routes = [];
    var markers = [];
    var routeinfo = [];

    var result = myData;

    var unservedkeys = Object.keys(result.output.unserved);
    var network = result.input.network;

    var solution = result.output.solution;

    var solutionkeys = Object.keys(solution);
    var networkkeys = Object.keys(network);

    var colors = randomColor({ count: solutionkeys.length });
    var counter = 0;
    for (var i = 0; i < solutionkeys.length; i++) {
      var stops = solution[solutionkeys[i]];
      var routename = solutionkeys[i];
      counter = 0;

      stops.forEach(stop => {
        if (stop.location_id != 'depot') {
          if (networkkeys.indexOf(stop.location_id) > -1) {
            var index = networkkeys.indexOf(stop.location_id);
            var address = network[networkkeys[index]];
            var marker = {};
            if (unservedkeys.indexOf(stop.location_id) > -1) {
              marker.color = "C0C0C0";
            }
            else {
              marker.color = colors[i];
            }
            marker.index = counter;
            marker.lat = address.lat;
            marker.lng = address.lng;
            marker.visible = true;
            marker.routename = routename;
            markers.push(marker);
            counter++;
          }
        }
      });
      var info = {};
      info.id = i;
      info.value = solutionkeys[i];
      info.isChecked = true;
      routeinfo.push(info);
    }
    if (unservedkeys != null && unservedkeys.length > 0) {
      var info = {};
      info.id = i;
      info.value = "unserved";
      info.isChecked = true;
      routeinfo.push(info);
      var counter = 0;
      for (var i = 0; i < unservedkeys.length; i++) {
        var stop = network[unservedkeys[i]];
        if (stop != null) {
          var marker = {};
          marker.color = "C0C0C0";
          marker.index = counter;
          marker.lat = stop.lat;
          marker.lng = stop.lng;
          marker.visible = true;
          marker.routename = "unserved";
          markers.push(marker);
          counter++;
        }
      }
    }

    var keys = Object.keys(result.output.polylines);

    for (var i = 0; i < keys.length; i++) {
      var route = {};
      route.name = keys[i];
      route.color = colors[i];
      var polyresult = decodePolyline(result.output.polylines[keys[i]][0]);
      polyresult.forEach(element => {
        element.lat = element.lat / 10;
        element.lng = element.lng / 10;
      });
      route.polyline = polyresult;
      route.visible = true;
      routes.push(route);
    }
    this.setState({ markers: markers });
    this.setState({ routes: routes });
    this.setState({ routeinfo: routeinfo });

    var stats = {};
    stats.visits = result.visits;
    stats.traveltime = result.output.total_travel_time;
    stats.idletime = result.output.total_idle_time;
    stats.numunserved = result.output.num_unserved;
    stats.workingtime = result.output.total_working_time;
    stats.distance = result.output.total_distance;
    stats.latevisits = result.output.num_late_visits;
    this.setState({ stats: stats });
  }


  handleAllChecked = (event) => {
    let routeinfo = this.state.routeinfo
    routeinfo.forEach(route => route.isChecked = event.target.checked)

    let markers = this.state.markers
    markers.forEach(marker => marker.visible = event.target.checked)

    let routes = this.state.routes
    routes.forEach(polyroute => polyroute.visible = event.target.checked)

    this.setState({ isallchecked: event.target.checked });

    this.setState({ routeinfo: routeinfo });

  }

  handleCheckChieldElement = (event) => {
    let routeinfo = this.state.routeinfo
    let markers = this.state.markers
    let routes = this.state.routes
    routeinfo.forEach(route => {
      if (route.value === event.target.value) {

        markers.forEach(marker => route.value === marker.routename ? marker.visible = event.target.checked : null)

        routes.forEach(polyroute => route.value === polyroute.name ? polyroute.visible = event.target.checked : null)

        route.isChecked = event.target.checked;
      }
    })

    this.setState({ routeinfo: routeinfo });
    this.setState({ routes: routes });
    this.setState({ markers: markers });
  }

  render() {
    const { isAuthenticated } = this.props.auth;

    var routes = this.state.routes;
    var markers = this.state.markers;

    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <div style={{ height: '100vh', width: '100%' }}>
                <Map google={this.props.google} zoom={8}
                  initialCenter={{
                    lat: 59.329382,
                    lng: 18.063798
                  }}>

                  {
                    routes.map(route => {
                      return <Polyline
                        path={route.polyline}
                        options={{
                          strokeOpacity: 1,
                          strokeWeight: 5,
                          strokeColor: route.color
                        }}
                      />;
                    })
                  }
                  {
                    markers.map((marker, i) => {
                      var index = marker.index + 1;
                      return <Marker
                        key={i}
                        icon={{
                          url: "http://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|" + marker.color.replace('#', ''),
                          scale: 3
                        }}
                        label={index.toString()}
                        position={{ lat: marker.lat, lng: marker.lng }}
                      />;
                    })
                  }

                  {
                    markers.map(marker => {
                      return <InfoWindow
                        position={{ lat: marker.lat, lng: marker.lng }} >
                        <small>
                          Click on any of the markers to display an additional info.
      </small>
                      </InfoWindow>;
                    })
                  }
                </Map>
              </div>
              <div className="over_map">
                <h4>Ruttinformation:</h4>
                <p>Antal stopp: {this.state.stats.visits}</p>
                <p>Antal sena stopp: {this.state.stats.latevisits}</p>
                <p>Ej ruttade: {this.state.stats.numunserved}</p>
                <p>Total restid (min): {this.state.stats.traveltime}</p>
                <p>Total arbetstid (min): {this.state.stats.workingtime}</p>
                <p>Total sträcka (km): {this.state.stats.distance}</p>
                <h5>Rutter:</h5>
                <input type="checkbox" onClick={this.handleAllChecked} value="checkedall" checked={this.state.isallchecked} />  Markera alla / Avmarkera alla
        <ul>
                  {
                    this.state.routeinfo.map((route) => {
                      return (<CheckBox handleCheckChieldElement={this.handleCheckChieldElement} {...route} />)
                    })
                  }
                </ul>
              </div>
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBTbP0T250T18ulE63WcbgXumSU8SNZpPQ'
})(Vrp);
