import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/sv';
import { LineChart, Line, XAxis, Tooltip } from 'recharts';

class CustomizedLabel extends React.Component{
    render() {
        const { x, y, stroke, value } = this.props;

        return <text x={x} y={y} dy={-10} fill="white" fontSize={12} textAnchor="middle">{value}</text>
    }
};

class TotalComplaints extends Component {

    componentWillMount() {

        this.setState({ period: '' });
        this.setState({ rows: [] });
        this.setState({ reklamationer: '' });
        return { reklamationer: '', period: '' };
    }

    componentDidMount() {
        moment.locale('sv');

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            fetch(process.env.REACT_APP_GORDON_API_URL + '/complaints/all/', {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => response.json())
                .then((dataJson) => {

                    this.state.reklamationer = dataJson.length;
                    this.setState({ reklamationer: dataJson.length });

                    var period = this.capitalizeFirstLetter(moment().add(0, 'M').format("MMMM YYYY"));
                    this.state.period = period;
                    this.setState({ period: period });

                    fetch(process.env.REACT_APP_GORDON_API_URL + '/complaints/allquarter/', {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + access_token,
                        },
                    })
                        .then((response) => response.json())
                        .then((dataJson) => {

                            for (let i = 0; i < dataJson.length; i++) {
                                dataJson[i].name = this.getMonth(dataJson[i]._id.month);
                                dataJson[i].antal = dataJson[i].count;
                            }

                            this.state.rows = dataJson;
                            this.setState({ rows: dataJson });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                })
                .catch((error) => {
                    console.error(error);
                });


        } else {
            console.error("401 Unauthorized");
        }
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    getMonth(int) {
        if (int == 1) {
            return "januari";
        }
        if (int == 2) {
            return "februari";
        }
        if (int == 3) {
            return "mars";
        }
        if (int == 4) {
            return "april";
        }
        if (int == 5) {
            return "maj";
        }
        if (int == 6) {
            return "juni";
        }
        if (int == 7) {
            return "juli";
        }
        if (int == 8) {
            return "augusti";
        }
        if (int == 9) {
            return "september";
        }
        if (int == 10) {
            return "oktober";
        }
        if (int == 11) {
            return "november";
        }
        if (int == 12) {
            return "december";
        }
    }
    render() {

        return (
            <div>

                <div className="dashboardreklamationer">
                    <h3><strong>{this.state.reklamationer} st</strong></h3>
                    <strong>Sen till sitt pass ({this.state.period})</strong>
                </div>
                <br />
                <div className="dashboardreklamationerright">
                    <LineChart width={270} height={170} data={this.state.rows}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <XAxis dataKey="name" stroke="#DB524A" />
                        <Tooltip itemStyle={{ color: '#000' }} />
                        <Line type="monotone" activeDot={{ r: 8 }} dataKey="antal" stroke="#fff" label={<CustomizedLabel />} />
                    </LineChart>
                </div>
            </div>

        );
    }
}

export default TotalComplaints;