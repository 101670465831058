import React from 'react';
import moment from 'moment'
import { notification } from '../notificationHandler';


class Notification extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            notifications: []
        }

        this.renderTableRows = this.renderTableRows.bind(this);
        this.noteUpdate = this.noteUpdate.bind(this);

    }

    noteUpdate(notes){
        this.setState({...this.state, notifications: notes});
    }

    componentDidMount() {
        let notes = notification.getNotifications();
        this.setState({ ...this.state, notifications: notes });
        notification.on('notificationUpdate', this.noteUpdate);
    }

    componentWillUnmount(){
        notification.removeListener('notificationUpdate', this.noteUpdate);
    }



    renderTableRows() {
        let { notifications } = this.state;
        let rows = [];

        notifications.forEach((note) => {
            rows.push(
                <tr key={note._id}>
                    <td>{note.subject}</td>
                    <td>{note.type}</td>
                    <td>{note.priority}</td>
                    <td>{note.reporter}</td>
                    <td>{note.tags}</td>
                    <td>{note.message}</td>
                    <td>{new moment(note.created_at).format('YYYY-MM-DD HH:mm')}</td>
                    <td><button onClick={() => { notification.signOffNote(note) }}>Sign off</button></td>
                </tr>)
        });

        return rows;

    }


    render() {
        let { notifications } = this.state;
        return (
            <div className="container">
                <h3>Notifications</h3>

                {notifications.length ? (
                    <table className="table table-dashed" style={{ width: "100%" }}>
                        <thead>
                            <tr>
                                <th style={{ border: "none" }}>Subject</th>
                                <th style={{ border: "none" }}>Type</th>
                                <th style={{ border: "none" }}>Priority</th>
                                <th style={{ border: "none" }}>Reporter</th>
                                <th style={{ border: "none" }}>Tags</th>
                                <th style={{ border: "none" }}>Message</th>
                                <th style={{ border: "none" }}></th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.renderTableRows()}
                        </tbody>
                    </table>
                ) :
                    (<div>
                        Nothing to report...
                    </div>)}


            </div>)
    }
}


export default Notification;