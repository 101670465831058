import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import _ from "lodash";
import moment from 'moment';
import Workbook from 'react-excel-workbook';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { SyncLoader } from 'react-spinners';
import matchSorter from 'match-sorter';
import { Button } from 'react-bootstrap';
const queryString = require('query-string');

class Routes extends Component {
    constructor(props) {
        super(props);
    }
    login() {
        this.props.auth.login();
    }
    componentWillMount() {
        this.setState({ group: '' });
        this.setState({ rows: [] });
        this.setState({ originalrows: [] });
        this.setState({ deliveryorder: null });
        this.setState({
            isLoading: true
        });
    }

    intervalID = 0;

    componentDidMount() {
        var group = localStorage.getItem('access_group');
        this.setState({
            group
        });
        this.getRoutes(group);

        this.intervalID =setInterval(this.getRoutes.bind(this, group), 30000);
    }

    componentWillUnmount() {
        clearInterval(this.intervalID);
      }
      

    getRoutes(group) {
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {
            var parsed = queryString.parse(this.props.location.search);
            if (parsed.deliveryorder != null) {

                var deliveryorder = parsed.deliveryorder;
                this.setState({ deliveryorder: deliveryorder });
                fetch(process.env.REACT_APP_GORDON_API_URL + '/route/routes?deliveryorder=' + deliveryorder + "&merchant=" + group, {
                    method: 'get',
                    headers: {
                        'Authorization': 'Bearer ' + access_token,
                        'Content-Type': 'application/json'
                    }
                })
                    .then((response) => response.json())
                    .then((responseJson) => {

                        var rows = [];

                        for (var i = 0; i < responseJson.length; i++) {
                            if (responseJson[i].numberOfStops > 0) {

                                responseJson[i].promisedStartTime = moment(responseJson[i].promisedStartTime).format("HH:mm");
                                responseJson[i].promisedEndTime = moment(responseJson[i].promisedEndTime).format("HH:mm");
                                responseJson[i].startTime = responseJson[i].startTime != null ? moment(responseJson[i].startTime).format("HH:mm") : "";
                                responseJson[i].endTime = responseJson[i].endTime != null ? moment(responseJson[i].endTime).format("HH:mm") : "";

                                responseJson[i].deliveryDate = moment(responseJson[i].deliveryDate).format("YYYY-MM-DD");
                                responseJson[i].distance = Math.round(responseJson[i].distanceinmeter / 1000);
                                if (responseJson[i].status == "Assigned") {
                                    responseJson[i].status = "Uppladdat till Bringg " + moment(responseJson[i].updated_at).format("YYYY-MM-DD HH:mm");

                                }
                                else if (responseJson[i].status == "Uploading") {
                                    responseJson[i].status = "Laddar upp till Bringg...";
                                }
                                else if (responseJson[i].status == "Done") {
                                    responseJson[i].status = "Avslutad";
                                }
                                else if (responseJson[i].status == "Started") {
                                    responseJson[i].status = "Startad";
                                }
                                else {
                                    responseJson[i].status = "Ej uppladdad";
                                }

                                rows.push(responseJson[i]);

                            }
                        }

                        this.state.rows = rows;
                        this.setState({ rows: rows });
                        this.state.originalrows = rows;
                        this.setState({ originalrows: rows });
                        this.setState({
                            isLoading: false,
                            pageSize: rows.length
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                        this.setState({
                            isLoading: false
                        });
                    });
            }
        } else {
            console.error("401 Unauthorized");
            this.setState({
                isLoading: false
            });
        }
    }
    sendToBringg(row) {
        this.setState({
            isLoading: true
        });

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            fetch(process.env.REACT_APP_GORDON_API_URL + '/planning/dispatch/' + row.original.name, {
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + access_token,
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    fetch(process.env.REACT_APP_GORDON_API_URL + '/planning/pickup/' + row.original.name, {
                        method: 'get',
                        headers: {
                            'Authorization': 'Bearer ' + access_token,
                            'Content-Type': 'application/json'
                        }
                    })
                        .then((response) => response.json())
                        .then((responseJson) => {
                            fetch(process.env.REACT_APP_GORDON_API_URL + '/planning/return/' + row.original.name, {
                                method: 'get',
                                headers: {
                                    'Authorization': 'Bearer ' + access_token,
                                    'Content-Type': 'application/json'
                                }
                            })
                                .then((response) => response.json())
                                .then((responseJson) => {

                                    this.getRoutes(this.state.group);
                                    this.setState({
                                        isLoading: false,
                                    });
                                })
                                .catch((error) => {
                                    console.error(error);
                                    this.setState({
                                        isLoading: false,
                                    });
                                });
                        })
                        .catch((error) => {
                            console.error(error);
                            this.setState({
                                isLoading: false,
                            });
                        });
                })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        isLoading: false,
                    });
                });

        } else {
            console.error("401 Unauthorized");
            this.setState({
                isLoading: false,
            });
        }
    }

    render() {

        const columns = [
            {
                Header: 'Rutt',
                accessor: 'name',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true,
                Cell: row => (
                    <a href={'/routedorders?routename=' + row.value}>
                        {row.value}
                    </a>
                )
            },

            {
                Header: 'Planerad starttid',
                accessor: 'promisedStartTime',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["promisedStartTime"] }),
                filterAll: true
            },
            {
                Header: 'Planerad sluttid',
                accessor: 'promisedEndTime',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["promisedEndTime"] }),
                filterAll: true
            },
            {
                Header: 'Faktisk starttid',
                accessor: 'startTime',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["startTime"] }),
                filterAll: true
                ,
                Cell: row => (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            color:  moment(row.value, "HH:mm").diff(moment(row.original.promisedStartTime, "HH:mm"), 'minutes') <= 0 ?  '#228B22' : '#ff0000'
                        }}
                    >
                    {row.value}
                  
                    </div>
                )
            },
            {
                Header: 'Faktisk sluttid',
                accessor: 'endTime',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["endTime"] }),
                filterAll: true
            },
            {
                Header: 'Kunder',
                accessor: 'numberOfStops',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["numberOfStops"] }),
                filterAll: true
            }, {
                Header: 'Körsträcka km',
                accessor: 'distance',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["distance"] }),
                filterAll: true,
            }, {
                Header: 'Gordoneer',
                accessor: 'driver',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["driver"] }),
                filterAll: true,
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["status"] }),
                filterAll: true,
                Cell: row => (
                    <div
                        style={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#dadada',
                            borderRadius: '2px'
                        }}
                    >

                        {
                            row != null && row.original != null && row.original.status != null && (row.original.status.startsWith('Uppladdat till Bringg') || row.original.status.startsWith('Startad') || row.original.status.startsWith('Avslutad')) && (
                                <div
                                    style={{
                                        width: `${row.original.percentage}%`,
                                        height: '100%',
                                        backgroundColor: row.original.percentage == 0 && !row.original.status.startsWith('Startad') ? '#808080' : row.original.percentage == 0 && row.original.status.startsWith('Startad') ? '#0000ff'
                                            : row.original.percentage > 99 ? '#228B22'
                                                : row.original.timediff > 29 || row.original.timediff < -29 ? '#ff0000' :
                                                    row.original.timediff > 14 || row.original.timediff < -14 ? '#FFA500' : '#0000ff',
                                        borderRadius: '2px',
                                        transition: 'all .2s ease-out'
                                    }}
                                >
                                    <span style={{
                                        color: '#ffffff',
                                        paddingLeft: '10px'

                                    }}>
                                        {row.original.percentage}% ({row.original.done} / ({row.original.numberOfStops})
                    </span>
                                </div>
                            )}
                        {
                            row != null && row.original != null && row.original.status != null &&
                            !row.original.status.startsWith('Uppladdat till Bringg') && !row.original.status.startsWith('Avslutad') && !row.original.status.startsWith('Startad') && (
                                <div
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: '2px'
                                    }}
                                >
                                    <span>{row.value}</span>
                                </div>
                            )}
                    </div>
                )
            },
            {
                Header: 'Sen/tidig',
                accessor: 'timediff',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["timediff"] }),
                filterAll: true,
                Cell: row => (
                    <span>{row.value > 0 ? row.value + " min tidig på rutten" : (row.value * -1) + " min sen på rutten"} </span>
                )
            },
            {
                Header: "Dispatch",
                accessor: '_id',
                Cell: row => (

                    this.state.group == 'Administrator' && (
                        <Button
                            bsStyle="primary"
                            className="btn-margin eventbtn" disabled={row.original.status === 'Avslutad' || row.original.status === 'Laddar upp till Bringg...' || row.original.percentage == 100}
                            onClick={this.sendToBringg.bind(this, row)}>Skicka till Bringg...</Button>
                    )


                )
            }
        ]
        var divStyle = {
            display: this.state.isLoading ? 'block' : 'none'
        };
        const exportdisabled = !(this.state.rows.length > 0);
        const { isAuthenticated } = this.props.auth;
        return (
            <div className="container">
                {
                    isAuthenticated() && (
                        <div>
                            <div className="cover" style={divStyle}>
                                <div className="covercontent">
                                    <SyncLoader
                                        color={'#174b5F'}
                                        loading={this.state.isLoading}
                                    />
                                </div>
                            </div>

                            <h3>{this.state.deliveryorder}</h3>
                            <br />
                            <Workbook filename="routes.xlsx"  element={<button disabled={exportdisabled} className="btn btn-primary">Exportera till Excel</button>}>
                                <Workbook.Sheet data={() => this.state.rows} name="Routes">
                                    <Workbook.Column label="Route" value="name" />
                                    <Workbook.Column label="Planerad starttid" value="promisedStartTime" />
                                    <Workbook.Column label="Planerad sluttid" value="promisedEndTime" />
                                    <Workbook.Column label="Faktisk starttid" value="startTime" />
                                    <Workbook.Column label="Faktisk sluttid" value="endTime" />
                                    <Workbook.Column label="Antal stopp" value="numberOfStops" />
                                    <Workbook.Column label="Antal stopp - ZON 1" value="numberOfStopsZone1" />
                                    <Workbook.Column label="Antal stopp - ZON 2" value="numberOfStopsZone2" />
                                    <Workbook.Column label="Antal stopp - ZON 3" value="numberOfStopsZone3" />
                                    <Workbook.Column label="Antal stopp - ZON 4" value="numberOfStopsZone4" />
                                    <Workbook.Column label="Körsträcka km" value="distance" />
                                    <Workbook.Column label="Förare" value="driver" />
                                    <Workbook.Column label="Status" value="status" />
                                    <Workbook.Column label="Sen/tidig på rutten (minuter)" value="timediff" />
                                </Workbook.Sheet>
                            </Workbook>
                            <br />

                            <br />
                            <ReactTable
                                filterable
                                data={this.state.rows}
                                defaultSorted={[{
                                    id: 'name',
                                    desc: false,
                                }]}
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value}
                                columns={columns}
                                pageSize={this.state.pageSize}
                                defaultPageSize={50} />

                                
                        </div>
                    )
                }
                {
                    !isAuthenticated() && (
                        <h4>
                            You're not logged in! Click {' '}
                            <a
                                style={{ cursor: 'pointer' }}
                                onClick={this.login.bind(this)}
                            >
                                Login
                </a>
                            {' '}to continue.
              </h4>
                    )
                }
            </div>

        );
    }
}

export default Routes;

