import React, { Component } from 'react';
import GordoneerProfil from './gordoneerprofil';

const queryString = require('query-string');

var user;

class Gordoneer extends Component {
  login() {
    this.props.auth.login();
  }
  componentWillMount() {
    const { userProfile, getProfile, isAuthenticated } = this.props.auth;
    if (isAuthenticated()) {

      if (!userProfile) {
        getProfile((err, profile) => {
          this.setState({ profile });

          localStorage.setItem('userProfileName', profile.name);
        });
      } else {
        this.setState({ profile: userProfile });


        localStorage.setItem('userProfileName', userProfile.name);
      }
    }

    var parsed = queryString.parse(this.props.location.search);
    localStorage.setItem('user', parsed.id);
    this.setState({ user: { id: parsed.id } });

    return { user: user };
  }
  componentDidMount() {

  }

  render() {
    const { isAuthenticated } = this.props.auth;

   

    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <p><a href="drivers">&lt; Tillbaka</a></p>
              <GordoneerProfil />
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default Gordoneer;

