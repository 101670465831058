import React, { Component } from 'react';

import DailyStats from './dailystats';
import DailyRoutes from './widgets/dailyroutes';

import HemkopDjur from './widgets/hemkopdjur';
import HemkopNacka from './widgets/hemkopnacka';
import WillysUppsala from './widgets/willysuppsala';
import WillysLanna from './widgets/willyslanna';
import WillysAlvsjo from './widgets/willysalvsjo';
import WillysUmea from './widgets/willysumea';
import WillysVaxjo from './widgets/willysvaxjo';
import WillysGavle from './widgets/willysgavle';
import WillysJonkoping from './widgets/willysjonkoping';
import WillysVasteras from './widgets/willysvasteras';
import WillysNorrkoping from './widgets/willysnorrkoping';
import WillysFalun from './widgets/willysfalun';
import WillysEskilstuna from './widgets/willyseskilstuna';

import HemkopLinkoping from './widgets/hemkoplinkoping';
import MinFarm from './widgets/minfarm';
import Gordon from './widgets/gordon';
import SlStockholm from './widgets/slstockholm';
import LinasMatkasse from './widgets/linasmatkasse';
import HighlightGordoneer from './widgets/highlightgordoneer';
import GordonTotal from './widgets/gordontotal';
import Fleet from './widgets/fleet';
import IcaTaby from './widgets/icastoptaby';
import IcaSjostaden from './widgets/icasjostaden';
import ParadisetEhandel from './widgets/paradisetehandel';
import ParadisetCity from './widgets/paradisetcity';
import Lundbergs from './widgets/lundbergs';
import Wrapsons from './widgets/wrapsons';
import BottomDrivers from './widgets/bottomdrivers';
import TopDrivers from './widgets/topdrivers';
import RookieDrivers from './widgets/rookiedrivers';
import TotalComplaints from './widgets/totalcomplaints';
import TotalDeliveries from './widgets/totaldeliveries';
import TotalPrecision from './widgets/totalprecision';
import TotalStoppTimme from './widgets/totalstopptimme';
import PointDrivers from './widgets/pointdrivers';

import CoopValbo from './widgets/coopvalbo';
import CoopKalmar from './widgets/coopkalmar';
import CoopArlov from './widgets/cooparlov';

import BlTaby from './widgets/bltaby';
import BlBromma from './widgets/blbromma';
import BlCity from './widgets/blcity';
import BlHaggvik from './widgets/blhaggvik';
import BlHaninge from './widgets/blhaninge';
import BlLidingo from './widgets/bllidingo';
import BlNacka from './widgets/blnacka';

import ShortCuts from './widgets/shortcuts';

import TotalComplaintsLastMonth from './widgets/totalcomplaintslastmonth';
import TotalDeliveriesLastMonth from './widgets/totaldeliverieslastmonth';
import TotalPrecisionLastMonth from './widgets/totalprecisionlastmonth';
import TotalStoppTimmeLastMonth from './widgets/totalstopptimmelastmonth';

import { Responsive, WidthProvider } from 'react-grid-layout';

import './overriden.css';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class Home extends Component {
  login() {
    this.props.auth.login();
  }
  componentWillMount() {
    const { userProfile, getProfile, isAuthenticated } = this.props.auth;
    if (isAuthenticated()) {

      if (!userProfile) {
        getProfile((err, profile) => {
          if (err) {
            return console.log(err);
          }
          this.setState({ profile });

          localStorage.setItem('userProfileName', profile.name);
        });
      } else {
        this.setState({ profile: userProfile });


        localStorage.setItem('userProfileName', userProfile.name);
      }
    }
    this.setState({ apikey: process.env.REACT_APP_API_KEY });
    return {};
  }

  render() {
    const { isAuthenticated } = this.props.auth;

    var layout = [
      { i: 'b', x: 0, y: 0, w: 2, h: 6, static: true },
      { i: 'c', x: 2, y: 0, w: 2, h: 6, static: true },
      { i: 'd', x: 4, y: 0, w: 2, h: 6, static: true },



      { i: 'minfarm', x: 0, y: 31, w: 2, h: 5, static: true },


      // { i: 'coop1', x: 0, y: 6, w: 2, h: 5, static: true },
      // { i: 'coop2', x: 0, y: 11, w: 2, h: 5, static: true },
      // { i: 'cooparlov', x: 0, y: 16, w: 2, h: 5, static: true },

      // { i: 'icasjostaden', x: 0, y: 36, w: 2, h: 5, static: true },
      // { i: 'icataby', x: 0, y: 41, w: 2, h: 5, static: true },
      // { i: 'lundbergs', x: 0, y: 46, w: 2, h: 5, static: true },
      // { i: 'paradisetcity', x: 0, y: 51, w: 2, h: 5, static: true },
      // { i: 'paradisetehandel', x: 0, y: 56, w: 2, h: 5, static: true },

      { i: 'shortcuts', x: 0, y: 6, w: 6, h: 3, static: true },

      { i: 'stor', x: 0, y: 8, w: 9, h: 20, static: true },

      // { i: 'lotus', x: 0, y: 26, w: 2, h: 5, static: true },

      { i: 'm', x: 0, y: 26, w: 2, h: 5, static: true },

      // { i: 'e', x: 4, y: 26, w: 2, h: 5, static: true },
      // { i: 'bltaby', x: 4, y: 31, w: 2, h: 5, static: true },
      // { i: 'blnacka', x: 4, y: 36, w: 2, h: 5, static: true },
      // { i: 'blcity', x: 4, y: 41, w: 2, h: 5, static: true },
      // { i: 'bllidingo', x: 4, y: 46, w: 2, h: 5, static: true },
      // { i: 'blhaggvik', x: 4, y: 51, w: 2, h: 5, static: true },
      // { i: 'blhaninge', x: 4, y: 56, w: 2, h: 5, static: true },
      // { i: 'blbromma', x: 4, y: 61, w: 2, h: 5, static: true },

      // { i: 'l', x: 2, y: 6, w: 2, h: 5, static: true },
      // { i: 'h', x: 2, y: 11, w: 2, h: 5, static: true },
      // { i: 'o', x: 2, y: 16, w: 2, h: 5, static: true },
      { i: 'f', x: 2, y: 26, w: 2, h: 5, static: true },
      { i: 'v', x: 2, y: 31, w: 2, h: 5, static: true },
      { i: 'y', x: 2, y: 36, w: 2, h: 5, static: true },
      { i: 'aa', x: 2, y: 41, w: 2, h: 5, static: true },
      { i: 'bb', x: 2, y: 46, w: 2, h: 5, static: true },
      // { i: 'falun', x: 2, y: 51, w: 2, h: 5, static: true },
      // { i: 'norrkoping', x: 2, y: 56, w: 2, h: 5, static: true },
      // { i: 'eskilstuna', x: 2, y: 61, w: 2, h: 5, static: true },
      // { i: 'vasteras', x: 2, y: 66, w: 2, h: 5, static: true },

      { i: 'g', x: 0, y: 61, w: 2, h: 5, static: true },
      { i: 'hemkoplinkoping', x: 4, y: 66, w: 2, h: 5, static: true },

      { i: 'k', x: 6, y: 0, w: 3, h: 8, static: true },
      // { i: 'fleet', x: 6, y: 8, w: 3, h: 6, static: true },
      { i: 'j', x: 6, y: 14, w: 3, h: 13, static: true },
      { i: 'z', x: 6, y: 27, w: 3, h: 14, static: true },
      { i: 'i', x: 6, y: 41, w: 3, h: 14, static: true },
      { i: 'n', x: 6, y: 55, w: 3, h: 14, static: true },

    ];
    var layouts = { lg: layout, md: layout }
    /*
        <div  key={'stor'}>
                  <DailyStats />
                <DailyRoutes />
        </div>
    */
    var apikey = this.state.apikey;
    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <ResponsiveReactGridLayout className="layout" layouts={layouts} rowHeight={30}
                breakpoints={{ lg: 1800, md: 1200, sm: 768, xs: 480, xxs: 0 }}
                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}>
                <div className="precision" key={'b'}>
                  {/* <TotalPrecision /> */}
                </div>
                <div className="antal" key={'c'}>
                  {/* <TotalDeliveries /> */}
                </div>
                <div className="complaints" key={'d'}>
                  {/* <TotalComplaints /> */}
                </div>



                <div key={'shortcuts'}>
                  <ShortCuts />
                </div>



                <div key={'k'}>
                  {/* <GordonTotal /> */}
                </div>

                {/* 
                <div key={'i'}>
                  <TopDrivers />
                </div>
                <div key={'j'}>
                  <BottomDrivers />
                </div>
              
                <div key={'z'}>
                  <PointDrivers />
                </div>
                <div key={'n'}>
                  <RookieDrivers />
                </div> */}
              </ResponsiveReactGridLayout>
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default Home;

