import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

class Precision extends Component {

  login() {
    this.props.auth.login();
  }
  componentWillMount() {
    let rows = [];
    let ratingrows = [];
    rows.push(["Datum", "Precision %", "Antal"]);
    ratingrows.push(["Datum", "Snittbetyg 1-5", "Antal"]);
    this.setState({ summary: [] });
    this.setState({ rows: rows });
    this.setState({ ratingrows: ratingrows });
    return { rows: rows, ratingrows: ratingrows };
  }
  componentDidMount() {

    let rows = this.state.rows;
    let ratingrows = this.state.ratingrows;
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch('https://api.gordondelivery.com/api/gordoneer/summaryprecisionweekly', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + access_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var summary = JSON.parse(responseJson);
          var numberOfRows = summary.length;

          fetch(process.env.REACT_APP_GORDON_API_URL + '/order/statsweekly', {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + access_token,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          })
            .then((response) => response.json())
            .then((responseJson) => {

              var resp = responseJson;

              for (let i = 0; i < numberOfRows; i++) {
                for (let j = 0; j < resp.length; j++) {
                  if (summary[i].Week == parseInt(resp[j].Week)) {

                    var delEtt = summary[i].Precision * (summary[i].Antal / (summary[i].Antal + resp[j].Antal));
                    var delTva = resp[j].LeveransPrecision * (resp[j].Antal / (summary[i].Antal + resp[j].Antal));
                    summary[i].Precision = Math.round(delEtt + delTva);

                    summary[i].Antal = summary[i].Antal + resp[j].Antal;
                    summary[i].AntalRating = summary[i].AntalRating + (resp[j].Ratings != null ? resp[j].Ratings : 0);

                    if (resp[j].averagerating != null && resp[j].averagerating > 0) {
                      summary[i].Rating = (summary[i].Rating + resp[j].averagerating) / 2;
                    }

                  }
                }
              }

              for (let i = 0; i < numberOfRows; i++) {
                rows.push([summary[i].Week, summary[i].Precision, summary[i].Antal]);
                ratingrows.push([summary[i].Week, summary[i].Rating, summary[i].AntalRating]);
              }
              for (let i = 0; i < numberOfRows; i++) {
                summary[i].Precision = Math.round(summary[i].Precision * 1) / 1;
                summary[i].Rating = Math.round(summary[i].Rating * 10) / 10;
              }
              this.state.rows = rows;
              this.setState({ rows: rows });
              this.state.ratingrows = ratingrows;
              this.setState({ ratingrows: ratingrows });
              this.setState({ summary: summary });
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });

    } else {
      console.error("401 Unauthorized");
    }
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Leveransprecision</h3>
              <div className={'my-pretty-chart-container'}>
                <Chart
                  chartType="ComboChart"
                  data={this.state.rows}
                  options={{
                    title: 'Leveransprecision - Totalt',
                    vAxes: [{
                      title: 'Procent %', viewWindow: {
                        min: 0,
                        max: 100
                      }
                    },
                    {
                      title: 'Antal', viewWindow: {
                        min: 0,
                        max: 4000
                      }
                    }
                    ], hAxis: { title: 'Vecka' }, seriesType: 'bars',
                    series: { 1: { type: 'line', targetAxisIndex: 1 } }
                  }}
                  graph_id="ComboChart"
                  width="100%"
                  height="500px"
                  legend_toggle
                />
              </div>
              <div className={'my-pretty-chart-container'}>
                <Chart
                  chartType="ComboChart"
                  data={this.state.ratingrows}
                  options={{
                    title: 'Snittbetyg per vecka',
                    vAxes: [{
                      title: 'Snitt 1-5', viewWindow: {
                        min: 0,
                        max: 5
                      }
                    },
                    {
                      title: 'Antal', viewWindow: {
                        min: 0,
                        max: 400
                      }
                    }
                    ], hAxis: { title: 'Vecka' }, seriesType: 'bars',
                    series: { 1: { type: 'line', targetAxisIndex: 1 } }
                  }}
                  graph_id="ComboChart2"
                  width="100%"
                  height="500px"
                  legend_toggle
                />
              </div>
              <div  >
                <BootstrapTable width="200" data={this.state.summary} striped={true} hover={true} >
                  <TableHeaderColumn width="200" isKey dataField='Week'>Veckonummer</TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField='Antal'>Antal leveranser</TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField='Precision'>Leveransprecision %</TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField='Rating'>Medelbetyg (max 5)</TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField='AntalRating'>Antal betyg</TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField='AntalRating5'>Betyg 5</TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField='AntalRating4'>Betyg 4</TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField='AntalRating3'>Betyg 3</TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField='AntalRating2'>Betyg 2</TableHeaderColumn>
                  <TableHeaderColumn width="200" dataField='AntalRating1'>Betyg 1</TableHeaderColumn>
                </BootstrapTable>
              </div>
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default Precision;

