import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import Workbook from "react-excel-workbook";
import _ from "lodash";
import { DateRange } from "react-date-range";
import { format, addDays } from "date-fns";
import moment from "moment";
import { Modal } from "react-bootstrap";
import matchSorter from "match-sorter";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file

class CoopInvoicing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dateRange: {
        selection: {
          startDate: addDays(new Date(), -7),
          endDate: new Date(),
          key: "selection"
        }
      },
      originalrows: [],
      isLoading: false,
      rows: []
    };

    this.fetchData = this.fetchData.bind(this);
    this.redirectToLoginPage = this.redirectToLoginPage.bind(this);
  }

  componentDidMount() {
    this.getOrders(
      this.state.dateRange.selection.startDate,
      this.state.dateRange.selection.endDate
    );
  }

  redirectToLoginPage() {
    this.props.history.push("/signin");
  }

  getOrders(startDate, endDate) {
    var access_token = localStorage.getItem("access_token");
    if (access_token != null) {
      this.setState({ isLoading: true });
      fetch(
        process.env.REACT_APP_GORDON_API_URL +
          "/coopfaktura?start=" +
          moment(startDate).format("YYYY-MM-DD") +
          "&end=" +
          moment(endDate).format("YYYY-MM-DD"),
        {
          method: "get",
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => response.json())
        .then(dataJson => {
          this.setState({ isLoading: false, pageSize: dataJson.length });
          this.setState({ rows: dataJson });
          this.setState({ originalrows: dataJson });
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }
  fetchData(state, instance) {
    let filteredData = this.state.originalrows;
    if (state.filtered.length) {
      filteredData = state.filtered.reduce((filteredSoFar, nextFilter) => {
        return filteredSoFar.filter(row => {
          return (row[nextFilter.id] + "").includes(nextFilter.value);
        });
      }, filteredData);
    }
    // You can also use the sorting in your request, but again, you are responsible for applying it.
    const sortedData = _.orderBy(
      filteredData,
      state.sorted.map(sort => {
        return row => {
          if (row[sort.id] === null || row[sort.id] === undefined) {
            return -Infinity;
          }
          return typeof row[sort.id] === "string"
            ? row[sort.id].toLowerCase()
            : row[sort.id];
        };
      }),
      state.sorted.map(d => (d.desc ? "desc" : "asc"))
    );

    var result = [];
    for (var i = 0; i < sortedData.length; i++) {
      if (
        moment(this.state.dateRange.selection.startDate)
          .startOf("day")
          .isBefore(moment(sortedData[i].deliverydatedate)) &&
        moment(sortedData[i].deliverydatedate).isBefore(
          moment(this.state.dateRange.selection.endDate).endOf("day")
        )
      ) {
        result.push(sortedData[i]);
      }
    }

    this.setState({ rows: result });
  }

  handleRangeChange(which, payload) {
    console.log(which, payload);
    this.setState({
      [which]: {
        ...this.state[which],
        ...payload
      }
    });

    this.getOrders(payload.selection.startDate, payload.selection.endDate);

    // var result = [];
    // if (payload.selection != null) {
    //   for (var i = 0; i < this.state.originalrows.length; i++) {
    //     if (moment(payload.selection.startDate).startOf('day').isBefore(moment(this.state.originalrows[i].deliverydatedate)) &&
    //       moment(this.state.originalrows[i].deliverydatedate).isBefore(moment(payload.selection.endDate).endOf('day'))) {
    //       result.push(this.state.originalrows[i]);
    //     }

    //   }
    // }
    // this.setState({
    //   rows:
    //     result
    //   ,
    // });
  }
  handleChange(which, payload) {
    this.setState({
      [which]: payload
    });
  }
  formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return format(date, "YYYY-MM-DD");
  }

  render() {
    const columns = [
      {
        Header: "Leveransdatum",
        accessor: "date",
        filterAll: true
      },
      {
        Header: "Rutt",
        accessor: "route",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["route"] }),
        filterAll: true,
        Cell: row => (
          <a href={"/routedorders?routename=" + row.value}>{row.value}</a>
        )
      },
      {
        Header: "Planerad starttid",
        accessor: "promisedStartTime",
        filterAll: true
      },
      {
        Header: "Faktisk startid",
        accessor: "startTime",
        filterAll: true,
        Cell: row => (
          <div
            style={{
              width: "100%",
              height: "100%",
              color:
                moment(row.value, "HH:mm").diff(
                  moment(row.original.promisedStartTime, "HH:mm"),
                  "minutes"
                ) <= 0
                  ? "#228B22"
                  : "#ff0000"
            }}
          >
            {row.value}
          </div>
        )
      },
      {
        Header: "Planerad sluttid",
        accessor: "promisedEndTime",
        filterAll: true
      },
      {
        Header: "Faktisk sluttid",
        accessor: "endTime",
        filterAll: true
      },
      {
        Header: "Antal stopp",
        accessor: "count",
        filterAll: true
      },
      {
        Header: "Varav antal Stopp-pris",
        accessor: "stopcount",
        filterAll: true
      },
      {
        Header: "Varav antal Timpris",
        accessor: "timecount",
        filterAll: true
      },
      {
        Header: "Intäkt Stopp-pris",
        accessor: "stopcost",
        filterAll: true,
        Cell: props =>
          new Intl.NumberFormat("sv-SE", {
            style: "currency",
            currency: "SEK"
          }).format(props.value)
      },
      {
        Header: "Antal minuter",
        accessor: "timesum",
        filterAll: true,
        Cell: row => (
          <div
            style={{
              width: "100%",
              height: "100%",
              color:
                row.value >= 0
                  ? "#228B22"
                  : "#ff0000"
            }}
          >
            {row.value}
          </div>
        )
      },
      {
        Header: "Intäkt Timpris",
        accessor: "timecost",
        filterAll: true,
        Cell: row => (
          
          <div
            style={{
              width: "100%",
              height: "100%",
              color:
                row.value >= 0
                  ? "#228B22"
                  : "#ff0000"
            }}
          >
            { new Intl.NumberFormat("sv-SE", {
            style: "currency",
            currency: "SEK"
          }).format(row.value)}
          </div>
        )
      },
      {
        Header: "Total intäkt",
        accessor: "totalsum",
        filterAll: true,
        Cell: props =>
          new Intl.NumberFormat("sv-SE", {
            style: "currency",
            currency: "SEK"
          }).format(props.value)
      }
    ];

    const isLoading = this.state.isLoading;
    const { isAuthenticated } = this.props.auth;
    let trusted = isAuthenticated();
    if (!trusted) {
      this.redirectToLoginPage();
    }
    return (
      <div className="container">
        <div>
          <h3>Fakturaunderlag för Coop</h3>
          <DateRange
            onChange={this.handleRangeChange.bind(this, "dateRange")}
            moveRangeOnFirstSelection={false}
            dateDisplayFormat="YYYY-MM-DD"
            ranges={[this.state.dateRange.selection]}
            className={"PreviewArea"}
          />

          <br />

          <Workbook
            filename="coopinvoicing.xlsx"
            element={
              <button disabled={isLoading} className="btn btn-primary">
                {isLoading ? "Hämtar ordar..." : "Exportera till Excel"}
              </button>
            }
          >
            <Workbook.Sheet
              data={() => this.state.rows}
              name="Fakturaunderlag Coop"
            >
              <Workbook.Column label="Leveransdatum" value="date" />
              <Workbook.Column label="Rutt" value="route" />
              <Workbook.Column
                label="Planerad starttid"
                value="promisedStartTime"
              />
              <Workbook.Column label="Faktisk startid" value="startTime" />
              <Workbook.Column
                label="Planerad sluttid"
                value="promisedEndTime"
              />
              <Workbook.Column label="Faktisk sluttid" value="endTime" />
              <Workbook.Column label="Antal stopp" value="count" />
              <Workbook.Column
                label="Varav antal Stopp-pris"
                value="stopcount"
              />
              <Workbook.Column
                label="Zon 1"
                value="zone1"
              />
              <Workbook.Column
                label="Zon 2"
                value="zone2"
              />
              <Workbook.Column
                label="Zon 3"
                value="zone3"
              />
              <Workbook.Column label="Varav antal Timpris" value="timecount" />
              <Workbook.Column label="Intäkt Stopp-pris" value="stopcost" />
              <Workbook.Column label="Antal minuter" value="timesum" />
              <Workbook.Column label="Intäkt Timpris" value="timecost" />
              <Workbook.Column label="Garantiutfyllnad" value="garanti" />
              <Workbook.Column label="Lastningsavgift" value="loadingfee" />
              
              <Workbook.Column label="Total intäkt" value="totalsum" />
              <Workbook.Column label="Från butik till första stopp" value="fromstart" />
              <Workbook.Column label="Från sista stopp till butik" value="toend" />
            </Workbook.Sheet>
          </Workbook>
          <br />
          <ReactTable
            filterable={true}
            defaultSorted={[
              {
                id: "date",
                desc: false
              }
            ]}
            data={this.state.rows}
            onFetchData={this.fetchData}
            columns={columns}
            pageSize={this.state.pageSize}
            defaultPageSize={100}
            showPageSizeOptions={true}
            pageSizeOptions={[20, 50, 100, 200, 500, 1000]}
          />

          <Modal
            {...this.props}
            show={this.state.isLoading}
            dialogClassName="custom-modal"
          >
            <Modal.Body>
              <h4>Hämtar ordrar...</h4>
            </Modal.Body>
          </Modal>
        </div>
      </div>
    );
  }
}

export default CoopInvoicing;
