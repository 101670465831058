import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Workbook from 'react-excel-workbook';
import lodash from "lodash";
import DatePicker from 'react-16-bootstrap-date-picker';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

class Fleetlog extends Component {
  login() {
    this.props.auth.login();
  }

  componentWillMount() {
    this.setState({ rows: [] });
    this.setState({ filename: '' });
    this.setState({ sheetname: '' });
    this.setState({ formattedValue: moment().format("YYYY-MM-DD") });
    this.setState({ value: moment().toISOString() });
  }
  componentDidMount() {
    this.getOrders(this.state.formattedValue);
  }

  getOrders(date) {
    this.state.rows = [];
    this.setState({ rows: [] });
    var rows = [];
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + '/metroteclog/getlogbydate/' + date, {
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + access_token,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((responseJson) => {

          var data = responseJson;
          for (let i = 0; i < data.length; i++) {
            if (data[i].action == 'LOCK') {
              data[i].action = "Låst";
            } else if (data[i].action == 'UNLOCK') {
              data[i].action = "Låste upp";
            }
            data[i].created_at = new Date(data[i].created_at).toLocaleString();
            if (data[i].action != 'GENERATE_PIN') {
              rows.push(data[i]);
            }
          }

          this.state.rows = rows;
          this.setState({ rows: rows });

        })
        .catch((error) => {
          console.error(error);
        });

    } else {
      console.error("401 Unauthorized");
    }
  }
  handleChange(value, formattedValue) {
    this.setState({
      value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
    });
    this.getOrders(formattedValue);
  }

  render() {

    const columns = [
      {
        Header: 'Tidpunkt',
        accessor: 'created_at'
      },
      {
        Header: 'Driver',
        accessor: 'name'
      },
      {
        Header: 'Regnr',
        accessor: 'registration'
      },
      {
        Header: 'Låsa upp/låsa',
        accessor: 'action'
      }
    ]

    const { isAuthenticated } = this.props.auth;
    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Fordonslogg</h3>

              <div className="datePicker">Datum: <DatePicker id="example-datepicker" value={this.state.value} dateFormat='YYYY-MM-DD' weekStartsOn={1} onChange={this.handleChange.bind(this)}
                monthLabels={['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']}
                dayLabels={['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör']} />
              </div>

              <br />
              <br />
              <ReactTable
                filterable={true}
                data={this.state.rows}
                defaultSorted={[{
                  id: 'created_at',
                  desc: false,
                }]}
                columns={columns}
                defaultPageSize={100} />
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default Fleetlog;

