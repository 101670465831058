import React, { Component } from "react";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import Dropzone from "react-dropzone";
import { SyncLoader } from "react-spinners";
import { Button } from "react-bootstrap";
import Select from "react-select";
import DatePicker from "react-16-bootstrap-date-picker";

class Import extends Component {
  constructor() {
    super();

    this.state = {
      selectedFile1: null
    };
  }

  login() {
    this.props.auth.login();
  }
  componentWillMount() {
    this.setState({
      isLoading: false,
      formattedValue: null,
      value: '',
      selectedDeliveryGroup: null,
    });

    this.setState({ toggleValue: null });
    moment.locale("sv");
    this.setState({
      filename: ""
    });
  }
  componentDidMount() { }

  upload() {
    const data = new FormData();
    var access_token = localStorage.getItem('access_token');
    data.append("file1", this.state.selectedFile1);
    if (this.state.showDatePicker) {
      data.append("datum", this.state.formattedValue);
    }
    if (this.state.showDeliveryGroups) {
      data.append("deliverygroup", this.state.selectedDeliveryGroup);
      if (this.state.selectedDeliveryGroup === "SimpleFeastJylland") {
        data.append("depot", "Karatmagervej 13A, 7000 Fredericia, Denmark");
      } else if (this.state.selectedDeliveryGroup === "SimpleFeastSjaelland") {
        data.append("depot", "Greve Main 9, 2670 Greve, Denmark");
      } else if (this.state.selectedDeliveryGroup === "SimpleFeastKøbenhavn") {
        data.append("depot", "Greve Main 9, 2670 Greve, Denmark");
      }
    }
    var path = this.state.toggleValue.value;
    const self = this;

    this.setState({
      isLoading: true
    });

    let config = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access_token,
      }
    }

    axios
      .post(process.env.REACT_APP_GORDON_API_URL + "/" + path, data, config)
      .then(function (response) {
        self.setState({
          toggleValue: null,
          filename: "",
          selectedFile1: null,
          isLoading: false,
          showDatePicker: false,
          showDeliveryGroups: false
        });
      })
      .catch(error => {
        console.error(error);
        self.setState({
          isLoading: false
        });
      });
  }

  onDrop = files => {
    files.forEach(file => {
      this.setState({
        filename: file.name,
        selectedFile1: file
      });
    });
  };

  handleDeliveryGroupChange = (selectedOption) => {
    if (selectedOption != null) {

      this.setState({
        selectedDeliveryGroup: selectedOption.label
      });

    }
    else {
      this.setState({
        selectedDeliveryGroup: null
      });

    }
  }

  handleMerchantChange = selectedOption => {
    if (selectedOption != null) {
      if (selectedOption.value == 'updateratings' || selectedOption.value == 'updateworkwave') {
        this.setState({
          showDatePicker: true
        });
      }
      else {
        this.setState({
          showDatePicker: false
        });
      }
      if (selectedOption.value == 'updateworkwave') {
        this.setState({
          showDeliveryGroups: true
        });
      }
      else {
        this.setState({
          showDeliveryGroups: false
        });
      }
      this.setState({
        toggleValue: selectedOption
      });
    } else {
      this.setState({
        toggleValue: null
      });
    }
  };
  handleDateChange(value, formattedValue) {
    this.setState({
      value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      formattedValue: formattedValue, // Formatted String, ex: "11/19/2016",
    });
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    var divStyle = {
      display: this.state.isLoading ? "block" : "none"
    };

    var clients = [
      { value: "importplanandgo", label: "Linas Matkasse - Importera ordrar (Plan&Go)" },
      { value: "importroutesplanandgo", label: "Linas Matkasse - Importera rutter (Plan&Go)" },
      { value: "importaxfood", label: "Axfood - PinDeliver" },
      { value: "importwrapsons", label: "Wrapsons" },
      // { value: "updateratings", label: "Importera Betyg" },
      { value: "updateworkwave", label: "Importera Workwave-rutter" }
    ];

    let importdisabled = !(
      this.state.selectedFile1 != null && this.state.toggleValue
    );
    if (this.state.showDatePicker && this.state.formattedValue == null) {
      importdisabled = true;
    }

    var deliveryGroups = [
      { value: 'Gordon Sthlm', label: 'Gordon Sthlm' },
      { value: 'Gordon Gbg', label: 'Gordon Gbg' },
      { value: 'Gordon Mma', label: 'Gordon Mma' },
      { value: 'Gordon Ostergotland', label: 'Gordon Ostergotland' },
      { value: 'Gordon Eskilstuna', label: 'Gordon Eskilstuna' },
      { value: "Gordon Ljungby", label: "Gordon Ljungby" },
      { value: 'SimpleFeastJylland', label: 'SimpleFeastJylland' },
      { value: 'SimpleFeastSjaelland', label: 'SimpleFeastSjaelland' },
      { value: 'Simple Feast København', label: 'SimpleFeastKøbenhavn' }
    ];

    return (
      <div className="container">
        {isAuthenticated() && (
          <div>
            <div className="cover" style={divStyle}>
              <div className="covercontent">
                <SyncLoader color={"#174b5F"} loading={this.state.isLoading} />
              </div>
            </div>

            <h3>Import av orderutfall</h3>
            <div className="dropdownSearch">
              <Select
                placeholder="Välj kund/butik"
                name="ddSelectMerchant"
                onChange={this.handleMerchantChange}
                options={clients}
                className="basic-multi-select"
              />
              <br />
            </div>
            <br />
            {this.state.showDatePicker && (
              <div className="datePicker">Datum: <DatePicker autoFocus={false} showTodayButton={true} todayButtonLabel="Idag" id="example-datepicker" value={this.state.value} dateFormat='YYYY-MM-DD' weekStartsOn={1} onChange={this.handleDateChange.bind(this)}
                monthLabels={['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']}
                dayLabels={['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör']} />
              </div>
            )}
            <br />
            {this.state.showDeliveryGroups && (
              <div className="dropdownSearch">
                <Select placeholder="Välj Delivery Group"
                  name="ddSelectDeliveryGroup"
                  onChange={this.handleDeliveryGroupChange}
                  options={deliveryGroups}
                />
              </div>
            )}
            <br />
            <div style={{ width: 200, padding: 20 }}>
              <Dropzone onDrop={this.onDrop} className="drop">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <i className="fas fa-cloud-upload-alt fa-5x iconcolor" />
                      <br />
                      <br />
                      <p>Dra och släpp en fil</p>
                      <p>Eller klicka här...</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="buttonSearch">
              <Button
                bsStyle="primary"
                className="btn-margin"
                disabled={importdisabled}
                onClick={this.upload.bind(this)}
              >
                Importera
              </Button>
            </div>
          </div>
        )}
        {!isAuthenticated() && (
          <h4>
            You're not logged in! Click{" "}
            <a style={{ cursor: "pointer" }} onClick={this.login.bind(this)}>
              Login
            </a>{" "}
            to continue.
          </h4>
        )}
      </div>
    );
  }
}

export default Import;
