import React, { Component } from 'react';
import moment from 'moment';
import * as Order from '../api/gordonApi/order';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import 'moment/locale/sv';
import { jsxExpressionContainer } from '@babel/types';

const queryString = require('query-string');

class OrderDetails extends Component {

  constructor(props) {
    super(props);

    this.state = {
      order: '',
      orderLog: [],
      loading: false,
      expandedRows: []
    }

    this.renderItemRows = this.renderItemRows.bind(this);
    this.renderOrderLogRows = this.renderOrderLogRows.bind(this);
    this.redirectToLoginPage = this.redirectToLoginPage.bind(this);
    this.expandRow = this.expandRow.bind(this);
    this.renderDriverRating = this.renderDriverRating.bind(this);
  }

  redirectToLoginPage() {
    this.props.history.push('/signin')
  }


  getOrderDetails(order) {
    let parsed = queryString.parse(this.props.location.search);
    if (!parsed.id && !order) {
      return
    }

    moment.locale('sv');
    console.log('will fetch order')

    this.setState({ ...this.state, loading: true })

    let id = parsed.id || order.internal_id;
    let self = this;

    Order.gerOrderById(id, function (err, order) {
      if (err) {
        console.error(err);
        return self.setState({ ...self.state, loading: false })
      }

      Order.getOrderLog(id, function (err, orderLog) {
        if (err) {
          console.errror(err)
          return self.setState({ ...self.state, loading: false });
        }

        self.setState({ ...self.state, order, orderLog, title: 'Order ' + order.external_ref, loading: false })
      });

    });

  }

  componentDidMount() {
    this.getOrderDetails();
  }

  componentWillReceiveProps(nextProps) {
    if (JSON.stringify(nextProps.order) !== JSON.stringify(this.props.order)) {

      this.getOrderDetails(nextProps.order);
    }
  }

  renderItemRows(items) {
    let { expandedRows } = this.state;
    let tableRows = [];

    let renderItemArticles = (articles) => {
      let articleRows = [];

      articles.forEach((a) => {
        articleRows.push([
          <tr key={a._id}>
            <td>{a.name ? a.name : '-'}</td>
            <td>{a.quantity ? a.quantity : ''}</td>
          </tr>
        ])
      });

      return articleRows;
    }

    items.forEach((item) => {
      tableRows = tableRows.concat([
        <tr key={item._id} onClick={item.articles && item.articles.length > 0 ? () => this.expandRow(item._id) : null}>
          <td>
            {item.name ? item.name : '-'}
            {item.articles && item.articles.length > 0 ? (` (click to view ${item.articles.length} articles) `) : ''}
          </td>
          <td>{item.numberOfItems ? item.numberOfItems : '-'}</td>
          <td>{item.scanned ? (item.manual_scan != null && item.manual_scan == false ? "Yes" : "Yes (manual)") : '-'}</td>
        </tr>
      ]);

      if (expandedRows.find((i) => i === item._id) !== undefined) {
        tableRows = tableRows.concat([
          <tr style={{ backgroundColor: "white" }}>
            <td colSpan="2">
              <div className="row">
                <div className="col-xs-12">
                  <div style={{ padding: "15px" }}>
                    <label>Articles</label>
                    <table className="table table-striped">
                      <thead>
                        <tr>
                          <th style={{ border: "0", width: "70%" }}>Name</th>
                          <th style={{ border: "0" }}>Quantity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {renderItemArticles(item.articles)}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        ])
      }
    });

    return (tableRows)
  }

  expandRow(itemId) {
    let { order, expandedRows } = this.state;
    //lookup if itemId exists on object
    let itemExist = order.items && order.items.find((item) => item._id === itemId);
    //copy of to work with array
    let expRows = expandedRows.slice();

    if (!itemExist) {
      return;
    }


    //add if not expanded, remove if already exists
    expRows.find((i) => i === itemId) !== undefined ? expRows = expRows.filter((i) => i !== itemId) : expRows.push(itemId);

    this.setState({ ...this.state, expandedRows: expRows });

  }

  renderOrderLogRows(logs) {
    let logRows = [];
    let logCopy = logs.slice();

    logCopy = logs.sort((a, b) => {
      let firstDate = new Date(a.created_at);
      let secondDate = new Date(b.created_at);
      return secondDate - firstDate;
    });

    logCopy.forEach((log, i) => {
      logRows = logRows.concat([
        <tr key={log._id}>
          <td>
            {log.type != null && (log.type == "Signature" || log.type == "Photo") &&
              <span><a href={log.url} target='_blank'>{log.type}</a><i style={{ "margin-left": "5px" }} className="fa fa-camera-retro" /></span>
            }
            {log.message != null &&
              log.message
            }
          </td>
          <td>{new moment(log.created_at).format('YYYY-MM-DD HH:mm')}</td>
        </tr >
      ]);

    });

    return logRows;
  }

  renderDriverRating(rating) {
    let stars = [];
    let diff = 5 - rating;
    let style = { margin: "0 2px" }

    for (let i = 0; i < rating; i++) {
      stars = stars.concat([<i key={i} style={style} className="fas fa-star"></i>]);
    }

    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        stars = stars.concat([<i key={(`diff_${i}`)} style={style} className="far fa-star"></i>]);
      }
    }

    return stars;
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    let { order, orderLog, title } = this.state;
    let trusted = isAuthenticated();

    let deliveryAddress = order && order.deliveryAddress;
    let deliveryContact = order && order.deliveryContactInformation;
    let orderInfo = order && order.orderInfo;

    let orderStatusStyle = orderInfo.status && orderInfo.status === "Done" ? "label label-success" : "label label-danger"

    if (!trusted) {
      this.redirectToLoginPage();
    }

    if (this.state.loading) {
      return (<div></div>)
    }

    return (
      <div className="container" style={{ color: "grey", fontSize: "12px" }}>

        <div className="row">
          <div className="col-xs-12">
            <div>
              <h4 className="pull-right">
                <span className={orderStatusStyle}>{orderInfo.status}</span>
              </h4>
              <h3>
                {title}
              </h3>
            </div>
            <div style={{ marginBottom: "10px" }}>{order ? order.pickupInfo.contactInfo.name : ''}</div>
          </div>
        </div>


        <div style={{ border: "1px solid lightgrey", marginBottom: "20px" }}>
          <div className="row is-table-row">
            <div className="col-xs-12 col-sm-6 col-md-6">
              <div style={{ padding: "15px" }}>
                <div style={{ marginBottom: "20px" }}>
                  <label>Delivery Address</label>
                  <div>{deliveryAddress ? deliveryAddress.streetAddress : ''}</div>
                  <div>{deliveryAddress ? deliveryAddress.streetAddress2 : ''}</div>
                  <div>{deliveryAddress ? deliveryAddress.postalCode != null ? deliveryAddress.postalCode + ' ' + deliveryAddress.city : '' : ''}</div>
                </div>

                <label style={{ display: "block" }}>{order.deliveryDate ? 'Delivery date' : 'No delivery date selected'}</label>
                {new moment(order.deliveryDate).format('YYYY-MM-DD')}
                {orderInfo.noEarlierThan && orderInfo.noLaterThan ? ' (' + new moment(orderInfo.noEarlierThan).format('HH:mm') + ' - ' + new moment(orderInfo.noLaterThan).format('HH:mm') + ')' : ''}

              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-6" style={{ position: "relative" }}>
              <div style={{ position: "relative", height: "170px", width: "100%" }} className="custom-map">

                <Map
                  google={this.props.google}
                  style={{ width: 'calc(100% - 30px)', height: '140px', top: "15px", left: "15px", position: "static" }}
                  initialCenter={{
                    lat: deliveryAddress.latitude,
                    lng: deliveryAddress.longitude
                  }}
                  zoom={15}

                >
                  <Marker
                    position={{ lat: deliveryAddress.latitude, lng: deliveryAddress.longitude }} />
                </Map>
              </div>

            </div>

          </div>
        </div>

        <div style={{ border: "1px solid lightgrey", marginBottom: "20px" }}>
          <div className="row">

            <div className="col-xs-12 col-sm-6 col-md-6">
              <div style={{ padding: "15px" }}>
                <div style={{ marginBottom: "px" }}>

                  <label style={{ display: "block" }}>Name</label>
                  <div>{deliveryAddress ? deliveryAddress.fullName : '-'}</div>

                  <label style={{ display: "block", marginTop: "5px" }}>Telephone Number</label>
                  <div>{deliveryContact && deliveryContact.mobilePhone ? deliveryContact.mobilePhone : '-'}</div>

                  <label style={{ display: "block", marginTop: "5px" }}>Email</label>
                  <div>{deliveryContact && deliveryContact.email ? deliveryContact.email : '-'}</div>

                  <label style={{ display: "block", marginTop: "5px" }}>Door code</label>
                  <div>{deliveryContact && deliveryContact.doorCode ? deliveryContact.doorCode : '-'}</div>

                  {orderInfo.orderTracked ?
                    <h4>
                      <span className="label label-warning">Tracking link has been used</span>
                    </h4> : ''
                  }

                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6 col-md-6" >
              <div style={{ padding: "15px" }}>
                <label>Additional information</label>
                <div>{order && order.description ? order.description : '-'}</div>
              </div>
            </div>

          </div>
        </div>

        <div style={{ border: "1px solid lightgrey", marginBottom: "20px" }}>
          <div className="row">

            <div className="col-xs-12">
              <div style={{ padding: "15px" }}>
                <label style={{ marginBottom: "10px" }}>Items</label>

                <table className="table table-striped" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "0", width: "70%" }}>Title</th>
                      <th style={{ border: "0" }}>Quantity</th>
                      <th style={{ border: "0" }}>Scanned</th>
                    </tr>
                  </thead>
                  <tbody>
                    {order.items ? this.renderItemRows(order.items) : ''}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div style={{ border: "1px solid lightgrey", marginBottom: "20px" }}>
          <div className="row">

            <div className="col-xs-12 col-sm-6">
              <div style={{ padding: "15px" }}>
                <label>Driver</label>
                <div>
                  {orderInfo && orderInfo.driver ? orderInfo.driver : '-'}
                  <h5 title="Rating" style={{ userSelect: "none" }}>
                    {
                      orderInfo && orderInfo.rating ? this.renderDriverRating(orderInfo.rating) : <span className="label label-default">Delivery not rated</span>
                    }

                  </h5>
                </div>

                <label style={{ marginTop: "5px" }}>Esitmated delivery time</label>
                <div>{orderInfo && orderInfo.promisedTime ? (`${new moment(orderInfo.promisedTime).format('HH:mm')} (${new moment(orderInfo.promisedTime).format('ddd YYYY-MM-DD')})`) : '-'}</div>

                <label style={{ marginTop: "5px" }}>Actual delivery time</label>
                <div>{orderInfo && orderInfo.timeArrived ?
                  (`${new moment(orderInfo.timeArrived).format('HH:mm')} (${new moment(orderInfo.timeArrived).format('ddd YYYY-MM-DD')})`)
                  : '-'}
                </div>
              </div>
            </div>

            <div className="col-xs-12 col-sm-6">
              <div style={{ padding: "15px" }}>

                <label style={{ marginTop: "5px" }}>Route name</label>
                <div>{orderInfo && orderInfo.routeName ? orderInfo.routeName : '-'}</div>

                <label style={{ marginTop: "5px" }}>Stop number</label>
                <div>{orderInfo && orderInfo.stopNumber ? orderInfo.stopNumber : '-'}</div>
              </div>
            </div>


          </div>
        </div>

        <div style={{ border: "1px solid lightgrey", marginBottom: "20px" }}>
          <div className="row">

            <div className="col-xs-12">
              <div style={{ padding: "15px" }}>
                <label style={{ marginBottom: "10px" }}>Events</label>

                <table className="table table-striped" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th style={{ border: "0", width: "70%" }}>Type</th>
                      <th style={{ border: "0" }}>Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {orderLog.length > 0 ? this.renderOrderLogRows(orderLog) : ''}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>

    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBTbP0T250T18ulE63WcbgXumSU8SNZpPQ'
})(OrderDetails);