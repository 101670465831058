import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';

const queryString = require('query-string');

class FleetInfoBesiktning extends Component {

  componentWillMount() {
  
  }

  render() {

    return (
      <div className="container">
             <div>
              <h3>Besiktning</h3>
         
            
            </div>
    
      </div>

    );
  }
}

export default FleetInfoBesiktning;

