import React, { Component } from "react";
import axios from "axios";
import _ from "lodash";
import moment from "moment";
import Dropzone from "react-dropzone";
import { SyncLoader } from "react-spinners";
import { Button } from "react-bootstrap";
import Select from "react-select";

class Import extends Component {
  constructor() {
    super();

    this.state = {
      selectedFile1: null
    };
  }

  login() {
    this.props.auth.login();
  }
  componentWillMount() {
    this.setState({
      isLoading: false
    });

    this.setState({ toggleValue: null });
    moment.locale("sv");
    this.setState({
      filename: ""
    });
  }
  componentDidMount() { }

  upload() {
    const data = new FormData();
    var access_token = localStorage.getItem('access_token');
    data.append("file1", this.state.selectedFile1);
    var path = this.state.toggleValue.value;
    if (this.state.toggleValue.value === "sfdkimporterJylland") {
      data.append("deliverygroup", "5db187d5aec4ca0012d45d03");
      path = "sfdkimporter";
    } else if (this.state.toggleValue.value === "sfdkimporterSjaelland") {
      data.append("deliverygroup", "5db19d10fee8b90012a7752e");
      path = "sfdkimporter";
    }

    const self = this;

    this.setState({
      isLoading: true
    });

    let config = {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access_token,
      }
    }

    axios
      .post(process.env.REACT_APP_GORDON_API_URL + "/" + path + "/gdp", data, config)
      .then(function (response) {
        self.setState({
          toggleValue: null,
          filename: "",
          selectedFile1: null,
          isLoading: false
        });
      })
      .catch(error => {
        console.error(error);
        self.setState({
          isLoading: false
        });
      });
  }

  onDrop = files => {
    files.forEach(file => {
      this.setState({
        filename: file.name,
        selectedFile1: file
      });
    });
  };

  handleMerchantChange = selectedOption => {
    if (selectedOption != null) {
      this.setState({
        toggleValue: selectedOption
      });
    } else {
      this.setState({
        toggleValue: null
      });
    }
  };

  render() {
    const { isAuthenticated } = this.props.auth;
    var divStyle = {
      display: this.state.isLoading ? "block" : "none"
    };

    var clients = [
      { value: "sfimporter", label: "Simple Feast" },
      { value: "coopimporter", label: "Coop Bäckebol - Expressleveranser" },
      { value: "smakboximporter", label: "Smakbox" },
      { value: "smakboxspecialimporter", label: "Smakbox - special" },
      { value: "minfarmimporter", label: "Min Farm" },
      { value: "sleximporter", label: "Svarta Lådan (Exclusive)" },
      { value: "sfdkimporterJylland", label: "Simple Feast DK - Jylland" },
      { value: "sfdkimporterSjaelland", label: "Simple Feast - Sjaelland" },
      { value: "simuleringimporter", label: "Gordon Simulering" }
    ];

    const importdisabled = !(
      this.state.selectedFile1 != null && this.state.toggleValue
    );
    return (
      <div className="container">
        {isAuthenticated() && (
          <div>
            <div className="cover" style={divStyle}>
              <div className="covercontent">
                <SyncLoader color={"#174b5F"} loading={this.state.isLoading} />
              </div>
            </div>

            <h3>Import av orderunderlag</h3>
            <div className="dropdownSearch">
              <Select
                placeholder="Välj kund/butik"
                name="ddSelectMerchant"
                onChange={this.handleMerchantChange}
                options={clients}
                className="basic-multi-select"
              />
              <br />
            </div>
            <div style={{ width: 200, padding: 20 }}>
              <Dropzone onDrop={this.onDrop} className="drop">
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()}>
                      <input {...getInputProps()} />
                      <i className="fas fa-cloud-upload-alt fa-5x iconcolor" />
                      <br />
                      <br />
                      <p>Dra och släpp en fil</p>
                      <p>Eller klicka här...</p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            <div className="buttonSearch">
              <Button
                bsStyle="primary"
                className="btn-margin"
                disabled={importdisabled}
                onClick={this.upload.bind(this)}
              >
                Importera
              </Button>
            </div>
          </div>
        )}
        {!isAuthenticated() && (
          <h4>
            You're not logged in! Click{" "}
            <a style={{ cursor: "pointer" }} onClick={this.login.bind(this)}>
              Login
            </a>{" "}
            to continue.
          </h4>
        )}
      </div>
    );
  }
}

export default Import;
