import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import _ from "lodash";
import { addDays } from 'date-fns';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-month-picker/css/month-picker.css';
import { DateRange } from 'react-date-range';
import Workbook from 'react-excel-workbook';
import moment from 'moment';

class WeeklyStats extends Component {
  constructor(props, context) {
    super(props, context)

    this.disableDiv = this.disableDiv.bind(this);
    this.enableDiv = this.enableDiv.bind(this);

  }

  componentWillMount() {

    this.setState({
      disableDiv: false
    });
  }

  login() {
    this.props.auth.login();
  }

  componentWillMount() {

    this.state = {
      dateRange: {
        selection: {
          startDate: addDays(new Date(), -7),
          endDate: new Date(),
          key: 'selection',
        },
      }
    };
    this.setState({
      dateRange: {
        selection: {
          startDate: addDays(new Date(), -7),
          endDate: new Date(),
          key: 'selection',
        },
      }
    });
    this.setState({ data: [] });
    this.setState({ isLoading: false });
  }
  componentDidMount() {
    this.getStats(moment().add(-7, 'days'), moment());
  }

  handleRangeChange(which, payload) {
    console.log(which, payload);
    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });

    this.getStats(payload.selection.startDate, payload.selection.endDate);
  }

  getStats(from, to) {

    this.disableDiv();
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {
      this.setState({ isLoading: true });
      fetch(process.env.REACT_APP_GORDON_API_URL + '/order/statsgroupeddaterange?from=' + moment(from).format("YYYY-MM-DD") + "&to=" + moment(to).format("YYYY-MM-DD"), {
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + access_token,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((dataJson) => {

          var data = dataJson;
          for (let i = 0; i < data.length; i++) {
            data[i].link = '/delivery?merchantname=' + data[i].kund + '&date=' + data[i].datum;
          };


          this.setState({ data: data });
          this.setState({ isLoading: false });
          this.enableDiv();

        })
        .catch((error) => {
          console.error(error);
          this.enableDiv();
        });
    } else {
      console.error("401 Unauthorized");
      this.enableDiv();
    }
  }

  disableDiv() {
    this.setState({
      disableDiv: true
    });
  }

  enableDiv() {
    this.setState({
      disableDiv: false
    });
  }


  render() {
    const { isAuthenticated } = this.props.auth;

    var divStyle = {
      display: this.state.disableDiv ? 'block' : 'none'
    };

    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Veckouppföljning</h3>
              <div className="cover" style={divStyle}></div>
              <label>Välj datumintervall</label>

              <DateRange
                onChange={this.handleRangeChange.bind(this, 'dateRange')}
                moveRangeOnFirstSelection={false}
                dateDisplayFormat="YYYY-MM-DD"
                ranges={[this.state.dateRange.selection]}
                className={'PreviewArea'}
              />

              <br />
              <br />
              <Workbook filename="weekly.xlsx" element={<button className="btn btn-primary">Exportera till Excel</button>}>
                <Workbook.Sheet data={() => this.state.data} name="Weekly stats">
                  <Workbook.Column label="Butik" value="kund" />
                  <Workbook.Column label="Leveransprecision" value="precision" />
                  <Workbook.Column label="Antal" value="antal" />
                </Workbook.Sheet>
              </Workbook>
              <br /><br />
              <ReactTable
                noDataText="Oh Noes!"
                data={this.state.data}
                columns={[


                  {
                    Header: 'Butik',
                    accessor: 'kund',
                  },
                  {
                    Header: 'Antal',
                    accessor: 'antal'
                  },
                  {
                    Header: 'Leveransprecision',
                    accessor: 'precision',
                    Cell: row => (
                      <div
                        style={{
                          width: '100%',
                          height: '100%',
                          backgroundColor: '#dadada',
                          borderRadius: '2px'
                        }}
                      >

                        <div
                          style={{
                            width: `${row.value}%`,
                            height: '100%',
                            backgroundColor: row.value > 90 ? '#228B22'
                              : row.value > 70 ? '#FFA500'
                                : '#fe0400',
                            borderRadius: '2px',
                            transition: 'all .2s ease-out'
                          }}
                        >
                          <span style={{
                            color: '#ffffff',
                            paddingLeft: '10px'

                          }}>
                            {row.value}%
                  </span>
                        </div>
                      </div>

                    )
                  }

                ]}
                defaultPageSize={16}
                filterable={true}
                defaultSorted={[{
                  id: 'antal',
                  desc: true,
                }]}
                className="-striped -highlight"
              />
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default WeeklyStats;

