import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Workbook from 'react-excel-workbook';
import _ from "lodash";
import DatePicker from "react-16-bootstrap-date-picker";
import TimePicker from "react-bootstrap-time-picker";
import Switch from "react-switch";
import { addDays } from 'date-fns';
import moment from 'moment';
import { Button, Modal, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { SyncLoader } from 'react-spinners';
import matchSorter from 'match-sorter';
import checkboxTableHOC from "./checkboxHOC";
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete'
/*global google*/
const CheckboxTable = checkboxTableHOC(ReactTable);
var clientsList = [];
var clients = [];

class Unplanned extends Component {
    constructor(props) {
        super(props);
        this.cancel = this.cancel.bind(this);
        this.renderEditable = this.renderEditable.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelect = this.handleSelect.bind(this);
        this.handleTimeChangeStart = this.handleTimeChangeStart.bind(this);
        this.handleTimeChangeEnd = this.handleTimeChangeEnd.bind(this);
        this.handleTimeChangeStartDriver = this.handleTimeChangeStartDriver.bind(this);
        this.handleTimeChangeEndDriver = this.handleTimeChangeEndDriver.bind(this);
        this.renderGeocodeFailure = this.renderGeocodeFailure.bind(this);
        this.renderGeocodeSuccess = this.renderGeocodeSuccess.bind(this);
        this.redirectToLoginPage = this.redirectToLoginPage.bind(this);
        this.removeItem = this.removeItem.bind(this);
        this.addItem = this.addItem.bind(this);

        this.state = {
            loading: false,
            geocodeResults: null,
            cartItems: [],
            isFakturerbar: true,
        };
    }

    handleTimeChangeStart(starttime) {
        this.setState({ starttime: starttime });
    }
    handleTimeChangeEnd(endtime) {
        this.setState({ endtime: endtime });
    }

    handleTimeChangeStartDriver(driverStarttime) {
        this.setState({ driverStarttime: driverStarttime });
    }
    handleTimeChangeEndDriver(driverEndtime) {
        this.setState({ driverEndtime: driverEndtime });
    }

    renderEditable(cellInfo) {
        return (
            <div
                style={{ backgroundColor: "#fafafa" }}
                contentEditable
                suppressContentEditableWarning
                onBlur={e => {
                    const rows = [...this.state.rows];
                    rows[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
                    this.setState({ rows });
                }}
                dangerouslySetInnerHTML={{
                    __html: this.state.rows[cellInfo.index][cellInfo.column.id]
                }}
            />
        );
    }

    redirectToLoginPage() {
        this.props.history.push("/signin");
    }

    componentWillMount() {
        let user = localStorage.getItem('userProfileName');
        let toggleValue = [];

        this.setState({
            starttime: 54000,// 15:00
            endtime: 79200,// 22:00
            driverStarttime: 46800,// 13:00
            driverEndtime: 84600,// 23:30
            cartItems: [],
            formattedValue: null,
            value: '',
            deliveryformattedValue: null,
            deliveryvalue: '',
            plocklista: [],
            author: user,

            isLoading: false,
            drivercount: 0,
            duration: 3,
            pageSize: 50,
            depot: null,

            showModal: false,
            showOrderModal: false,
            showConfirmModal: false,
            showValidationModal: false,
            selectedclients: [],
            errors: [],
            warnings: [],

            clients: [],
            clientname: '',
            statuses: ['New', 'Planned', 'Routed', 'Assigned', 'Unassigned', 'Dispatched', 'Deleted', 'Cancelled', 'Done'],


            deliveryGroups: [
                // { value: 'Gordon Sthlm', label: 'Gordon Sthlm' },
                // { value: 'Gordon Gbg', label: 'Gordon Gbg' },
                // { value: 'Gordon Mma', label: 'Gordon Mma' },
                // { value: 'Gordon Ostergotland', label: 'Gordon Ostergotland' },
                // { value: 'Gordon Eskilstuna', label: 'Gordon Eskilstuna' },
                // { value: "Gordon Ljungby", label: "Gordon Ljungby" },
                // { value: 'Gordon Sjælland', label: 'Gordon Sjælland' },
                // { value: 'Gordon Aaborg', label: 'Gordon Aaborg' },
                // { value: 'Gordon Fredericia', label: 'Gordon Fredericia' },
                // { value: 'Gordon Århus', label: 'Gordon Århus' },],
                { value: "5efcaba003fd75517c04fb37", label: "Gordon Stockholm Tumba" },
                { value: "61ee68c02bf9b60bbcbe7d63", label: "Gordon Stockholm Årsta" },
                { value: "5efcaba003fd75517c04fb37", label: "Gordon Stockholm Västberga" },
                { value: "648064d45a639704741f519e", label: "Gordon Stockholm Tumba Temp" },
                { value: "5efcabc203fd75517c04fb39", label: "Gordon Göteborg" },
                { value: "5efcabb103fd75517c04fb38", label: "Gordon Malmö" },
                { value: '5efcac8903fd75517c04fb3b', label: 'Gordon Linköping' },
                { value: '61695c442d8ad20f37a40179', label: 'Gordon Karlstad' },
                { value: '5efcacf603fd75517c04fb3e', label: 'Gordon København, DK' },
                { value: '5efcae2d03fd75517c04fb42', label: 'Gordon Aalborg, DK' },
                { value: '5efcacdb03fd75517c04fb3d', label: 'Gordon Fredericia, DK' },
                { value: '5efcad0703fd75517c04fb3f', label: 'Gordon Århus, DK' },
                { value: '5efcacc303fd75517c04fb3c', label: 'Gordon Eskilstuna' },
                { value: "5f71b3f408755a0c6592c78a", label: "Gordon Halmstad" },
                { value: "5f71b3d9d4ecbe0c79a0d28f", label: "Gordon Jönköping" },
                { value: "5f71b3b708755a0c6592c782", label: "Gordon Växjö" },
                { value: '602b8e0464849a0bb91dbec5', label: 'Gordon Uppsala' },
                { value: "5efcac3303fd75517c04fb3a", label: "Gordon Ljungby" },
                { value: "62a08775ce90de0beb76b28c", label: "Gordon Karlshamn" },
                { value: "630dd5385c0c3b0c06292014", label: "Gordon hissing kärra" },
                { value: "641a0370bf36d430617eaab5", label: "Gordon Gävle" },
                { value: "633aa933d74ce70b8d0e9a6a", label: "Gordon Sundsvall" }],

            user: '',

            dateRange: {
                selection: {
                    startDate: addDays(new Date(), -7),
                    endDate: addDays(new Date(), 7),
                    key: 'selection',
                },
            },

            toggleValue: toggleValue,
            selectedNewClient: null,
            rows: [],
            selectedDeliveryGroup: [],

            originalrows: [],

            external_ref: null,
            selectedorder: null,
            customername: null,
            customerphone: null,
            customeraddress: null,
            customerzipcode: null,
            customercity: null,
            customerentrycode: null,
            latitude: null,
            longitude: null,
            customeremail: null,
            errormessage: null,
            deliveryProject: null
        });





        this.fetchData = this.fetchData.bind(this);

    }

    addItem() {
        var exists = false;

        for (let i = 0; i < this.state.cartItems.length; i++) {
            if (
                this.state.cartItems[i].value === this.state.kolli
            ) {
                exists = true;
                this.state.cartItems[i].count = this.state.cartItems[i].count + this.state.count;
            }
        }
        if (!exists) {
            this.state.cartItems.unshift({
                index: this.state.cartItems.length + 1,
                value: this.state.kolli,
                count: this.state.count,
            });
        }
        this.setState({ cartItems: this.state.cartItems, count: null, kolli: null });
    }

    removeItem(itemIndex) {
        var itemToRemove = this.state.cartItems[itemIndex];

        if (itemToRemove.count > 1) {
            itemToRemove.count = itemToRemove.count - 1;
        } else {
            this.state.cartItems.splice(itemIndex, 1);
        }
        this.setState({ cartItems: this.state.cartItems });
    }

    renderGeocodeFailure(err) {
        return (
            <div className="alert alert-danger" role="alert">
                <strong>Oops, något gick fel!</strong> Kunde inte hitta adressen, försök igen!
            </div>
        )
    }

    renderGeocodeSuccess(lat, lng) {

    }

    handleSelect(address) {
        this.setState({
            searchaddress: address,
            loading: true
        })

        geocodeByAddress(address)
            .then((results) => {
                if (results != null && results[0]) {

                    var lat = results[0].geometry.location.lat();
                    var lng = results[0].geometry.location.lng();
                    var street_number = null;
                    var route = null;
                    var zipode = null;
                    var city = null;

                    results[0].address_components.forEach(component => {
                        if (component.types != null && component.types.length > 0) {
                            if (component.types[0] == "street_number") {
                                street_number = component.long_name;
                            }
                            if (component.types[0] == "route") {
                                route = component.long_name;
                            }
                            if (component.types[0] == "postal_town") {
                                city = component.long_name;
                            }
                            if (component.types[0] == "postal_code") {
                                zipode = component.long_name;
                            }
                        }
                    });
                    if (zipode != null) {
                        this.setState({
                            geocodeResults: this.renderGeocodeSuccess(lat, lng),
                            errormessage: null,
                            loading: false,
                            latitude: lat,
                            longitude: lng,
                            customerzipcode: zipode,
                            customercity: city,
                            customeraddress: route + " " + street_number
                        })
                    }
                    else {
                        this.setState({
                            geocodeResults: this.renderGeocodeFailure("Felaktig adress, försök igen..."),
                            loading: false
                        })
                    }
                }
            })
            .catch((error) => {
                console.log('Oh no!', error)
                this.setState({
                    geocodeResults: this.renderGeocodeFailure(error),
                    loading: false
                })
            })
    }

    handleChange(address) {
        this.setState({
            searchaddress: address,
            geocodeResults: null
        })
    }

    nameChanged(evt) {
        const customername = evt.target.value;
        this.setState({ customername });
    }
    phoneChanged(evt) {
        const customerphone = evt.target.value;
        this.setState({ customerphone });
    }
    addressChanged(evt) {
        const customeraddress = evt.target.value;
        this.setState({ customeraddress });
    }
    zipcodeChanged(evt) {
        const customerzipcode = evt.target.value;
        this.setState({ customerzipcode });
    }
    cityChanged(evt) {
        const customercity = evt.target.value;
        this.setState({ customercity });
    }
    emailChanged(evt) {
        const customeremail = evt.target.value;
        this.setState({ customeremail });
    }
    latitudeChanged(evt) {
        const latitude = evt.target.value;
        this.setState({ latitude });
    }
    longitudeChanged(evt) {
        const longitude = evt.target.value;
        this.setState({ longitude });
    }
    entrycodeChanged(evt) {
        const customerentrycode = evt.target.value;
        this.setState({ customerentrycode });
    }
    instructionsChanged(evt) {
        const instructions = evt.target.value;
        this.setState({ instructions });
    }
    priceChanged(evt) {
        const price = evt.target.value;
        this.setState({ price });
    }
    countChanged(evt) {
        const count = evt.target.value;
        this.setState({ count });
    }
    kolliChanged(evt) {
        const kolli = evt.target.value;
        this.setState({ kolli });
    }

    componentDidMount() {
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {
            var url = process.env.REACT_APP_GORDON_API_URL + "/client/active";

            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((resp) => resp.json())
                .then((dataJson) => {
                    clientsList = [];
                    clients = [];
                    for (let i = 0; i < dataJson.length; i++) {
                        clients.push({ value: dataJson[i]._id, label: dataJson[i].name });
                        clientsList.push(dataJson[i])
                    }
                    const sorter = (a, b) => {
                        if (a.label > b.label) return 1;
                        if (a.label < b.label) return -1;
                        return 0;
                    };

                    clients = clients.sort(sorter);

                    this.setState({ clients: clients });
                    this.setState({ clientsList: clientsList });
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }

    getOrders(merchant, startDate, endDate, deliverygroup) {
        this.setState({
            isLoading: true
        });
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            fetch(process.env.REACT_APP_GORDON_API_URL + '/planning?merchant=' + merchant + '&from=' + moment(startDate).format("YYYY-MM-DD") + '&to=' + moment(endDate).format("YYYY-MM-DD"), {
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + access_token,
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {

                    var items = [];
                    var result = [];

                    for (var i = 0; i < responseJson.length; i++) {
                        // if ((responseJson[i].validation != null && responseJson[i].validation.deliveryGroup != null && responseJson[i].validation.deliveryGroup == deliverygroup) || (responseJson[i].validation == null) || (responseJson[i].validation.deliveryGroup == null)) {

                        var row = responseJson[i];

                        row.created_at = moment(responseJson[i].created_at).format("YYYY-MM-DD HH:mm");
                        row.updated_at = moment(responseJson[i].updated_at).format("YYYY-MM-DD HH:mm");
                        row.deliveryDate = moment(responseJson[i].deliveryDate).format("YYYY-MM-DD");
                        row.deliveryDateWW = moment(responseJson[i].deliveryDate).format("MM/DD/YYYY");
                        row.merchant = responseJson[i].pickupInfo.contactInfo.name;
                        row.customername = responseJson[i].deliveryAddress.fullName;
                        row.customeraddress = responseJson[i].deliveryAddress.streetAddress;
                        row.customerzipcode = responseJson[i].deliveryAddress.postalCode;
                        row.customercity = responseJson[i].deliveryAddress.city;
                        // row.latitude = responseJson[i].deliveryAddress.latitude;
                        // row.longitude = responseJson[i].deliveryAddress.longitude;
                        row.partialmatch = responseJson[i].deliveryAddress.partialmatch;
                        row.entrycode = responseJson[i].deliveryAddress.entryCode;
                        row.mobilePhone = responseJson[i].deliveryContactInformation != null ? responseJson[i].deliveryContactInformation.mobilePhone : null;
                        row.email = responseJson[i].deliveryContactInformation != null ? responseJson[i].deliveryContactInformation.email : null;
                        row.zipcodeApproved = responseJson[i].validation ? responseJson[i].validation.zipcodeApproved : false;
                        row.zone = responseJson[i].validation ? responseJson[i].validation.zone : null;
                        row.deliveryGroup = responseJson[i].validation ? responseJson[i].validation.deliveryGroup : null;
                        row.collectionPoint = responseJson[i].validation ? responseJson[i].validation.collectionPoint : null;
                        row.status = responseJson[i].orderInfo.status;

                        row.entrycode = responseJson[i].deliveryAddress.entryCode;

                        row.noearlierthan = moment(responseJson[i].orderInfo.noEarlierThan).format("HH:mm");
                        row.nolaterhan = moment(responseJson[i].orderInfo.noLaterThan).format("HH:mm");

                        var totalWeight = 0;
                        var dryWeight = 0;
                        var chilledWeight = 0;
                        var frozenWeight = 0;

                        if (responseJson[i].items != null && responseJson[i].items.length > 0) {
                            var antal = 0;
                            var content = "";
                            var packageType = "";
                            for (let j = 0; j < responseJson[i].items.length; j++) {
                                if (responseJson[i].items[j].weight != null && responseJson[i].items[j].weight > 0) {
                                    totalWeight = totalWeight + responseJson[i].items[j].weight;
                                    if (responseJson[i].items[j].itemType != null && responseJson[i].items[j].itemType == "dry") {
                                        dryWeight = dryWeight + responseJson[i].items[j].weight;
                                    } else if (responseJson[i].items[j].itemType != null && responseJson[i].items[j].itemType == "chilled") {
                                        chilledWeight = chilledWeight + responseJson[i].items[j].weight;
                                    } else if (responseJson[i].items[j].itemType != null && responseJson[i].items[j].itemType == "frozen") {
                                        frozenWeight = frozenWeight + responseJson[i].items[j].weight;
                                    }
                                }
                                if (j > 0) {
                                    content = content + " \n\r";
                                    packageType = packageType + " | ";
                                }
                                antal = antal + responseJson[i].items[j].numberOfItems;
                                if (responseJson[i].merchant == "GI-Boxen") {
                                    content = content + responseJson[i].items[j].name;
                                    packageType = packageType + responseJson[i].items[j].name;
                                }
                                else if (responseJson[i].merchant == "outofhome.se") {
                                    packageType = packageType + responseJson[i].items[j].itemType + " - " + responseJson[i].items[j].packageType + " (" + responseJson[i].items[j].weight + " kg)";
                                }
                                else {

                                    packageType = `${packageType}${responseJson[i].items[j].numberOfItems} x ${responseJson[i].items[j].name}`;
                                }

                                if (responseJson[i].items[j].articles != null && responseJson[i].items[j].articles.length > 0) {
                                    content = content + ":\n\r";
                                    for (let k = 0; k < responseJson[i].items[j].articles.length; k++) {
                                        if (k > 0) {
                                            content = content + " \n\r";
                                        }
                                        items.push({
                                            "name": responseJson[i].items[j].articles[k].name,
                                            "count": responseJson[i].items[j].articles[k].quantity
                                        });

                                        content = content + responseJson[i].items[j].articles[k].quantity + "x " + responseJson[i].items[j].articles[k].name;
                                    }
                                    content = content + " \n\r";
                                }
                            }
                            row.numberOfItems = antal;
                            row.packageType = packageType;
                            row.packageContent = content;

                            row.totalWeight = totalWeight;
                            row.dryWeight = dryWeight;
                            row.chilledWeight = chilledWeight;
                            row.frozenWeight = frozenWeight;
                        }
                        else {
                            row.packageType = responseJson[i].orderInfo.title;
                            row.numberOfItems = 1;
                        }
                        var timewindow = moment(responseJson[i].orderInfo.noEarlierThan).format("HH:mm") + " - " +
                            moment(responseJson[i].orderInfo.noLaterThan).format("HH:mm");
                        row.timewindow = timewindow;

                        row.timewindowstart = moment(responseJson[i].orderInfo.noEarlierThan).format("HH:mm");
                        row.timewindowend = moment(responseJson[i].orderInfo.noLaterThan).format("HH:mm");
                        row.duration = "3"; //set default duration


                        result.push(row);
                        // }
                    }

                    var plocklista = [];
                    plocklista = [];
                    items.reduce(function (res, value) {
                        if (!res[value.name]) {
                            res[value.name] = {
                                count: 0,
                                name: value.name
                            };
                            plocklista.push(res[value.name])
                        }
                        res[value.name].count += value.count
                        return res;
                    }, {});

                    const sorter = (a, b) => {
                        if (a.name < b.name) return -1;
                        if (a.name > b.name) return 1;
                        return 0;
                    };

                    plocklista = plocklista.sort(sorter);

                    let rows = result;
                    this.setState({ plocklista: plocklista });
                    this.setState({ rows: rows });
                    this.setState({ originalrows: result });
                    this.setState({
                        isLoading: false,
                        pageSize: rows.length
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        isLoading: false
                    });
                });

        } else {
            console.error("401 Unauthorized");
            this.setState({
                isLoading: false
            });
        }
    }

    updateStatus() {
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            let url = process.env.REACT_APP_GORDON_API_URL + '/order/orders/' + this.state.selectedorder + '/' + "ORDER_DELETED";
            fetch(url, {
                method: 'PUT',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => {

                    this.setState({
                        showOrderModal: false,
                        showConfirmModal: false
                    });
                    this.setState({
                        external_ref: null,
                        selectedorder: null,
                        searchaddress: null,
                        customername: null,
                        customerphone: null,
                        customeraddress: null,
                        customerzipcode: null,
                        customercity: null,
                        customerentrycode: null,
                        latitude: null,
                        longitude: null,
                        customeremail: null,
                        errormessage: null,
                        instructions: null,
                        price: null,
                        deliveryGroupZone: null,
                        merchant: null,
                        isFakturerbar: true,
                        starttime: 54000,
                        endtime: 79200,
                        formattedValue: moment()
                            .format("YYYY-MM-DD"),
                        value: new Date(moment()).toISOString(),
                        deliveryformattedValue: moment()
                            .format("YYYY-MM-DD"),
                        deliveryvalue: new Date(moment()).toISOString()
                    });
                    this.searchClick();
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            console.error("401 Unauthorized");
        }
    }

    fetchData(state, instance) {
        let filteredData = this.state.originalrows;
        if (state.filtered.length) {
            filteredData = state.filtered.reduce((filteredSoFar, nextFilter) => {
                return filteredSoFar.filter(row => {
                    return (row[nextFilter.id] + "").includes(nextFilter.value);
                });
            }, filteredData);
        }
        // You can also use the sorting in your request, but again, you are responsible for applying it.
        const sortedData = _.orderBy(
            filteredData,
            state.sorted.map(sort => {
                return row => {
                    if (row[sort.id] === null || row[sort.id] === undefined) {
                        return -Infinity;
                    }
                    return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                };
            }),
            state.sorted.map(d => (d.desc ? "desc" : "asc"))
        );

        var result = [];
        for (var i = 0; i < sortedData.length; i++) {
            if (moment().add(-7, 'days').isBefore(moment(sortedData[i].deliveryDate)) &&
                moment(sortedData[i].deliveryDate).isBefore(moment())) {
                result.push(sortedData[i]);
            }
        }
        let rows = sortedData;
        this.state.rows = rows;
        this.setState({ rows: rows });
    }

    handleRangeChange(which, payload) {
        console.log(which, payload);
        this.setState({
            [which]: {
                ...this.state[which],
                ...payload,
            },
        });

    }

    cancel() {
        this.setState({
            showModal: false
        });
    }

    cancelDelete() {
        this.setState({
            showConfirmModal: false
        });
    }

    cancelValidation() {
        this.setState({
            showValidationModal: false
        });
    }

    routeClick() {
        var rows = this.state.rows;
        var selectedclients = [];
        var errors = [];
        var warnings = [];
        if (this.state.toggleValue != null && this.state.toggleValue.length == 1) {
            var client = this.state.clientsList[clientsList.findIndex(i => i.external_ref == this.state.toggleValue[0].value)];
            if (client != null && client.address != null) {
                this.setState({
                    depot: client.address.streetAddress + ", " + client.address.postalCode + " " + client.address.city
                });
            }
            else {
                if (this.state.selectedDeliveryGroup == "Gordon Sthlm") {
                    this.setState({
                        depot: "Grosshandlarvägen 7, 120 44 Årsta"
                    });
                }
                else if (this.state.selectedDeliveryGroup == "Gordon Gbg") {
                    this.setState({
                        depot: "Långavallsgatan 8-10, 424 57 Gunnilse"
                    });
                }
                else if (this.state.selectedDeliveryGroup == "Gordon Mma") {
                    this.setState({
                        depot: "Kantyxegatan 22, 213 76 Malmö"
                    });
                }
            }
        } else {
            if (this.state.selectedDeliveryGroup == "Gordon Sthlm") {
                this.setState({
                    depot: "Grosshandlarvägen 7, 120 44 Årsta"
                });
            }
            else if (this.state.selectedDeliveryGroup == "Gordon Gbg") {
                this.setState({
                    depot: "Långavallsgatan 8-10, 424 57 Gunnilse"
                });
            }
            else if (this.state.selectedDeliveryGroup == "Gordon Mma") {
                this.setState({
                    depot: "Kantyxegatan 22, 213 76 Malmö"
                });
            }
        }
        for (let index = 0; index < rows.length; index++) {
            const cell = rows[index];
            if (this.state.selection.indexOf(cell._id) >= 0) {

                if (selectedclients.indexOf(cell.merchant) < 0) {
                    selectedclients.push(cell.merchant);
                }
                if (cell.zipcodeApproved != 'OK') {
                    if (cell.zipcodeApproved == 'Ogiltig') {
                        errors.push("Order ID " + cell.external_ref + " med postnummer " + cell.customerzipcode + " är inte giltigt inom vald Delivery Group.");
                    }
                    if (cell.zipcodeApproved == 'Inget postnummer') {
                        errors.push("Order ID " + cell.external_ref + " saknar postnummer.");
                    }
                    if (cell.zipcodeApproved == 'Geolocation Error') {
                        warnings.push("Order ID " + cell.external_ref + ", kan inte matcha exakt adress.");
                    }
                }
            }
        }

        this.setState({
            errors: errors,
            warnings: warnings,
            drivercount: Math.round(this.state.selection.length / 30) == 0 ? 1 : Math.round(this.state.selection.length / 30),
            deliveryProject: this.state.selectedDeliveryGroup + "-" + moment(this.state.formattedValue).format("YYYY-MM-DD")
        });
        this.setState({
            showModal: true,
            selectedclients, selectedclients,
        });
    }

    searchClick() {
        var merchant = this.state.toggleValue != null ? this.state.toggleValue.map(e => e.value).join(",") : "";
        this.setState({ merchant: merchant });
        this.setState({ selection: [] });

        this.getOrders(merchant, this.state.formattedValue, this.state.formattedValue, this.state.selectedDeliveryGroup);
    }

    durationChanged = (evt) => {
        const duration = parseInt(evt.target.value);
        this.setState({ duration });
    }

    depotChanged = (evt) => {
        const depot = evt.target.value;
        this.setState({ depot });
    }

    driversChanged = (evt) => {
        const drivercount = evt.target.value;
        this.setState({ drivercount });
    }

    deliveryProjectChanged = (evt) => {
        const deliveryProject = evt.target.value;
        this.setState({ deliveryProject });
    }
    handleDeliveryGroupChange = (selectedOption) => {
        if (selectedOption != null) {

            this.setState({
                selectedDeliveryGroup: selectedOption.value,
            });
            if (selectedOption.label == "Gordon Sthlm") {
                this.setState({
                    depot: "Grosshandlarvägen 7, 120 44 Årsta"
                });
            }
            else if (selectedOption.label == "Gordon Gbg") {
                this.setState({
                    depot: "Långavallsgatan 8-10, 424 57 Gunnilse"
                });
            }
            else if (selectedOption.label == "Gordon Mma") {
                this.setState({
                    depot: "Kantyxegatan 22, 213 76 Malmö"
                });
            }
        }
        else {
            this.setState({
                selectedDeliveryGroup: null
            });
        }
    }
    confirmDelete() {
        this.setState({
            showConfirmModal: true,
        });
    }

    openNew(row) {
        this.setState({
            method: 'POST',
            title: "Skapa ny order",
            starttime: 61200,
            endtime: 79200,
            isFakturerbar: true,
            deliveryformattedValue: moment()
                .format("YYYY-MM-DD"),
            deliveryvalue: new Date(moment()).toISOString()
        });
        var cartItems = [];

        this.setState({ cartItems: cartItems });
        var errormessage = null;

        this.setState({
            showOrderModal: true,
            errormessage: errormessage
        });
    }
    open(row) {
        this.setState({
            method: 'PUT',
            title: "Uppdatera order",
            selectedorder: row.original._id,
            external_ref: row.original.external_ref,
            customername: row.original.customername,
            customerphone: row.original.mobilePhone,
            customeraddress: row.original.customeraddress,
            customerzipcode: row.original.customerzipcode,
            customercity: row.original.customercity,
            latitude: row.original.deliveryAddress.latitude,
            longitude: row.original.deliveryAddress.longitude,
            customeremail: row.original.email,
            selectedNewClient: { value: row.original.pickupInfo.contactInfo.name, label: row.original.pickupInfo.contactInfo.name },
            customerentrycode: row.original.entrycode,
            instructions: row.original.deliveryInstructions,
            price: row.original.orderInfo.price,
            isFakturerbar: row.original.orderInfo.price != null && row.original.orderInfo.price > 0 ? true : false,
            deliveryGroupZone:
                row.original.validation != null
                    ? row.original.validation.deliveryGroup + (row.original.validation.zone != null ? " / " + row.original.validation.zone : "")
                    : "",
            searchaddress:
                row.original.customeraddress +
                (row.original.deliveryAddress != null && row.original.deliveryAddress.postalCode != null
                    ? ", " +
                    row.original.deliveryAddress.postalCode +
                    " " +
                    row.original.deliveryAddress.city
                    : ""),
            merchant: row.original.pickupInfo.contactInfo.name,

            starttime: moment(row.original.orderInfo.noEarlierThan).diff(moment(row.original.orderInfo.noEarlierThan).startOf('day'), 'seconds'),
            endtime: moment(row.original.orderInfo.noLaterThan).diff(moment(row.original.orderInfo.noEarlierThan).startOf('day'), 'seconds')
            // searchaddress: row.original.customeraddress + ", " + row.original.customerzipcode + " " + row.original.customercity
        });
        var cartItems = [];
        for (let index = 0; index < row.original.items.length; index++) {
            const element = row.original.items[index];
            cartItems.push({
                index: cartItems.length,
                value: element.name,
                count: element.numberOfItems,
                orderitem: element
            });
        }
        this.setState({ cartItems: cartItems });
        var errormessage = null;
        if (row.original.zipcodeApproved != 'OK') {
            if (row.original.zipcodeApproved == 'Ogiltig') {
                errormessage = "Postnummer " + row.original.customerzipcode + " är inte giltigt inom vald Delivery Group.";
            }
            if (row.original.zipcodeApproved == 'Inget postnummer') {
                errormessage = "Postnummer saknas.";
            }
            if (row.original.zipcodeApproved == 'Geolocation Error') {
                errormessage = "Kan inte verifiera adressen. Uppdatera adressen eller ange koordinater.";
            }
        }
        if (row.original.latitude == null || row.original.longitude == null) {
            errormessage = "Koordinater saknas";
        }
        else if (row.original.partialmatch) {
            errormessage = "Kan inte verifiera adressen. Uppdatera adressen eller ange koordinater";
        }

        this.setState({
            showOrderModal: true,
            errormessage: errormessage
        });
    }
    toggleFakturerbar = () => {
        this.setState({
            isFakturerbar: !this.state.isFakturerbar
        });
    };
    handleMerchantChange = (selectedOption) => {
        if (selectedOption != null) {
            this.setState({
                toggleValue: selectedOption
            });
        }
        else {
            this.setState({
                toggleValue: []
            });
        }
    }
    handleNewOrderMerchantChange = (selectedOption) => {

        if (selectedOption != null) {
            this.setState({
                selectedNewClient: selectedOption
            });
        }
        else {
            this.setState({
                selectedNewClient: {}
            });
        }
    }
    toggleSelection = (key, shift, row) => {
        // start off with the existing state
        let selection = [...this.state.selection];
        const keyIndex = selection.indexOf(key);
        // check to see if the key exists
        if (keyIndex >= 0) {
            // it does exist so we will remove it using destructing
            selection = [
                ...selection.slice(0, keyIndex),
                ...selection.slice(keyIndex + 1)
            ];
        } else {
            // it does not exist so add it
            selection.push(key);
        }
        // update the state
        this.setState({ selection });
    };

    toggleAll = () => {
        const selectAll = this.state.selectAll ? false : true;
        const selection = [];
        if (selectAll) {
            // we need to get at the internals of ReactTable
            const wrappedInstance = this.checkboxTable.getWrappedInstance();
            // the 'sortedData' property contains the currently accessible records based on the filter and sort
            const currentRecords = wrappedInstance.getResolvedState().sortedData;
            // we just push all the IDs onto the selection array
            currentRecords.forEach(item => {
                selection.push(item._original._id);
            });
        }
        this.setState({ selectAll, selection });
    };

    isSelected = key => {
        return this.state.selection.includes(key);
    };

    sendToRoutific() {
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            var postbody = {
                'name': this.state.deliveryProject,
                'date': moment(this.state.formattedValue).format("YYYY-MM-DD"),
                'duration': this.state.duration,
                'drivers': this.state.drivercount,
                'depot': this.state.depot,
                'deliveryGroup': this.state.selectedDeliveryGroup,
                'starttime': this.state.driverStarttime,
                'endtime': this.state.driverEndtime,
                'orders': []
            };
            var orders = [];
            this.state.rows.forEach(row => {
                if (this.state.selection.indexOf(row._id) >= 0) {
                    var address = row.customeraddress;
                    if (row.customerzipcode != null && row.customerzipcode != "") {
                        address = address + ", " + row.customerzipcode + " " + row.customercity + ", Sweden";
                    }
                    orders.push({
                        customername: row.customername,
                        customeraddress: address,
                        latitude: row.latitude,
                        longitude: row.longitude,
                        orderid: row._id,
                        deliveryGroup: row.deliveryGroup,
                        zone: row.zone,
                        collectionPoint: row.collectionPoint,
                        merchant: row.merchant,
                        noearlierthan: row.noearlierthan,
                        nolaterthan: row.nolaterhan
                    })
                }
            });

            postbody.orders = orders;
            fetch(process.env.REACT_APP_GORDON_API_URL + '/planning/route', {
                method: 'POST',
                body: JSON.stringify(postbody),
                headers: {
                    'Authorization': 'Bearer ' + access_token,
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {

                    this.setState({
                        showModal: false,
                    });

                    this.searchClick();
                })
                .catch((error) => {
                    console.error(error);
                });

        } else {
            console.error("401 Unauthorized");
        }
    };

    handleDateChange(value, formattedValue) {
        this.setState({
            value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
            formattedValue: formattedValue, // Formatted String, ex: "11/19/2016",
            deliveryvalue: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
            deliveryformattedValue: formattedValue // Formatted String, ex: "11/19/2016",
        });
    }

    handleDeliveryDateChange(value, formattedValue) {
        this.setState({
            deliveryvalue: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
            deliveryformattedValue: formattedValue // Formatted String, ex: "11/19/2016"
        });
    }

    cancelOrder() {
        this.setState({
            external_ref: null,
            selectedorder: null,
            searchaddress: null,
            customername: null,
            customerphone: null,
            customeraddress: null,
            customerzipcode: null,
            customercity: null,
            customerentrycode: null,
            latitude: null,
            longitude: null,
            customeremail: null,
            errormessage: null,
            instructions: null,
            price: null,
            deliveryGroupZone: null,
            merchant: null,
            isFakturerbar: true,
            starttime: 54000,
            endtime: 79200,

            deliveryformattedValue: moment()
                .format("YYYY-MM-DD"),
            deliveryvalue: new Date(moment()).toISOString()
        });
        this.setState({
            showOrderModal: false
        });
    }

    updateOrder() {
        if (this.state.customername != null && this.state.customername.length > 0 &&
            this.state.deliveryformattedValue != null && this.state.deliveryformattedValue.length > 0 &&
            this.state.selectedNewClient != null && this.state.selectedNewClient.value != null &&
            this.state.customeraddress != null && this.state.customeraddress.length > 0 &&
            this.state.customerzipcode != null && this.state.customerzipcode.length > 0 &&
            this.state.customercity != null && this.state.customercity.length > 0 &&
            this.state.cartItems != null && this.state.cartItems.length > 0
        ) {

            var updatebody = {
                'id': this.state.selectedorder,
                'customername': this.state.customername,
                'customerphone': this.state.customerphone,
                'customeraddress': this.state.customeraddress,
                'customerzipcode': this.state.customerzipcode,
                'customercity': this.state.customercity,
                'customerentrycode': this.state.customerentrycode,
                'latitude': this.state.latitude,
                'longitude': this.state.longitude,
                'customeremail': this.state.customeremail,
                'instructions': this.state.instructions,
                'price': this.state.isFakturerbar ? this.state.price : 0,
                'deliverydate': moment(this.state.deliveryformattedValue),
                'starttime': this.state.starttime,
                'endtime': this.state.endtime,
                'merchant': this.state.selectedNewClient.label
            };
            var items = [];
            if (this.state.cartItems != null && this.state.cartItems.length > 0) {
                this.state.cartItems.forEach(cartItem => {
                    if (cartItem.orderitem != null) {
                        items.push(cartItem.orderitem);
                    }
                    else {
                        items.push({ name: cartItem.value, numberOfItems: cartItem.count });
                    }
                });
            }
            updatebody.items = items;

            var access_token = localStorage.getItem('access_token');
            if (access_token != null) {
                var url = process.env.REACT_APP_GORDON_API_URL + '/planning/order/' + (this.state.method == 'PUT' ? this.state.selectedorder : '');
                fetch(url, {
                    method: this.state.method,
                    body: JSON.stringify(updatebody),
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + access_token,
                    },
                })
                    .then((response) => {

                        this.setState({
                            showOrderModal: false
                        });
                        this.setState({
                            external_ref: null,
                            selectedorder: null,
                            searchaddress: null,
                            customername: null,
                            customerphone: null,
                            customeraddress: null,
                            customerzipcode: null,
                            customercity: null,
                            customerentrycode: null,
                            latitude: null,
                            longitude: null,
                            customeremail: null,
                            errormessage: null,
                            instructions: null,
                            price: null,
                            deliveryGroupZone: null,
                            merchant: null,
                            isFakturerbar: true,
                            starttime: 54000,
                            endtime: 79200,
                            deliveryformattedValue: moment()
                                .format("YYYY-MM-DD"),
                            deliveryvalue: new Date(moment()).toISOString()
                        });
                        this.searchClick();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            } else {
                console.error("401 Unauthorized");
            }
        }
        else {
            this.setState({
                showValidationModal: true
            });
        }
    }
    render() {

        var { statuses, clients, deliveryGroups } = this.state;
        if (statuses == null) {
            statuses = [];
        }
        if (clients == null) {
            clients = [];
        }
        if (deliveryGroups == null) {
            deliveryGroups = [
                // { value: 'Gordon Sthlm', label: 'Gordon Sthlm' },
                // { value: 'Gordon Gbg', label: 'Gordon Gbg' },
                // { value: 'Gordon Mma', label: 'Gordon Mma' },
                // { value: "Gordon Ljungby", label: "Gordon Ljungby" },
                // { value: 'Gordon Ostergotland', label: 'Gordon Ostergotland' },
                // { value: 'Gordon Eskilstuna', label: 'Gordon Eskilstuna' },
                // { value: 'Gordon Sjælland', label: 'Gordon Sjælland' },
                // { value: 'Gordon Aaborg', label: 'Gordon Aaborg' },
                // { value: 'Gordon Fredericia', label: 'Gordon Fredericia' },
                // { value: 'Gordon Århus', label: 'Gordon Århus' },]
                { value: "5efcaba003fd75517c04fb37", label: "Gordon Stockholm Tumba" },
                { value: "61ee68c02bf9b60bbcbe7d63", label: "Gordon Stockholm Årsta" },
                { value: "5efcaba003fd75517c04fb37", label: "Gordon Stockholm Västberga" },
                { value: "648064d45a639704741f519e", label: "Gordon Stockholm Tumba Temp" },
                { value: "5efcabc203fd75517c04fb39", label: "Gordon Göteborg" },
                { value: "5efcabb103fd75517c04fb38", label: "Gordon Malmö" },
                { value: '5efcac8903fd75517c04fb3b', label: 'Gordon Linköping' },
                { value: '5efcacf603fd75517c04fb3e', label: 'Gordon København, DK' },
                { value: '5efcae2d03fd75517c04fb42', label: 'Gordon Aalborg, DK' },
                { value: '5efcacdb03fd75517c04fb3d', label: 'Gordon Fredericia, DK' },
                { value: '5efcad0703fd75517c04fb3f', label: 'Gordon Århus, DK' },
                { value: '5efcacc303fd75517c04fb3c', label: 'Gordon Eskilstuna' },
                { value: "5f71b3f408755a0c6592c78a", label: "Gordon Halmstad" },
                { value: "5f71b3d9d4ecbe0c79a0d28f", label: "Gordon Jönköping" },
                { value: "5f71b3b708755a0c6592c782", label: "Gordon Växjö" }, 
                { value: '602b8e0464849a0bb91dbec5', label: 'Gordon Uppsala' },
                { value: "5efcac3303fd75517c04fb3a", label: "Gordon Ljungby" },
                { value: "62a08775ce90de0beb76b28c", label: "Gordon Karlshamn" },]
                ;
        }
        const columns = [
            {
                Header: 'Butik/Kund',
                accessor: 'merchant',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["merchant"] }),
                filterAll: true
            },
            {
                Header: 'Order ID',
                accessor: 'external_ref',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["external_ref"] }),
                filterAll: true
            },
            {
                Header: 'Leveransdatum',
                accessor: 'deliveryDate',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["deliveryDate"] }),
                filterAll: true
            },
            {
                Header: 'Leveransintervall',
                accessor: 'timewindow',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["timewindow"] }),
                filterAll: true
            },
            {
                Header: 'Kund - namn',
                accessor: 'customername',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["customername"] }),
                filterAll: true,
                // Cell: this.renderEditable
            },
            {
                Header: 'Kund - telefon',
                accessor: 'mobilePhone',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["mobilePhone"] }),
                filterAll: true
            },
            {
                Header: 'Kund - leveransadress',
                accessor: 'customeraddress',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["customeraddress"] }),
                filterAll: true
            },
            {
                Header: 'Kund - ort',
                accessor: 'customercity',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["customercity"] }),
                filterAll: true
            },
            {
                Header: 'Kund - postnummer',
                accessor: 'customerzipcode',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["customerzipcode"] }),
                filterAll: true
            },
            {
                Header: 'Postnummer OK',
                accessor: 'zipcodeApproved',
                Cell: row => (
                    <span>
                        <span style={{
                            color: row.value === 'Ogiltig' ? '#ff2e00'
                                : row.value === 'Geolocation Error' ? '#ffbf00'
                                    : row.value === 'Inget postnummer' ? '#ff2e00' : '#57d500',
                            transition: 'all .3s ease'
                        }}>
                            &#x25cf;
                      </span> {row.value}
                    </span>
                ),
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "ok") {
                        return row[filter.id] == 'OK';
                    }
                    if (filter.value === "nook") {
                        return row[filter.id] == 'Ogiltig';
                    }
                    if (filter.value === "missing") {
                        return row[filter.id] == 'Inget postnummer';
                    }
                    if (filter.value === "geolocation") {
                        return row[filter.id] == 'Geolocation Error';
                    }
                },
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">Alla</option>
                        <option value="ok">OK</option>
                        <option value="nook">Ogiltig</option>
                        <option value="missing">Inget postnummer</option>
                        <option value="geolocation">Geolocation Error</option>
                    </select>
            },
            {
                Header: 'Collection Point',
                accessor: 'collectionPoint',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["collectionPoint"] }),
                filterAll: true
            },
            {
                Header: 'Delivery Group',
                accessor: 'deliveryGroup',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["deliveryGroup"] }),
                filterAll: true
            },
            {
                Header: 'Zon',
                accessor: 'zone',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["zone"] }),
                filterAll: true
            },
            {
                Header: 'Status',
                accessor: 'status',
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "New") {
                        return row[filter.id] == 'New';
                    }
                    if (filter.value === "Test") {
                        return row[filter.id] == 'Test';
                    }
                    if (filter.value === "Routing") {
                        return row[filter.id] == 'Routing';
                    }
                },
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">Alla</option>
                        <option value="New">New</option>
                        <option value="Test">Test</option>
                        <option value="Routing">Routing</option>
                    </select>
            },
            {
                Header: 'Order - kollin',
                accessor: 'packageType',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["packageType"] }),
                filterAll: true
            },
            {
                Header: 'Antal kollin',
                accessor: 'numberOfItems',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["numberOfItems"] }),
                filterAll: true
            },
            {
                Header: 'Totalvikt',
                accessor: 'totalWeight',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["totalWeight"] }),
                filterAll: true,
            },
            {
                Header: 'Vikt torrt',
                accessor: 'dryWeight',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["dryWeight"] }),
                filterAll: true,
            },
            {
                Header: 'Vikt kylt',
                accessor: 'chilledWeight',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["chilledWeight"] }),
                filterAll: true,
            },
            {
                Header: 'Vikt fryst',
                accessor: 'frozenWeight',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["frozenWeight"] }),
                filterAll: true,
            },
            // {
            //     Header: 'Order - artiklar',
            //     accessor: 'packageContent',
            //     filterMethod: (filter, rows) =>
            //         matchSorter(rows, filter.value, { keys: ["packageContent"] }),
            //     filterAll: true
            // },
            // {
            //     Header: 'Skapad',
            //     accessor: 'created_at',
            //     filterable: false
            // },
            {
                Header: "Visa",
                accessor: '_id',
                Cell: row => (

                    <Button
                        bsStyle="primary"
                        className="btn-margin eventbtn"
                        onClick={this.open.bind(this, row)}>Öppna...</Button>
                )
            }
            // , {
            //     Header: 'Uppdaterad',
            //     accessor: 'updated_at',
            //     filterable: false
            // }
        ]
        var divStyle = {
            display: this.state.isLoading ? 'block' : 'none'
        };
        const { isAuthenticated } = this.props.auth;
        let trusted = isAuthenticated();
        if (!trusted) {
            this.redirectToLoginPage();
        }
        const { toggleSelection, toggleAll, isSelected } = this;
        const { selectAll, selection, selectedDeliveryGroup } = this.state;

        const routedisabled = selection == null || selection.length == 0;
        const searchdisabled = !(selectedDeliveryGroup != null && this.state.formattedValue != null);
        const routificdisabled = ((this.state.errors != null && this.state.errors.length > 0) || this.state.drivercount == 0);

        const exportdisabled = !(this.state.rows != null && this.state.rows.length > 0);
        const checkboxProps = {
            selectAll,
            isSelected,
            toggleSelection,
            toggleAll,
            selectType: "checkbox",
            getTrProps: (s, r) => {
                const selected = r ? this.isSelected(r.original._id) : false
                return {
                    style: {
                        backgroundColor: selected ? r.original.zipcodeApproved == 'OK' ? "lightgreen" : r.original.zipcodeApproved == 'Geolocation Error' ? '#f2f19a' : 'lightcoral' : "inherit"
                    }
                };
            }
        };
        const cssClasses = {
            root: 'form-group',
            input: 'Demo__search-input',
            autocompleteContainer: 'Demo__autocomplete-container',
        }
        const AutocompleteItem = ({ formattedSuggestion }) => (
            <div className="Demo__suggestion-item">
                <i className='fa fa-map-marker Demo__suggestion-icon' />
                <strong>{formattedSuggestion.mainText}</strong>{' '}
                <small className="text-muted">{formattedSuggestion.secondaryText}</small>
            </div>)

        const inputProps = {
            type: "text",
            value: this.state.searchaddress,
            onChange: this.handleChange,
            autoFocus: true,
            placeholder: "Ange leveransadress...",
            name: 'Demo__input',
            id: "my-input-id",
        }

        const options = {
            componentRestrictions: { country: 'se' }
        }

        const filename = 'Inleveranslist_' + this.state.selectedDeliveryGroup + "_" + this.state.formattedValue + '.xlsx';

        var disableprice = !this.state.isFakturerbar;

        return (
            <div className="container">

                <div>
                    <div className="cover" style={divStyle}>
                        <div className="covercontent">
                            <SyncLoader
                                color={'#174b5F'}
                                loading={this.state.isLoading}
                            />
                        </div>
                    </div>

                    <h3>Oplanerade ordrar</h3>
                    <div className="summarySearch">
                        <div className="dropdownSearch">
                            <div className="datePicker">Datum: <DatePicker autoFocus={false} showTodayButton={true} todayButtonLabel="Idag" id="example-datepicker" value={this.state.value} dateFormat='YYYY-MM-DD' weekStartsOn={1} onChange={this.handleDateChange.bind(this)}
                                monthLabels={['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']}
                                dayLabels={['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör']} />
                            </div>
                            <br /> <br />
                            <Select placeholder="Välj Delivery Group"
                                name="ddSelectDeliveryGroup"
                                onChange={this.handleDeliveryGroupChange}
                                options={deliveryGroups}
                            />
                            <br />

                            <Select placeholder="Välj kund/butik"
                                name="ddSelectMerchant" isMulti
                                onChange={this.handleMerchantChange}
                                options={clients}

                                className="basic-multi-select"
                            />

                            {/* <DateRange
                                        onChange={this.handleRangeChange.bind(this, 'dateRange')}
                                        moveRangeOnFirstSelection={false}
                                        dateDisplayFormat="YYYY-MM-DD"
                                        ranges={[this.state.dateRange.selection]}
                                        className={'PreviewArea'}
                                    /> */}
                        </div>



                        <div className="buttonSearch">
                            <Button
                                bsStyle="primary"
                                className="btn-margin" disabled={searchdisabled}
                                onClick={this.searchClick.bind(this)}>Sök</Button>
                        </div>
                    </div>
                    <br />
                    <br />
                    <div className="buttonSearch">
                        <Button
                            bsStyle="primary"
                            className="btn-margin" disabled={routedisabled}
                            onClick={this.routeClick.bind(this)}>Ruttoptimera...</Button>   <Button
                                bsStyle="primary"
                                className="btn-margin"
                                onClick={this.openNew.bind(this)}>Skapa ny order...</Button>
                    </div>

                    <br />       <br />
                    <Workbook filename="unplannedorders.xlsx" element={<button disabled={exportdisabled} className="btn btn-primary">Exportera till Excel</button>}>
                        <Workbook.Sheet data={() => this.state.rows} name="Unplanned orders">
                            <Workbook.Column label="Merchant" value="merchant" />
                            <Workbook.Column label="Order ID" value="external_ref" />
                            <Workbook.Column label="Delivery Date" value="deliveryDate" />
                            <Workbook.Column label="Time Window" value="timewindow" />
                            <Workbook.Column label="Service Time" value="duration" />
                            <Workbook.Column label="Customer name" value="customername" />
                            <Workbook.Column label="Customer Address" value="customeraddress" />
                            <Workbook.Column label="Customer Zipcode" value="customerzipcode" />
                            <Workbook.Column label="Customer City" value="customercity" />
                            <Workbook.Column label="Delivery Group" value="deliveryGroup" />
                            <Workbook.Column label="Zone" value="zone" />
                            <Workbook.Column label="Package Type" value="packageType" />
                            <Workbook.Column label="Number of Packages" value="numberOfItems" />
                            <Workbook.Column label="Package Content" value="packageContent" />
                            <Workbook.Column label="Status" value="status" />
                            <Workbook.Column label="Total Weight" value="totalWeight" />
                            <Workbook.Column label="Dry Weight" value="dryWeight" />
                            <Workbook.Column label="Chilled Weight" value="chilledWeight" />
                            <Workbook.Column label="Frozen Weight" value="frozenWeight" />
                        </Workbook.Sheet>
                    </Workbook>
                    <br /><br />
                    <Workbook filename="workwave.xlsx" element={<button disabled={exportdisabled} className="btn btn-primary">Workwave-export</button>}>
                        <Workbook.Sheet data={() => this.state.rows} name="Workwave orders">
                            <Workbook.Column label="Merchant" value="merchant" />
                            <Workbook.Column label="Order ID" value="external_ref" />
                            <Workbook.Column label="Delivery Date" value="deliveryDateWW" />
                            <Workbook.Column label="Time Window Start" value="timewindowstart" />
                            <Workbook.Column label="Time Window End" value="timewindowend" />
                            <Workbook.Column label="Service Time" value="duration" />
                            <Workbook.Column label="Customer name" value="customername" />
                            <Workbook.Column label="Customer Address" value="customeraddress" />
                            <Workbook.Column label="Customer Zipcode" value="customerzipcode" />
                            <Workbook.Column label="Customer City" value="customercity" />
                            <Workbook.Column label="Delivery Group" value="deliveryGroup" />
                            <Workbook.Column label="Zone" value="zone" />
                            <Workbook.Column label="Package Type" value="packageType" />
                            <Workbook.Column label="Number of Packages" value="numberOfItems" />
                            <Workbook.Column label="Package Content" value="packageContent" />
                            <Workbook.Column label="Status" value="status" />
                            <Workbook.Column label="latitude" value="latitude" />
                            <Workbook.Column label="longitude" value="longitude" />
                            <Workbook.Column label="id" value="_id" />
                            <Workbook.Column label="external_id" value="external_id" />
                        </Workbook.Sheet>
                    </Workbook>
                    <br /><br />
                    <Workbook filename={filename} element={<button disabled={exportdisabled} className="btn btn-primary">Inleveranslista till Excel</button>}>
                        <Workbook.Sheet data={() => this.state.plocklista} name="Inleveranslista">
                            <Workbook.Column label="Antal" value="count" />
                            <Workbook.Column label="Artikel" value="name" />
                        </Workbook.Sheet>
                    </Workbook>
                    <br /><br />
                    <CheckboxTable
                        ref={r => (this.checkboxTable = r)}
                        filterable
                        data={this.state.rows}
                        defaultSorted={[{
                            id: 'zipcodeApproved',
                            desc: false,
                        }]}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]) === filter.value}
                        columns={columns}
                        {...checkboxProps}
                        pageSize={this.state.pageSize}
                        defaultPageSize={50} >
                        {(state, makeTable, instance) => {
                            let recordsInfoText = "";

                            const { filtered, pageRows, pageSize, sortedData, page } = state;

                            if (sortedData && sortedData.length > 0) {
                                let isFiltered = filtered.length > 0;

                                let totalRecords = sortedData.length;

                                let recordsCountFrom = page * pageSize + 1;

                                let recordsCountTo = recordsCountFrom + pageRows.length - 1;

                                if (isFiltered)
                                    recordsInfoText = `${totalRecords} filtrerade rader`;
                                else
                                    recordsInfoText = `${totalRecords} rader`;
                            } else recordsInfoText = "Inga träffar";

                            return (
                                <div className="main-grid">
                                    <div className="above-table text-left">
                                        <div className="col-sm-12">
                                            <span className="records-info">{recordsInfoText}</span>
                                        </div>
                                    </div>

                                    {makeTable()}
                                </div>
                            );
                        }}
                    </CheckboxTable>

                    <Modal bsSize="small" show={
                        this.state.showValidationModal
                    }>
                        <Modal.Header >
                            <Modal.Title>Valideringsfel</Modal.Title> </Modal.Header >
                        <Modal.Body  >
                            <p>Du måste ange följande uppgifter på ordern:</p>
                            <ul>
                                <li>Leveransdatum</li>
                                <li>Kund/butik</li>
                                <li>Kundens namn</li>
                                <li>Kundens adress</li>
                                <li>Minst ett kolli</li>
                            </ul>
                        </Modal.Body> <Modal.Footer>
                            <Button onClick={
                                this.cancelValidation.bind(this)
                            } > OK </Button>

                        </Modal.Footer> </Modal>

                    <Modal bsSize="small" show={
                        this.state.showConfirmModal
                    }>
                        <Modal.Header >
                            <Modal.Title>Radera order</Modal.Title> </Modal.Header >
                        <Modal.Body  >
                            <p>Är du säker på att du vill radera ordern?</p>
                        </Modal.Body> <Modal.Footer>
                            <Button onClick={
                                this.cancelDelete.bind(this)
                            } > Nej </Button>
                            <Button onClick={
                                this.updateStatus.bind(this)
                            } > Ja </Button>
                        </Modal.Footer> </Modal>

                    <Modal bsSize="large" show={
                        this.state.showOrderModal
                    }
                        dimmer={"blurring"}>
                        <Modal.Header >
                            <Modal.Title>{this.state.title}</Modal.Title> </Modal.Header >
                        <Modal.Body  >
                            <div className="row overview">
                                <div className="col-md-4 user-pad text-left">
                                    <p>Nytt leveransdatum:</p>
                                    <DatePicker
                                        autoFocus={false}
                                        minDate={moment().format("YYYY-MM-DD")}
                                        showTodayButton={true}
                                        todayButtonLabel="Idag"
                                        id="example-datepicker"
                                        value={this.state.deliveryvalue}
                                        dateFormat="YYYY-MM-DD"
                                        weekStartsOn={1}
                                        onChange={this.handleDeliveryDateChange.bind(this)}
                                        monthLabels={[
                                            "Januari",
                                            "Februari",
                                            "Mars",
                                            "April",
                                            "Maj",
                                            "Juni",
                                            "Juli",
                                            "Augusti",
                                            "September",
                                            "Oktober",
                                            "November",
                                            "December"
                                        ]}
                                        dayLabels={[
                                            "Sön",
                                            "Mån",
                                            "Tis",
                                            "Ons",
                                            "Tors",
                                            "Fre",
                                            "Lör"
                                        ]}
                                    />
                                </div>
                                <div className="col-md-4 user-pad text-left">
                                    <p>Leveransfönster start:</p>
                                    <TimePicker
                                        start="00:00"
                                        end="23:59"
                                        step={60}
                                        format={24}
                                        initialValue="00:00"
                                        format={24}
                                        value={this.state.starttime}
                                        onChange={this.handleTimeChangeStart}
                                    />
                                </div>
                                <div className="col-md-4 user-pad text-left">
                                    <p>Leveransfönster slut:</p>
                                    <TimePicker
                                        start="00:00"
                                        end="23:59"
                                        step={60}
                                        format={24}
                                        initialValue="23:00"
                                        value={this.state.endtime}
                                        onChange={this.handleTimeChangeEnd}
                                    />
                                </div>
                            </div>
                            <br />
                            <div className="row overview">
                                <div className="col-md-4 user-pad text-left">
                                    <p>Kundnamn:</p>
                                    <FormControl onInput={this.nameChanged.bind(this)} value={this.state.customername}
                                        placeholder='Kundens namn' />
                                </div>
                                <div className="col-md-4 user-pad text-left">
                                    <p>Telefonnummer:</p>
                                    <FormControl onInput={this.phoneChanged.bind(this)} value={this.state.customerphone}
                                        placeholder='Kundens telefonnummer' />
                                </div>
                                <div className="col-md-4 user-pad text-left">
                                    <p>E-post:</p>
                                    <FormControl onInput={this.emailChanged.bind(this)} value={this.state.customeremail}
                                        placeholder='E-post' />
                                </div>
                            </div>
                            <br />

                            <div className="row overview">
                                <div className="col-md-8 user-pad text-left">
                                    Leveransadress: <PlacesAutocomplete
                                        onSelect={this.handleSelect}
                                        autocompleteItem={AutocompleteItem}
                                        onEnterKeyDown={this.handleSelect}
                                        classNames={cssClasses}
                                        inputProps={inputProps}
                                        options={options}
                                        googleLogo={false}
                                    />
                                    {this.state.loading ? <div><i className="fa fa-spinner fa-pulse fa-3x fa-fw Demo__spinner" /></div> : null}
                                    {!this.state.loading && this.state.geocodeResults ?
                                        <div className='geocoding-results'>{this.state.geocodeResults}</div> :
                                        null}
                                </div>

                                <div className="col-md-4 user-pad text-left">
                                    <p>Butik/Kund:</p>
                                    {
                                        this.state.method == 'PUT' && (

                                            <p><strong>{this.state.merchant}</strong></p>
                                        )}
                                    {
                                        this.state.method == 'POST' && (
                                            <Select placeholder="Välj kund/butik"
                                                name="ddSelectMerchant2"
                                                onChange={this.handleNewOrderMerchantChange}
                                                options={clients}
                                            />
                                        )}


                                </div>

                            </div>
                            <br />
                            <div className="row overview">
                                <div className="col-md-4 user-pad text-left">
                                    <p>Latitude:</p>
                                    <FormControl type="number" onInput={this.latitudeChanged.bind(this)} value={this.state.latitude}
                                        placeholder='Latitude' />
                                </div>
                                <div className="col-md-4 user-pad text-left">
                                    <p>Longitude:</p>
                                    <FormControl type="number" onInput={this.longitudeChanged.bind(this)} value={this.state.longitude}
                                        placeholder='Longitude' />
                                </div>
                                <div className="col-md-4 user-pad text-left">
                                    {
                                        this.state.errormessage != null && (
                                            <div>
                                                <p><i className="fas fa-exclamation-triangle" style={{ color: 'orange' }}></i></p>
                                                <p>{this.state.errormessage}</p>
                                            </div>
                                        )}
                                </div>
                            </div>
                            <br />
                            <div className="row overview">
                                <div className="col-md-4 user-pad text-left">
                                    <p>Portkod:</p>
                                    <FormControl onInput={this.entrycodeChanged.bind(this)} value={this.state.customerentrycode}
                                        placeholder='Portkod' />
                                </div>
                                <div className="col-md-4 user-pad text-left">
                                    <p>Instruktion till föraren:</p>
                                    <FormControl
                                        onInput={this.instructionsChanged.bind(this)}
                                        value={this.state.instructions}
                                        placeholder="Instuktion till föraren"
                                    />
                                </div>
                                <div className="col-md-4 user-pad text-left">
                                    <p>Leveransgrupp / Zon:</p>
                                    <p>
                                        <strong>{this.state.deliveryGroupZone}</strong>
                                    </p>
                                </div>
                            </div>
                            <br />
                            <div className="row overview">
                                <div className="col-md-4 user-pad text-left">
                                    <p>
                                        <strong>Pris:</strong>
                                    </p>
                                    <FormControl
                                        type="number"
                                        disabled={disableprice}
                                        onInput={this.priceChanged.bind(this)}
                                        value={this.state.price}
                                        placeholder="Pris ex moms"
                                    />
                                </div>
                                <div className="col-md-4 user-pad text-left">
                                    <p>Fakturerbar:</p>
                                    <Switch
                                        onChange={this.toggleFakturerbar}
                                        checked={this.state.isFakturerbar}
                                        id="normal-switch"
                                    />
                                </div>
                            </div>
                            <br />

                            <br />
                            <div className="panel panel-default">
                                <div className="panel-heading">Kollin:</div>
                                <div className="panel-body">
                                    <ul className="list-group">
                                        {this.state.cartItems != null && this.state.cartItems.map((item, i) => (
                                            <li className="list-group-item text-right">
                                                <div>
                                                    <span className="pull-left">
                                                        <strong className="">
                                                            {item.count} st {item.value}
                                                        </strong>{" "}

                                                    </span>{" "}
                                                    _
                            <button
                                                        type="button"
                                                        className="close"
                                                        onClick={this.removeItem.bind(this, item.index)}
                                                    >
                                                        <span className="fa fa-times" />
                                                    </button>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                    <p><strong>Lägg till kolli:</strong>(OBS! Kollin man lägger till manuellt kommer inte ha etikett och kommer inte gå att scanna.)</p>
                                    <div className="row overview">
                                        <div className="col-md-4 user-pad text-left">
                                            <p>Antal:</p>
                                            <FormControl
                                                type="number"
                                                onInput={this.countChanged.bind(this)}
                                                value={this.state.count}
                                                placeholder="Antal kollin"
                                            />
                                        </div>
                                        <div className="col-md-4 user-pad text-left">
                                            <p>Kasse:</p>
                                            <FormControl
                                                onInput={this.kolliChanged.bind(this)}
                                                value={this.state.kolli}
                                                placeholder="Typ av kasse"
                                            />
                                        </div>
                                        <div className="col-md-4 user-pad text-left">
                                            <p> </p>
                                            <Button
                                                positive
                                                bsStyle="success"
                                                onClick={this.addItem}
                                            >
                                                Lägg till
                </Button>
                                        </div>
                                    </div>


                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button negative bsStyle="warning" onClick={this.confirmDelete.bind(this)}>Radera</Button>
                            <Button negative onClick={this.cancelOrder.bind(this)}>Avbryt</Button>
                            <Button positive
                                bsStyle="success"
                                onClick={this.updateOrder.bind(this)}>Uppdatera</Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal bsSize="large" show={
                        this.state.showModal
                    }
                        dimmer={"blurring"}>
                        <Modal.Header >
                            <Modal.Title>Ruttoptimera ordrar</Modal.Title>
                        </Modal.Header >
                        <Modal.Body  >
                            <div className="row overview">
                                <div className="col-md-4 user-pad text-left">
                                    <p>Leveransorder:</p>
                                    <FormControl onInput={this.deliveryProjectChanged.bind(this)} value={this.state.deliveryProject}
                                        placeholder='Namn på projekt' />
                                </div>

                            </div>
                            <br />
                            <div className="row overview">
                                <div className="col-md-4 user-pad text-left">
                                    <p>Antal ordrar:</p>
                                    <p><strong>{this.state.selection != null ? this.state.selection.length : ''}</strong></p>
                                </div>
                                <div className="col-md-4 user-pad text-left">
                                    <p>Delivery Group:</p>
                                    <p><strong>{this.state.selectedDeliveryGroup}</strong></p>
                                </div>
                                <div className="col-md-4 user-pad text-left">
                                    <p>Kunder/butiker:</p>
                                    <p><strong>{this.state.selectedclients != null && this.state.selectedclients.length > 0 ? this.state.selectedclients.join(", ") : ''}</strong></p>
                                </div>
                            </div>
                            <br />
                            <div className="row overview">
                                <div className="col-md-6 user-pad text-left">
                                    <p>Antal fordon / förare:</p>
                                    <FormControl type="number" onInput={this.driversChanged.bind(this)} value={this.state.drivercount}
                                        placeholder='Antal fordon/förare' />
                                </div>
                                <div className="col-md-6 user-pad text-left">
                                    <p>Service-tid / duration:</p>
                                    <FormControl type="number" onInput={this.durationChanged.bind(this)} value={this.state.duration}
                                        placeholder='Service-tid / duration' />
                                </div>
                            </div>
                            <br />
                            <div className="row overview">
                                <div className="col-md-6 user-pad text-left">
                                    <p>Lastkaj:</p>
                                    <FormControl onInput={this.depotChanged.bind(this)} value={this.state.depot}
                                        placeholder='Lastkaj adress' />
                                </div>
                                <div className="col-md-3 user-pad text-left">
                                    <p>Förarens starttid:</p>
                                    <TimePicker
                                        start="00:00"
                                        end="23:59"
                                        step={30}
                                        format={24}
                                        initialValue="00:00"
                                        format={24}
                                        value={this.state.driverStarttime}
                                        onChange={this.handleTimeChangeStartDriver}
                                    />
                                </div>
                                <div className="col-md-3 user-pad text-left">
                                    <p>Förarens sluttid:</p>
                                    <TimePicker
                                        start="00:00"
                                        end="23:59"
                                        step={30}
                                        format={24}
                                        initialValue="00:00"
                                        format={24}
                                        value={this.state.driverEndtime}
                                        onChange={this.handleTimeChangeEndDriver}
                                    />
                                </div>
                            </div>

                            <br />
                            {
                                this.state.errors != null && this.state.errors.length > 0 && (
                                    <div>
                                        <p><i className="fas fa-exclamation-triangle routingerror"></i> Problem:</p>
                                        <p><strong>{this.state.errors != null && this.state.errors.length > 0 ? this.state.errors.map(place => <p> {place} </p>) : ''}</strong></p>
                                    </div>
                                )}
                            {
                                this.state.warnings != null && this.state.warnings.length > 0 && (
                                    <div>
                                        <p><i className="fas fa-exclamation-triangle routingwarning"></i> Varning:</p>
                                        <p><strong>Det finns en eller flera ordrar där adressen inte kunde lokalieras till 100%</strong></p>
                                    </div>
                                )}
                            <br />

                        </Modal.Body>
                        <Modal.Footer>
                            <Button negative onClick={this.cancel.bind(this)}>Avbryt</Button>
                            <Button positive
                                bsStyle="success" disabled={routificdisabled}
                                onClick={this.sendToRoutific.bind(this)}>Skicka</Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>

        );
    }
}

export default Unplanned;