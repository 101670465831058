import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import React, { Component } from 'react';
import { Chart } from 'react-google-charts';

class SvartaLadan extends Component {

  handleSelection(Chart) {
    if (Chart.chart.getSelection()[0]) {
      var date = this.state.rows[Chart.chart.getSelection()[0]['row'] + 1][0];
      this.props.history.push('/delivery?merchant=SL&date=' + date);
    }
  }

  login() {
    this.props.auth.login();
  }
  componentWillMount() {
    let rows = [];

    rows.push(["Datum", "Precision %", "Antal"]);

    this.setState({ rows: rows });
    return { rows: rows };
  }
  componentDidMount() {

    let rows = this.state.rows;

    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch('https://api.gordondelivery.com/api/svartaladan/summary', {
        method: 'GET',
        headers: {
          'Authorization': 'Bearer ' + access_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var summary = JSON.parse(responseJson);
          var numberOfRows = summary.length;
          for (let i = 0; i < numberOfRows; i++) {

            rows.push([summary[i].Datum, summary[i].LeveransPrecision, summary[i].AntalLeveranser]);
          }

          this.state.rows = rows;
          this.setState({ rows: rows });
        })
        .catch((error) => {
          console.error(error);
        });

    } else {
      console.error("401 Unauthorized");
    }
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Svarta Lådan</h3>
              <div className={'my-pretty-chart-container'}>
                <Chart
                  chartType="ComboChart"
                  data={this.state.rows}
                  options={{
                    title: 'Svarta Lådan - Leveransprecision',
                    vAxes: [{title: 'Procent %', viewWindow: {
                      min: 0,
                      max: 100
                    }},
                    {title: 'Antal', viewWindow: {
                      min: 0,
                      max: 500
                    }}
                  ],hAxis: { title: 'Datum' }, seriesType: 'bars', 
                    series: { 1: { type: 'line', targetAxisIndex: 1 } }
                  }}
                  graph_id="ComboChart"
                  chartEvents={[{
                    eventName: 'select',
                    callback: this.handleSelection.bind(this)
                  }]}
                  width="100%"
                  height="500px"
                  legend_toggle
                />
              </div>
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default SvartaLadan;

