import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import _ from "lodash";
import moment from 'moment';
import DatePicker from 'react-16-bootstrap-date-picker';
import { Button } from 'react-bootstrap';
import Select from 'react-select';
import { SyncLoader } from 'react-spinners';
import matchSorter from 'match-sorter';

class DeliveryOrders extends Component {
    constructor(props) {
        super(props);
        this.cancel = this.cancel.bind(this);
    }
    login() {
        this.props.auth.login();
    }
    componentWillMount() {

        var user = localStorage.getItem('userProfileName');

        this.setState({ formattedValue: null });
        this.setState({ value: '' });

        this.setState({
            author: user
        });

        this.setState({ clients: [] });
        this.setState({
            deliveryGroups: [
                { value: 'Gordon Sthlm', label: 'Gordon Sthlm' },
                { value: 'Gordon Gbg', label: 'Gordon Gbg' },
                { value: 'Gordon Mma', label: 'Gordon Mma' },
                { value: 'Gordon Ostergotland', label: 'Gordon Ostergotland' },
                { value: 'Gordon Eskilstuna', label: 'Gordon Eskilstuna' },
                { value: 'Stora Coop Kalmar', label: 'Stora Coop Kalmar' },
                { value: 'Coop Landskrona', label: 'Coop Landskrona' },
                { value: 'Coop Valbo Gävle', label: 'Coop Valbo Gävle' },
                { value: 'Linas Matkasse', label: 'Linas Matkasse' },
                { value: 'MFK sthlm', label: 'MFK Sthlm' },
                { value: 'MFK Malmö', label: 'MFK Malmö' },
                { value: 'MFK Gbg', label: 'MFK Gbg' },
                { value: 'Gordon Denmark', label: 'Gordon Denmark' },
                { value: "Gordon Ljungby", label: "Gordon Ljungby" },
            ]

        });
        this.setState({ clientname: '' });
        this.setState({ statuses: ['New', 'Planned', 'Routed', 'Assigned', 'Unassigned', 'Dispatched', 'Deleted', 'Cancelled', 'Done'] });
        this.setState({ user: '' });

        this.setState({ selectedDeliveryGroup: [] });

        this.fetchData = this.fetchData.bind(this);
        this.setState({ rows: [] });
        this.setState({ originalrows: [] });
    }
    componentDidMount() {

    }
    getDeliveryOrders(startDate, deliverygroup) {

        var group = localStorage.getItem('access_group');
        this.setState({
            isLoading: true
        });
        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            fetch(process.env.REACT_APP_GORDON_API_URL + '/route/deliveryorder?date=' + moment(startDate).format("YYYY-MM-DD") + '&deliverygroup=' + deliverygroup, {
                method: 'get',
                headers: {
                    'Authorization': 'Bearer ' + access_token,
                    'Content-Type': 'application/json'
                }
            })
                .then((response) => response.json())
                .then((responseJson) => {

                    for (var i = 0; i < responseJson.length; i++) {
                        responseJson[i].created_at = moment(responseJson[i].created_at).format("YYYY-MM-DD HH:mm");
                        responseJson[i].updated_at = moment(responseJson[i].updated_at).format("YYYY-MM-DD HH:mm");
                        responseJson[i].deliveryDate = moment(responseJson[i].deliveryDate).format("YYYY-MM-DD");

                        responseJson[i].distance = Math.round(responseJson[i].distance);
                        responseJson[i].routescount = responseJson[i].routes != null && responseJson[i].routes.length > 0 ? responseJson[i].routes.length : 0
                    }

                    let rows = responseJson;
                    this.state.rows = rows;
                    this.setState({ rows: rows });
                    this.state.originalrows = responseJson;
                    this.setState({ originalrows: responseJson });
                    this.setState({
                        isLoading: false,
                        pageSize: rows.length
                    });
                })
                .catch((error) => {
                    console.error(error);
                    this.setState({
                        isLoading: false
                    });
                });

        } else {
            console.error("401 Unauthorized");
            this.setState({
                isLoading: false
            });
        }
    }
    fetchData(state, instance) {
        let filteredData = this.state.originalrows;
        if (state.filtered.length) {
            filteredData = state.filtered.reduce((filteredSoFar, nextFilter) => {
                return filteredSoFar.filter(row => {
                    return (row[nextFilter.id] + "").includes(nextFilter.value);
                });
            }, filteredData);
        }
        // You can also use the sorting in your request, but again, you are responsible for applying it.
        const sortedData = _.orderBy(
            filteredData,
            state.sorted.map(sort => {
                return row => {
                    if (row[sort.id] === null || row[sort.id] === undefined) {
                        return -Infinity;
                    }
                    return typeof row[sort.id] === "string"
                        ? row[sort.id].toLowerCase()
                        : row[sort.id];
                };
            }),
            state.sorted.map(d => (d.desc ? "desc" : "asc"))
        );

        var result = [];
        for (var i = 0; i < sortedData.length; i++) {
            if (moment().add(-7, 'days').isBefore(moment(sortedData[i].deliveryDate)) &&
                moment(sortedData[i].deliveryDate).isBefore(moment())) {
                result.push(sortedData[i]);
            }
        }
        let rows = sortedData;
        this.state.rows = rows;
        this.setState({ rows: rows });
    }
    handleDateChange(value, formattedValue) {
        this.setState({
            value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
            formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
        });

    }
    cancel() {
        this.setState({
            showModal: false
        });
    }

    searchClick() {
        var deliveryGroup = this.state.selectedDeliveryGroup.map(e => e.label).join(",");

        this.getDeliveryOrders(this.state.formattedValue, deliveryGroup);
    }

    handleDeliveryGroupChange = (selectedOption) => {
        if (selectedOption != null) {

            this.setState({
                selectedDeliveryGroup: selectedOption
            });
        }
        else {
            this.setState({
                selectedDeliveryGroup: []
            });
        }
    }

    render() {

        var { statuses, clients, deliveryGroups } = this.state;
        if (statuses == null) {
            statuses = [];
        }
        if (clients == null) {
            clients = [];
        }
        if (deliveryGroups == null) {
            deliveryGroups = [];
        }
        const columns = [
            {
                Header: 'Namn',
                accessor: 'name',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["name"] }),
                filterAll: true,
                Cell: row => (
                    <a href={'/routes?deliveryorder=' + row.value}>
                        {row.value}
                    </a>
                )
            },
            {
                Header: 'Rutter',
                accessor: 'routescount',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["routescount"] }),
                filterAll: true
            },
            {
                Header: 'Kunder',
                accessor: 'numberOfStops',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["numberOfStops"] }),
                filterAll: true
            },
            {
                Header: 'Körsträcka',
                accessor: 'distance',
                filterMethod: (filter, rows) =>
                    matchSorter(rows, filter.value, { keys: ["distance"] }),
                filterAll: true
            }
        ]
        var divStyle = {
            display: this.state.isLoading ? 'block' : 'none'
        };
        const { isAuthenticated } = this.props.auth;

        const { selectedDeliveryGroup } = this.state;

        const searchdisabled = !(selectedDeliveryGroup != [] && this.state.formattedValue != null);

        return (
            <div className="container">
                {
                    isAuthenticated() && (
                        <div>
                            <div className="cover" style={divStyle}>
                                <div className="covercontent">
                                    <SyncLoader
                                        color={'#174b5F'}
                                        loading={this.state.isLoading}
                                    />
                                </div>
                            </div>

                            <h3>Leveransordrar</h3>
                            <div className="summarySearch">
                                <div className="datePicker">Datum: <DatePicker autoFocus={false} showTodayButton={true} todayButtonLabel="Idag" id="example-datepicker" value={this.state.value} dateFormat='YYYY-MM-DD' weekStartsOn={1} onChange={this.handleDateChange.bind(this)}
                                    monthLabels={['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']}
                                    dayLabels={['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör']} />
                                </div>
                                <div className="dropdownSearch">
                                    <Select placeholder="Välj Delivery Group"
                                        name="ddSelectDeliveryGroup" isMulti
                                        onChange={this.handleDeliveryGroupChange}
                                        options={deliveryGroups}
                                        className="basic-multi-select"
                                    />

                                </div>

                                <div className="buttonSearch">
                                    <Button
                                        bsStyle="primary"
                                        className="btn-margin" disabled={searchdisabled}
                                        onClick={this.searchClick.bind(this)}>Sök</Button>
                                </div>
                            </div>
                            <br />
                            <ReactTable
                                filterable
                                data={this.state.rows}
                                defaultSorted={[{
                                    id: 'zipcodeApproved',
                                    desc: false,
                                }]}
                                defaultFilterMethod={(filter, row) =>
                                    String(row[filter.id]) === filter.value}
                                columns={columns}

                                pageSize={this.state.pageSize}
                                defaultPageSize={50} />
                        </div>
                    )
                }
                {
                    !isAuthenticated() && (
                        <h4>
                            You're not logged in! Click {' '}
                            <a
                                style={{ cursor: 'pointer' }}
                                onClick={this.login.bind(this)}
                            >
                                Login
                </a>
                            {' '}to continue.
                        </h4>
                    )
                }
            </div>

        );
    }
}

export default DeliveryOrders;