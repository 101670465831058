import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { ToggleButton, ToggleButtonGroup, ButtonToolbar } from 'react-bootstrap';
import PropTypes from "prop-types";
var datadag;
var datakvall;

class AxfoodWillysAlvsjo extends Component {
  static contextTypes = {
    router: PropTypes.object
  }
  constructor(props, context) {
    super(props, context);
  }

  handleWillysSelection(Chart) {
    if (Chart.chart.getSelection()[0]) {
      var date = this.state.rows[Chart.chart.getSelection()[0]['row'] + 1][0];
      this.context.router.history.push('/delivery?merchant=AXWA&date=' + date);
    }
  }

  componentWillMount() {
    let rows = [];
    rows.push(['Datum', "Precision %", "Antal"]);
    this.setState({ rows: rows });

    let dagrows = [];
    dagrows.push(['Datum', "Antal"]);

    let toggleValueKvall = 1;
    this.setState({ toggleValueKvall: toggleValueKvall });

    let toggleValueDag = 1;
    this.setState({ toggleValueDag: toggleValueDag });

    let kvallrows = [];
    kvallrows.push(['Datum', "Antal"]);
    this.setState({ datadag: {} });
    this.setState({ datakvall: {} });
    this.setState({ dagrows: dagrows });
    this.setState({ kvallrows: kvallrows });

    return { rows: rows, dagrows: dagrows, kvallrows: kvallrows, datadag: {}, datakvall: {} };
  }
  componentDidMount() {

    this.state.toggleValueKvall = 1;
    this.setState({ toggleValueKvall: 1 });

    this.getKvall(1);

    this.state.toggleValueDag = 1;
    this.setState({ toggleValueDag: 1 });

    this.getDag(1);

    let rows = this.state.rows;

    let dagrows = this.state.dagrows;
    let kvallrows = this.state.kvallrows;

    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch('https://api.gordondelivery.com/api/axfood/summary', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var summary = JSON.parse(responseJson);
          var numberOfRows = summary.length;
          for (let i = 0; i < numberOfRows; i++) {

            rows.push([summary[i].Datum, summary[i].LeveransPrecision, summary[i].Antal]);
          }
          fetch('https://api.gordondelivery.com/api/axfood/staffingday', {
            method: 'GET',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + access_token,
            },
          })
            .then((response) => response.json())
            .then((responseJson) => {
              var summary = JSON.parse(responseJson);
              var numberOfRows = summary.length;
              for (let i = 0; i < numberOfRows; i++) {

                dagrows.push([summary[i].Datum, summary[i].Antal]);
              }
              fetch('https://api.gordondelivery.com/api/axfood/staffingnight', {
                method: 'GET',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + access_token,
                },
              })
                .then((response) => response.json())
                .then((responseJson) => {
                  var summary = JSON.parse(responseJson);
                  var numberOfRows = summary.length;
                  for (let i = 0; i < numberOfRows; i++) {

                    kvallrows.push([summary[i].Datum, summary[i].Antal]);
                  }

                  this.state.kvallrows = kvallrows;
                  this.setState({ kvallrows: kvallrows });
                })
                .catch((error) => {
                  console.error(error);
                });
              this.state.dagrows = dagrows;
              this.setState({ dagrows: dagrows });
            })
            .catch((error) => {
              console.error(error);
            });
          this.state.rows = rows;
          this.setState({ rows: rows });

        })
        .catch((error) => {
          console.error(error);
        });

    } else {
      console.error("401 Unauthorized");
    }
  }


  getKvall(toggleValueKvall) {

    var days = 30;
    if (toggleValueKvall == 2) {
      days = 60;
    } else if (toggleValueKvall == 3) {
      days = 90;
    }
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {


      fetch('https://api.gordondelivery.com/api/axfood/averagestaffingnight/' + days, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          datakvall = JSON.parse(responseJson);


          this.state.datakvall = datakvall;
          this.setState({ datakvall: datakvall });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
  getDag(toggleValueDag) {

    var days = 30;
    if (toggleValueDag == 2) {
      days = 60;
    } else if (toggleValueDag == 3) {
      days = 90;
    }

    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {


      fetch('https://api.gordondelivery.com/api/axfood/averagestaffingday/' + days, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          datadag = JSON.parse(responseJson);

          this.state.datadag = datadag;
          this.setState({ datadag: datadag });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleToggleChangeDag(toggleValueDag) {
    this.setState({
      toggleValueDag: toggleValueDag
    });

    this.getDag(toggleValueDag);
  }
  handleToggleChangeKvall(toggleValueKvall) {
    this.setState({
      toggleValueKvall: toggleValueKvall
    });

    this.getKvall(toggleValueKvall);
  }

  render() {

    return (
      <div className="container">


        <div className={'my-pretty-chart-container'}>
          <Chart
            chartType="ComboChart"
            data={this.state.rows}
            options={{
              title: 'Willys Älvsjö - Leveransprecision',
              vAxes: [{
                title: 'Procent %', viewWindow: {
                  min: 0,
                  max: 100
                }
              },
              {
                title: 'Antal', viewWindow: {
                  min: 0,
                  max: 100
                }
              }
              ],
              hAxis: { title: 'Datum' }, seriesType: 'bars',
              series: { 1: { type: 'line', targetAxisIndex: 1 } }
            }}
            graph_id="ComboChart"
            chartEvents={[{
              eventName: 'select',
              callback: this.handleWillysSelection.bind(this)
            }]}
            width="100%"
            height="500px"
            legend_toggle
          />
        </div>
        <br />
        <h3>Bemanning dagspass</h3>
        <div className="buttonSearch">
          <ButtonToolbar>
            <ToggleButtonGroup type="radio" name="optionsdag" value={this.state.toggleValueDag} onChange={this.handleToggleChangeDag.bind(this)}>
              <ToggleButton value={1}>Senaste 30 dagarna</ToggleButton>
              <ToggleButton value={2}>Senaste 60 dagarna</ToggleButton>
              <ToggleButton value={3}>Senaste 90 dagarna</ToggleButton>
            </ToggleButtonGroup>
          </ButtonToolbar>
        </div>
        <BootstrapTable width='300' data={datadag} striped={true} hover={true}>
          <TableHeaderColumn width='150' dataField='dag' isKey>Veckodag</TableHeaderColumn>
          <TableHeaderColumn width='150' dataField='medel'>Medel - antal förare</TableHeaderColumn>
          <TableHeaderColumn width='150' dataField='min'>Min - antal förare</TableHeaderColumn>
          <TableHeaderColumn width='150' dataField='max'>Max - antal förare</TableHeaderColumn>
        </BootstrapTable>

        <br />
        <br />
        <h3>Bemanning kvällspass</h3>
        <div className="buttonSearch">
          <ButtonToolbar>
            <ToggleButtonGroup type="radio" name="optionskvall" value={this.state.toggleValueKvall} onChange={this.handleToggleChangeKvall.bind(this)}>
              <ToggleButton value={1}>Senaste 30 dagarna</ToggleButton>
              <ToggleButton value={2}>Senaste 60 dagarna</ToggleButton>
              <ToggleButton value={3}>Senaste 90 dagarna</ToggleButton>
            </ToggleButtonGroup>
          </ButtonToolbar>
        </div>
        <BootstrapTable width='300' data={datakvall} striped={true} hover={true}>
          <TableHeaderColumn width='150' dataField='dag' isKey>Veckodag</TableHeaderColumn>
          <TableHeaderColumn width='150' dataField='medel'>Medel - antal förare</TableHeaderColumn>
          <TableHeaderColumn width='150' dataField='min'>Min - antal förare</TableHeaderColumn>
          <TableHeaderColumn width='150' dataField='max'>Max - antal förare</TableHeaderColumn>
        </BootstrapTable>

        <br />
        <div className={'my-pretty-chart-container'}>
          <Chart
            chartType="ComboChart"
            data={this.state.dagrows}
            options={{
              title: 'Bemanning Dag - antal förare',
              vAxes: [{
                title: 'Antal', viewWindow: {
                  min: 0,
                  max: 5
                }
              }
              ],
              hAxis: { title: 'Datum' }, seriesType: 'bars',
              series: { 1: { type: 'line', targetAxisIndex: 1 } }
            }}
            graph_id="ComboChart3"

            width="100%"
            height="500px"
            legend_toggle
          />
        </div>
        <br />
        <div className={'my-pretty-chart-container'}>
          <Chart
            chartType="ComboChart"
            data={this.state.kvallrows}
            options={{
              title: 'Bemanning Kväll - antal förare',
              vAxes: [{
                title: 'Antal', viewWindow: {
                  min: 0,
                  max: 5
                }
              }
              ],
              hAxis: { title: 'Datum' }, seriesType: 'bars',
              series: { 1: { type: 'line', targetAxisIndex: 1 } }
            }}
            graph_id="ComboChart4"

            width="100%"
            height="500px"
            legend_toggle
          />
        </div>
      </div>

    );
  }
}

export default AxfoodWillysAlvsjo;

