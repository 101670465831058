import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import React, { Component } from 'react';

import FleetInfoPhoto from './fleetinfophoto';
import FleetInfoDamage from './fleetinfodamage';
import FleetInformation from './fleetinformation';
import FleetInfoService from './fleetinfoservice';
import FleetInfoLeasing from './fleetinfoleasing';
import FleetInfoBesiktning from './fleetinfobesiktning';

import Tabs from 'react-responsive-tabs';
import 'react-responsive-tabs/styles.css'
const queryString = require('query-string');

class FleetInfo extends Component {
  login() {
    this.props.auth.login();
  }

  componentWillMount() {
    var parsed = queryString.parse(this.props.location.search);
    localStorage.setItem('regnr', parsed.regnr);
    this.setState({ regnr: parsed.regnr });
  }

  componentDidMount() {

  }

  render() {
    const { isAuthenticated } = this.props.auth;

    const ordersTabs = [
      { name: 'Information', content: <FleetInformation /> },
      { name: 'Leasing', content: <FleetInfoLeasing /> },
      { name: 'Besiktning', content: <FleetInfoBesiktning /> },
      { name: 'Service', content: <FleetInfoService /> },
      { name: 'Skador', content: <FleetInfoDamage /> },
      { name: 'Fotobesiktning', content: <FleetInfoPhoto /> },
    ];

    function getTabs() {
      return ordersTabs.map(ordertab => ({
        tabClassName: 'tab', // Optional
        panelClassName: 'panel', // Optional
        title: ordertab.name,
        getContent: () => ordertab.content,
      }));
    }


    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
               <p><a href="fleet">&lt; Tillbaka</a></p>
              <h3>{this.state.regnr}</h3>
             
              <Tabs items={getTabs()} />
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default FleetInfo;

