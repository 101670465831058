import React, { Component } from 'react';
import Tabs from 'react-responsive-tabs';

import BlBromma from './blbromma';
import BlCity from './blcity';
import BlHaggvik from './blhaggvik';
import BlHaninge from './blhaninge';
import BlNacka from './blnacka';
import BlLidingo from './bllidingo';
import BlTaby from './bltaby';

class Blomsterlandet extends Component {
  login() {
    this.props.auth.login();
  }

  render() {
    const { isAuthenticated } = this.props.auth;

    const ordersTabs = [
      { name: 'Bromma', content: <BlBromma /> },
      { name: 'City', content: <BlCity /> },
      { name: 'Häggvik', content: <BlHaggvik /> },
      { name: 'Haninge', content: <BlHaninge /> },
      { name: 'Lidingö', content: <BlLidingo /> },
      { name: 'Nacka', content: <BlNacka /> },
      { name: 'Täby', content: <BlTaby /> },
    ];

    function getTabs() {
      return ordersTabs.map(ordertab => ({
        tabClassName: 'tab', // Optional
        panelClassName: 'panel', // Optional
        title: ordertab.name,
        getContent: () => ordertab.content,
      }));
    }

    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
                  <Tabs items={getTabs()} />
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default Blomsterlandet;

