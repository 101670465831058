import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/sv';
import { LineChart, Line, XAxis, YAxis, Tooltip } from 'recharts';

class CustomizedLabel extends React.Component {
    render() {
        const { x, y, stroke, value } = this.props;

        return <text x={x} y={y} dy={-10} fill="white" fontSize={12} textAnchor="middle">{value}</text>
    }
};

class TotalPrecision extends Component {

    componentWillMount() {

        this.setState({ period: '' });
        this.setState({ rows: [] });
        this.setState({ precision: '' });
        return { precision: '', period: '' };
    }

    componentDidMount() {
        moment.locale('sv');

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            var url = process.env.REACT_APP_GORDON_API_URL + '/order/statsallthismonth';
            fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    var stats = responseJson;


                    this.state.precision = Math.round(stats.precision);
                    this.setState({ precision: Math.round(stats.precision) });

                    var period = this.capitalizeFirstLetter(moment().add(0, 'M').format("MMMM YYYY"));
                    this.state.period = period;
                    this.setState({ period: period });


                    var url = process.env.REACT_APP_GORDON_API_URL + '/order/statsprecisionlastquarter';
                    fetch(url, {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + access_token,
                        },
                    })
                        .then((response) => response.json())
                        .then((responseJson) => {

                            var result = [];
                            for (let j = 0; j < responseJson.length; j++) {

                                if (moment(responseJson[j].datum).month() != moment().month()) {
                                    result.push({ name: moment(responseJson[j].datum).format('MMMM'), Precision: responseJson[j].precision });
                                }

                            }
                            this.state.rows = result;
                            this.setState({ rows: result });
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                })
                .catch((error) => {
                    console.error(error);
                });

        } else {
            console.error("401 Unauthorized");
        }
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    render() {

        return (
            <div>
                <div className="dashboardreklamationer">
                    <h3><strong>{this.state.precision}%</strong></h3>
                    <strong>Leveransprecision ({this.state.period})</strong>
                </div>
                <br />
                <div className="dashboardreklamationerright">
                    <LineChart width={270} height={170} data={this.state.rows}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                        <XAxis dataKey="name" stroke="#4DBD74" />
                        <YAxis stroke="#4DBD74" domain={[50, 100]} />
                        <Tooltip itemStyle={{ color: '#000' }} />
                        <Line type="monotone" activeDot={{ r: 8 }} dataKey="Precision" stroke="#fff" label={<CustomizedLabel />} />
                    </LineChart>
                </div>
            </div>

        );
    }
}

export default TotalPrecision;