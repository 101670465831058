import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
import _ from "lodash";
import { DateRange } from 'react-date-range';
import { format, addDays } from 'date-fns';
import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

const rows = [];

class Wrapsons extends Component {

  state = { rows };
  getInitalState() {
    this.setState({ rows: [] });
  }
  handleSelection(Chart) {
    if (Chart.chart.getSelection()[0]) {
      var date = this.state.rows[Chart.chart.getSelection()[0]['row'] + 1][0];
      this.props.history.push('/delivery?merchant=WR&date=' + date);
    }
  }

  login() {
    this.props.auth.login();
  }
  componentWillMount() {

    
    this.state = {
      dateRange: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      }
    };

    this.setState({
      dateRange: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      }
    });

    let rows = [];

    rows.push(["Datum", "Precision %", "Antal"]);

    this.setState({ rows: rows });
    return { rows: rows };
  }
  componentDidMount() {

  }

  handleRangeChange(which, payload) {
    console.log(which, payload);
    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });

    let rows = [];

    rows.push(["Datum", "Precision %", "Antal"]);
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      var to = moment(payload.selection.endDate).endOf('day');
      var from = moment(payload.selection.startDate).startOf('day');
      var url = 'https://api.gordondelivery.com/api/wrapsons/summary/' + from.format("YYYYMMDD") + "/" + to.format("YYYYMMDD");
      fetch(url, {
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + access_token,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var summary = JSON.parse(responseJson);
          var numberOfRows = summary.length;
          for (let i = 0; i < numberOfRows; i++) {

            rows.push([summary[i].Datum, summary[i].LeveransPrecision, summary[i].AntalLeveranser]);
          }

          this.state.rows = rows;
          this.setState({ rows: rows });
        })
        .catch((error) => {
          console.error(error);
        });

    } else {
      console.error("401 Unauthorized");
    }
  }

  formatDateDisplay(date, defaultText) {
    if (!date) return defaultText;
    return format(date, 'YYYY-MM-DD');
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Wrapsons</h3>
              <DateRange
                onChange={this.handleRangeChange.bind(this, 'dateRange')}
                moveRangeOnFirstSelection={false}
                dateDisplayFormat="YYYY-MM-DD"
                ranges={[this.state.dateRange.selection]}
                className={'PreviewArea'}
              />

              <br />
              <div className={'my-pretty-chart-container'}>
              {
          this.state.rows != null && this.state.rows.length > 0 && (
                <Chart
                  chartType="ComboChart"
                  data={this.state.rows}
                  options={{
                    title: 'Wrapsons - Leveransprecision',
                    vAxes: [{title: 'Procent %', viewWindow: {
                      min: 0,
                      max: 100
                    }},
                    {title: 'Antal', viewWindow: {
                      min: 0,
                      max: 500
                    }}
                  ],hAxis: { title: 'Datum' }, seriesType: 'bars', 
                    series: { 1: { type: 'line', targetAxisIndex: 1 } }
                  }}
                  graph_id="ComboChart"
                  chartEvents={[{
                    eventName: 'select',
                    callback: this.handleSelection.bind(this)
                  }]}
                  width="100%"
                  height="500px"
                  legend_toggle
                />
          )}
              </div>
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default Wrapsons;

