import React from 'react';
import OrderDetails from './orderdetails';
import moment from 'moment';

class SearchResults extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedOrder: {},
            searchResults: [],
        }

        this.renderResults = this.renderResults.bind(this);
        this.setSelectedOrder = this.setSelectedOrder.bind(this);
    }

    componentDidMount() {
        if (this.props.location.state) {
            this.setState({ ...this.state, searchResults: this.props.location.state, selectedOrder: this.props.location.state[0] })
        }
    }

    setSelectedOrder(order) {
        this.setState({ ...this.state, selectedOrder: order });

    }

    renderResults() {
        let { searchResults } = this.state;
        let returnOrders = [];

        if (!searchResults || searchResults.length < 1) {
            return returnOrders;
        }

        returnOrders = searchResults.map((o) => {

            return (
                <div key={o._id} onClick={() => this.setSelectedOrder(o)} style={o._id === this.state.selectedOrder._id ? { borderRadius: "10px", fontWeight:"bold" } : {}} >
                    <span className="label label-default" style={{ marginRight: "5px" }}>{new moment(o.deliveryDate).format('YYYY-MM-DD')}</span>

                    {(`${o.deliveryAddress.fullName}, ${o.deliveryAddress.streetAddress}, ${o.deliveryAddress.postalCode} ${o.deliveryAddress.city}, ${o.pickupInfo.contactInfo.name}`)}

                </div>)
        });

        return returnOrders;

    }

    componentWillReceiveProps(nextProps) {
        if (JSON.stringify(this.props.location.state) !== JSON.stringify(nextProps.location.state)) {
            this.setState({ ...this.state, searchResults: nextProps.location.state })
        }
    }

    render() {

        return (
            <div className="container">
                <div className="row" style={{color:"grey"}}>

                    <div className="col-xs-12 col-sm-12 col-lg-4" style={{ cursor: "pointer", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                        <h3>Search results {this.state.searchResults.length}</h3>
                        {this.renderResults()}
                    </div>
                    <div className="col-xs-12 col-sm-12 col-lg-8" style={{}}>
                        <div className="row">

                            <OrderDetails auth={this.props.auth} {...this.props} order={this.state.selectedOrder} />
                        </div>
                    </div>

                </div>

            </div>)
    }
}


export default SearchResults;