
/**
 * @param {String}   url              Path to API endpoint
 * @param {Object}   options 
 * @param {String}   options.method   POST,GET,PUT,DELTE
 * @param {String}   options.body     JSON.stringify(params)
 * @param {Function} callback         Called with callback(err, res) pattern
 */

export function sendRequest(url, options, callback) {
    if (!url || !options) {
        return callback('Missing required field options (url, options = {method, bodyString})');
    }

    let access_token = getAccessToken();

    if(!access_token){
        return callback('Unable to retrieve access_token / 401 Unauthorized');
    }

    fetch(url, {
        method: options.method,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + access_token,
        },
        body: options.body ? options.body : undefined
    })
        .then((res) => res.json())
        .then((respons) => {
            return callback(null, respons)
        })
        .catch((err) => {
            return callback(err)
        });
}


export function getAccessToken() {
    return localStorage.getItem('access_token');
}