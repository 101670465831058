
import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Button, MenuItem, DropdownButton, Panel, ToggleButton, ToggleButtonGroup, ButtonToolbar } from 'react-bootstrap';

var data;

class BottomDrivers extends Component {

    componentWillMount() {

        let toggleValue = 1;
        this.setState({ toggleValue: toggleValue });

        this.setState({ data: {} });
        return { data: data };
    }


    componentDidMount() {

        this.state.toggleValue = 1;
        this.setState({ toggleValue: 1 });

        this.getList(1);
    }

    getList(toggleValue) {
        var days = 7;
        if (toggleValue == 2) {
            days = 30;
        }

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            fetch('https://api.gordondelivery.com/api/gordoneer/bottomprecisionextended/' + days, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => response.json())
                .then((dataJson) => {
                    var dataJ = JSON.parse(dataJson);
                    for (let i = 0; i < dataJ.length; i++) {
                        dataJ[i].Precision = Math.round(dataJ[i].Precision * 100) / 100;
                      }
                    data = dataJ;
                    this.state.data = data;
                    this.setState({ data: data });
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
            console.error("401 Unauthorized");
        }
    }

    handleToggleChange(toggleValue) {
        this.setState({
            toggleValue: toggleValue
        });

        this.getList(toggleValue);
    }

    gordoneerFormatter(cell, row) {
        const link = 'gordoneer?name=' + cell;
        return ' <a href="' + link + '">' + cell + '</a>';
      }

    render() {

        return (
            <div className="container">

                <div>
                    <h4>"Håll koll på"-listan</h4>
                    <div className="buttonSearch">
                        <ButtonToolbar>
                            <ToggleButtonGroup type="radio" name="options" value={this.state.toggleValue} onChange={this.handleToggleChange.bind(this)}>
                                <ToggleButton value={1}>Senaste 7 dagarna</ToggleButton>
                                <ToggleButton value={2}>Senaste 30 dagarna</ToggleButton>
                            </ToggleButtonGroup>
                        </ButtonToolbar>
                    </div>
                    <div >
                        <BootstrapTable data={data} striped={true} hover={true}>
                            <TableHeaderColumn width='180' dataField='Name' dataFormat={this.gordoneerFormatter} isKey>Gordoneer</TableHeaderColumn>
                            <TableHeaderColumn dataField='Precision'>Precision %</TableHeaderColumn>
                            <TableHeaderColumn dataField='Antal'>Antal stopp</TableHeaderColumn>
                        </BootstrapTable>
                    </div>

                </div>

            </div>

        );
    }
}

export default BottomDrivers;