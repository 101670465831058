import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Workbook from 'react-excel-workbook';
import _ from "lodash";
import { Button, Modal, FormControl } from 'react-bootstrap';
import Select from 'react-select';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import './zipcodes.css'
class ZipCodes extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem('userProfileName');

    this.state = {
      author: user,
      user: user,
      showModal: false,

      zipcode: '',
      locality: '',
      zone: '',
      selectedDeliveryGroup: '',

      deliveryGroups: [
        { value: '', label: 'Ingen delivery group' },
        { value: 'Gordon Sthlm', label: 'Gordon Sthlm' },
        { value: 'Gordon Gbg', label: 'Gordon Gbg' },
        { value: 'Gordon Mma', label: 'Gordon Mma' },
        { value: 'Gordon Ostergotland', label: 'Gordon Ostergotland' },
        { value: 'Gordon Sjælland', label: 'Gordon Sjælland' },
        { value: 'Gordon Aaborg', label: 'Gordon Aaborg' },
        { value: 'Gordon Fredericia', label: 'Gordon Fredericia' },
        { value: 'Gordon Århus', label: 'Gordon Århus' },
        { value: 'Gordon Eskilstuna', label: 'Gordon Eskilstuna' },
        { value: "Gordon Ljungby", label: "Gordon Ljungby" },
      ],

      rows: [],
      originalrows: [],
      listNames: [],
      selectedListName: '',

      listClients: []
    };


    this.fetchData = this.fetchData.bind(this);
    this.getZipcodes = this.getZipcodes.bind(this);
    this.addZipcode = this.addZipcode.bind(this);
    this.cancel = this.cancel.bind(this);
    this.open = this.open.bind(this);
    this.getListNames = this.getListNames.bind(this);
    this.getListClients = this.getListClients.bind(this);
  }

  componentDidMount() {
    this.getListNames();
  }


  getListClients() {
    let { selectedListName } = this.state;
    let token = localStorage.getItem('access_token');
    if (!token) {
      return console.log('missing token');
    }

    return fetch(`${process.env.REACT_APP_GORDON_API_URL}/zipcode/clients/${selectedListName}`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } })
      .then(res => res.json())
      .then(data => this.setState({ ...this.state, listClients: data }))
      .catch(console.log)
  }


  getListNames() {
    let token = localStorage.getItem('access_token');
    let { selectedListName } = this.state;

    if (!token) {
      return console.log('missing token');
    }

    return fetch(`${process.env.REACT_APP_GORDON_API_URL}/zipcode/listNames`, { method: 'GET', headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' } })
      .then(response => response.json())
      .then(data => {
        if (!selectedListName) {

          this.setState({ ...this.state, listNames: data, selectedListName: data[0] })
        } else {

          this.setState({ ...this.state, listNames: data })
        }
        this.getZipcodes();
        this.getListClients();
      })
      .catch(err => console.log(err));
  }



  getZipcodes() {
    let access_token = localStorage.getItem('access_token');
    let { selectedListName } = this.state;
    if (!access_token) {
      return console.error("401 Unauthorized");

    }
    return fetch(process.env.REACT_APP_GORDON_API_URL + '/zipcode/' + selectedListName, {
      method: 'get',
      headers: {
        'Authorization': 'Bearer ' + access_token,
        'Content-Type': 'application/json'
      }
    })
      .then((response) => response.json())
      .then((responseJson) => {
        let rows = responseJson;

        this.setState({
          rows: rows,
          originalrows: responseJson
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  fetchData(state, instance) {
    let filteredData = this.state.originalrows;
    if (state.filtered.length) {
      filteredData = state.filtered.reduce((filteredSoFar, nextFilter) => {
        return filteredSoFar.filter(row => {
          return (row[nextFilter.id] + "").includes(nextFilter.value);
        });
      }, filteredData);
    }
    // You can also use the sorting in your request, but again, you are responsible for applying it.
    const sortedData = _.orderBy(
      filteredData,
      state.sorted.map(sort => {
        return row => {
          if (row[sort.id] === null || row[sort.id] === undefined) {
            return -Infinity;
          }
          return typeof row[sort.id] === "string"
            ? row[sort.id].toLowerCase()
            : row[sort.id];
        };
      }),
      state.sorted.map(d => (d.desc ? "desc" : "asc"))
    );

    let rows = sortedData;
    this.setState({ rows: rows });
  }

  openzipcode(row) {
    return this.setState({
      zipcode: row.original.zipcode,
      locality: row.original.locality,
      selectedDeliveryGroup: { value: row.original.deliveryGroup, label: row.original.deliveryGroup },
      zone: row.original.zone,
      selectedId: row.original._id,

      showModal: true
    });
  }

  open() {
    return this.setState({ ...this.state, showModal: true });
  }

  delete() {
    let { selectedListName } = this.state;
    let access_token = localStorage.getItem('access_token');
    if (!access_token) {
      return console.error("401 Unauthorized");
    }

    let url = process.env.REACT_APP_GORDON_API_URL + '/zipcode/' + this.state.zipcode + '/' + selectedListName;
    return fetch(url, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
    })
      .then((response) => {
        let deletedId = this.state.selectedId;
        let updatedRows = this.state.rows.filter(row => row._id !== deletedId);

        return this.setState({
          zipcode: '',
          locality: '',
          selectedDeliveryGroup: '',
          zone: '',

          selectedId: '',
          rows: updatedRows,
          showModal: false
        });

      })
      .catch((error) => {
        console.error(error);
      });

  }

  cancel() {
    return this.setState({
      zipcode: '',
      locality: '',
      selectedDeliveryGroup: '',
      zone: '',

      selectedId: '',
      showModal: false
    });
  }

  updatezipcode() {
    let updatebody = {
      'id': this.state.selectedId,
      'zipcode': this.state.zipcode,
      'locality': this.state.locality,
      'deliveryGroup': this.state.selectedDeliveryGroup != null ? this.state.selectedDeliveryGroup.value : null,
      'zone': this.state.zone
    };

    let access_token = localStorage.getItem('access_token');
    if (!access_token) {
      return console.error("401 Unauthorized");
    }

    return fetch(process.env.REACT_APP_GORDON_API_URL + '/zipcode/' + this.state.selectedListName, {
      method: 'PUT',
      body: JSON.stringify(updatebody),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
    })
      .then((response) => {

        this.setState({
          zipcode: '',
          locality: '',
          selectedDeliveryGroup: '',
          zone: '',

          selectedId: '',
          showModal: false,
        });

        this.getZipcodes();
      })
      .catch((error) => {
        console.error(error);
      });
  }


  addZipcode() {
    console.log('add zipcode')
    let { selectedListName, zipcode, locality, selectedDeliveryGroup, zone, pricemodel, price, rows } = this.state
    /*
      if (this.state.selectedId != null) {
        return this.updatezipcode();
      }
    */
    console.log({ selectedListName, zipcode, locality, selectedDeliveryGroup, zone })

    let postbody = {
      'zipcode': zipcode,
      'locality': locality,
      'deliveryGroup': selectedDeliveryGroup ? selectedDeliveryGroup.value : null,
      'zone': zone
    };

    let access_token = localStorage.getItem('access_token');

    if (!access_token) {
      return console.error("401 Unauthorized");
    }

    return fetch(process.env.REACT_APP_GORDON_API_URL + '/zipcode/' + selectedListName, {
      method: 'POST',
      body: JSON.stringify(postbody),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
    })
      .then(response => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        return response.json()
      })
      .then(data => {
        console.log(data)
        if (data) {
          let rowIndex = rows.findIndex(r => r._id === data._id);
          rows.splice(rowIndex, 1, data);
        }

        return this.setState({
          zipcode: '',
          locality: '',
          selectedDeliveryGroup: '',
          zone: '',
          rows: rows,

          selectedId: '',
          showModal: false,
        });

      })
      .catch((error) => {
        return console.error(error);
      });
  }

  updateValue(value, field) {
    this.setState({ ...this.state, [field]: value })
  }

  handleDeliveryGroupChange = (selectedOption) => {
    return this.setState({ ...this.state, selectedDeliveryGroup: selectedOption || '' });
  }



  render() {
    let { selectedListName } = this.state;
    let isEnabled = this.state.zipcode &&
      this.state.locality &&
      this.state.selectedDeliveryGroup &&
      this.state.zone;

    const columns = [{
      Header: 'Postnummer',
      accessor: 'zipcode'
    }, {
      Header: 'Ort',
      accessor: 'locality'
    }, {
      Header: 'Delivery Group',
      accessor: 'deliveryGroup'
    }, {
      Header: 'Zon',
      accessor: 'zone'
    },
    {
      Header: "Visa",
      accessor: '_id',
      Cell: row => (

        <Button
          bsStyle="primary"
          className="btn-margin eventbtn"
          onClick={this.openzipcode.bind(this, row)}>Ändra</Button>
      )
    }]

    const handleListClick = (listName) => {
      this.setState({ ...this.state, selectedListName: listName }, () => {
        this.getListClients();
        this.getZipcodes();
      })
    }

    return (
      <div className="container">

        <div>
          <h4>
            Aktiva postnummerlistor
          </h4>

          <div >
            {this.state.listNames.map((name, i) =>
              <span key={i} className={name === selectedListName ? 'zipcodes__listNames__item__active' : 'zipcodes__listNames__item'} onClick={name !== selectedListName ? () => handleListClick(name) : null}>
                {name}
              </span>)
            }

          </div>

          <h4>Subscribing delivery groups</h4>
          {this.state.listClients.map(client => <span key={client._id} className='zipcodes__listNames__item'>{client.name}</span>)}

          <div style={{ marginBottom: '10px' }}>

            <Button
              bsStyle="primary"
              onClick={this.open.bind(this)}>Lägg till postnummer
            </Button>

            <span style={{ float: 'right' }}>
              <Workbook filename="postnummer.xlsx" element={
                <button className="btn btn-primary">Exportera till Excel
              </button>
              }>

                <Workbook.Sheet data={() => this.state.rows} name="Giltiga postnummer">
                  <Workbook.Column label="Postnummer" value="zipcode" />
                  <Workbook.Column label="Ort" value="locality" />
                  <Workbook.Column label="Delivery Group" value="deliveryGroup" />
                  <Workbook.Column label="Zon" value="zone" />
                  <Workbook.Column label="Postnummer lista" value="listName" />
                </Workbook.Sheet>
              </Workbook>
            </span>
          </div>

          <ReactTable
            filterable={true}
            data={this.state.rows}
            defaultSorted={[{
              id: 'zipcode',
              desc: false,
            }]}
            onFetchData={this.fetchData}
            columns={columns}
            defaultPageSize={200} />

          <Modal bsSize="large"
            show={this.state.showModal}
            onHide={this.cancel.bind(this)}
            dimmer={"blurring"}>

            <Modal.Header >
              <Modal.Title>
                {this.state.selectedId != null ? 'Uppdatera postnummer till ' + selectedListName : 'Lägg till postnummer' + selectedListName}
              </Modal.Title>
            </Modal.Header >

            <Modal.Body  >

              <div className="row overview">

                <div className="col-md-6 user-pad text-left">
                  <p>Postnummer:</p>
                  <FormControl type="number" onInput={(e) => this.updateValue(e.target.value, 'zipcode')} value={this.state.zipcode}
                    placeholder='Postnummer' />
                </div>

                <div className="col-md-6 user-pad text-left">
                  <p>Ort:</p>
                  <FormControl onInput={(e) => this.updateValue(e.target.value, 'locality')} value={this.state.locality}
                    placeholder='t ex "LUND", "STOCKHOLM", "HALMSTAD"' />
                </div>

              </div>

              <br />

              <div className="row overview">

                <div className="col-md-6 user-pad text-left">
                  <p>Zon:</p>
                  <FormControl type="number" onInput={(e) => this.updateValue(e.target.value, 'zone')} value={this.state.zone} placeholder="t ex 1, 2, 3" />
                </div>

                <div className="col-md-6 user-pad text-left">

                  <p>Delivery Group:</p>

                  <Select placeholder="Välj Delivery Group"
                    name="ddSelectDeliveryGroup"
                    value={this.state.selectedDeliveryGroup}
                    onChange={this.handleDeliveryGroupChange}
                    options={this.state.deliveryGroups} />

                </div>

              </div>
            </Modal.Body>

            <Modal.Footer>
              {
                this.state.selectedId != null && (
                  <Button bsStyle="danger" className="pull-left" onClick={this.delete.bind(this)}>Radera</Button>
                )}
              <Button negative onClick={this.cancel.bind(this)}>Avbryt</Button>
              <Button positive
                bsStyle="success"
                onClick={this.addZipcode.bind(this)} disabled={!isEnabled} >{this.state.selectedId != null ? 'Uppdatera' : 'Spara'}</Button>
            </Modal.Footer>

          </Modal>

        </div >
      </div>)
  }

}



export default ZipCodes;

