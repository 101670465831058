import React, { Component } from 'react';
import Tabs from 'react-responsive-tabs';

import LinasStatistik from './linasmatkassestatistik';
import LinasRapporter from './linasmatkasserapporter';
import LinasNoteringar from './linasmatkassenoteringar';

class LinasMatkasse extends Component {
 
  login() {
    this.props.auth.login();
  }

  render() {
    const { isAuthenticated } = this.props.auth;

    const ordersTabs = [
      { name: 'Rapporter', content: <LinasRapporter /> },
      { name: 'Statistik', content: <LinasStatistik /> },
      { name: 'Noteringar', content: <LinasNoteringar /> }
    ];

    function getTabs() {
      return ordersTabs.map(ordertab => ({
        tabClassName: 'tab', // Optional
        panelClassName: 'panel', // Optional
        title: ordertab.name,
        getContent: () => ordertab.content,
      }));
    }

    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Linas Matkasse</h3>
              <Tabs items={getTabs()} />
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default LinasMatkasse;

