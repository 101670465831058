import {
    BrowserRouter as Router,
    Route,
    Link
} from 'react-router-dom';
import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/sv';

class TotalPrecisionLastMonth extends Component {

    componentWillMount() {
        
        this.setState({ period: '' });
        this.setState({ precision: '' });
        return { precision: '', period: '' };
    }

    componentDidMount() {
        moment.locale('sv');

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            var donuturl = 'https://api.gordondelivery.com/api/gordoneer/totalprecisionpreviousmonth/';
            fetch(donuturl, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    var summary = JSON.parse(responseJson);

                    this.state.precision =  Math.round(summary[0].Precision * 100) / 100;
                    this.setState({ precision: Math.round(summary[0].Precision * 100) / 100 });

                    var period = this.capitalizeFirstLetter(moment().add(-1, 'M').format("MMMM YYYY"));
                    this.state.period = period;
                    this.setState({ period: period });
                })
                .catch((error) => {
                    console.error(error);
                });

        } else {
            console.error("401 Unauthorized");
        }
    }

    capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    render() {

        return (
            <div>
                <div className="dashboardsmallleft">

                    <img src="images/beat.png" width="100" />
                </div>
                <div className="dashboardsmallright">

                    <span><strong>Leveransprecision</strong></span>
                    <h3>{this.state.precision}%</h3>
                    {this.state.period}
                    </div>
            </div>

        );
    }
}

export default TotalPrecisionLastMonth;