import React, { Component } from "react";
import moment from "moment";
import "moment/locale/sv";
import {
  Button,
  ButtonToolbar,
  Panel,
  Modal,
  FormControl
} from "react-bootstrap";

class FleetManagement extends Component {
  login() {
    this.props.auth.login();
  }

  handleKeyPress(target) {
    if (target.charCode == 13) {
      this.openDoor(this.textjson);
    }
  }
  componentWillMount() {
    this.setState({
      clientLista: []
    });
    this.setState({
      pin: ""
    });
    this.setState({
      disabled: false
    });
    this.setState({
      showModal: false
    });

    this.setState({
      showErrorModal: false
    });
    this.setState({
      showLockModal: false
    });
    this.setState({
      nopass: false
    });
    this.setState({
      user: ""
    });
    this.setState({
      regnr: ""
    });
    this.setState({
      errormsg: ""
    });
    this.setState({
      errormsglock: ""
    });
    this.setState({
      deliveryDate: null
    });
    this.setState({
      timezynk: 0
    });
    this.setState({
      vehicle: 0
    });
    this.setState({
      treatment: 0
    });

    this.setState({
      arbetsplats: ""
    });

    this.setState({
      isLoadingUnlock: false
    });
    this.setState({
      isLoadingLock: false
    });
  }
  componentDidMount() {
    moment.locale("sv");

    const { isAuthenticated } = this.props.auth;

    if (isAuthenticated()) {
      const { getProfile } = this.props.auth;
      getProfile((err, profile) => {
        this.setState({
          profile
        });

        var access_token = localStorage.getItem("access_token");
        if (access_token != null) {
          var clientLista = [];

          var url = process.env.REACT_APP_GORDON_API_URL + "/timezynkadmin";
          fetch(url, {
            method: "GET",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              Authorization: "Bearer " + access_token
            }
          })
            .then(response => response.json())
            .then(responseJson => {
              var result = responseJson;
              if (result != null && result.length > 0) {
                for (let i = 0; i < result.length; i++) {
                  var client = result[i][0];
                  var pass = result[i][1];

                  var arbetspassLista = [];

                  for (let i = 0; i < pass.length; i++) {
                    var passet = pass[i].arbetsplats;

                    var mstart = moment(
                      pass[i].start,
                      "YYYY-MM-DDTHH:mm:ss.SSSS",
                      true
                    );
                    var mend = moment(
                      pass[i].end,
                      "YYYY-MM-DDTHH:mm:ss.SSSS",
                      true
                    );

                    var today = new Date();
                    var newStart = moment(mstart)
                      .add(-120, "m")
                      .toDate();
                    var newEnd = moment(mend)
                      .add(720, "m")
                      .toDate();

                    var disabled = true;
                    if (
                      today.getTime() > newStart.getTime() &&
                      today.getTime() < newEnd.getTime()
                    ) {
                      disabled = false;
                    } else {
                      pass[i].msg =
                        "Det går inte att låsa upp/låsa fordon eftersom passet inte är aktuellt.";
                    }
                    if (pass[i].fordon == "Egen/Ingen/Hyrbil") {
                      disabled = true;
                      pass[i].msg =
                        'Det går inte att låsa upp/låsa fordon "Egen/Ingen/Hyrbil"';
                    }
                    pass[i].disabled = disabled;
                    var passet =
                      mstart.format("LT") + " - " + mend.format("LT");
                    pass[i].passet = passet;

                    arbetspassLista.push(pass[i]);
                  }

                  const sortering = (a, b) => {
                    if (moment(a.start) > moment(b.start)) return 1;
                    if (moment(a.start) < moment(b.start)) return -1;
                    return 0;
                  };
                  arbetspassLista = arbetspassLista.sort(sortering);
                  clientLista.push({ client: client, pass: arbetspassLista });
                }

                const sorter = (a, b) => {
                  if (a.client > b.client) return 1;
                  if (a.client < b.client) return -1;
                  return 0;
                };
                clientLista = clientLista.sort(sorter);
                this.state.clientLista = clientLista;
                this.setState({
                  clientLista: clientLista
                });
              } else {
                this.state.nopass = true;
                this.setState({
                  nopass: true
                });
              }
            })
            .catch(error => {
              console.error(error);
            });
        }
      });
    }
  }

  cancel() {
    this.setState({
      showModal: false
    });
  }

  cancelError() {
    this.setState({
      showErrorModal: false
    });
  }

  openDoor() {
    var access_token = localStorage.getItem("access_token");
    this.setState({
      disabled: true
    });

    if (
      access_token != null &&
      this.textjson.value != null &&
      this.textjson.value.length > 3
    ) {
      var url =
        process.env.REACT_APP_GORDON_API_URL +
        "/metroteclog/validatepin/" +
        this.state.regnr +
        "/" +
        this.state.user +
        "/" +
        this.textjson.value +
        "/" +
        this.state.arbetsplats +
        "/" +
        this.state.profile.name;
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          var result = responseJson;
          if (result.unlock == true) {
            this.setState({
              errormsg: ""
            });
            this.setState({
              showModal: false
            });
            this.setState({
              disabled: false
            });
            alert("Bilen är upplåst!");
          } else {
            this.setState({
              errormsg:
                "Kunde inte låsa upp bilen. Antingen felaktig pinkod eller nätverksfel. Försök igen!"
            });
          }
        })
        .catch(error => {
          console.error(error);
          this.setState({
            disabled: false
          });
          alert("Kunde inte låsa upp bilen, försök igen. " + error);
        });
    }
  }

  open(regnr, start, end, arbetsplats, user) {
    var today = new Date();
    var newStart = moment(start)
      .add(-70, "m")
      .toDate();
    var newEnd = moment(end)
      .add(720, "m")
      .toDate();

    if (
      today.getTime() > newStart.getTime() &&
      today.getTime() < newEnd.getTime()
    ) {
      this.setState({
        regnr: regnr
      });
      this.setState({
        user: user
      });
      this.setState({
        arbetsplats: arbetsplats
      });

      this.getPin(regnr, user);
    } else {
      alert(
        "Det går inte att låsa fordonet just nu eftersom passet inte är aktuellt. Ladda om sidan."
      );
    }
  }

  getPin(regnr, user) {
    var access_token = localStorage.getItem("access_token");
    if (access_token != null) {
      var url =
        process.env.REACT_APP_GORDON_API_URL +
        "/metroteclog/getpin/" +
        regnr +
        "/" +
        user +
        "/" +
        this.state.profile.name;
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          var result = responseJson;
          if (result != null && result.token != null) {
            this.setState({
              showModal: true
            });
          } else {
            alert(
              "Kunde inte låsa upp fordon, kontrollera att föraren är registrerad i Gordon Portalen och försök igen!"
            );
          }
        })
        .catch(error => {
          console.error(error);
          alert(
            "Kunde inte låsa upp fordon, kontrollera att föraren är registrerad i Gordon Portalen och försök igen!"
          );
        });
    }
  }
  close(regnr, start, end, arbetsplats, user) {
    var today = new Date();
    var newEnd = moment(end)
      .add(720, "m")
      .toDate();

    if (today.getTime() < newEnd.getTime()) {
      this.setState({
        regnr: regnr
      });
      this.setState({
        user: user
      });
      this.setState({
        arbetsplats: arbetsplats
      });
      var mstart = moment(start, "YYYY-MM-DDTHH:mm:ss.SSSS", true);

      this.setState({
        deliveryDate: mstart.format("YYYY-MM-DD")
      });

      this.setState({
        showLockModal: true
      });
    } else {
      alert(
        "Det går inte att låsa ditt fordon just nu eftersom passet inte är aktuellt. Ladda om sidan."
      );
    }
  }

  closeLock() {
    var access_token = localStorage.getItem("access_token");
    if (access_token != null) {
      var url =
        process.env.REACT_APP_GORDON_API_URL +
        "/metroteclog/lock/" +
        this.state.regnr +
        "/" +
        this.state.user +
        "/" +
        this.state.arbetsplats +
        "/" +
        this.state.profile.name;
      fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token
        }
      })
        .then(response => response.json())
        .then(responseJson => {
          var result = responseJson;
          if (result.lock == true) {
            this.setState({
              errormsg: ""
            });

            this.setState({
              disabled: false
            });
            this.setState({
              showLockModal: false
            });
            alert(
              "Lås-kommando skickat till bilen, kontrollera att den verkligen är låst!"
            );
          } else {
            this.setState({
              errormsg: "Kunde inte låsa bilen. Försök igen!"
            });
          }
        })
        .catch(error => {
          console.error(error);
          this.setState({
            disabled: false
          });
          alert("Kunde inte låsa bilen. Försök igen!");
        });
    }
  }

  cancelLock() {
    this.setState({
      showLockModal: false
    });
  }

  render() {
    const { isAuthenticated } = this.props.auth;

    return (
      <div className="container">
        {isAuthenticated() && (
          <div>
            <h3>Dagens pass</h3>
            {!this.state.nopass && (
              <div>
                {Object.keys(this.state.clientLista).map(function(key) {
                  return (
                    <div>
                      <Panel>
                        <Panel.Heading>
                          <Panel.Title toggle>
                            {this.state.clientLista[key].client}
                          </Panel.Title>
                        </Panel.Heading>{" "}
                        <Panel.Collapse>
                          {" "}
                          <Panel.Body>
                            <ul className="list-group text-center pass">
                              {Object.keys(
                                this.state.clientLista[key].pass
                              ).map(function(index) {
                                return (
                                  <li>
                                    <Panel bsStyle="info">
                                      <Panel.Heading>
                                        <Panel.Title toggle>
                                          {
                                            this.state.clientLista[key].pass[
                                              index
                                            ].passet
                                          }
                                        </Panel.Title>
                                      </Panel.Heading>
                                      <Panel.Body>
                                        <p>
                                          {" "}
                                          <i className="fa fa-user fa-2x" />{" "}
                                          <b>
                                            {" "}
                                            {
                                              this.state.clientLista[key].pass[
                                                index
                                              ].user
                                            }{" "}
                                          </b>
                                        </p>
                                        <p>
                                          {" "}
                                          <i className="fa fa-truck fa-2x" />{" "}
                                          <b>
                                            {" "}
                                            {
                                              this.state.clientLista[key].pass[
                                                index
                                              ].fordon
                                            }{" "}
                                          </b>
                                        </p>
                                        <div class="text-center">
                                          <Button
                                            bsStyle="success"
                                            bsSize="large"
                                            width="100px"
                                            className="btn-margin navbar-btn btn-gordoneer"
                                            onClick={this.open.bind(
                                              this,
                                              this.state.clientLista[key].pass[
                                                index
                                              ].fordon,
                                              this.state.clientLista[key].pass[
                                                index
                                              ].start,
                                              this.state.clientLista[key].pass[
                                                index
                                              ].end,
                                              this.state.clientLista[key].pass[
                                                index
                                              ].arbetsplats,
                                              this.state.clientLista[key].pass[
                                                index
                                              ].user
                                            )}
                                            disabled={
                                              this.state.clientLista[key].pass[
                                                index
                                              ].disabled
                                            }
                                          >
                                            <i className="fa fa-unlock" />
                                            Lås upp{" "}
                                          </Button>{" "}
                                          <Button
                                            bsStyle="success"
                                            bsSize="large"
                                            width="100px"
                                            disabled={
                                              this.state.clientLista[key].pass[
                                                index
                                              ].disabled
                                            }
                                            className="btn-margin navbar-btn btn-gordoneer"
                                            onClick={this.close.bind(
                                              this,
                                              this.state.clientLista[key].pass[
                                                index
                                              ].fordon,
                                              this.state.clientLista[key].pass[
                                                index
                                              ].start,
                                              this.state.clientLista[key].pass[
                                                index
                                              ].end,
                                              this.state.clientLista[key].pass[
                                                index
                                              ].arbetsplats,
                                              this.state.clientLista[key].pass[
                                                index
                                              ].user
                                            )}
                                          >
                                            <i className="fa fa-lock" />
                                            Lås{" "}
                                          </Button>{" "}
                                        </div>{" "}
                                        <p className="warning">
                                          {" "}
                                          {
                                            this.state.clientLista[key].pass[
                                              index
                                            ].msg
                                          }{" "}
                                        </p>
                                      </Panel.Body>{" "}
                                    </Panel>
                                  </li>
                                );
                              }, this)}
                            </ul>{" "}
                          </Panel.Body>
                        </Panel.Collapse>
                      </Panel>
                    </div>
                  );
                }, this)}
              </div>
            )}{" "}
            {this.state.nopass && <p> Det finns inga bokade pass idag </p>}
            {}{" "}
            <Modal
              bsSize="small"
              show={this.state.showModal}
              onHide={this.cancel.bind(this)}
            >
              <Modal.Header>
                <Modal.Title> Ange pinkod </Modal.Title>{" "}
              </Modal.Header>
              <Modal.Body>
                <FormControl
                  autoFocus="true"
                  disabled={this.state.disabled}
                  inputRef={ref => {
                    this.textjson = ref;
                  }}
                  componentClass="input"
                  pattern="[0-9]*"
                  inputmode="numeric"
                  onKeyPress={this.handleKeyPress.bind(this)}
                  placeholder="pinkod"
                />
              </Modal.Body>{" "}
              <Modal.Footer>
                <Button
                  disabled={this.state.disabled}
                  onClick={this.cancel.bind(this)}
                >
                  {" "}
                  Avbryt{" "}
                </Button>{" "}
                <Button
                  bsStyle="success"
                  disabled={this.state.disabled}
                  bsSize="large"
                  onClick={this.openDoor.bind(this)}
                >
                  {" "}
                  Lås upp{" "}
                </Button>{" "}
                <br />
                <p> {this.state.errormsg} </p>{" "}
              </Modal.Footer>{" "}
            </Modal>
            <Modal
              bsSize="small"
              show={this.state.showErrorModal}
              onHide={this.cancelError.bind(this)}
            >
              <Modal.Header>
                <Modal.Title> Kontrollera checklistan! </Modal.Title>{" "}
              </Modal.Header>
              <Modal.Body>
                <p>
                  Du måste gå igenom checklistan innan du låser bilen!
                  <br />
                  <br />
                  Kontrollera att skåpet är låst och att det är snyggt och
                  städat.
                </p>
              </Modal.Body>{" "}
              <Modal.Footer>
                <Button onClick={this.cancelError.bind(this)}> OK </Button>
              </Modal.Footer>{" "}
            </Modal>
            <Modal
              show={this.state.showLockModal}
              onHide={this.cancelLock.bind(this)}
            >
              <Modal.Header>
                <Modal.Title>
                  {" "}
                  Gå igenom checklistan innan du låser bilen:{" "}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <ul className="checklistUl" disabled={this.state.disabled}>
                  <li>
                    <input
                      autoFocus="true"
                      type="checkbox"
                      aria-label="..."
                      ref="stadat"
                    />{" "}
                    Snyggt och städat
                  </li>
                  <li>
                    <input type="checkbox" aria-label="..." ref="lasskap" /> Lås
                    skåpet
                  </li>
                  <li>
                    <input type="checkbox" aria-label="..." ref="spannband" />{" "}
                    Spännband finns
                  </li>
                  <li>
                    <input
                      type="checkbox"
                      aria-label="..."
                      ref="mobilladdare"
                    />{" "}
                    Mobilladdare och mobilhållare finns
                  </li>
                  <li>
                    <input type="checkbox" aria-label="..." ref="fulltankad" />{" "}
                    Bilen ska ha minst halv tank
                  </li>
                  <li>
                    <input type="checkbox" aria-label="..." ref="adblue" /> Fyll
                    på AdBlue och spolarvätska vid behov
                  </li>
                </ul>
                <p>
                  Ange kommentar om något saknas eller om något i övrigt bör
                  nämnas:{" "}
                </p>
                <FormControl
                  disabled={this.state.disabled}
                  inputRef={ref => {
                    this.textjsonlock = ref;
                  }}
                  componentClass="input"
                  placeholder="Ange kommentar..."
                />{" "}
              </Modal.Body>
              <Modal.Footer>
                <Button
                  disabled={this.state.disabled}
                  onClick={this.cancelLock.bind(this)}
                >
                  {" "}
                  Avbryt{" "}
                </Button>{" "}
                <Button
                  bsStyle="success"
                  disabled={this.state.disabled}
                  bsSize="large"
                  onClick={this.closeLock.bind(this)}
                >
                  {" "}
                  Lås{" "}
                </Button>{" "}
                <br />
                <p> {this.state.errormsglock} </p>{" "}
              </Modal.Footer>{" "}
            </Modal>
          </div>
        )}
        {!isAuthenticated() && (
          <h4>
            You're not logged in! Click{" "}
            <a style={{ cursor: "pointer" }} onClick={this.login.bind(this)}>
              Login
            </a>{" "}
            to continue.
          </h4>
        )}
      </div>
    );
  }
}

export default FleetManagement;
