import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Workbook from 'react-excel-workbook';
import lodash from "lodash";
import { DateRange } from 'react-date-range';
import { format } from 'date-fns';

import moment from 'moment';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

class Followup extends Component {
  login() {
    this.props.auth.login();
  }

  componentWillMount() {
    this.setState({ rows: [] });
    this.setState({ filename: '' });
    this.setState({ sheetname: '' });
    this.setState({ formattedValue: moment().format("YYYY-MM-DD") });
    this.setState({ value: moment().toISOString() });

    this.setState({ isLoading: false });

    this.state = {
      dateRange: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      }
    };
    this.setState({
      dateRange: {
        selection: {
          startDate: new Date(),
          endDate: new Date(),
          key: 'selection',
        },
      }
    });
  }
  componentDidMount() {
    // this.getOrders(this.state.formattedValue);
  }

  handleRangeChange(which, payload) {
    console.log(which, payload);
    this.setState({
      [which]: {
        ...this.state[which],
        ...payload,
      },
    });

    this.getOrders(payload.selection.startDate, payload.selection.endDate);
  }

  getday(i) {
    if (i == 1) {
      return "mån";
    }
    else if (i == 2) {
      return "tis";
    }
    else if (i == 3) {
      return "ons";
    }
    else if (i == 4) {
      return "tor";
    }
    else if (i == 5) {
      return "fre";
    }
    else if (i == 6) {
      return "lör";
    }
    else if (i == 7) {
      return "sön";
    }
  }

  getOrders(from, to) {
    this.state.rows = [];
    this.setState({ rows: [] });
    var rows = [];
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      this.setState({ isLoading: true });

      fetch(process.env.REACT_APP_GORDON_API_URL + '/order/dailydriverstats?from=' + moment(from).format("YYYY-MM-DD") + '&to=' + moment(to).format("YYYY-MM-DD"), {
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + access_token,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((responseJson) => {
          rows = responseJson;

          for (let i = 0; i < rows.length; i++) {
            rows[i].month = moment(rows[i].datum).format("MM") + "-" + moment(rows[i].datum).format("MMMM");
            rows[i].week = moment(rows[i].datum).week();
            rows[i].dayofweek = moment(rows[i].datum).isoWeekday() + "-" + this.getday(moment(rows[i].datum).isoWeekday());
          };
          fetch(process.env.REACT_APP_GORDON_API_URL + '/complaints/bydate?from=' + moment(from).format("YYYY-MM-DD") + '&to=' + moment(to).format("YYYY-MM-DD"), {
            method: 'get',
            headers: {
              'Authorization': 'Bearer ' + access_token,
              'Content-Type': 'application/json'
            }
          })
            .then((response) => response.json())
            .then((responseJson) => {
              var complaints = responseJson;

              var sentillpass = 0;
              var reklamation = 0;
              var anmarkning = 0;
              var godsejklart = 0;
              var fordonsskada = 0;
              for (let i = 0; i < rows.length; i++) {
                sentillpass = 0;
                reklamation = 0;
                anmarkning = 0;
                godsejklart = 0;
                fordonsskada = 0;

                if (lodash.filter(complaints, x => x[0] === rows[i].driver).length > 0) {
                  var lista = lodash.filter(complaints, x => x[0] === rows[i].driver)[0][1];
                  lista.forEach(element => {
                    if (element.category == 'Sen till pass' && element.client == rows[i].kund && moment(element.deliverydate).format("YYYY-MM-DD") == moment(rows.deliveryDate).format("YYYY-MM-DD")) {
                      sentillpass = sentillpass + element.time
                    } else if (element.category == 'Reklamation' && element.client == rows[i].kund && moment(element.deliverydate).format("YYYY-MM-DD") == moment(rows.deliveryDate).format("YYYY-MM-DD")) {
                      reklamation++;
                    } else if (element.category == 'Gods ej klar för lastning' && element.client == rows[i].kund && moment(element.deliverydate).format("YYYY-MM-DD") == moment(rows.deliveryDate).format("YYYY-MM-DD")) {
                      godsejklart = godsejklart + element.time;
                    } else if (element.category == 'Anmärkning' && element.client == rows[i].kund && moment(element.deliverydate).format("YYYY-MM-DD") == moment(rows.deliveryDate).format("YYYY-MM-DD")) {
                      anmarkning++;
                    } else if (element.category == 'Fordonsskada' && element.client == rows[i].kund && moment(element.deliverydate).format("YYYY-MM-DD") == moment(rows.deliveryDate).format("YYYY-MM-DD")) {
                      fordonsskada++;
                    }
                  });
                }

                if (sentillpass > 0) {
                  rows[i].sentillpass = sentillpass;
                }
                if (rows[i].sentillpass == null) {
                  rows[i].sentillpass = 0;
                }

                if (reklamation > 0) {
                  rows[i].reklamation = reklamation;
                }
                if (rows[i].reklamation == null) {
                  rows[i].reklamation = 0;
                }

                if (godsejklart > 0) {
                  rows[i].godsejklart = godsejklart;
                }
                if (rows[i].godsejklart == null) {
                  rows[i].godsejklart = 0;
                }

                if (anmarkning > 0) {
                  rows[i].anmarkning = anmarkning;
                }
                if (rows[i].anmarkning == null) {
                  rows[i].anmarkning = 0;
                }

                if (fordonsskada > 0) {
                  rows[i].fordonsskada = fordonsskada;
                }
                if (rows[i].fordonsskada == null) {
                  rows[i].fordonsskada = 0;
                }
              }
              this.state.rows = rows;
              this.setState({ rows: rows });

              this.setState({ filename: "stats_" + moment(from).format("YYYY-MM-DD") + ".xlsx" });
              this.setState({ sheetname: "stats" });
              this.setState({ isLoading: false });

            })
            .catch((error) => {
              console.error(error);
              this.setState({ isLoading: false });
            });
        })
        .catch((error) => {
          console.error(error);
          this.setState({ isLoading: false });
        });


    } else {
      console.error("401 Unauthorized");
      this.setState({ isLoading: false });
    }
  }

  render() {

    const columns = [
      {
        Header: 'Datum',
        accessor: 'datum'
      },
      {
        Header: 'Månad',
        accessor: 'month'
      },
      {
        Header: 'Veckonr',
        accessor: 'week'
      },
      {
        Header: 'Veckodag',
        accessor: 'dayofweek'
      },
      {
        Header: 'Kund',
        accessor: 'kund'
      },
      {
        Header: 'Gordoneer',
        accessor: 'driver'
      },
      {
        Header: 'Antal',
        accessor: 'antal'
      }, {
        Header: 'Tidig leverans',
        accessor: 'tidig'
      }, {
        Header: 'Sen leverans',
        accessor: 'sen'
      }, {
        Header: 'Utebliven leverans',
        accessor: 'Utebliven'
      }, {
        Header: 'Leveransprecision',
        accessor: 'precision'
      },
      {
        Header: 'Planerad starttid',
        accessor: 'PlaneradStarttid'
      },
      {
        Header: 'Faktisk starttid',
        accessor: 'FaktiskStarttid'
      },
      {
        Header: 'Snittbetyg',
        accessor: 'averagerating'
      },
      {
        Header: 'Antal betyg',
        accessor: 'Ratings'
      }
      , {
        Header: 'Kund ej hemma',
        accessor: 'Kundejhemma'
      }, {
        Header: 'Kund avbokade',
        accessor: 'Kundavbokade'
      }, {
        Header: 'Kom ej in i port',
        accessor: 'Komejin'
      },
      {
        Header: 'Sen till pass (minuter)',
        accessor: 'sentillpass'
      }, {
        Header: 'Reklamationer',
        accessor: 'reklamation'
      }, {
        Header: 'Anmärkning',
        accessor: 'anmarkning'
      },
      {
        Header: 'Gods ej klar för lastning (minuter)',
        accessor: 'godsejklart'
      },
      {
        Header: 'Fordonsskada',
        accessor: 'fordonsskada'
      },
      {
        Header: 'Exkludera, Gods ej klar för lastning',
        accessor: 'exkludera'
      },
      {
        Header: 'Faktisk sluttid',
        accessor: 'FaktiskSluttid'
      },
      {
        Header: 'Premium-Gordoneer',
        accessor: 'isPremium'
      }
    ]
    const isLoading = this.state.isLoading;
    const { isAuthenticated } = this.props.auth;
    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div >
              <h3>Leveransuppföljning</h3>

              <DateRange
                onChange={this.handleRangeChange.bind(this, 'dateRange')}
                moveRangeOnFirstSelection={false}
                dateDisplayFormat="YYYY-MM-DD"
                ranges={[this.state.dateRange.selection]}
                className={'PreviewArea'}
              />

              <br />
              <br />
              <Workbook filename={this.state.filename} element={<button disabled={isLoading} className="btn btn-primary">Exportera till Excel</button>}>
                <Workbook.Sheet data={() => this.state.rows} name={this.state.sheetname}>
                  <Workbook.Column label="Datum" value="datum" />
                  <Workbook.Column label="Månad" value="month" />
                  <Workbook.Column label="Veckonr" value="week" />
                  <Workbook.Column label="Veckodag" value="dayofweek" />
                  <Workbook.Column label="Kund" value="kund" />
                  <Workbook.Column label="Gordoneer" value="driver" />
                  <Workbook.Column label="Antal" value="antal" />
                  <Workbook.Column label="Tidig leverans" value="tidig" />
                  <Workbook.Column label="Sen leverans" value="sen" />
                  <Workbook.Column label="Utebliven leverans" value="Utebliven" />
                  <Workbook.Column label="Leveransprecision" value="precision" />
                  <Workbook.Column label="Planerad starttid" value="PlaneradStarttid" />
                  <Workbook.Column label="Faktisk starttid" value="FaktiskStarttid" />
                  <Workbook.Column label="Snittbetyg" value="averagerating" />
                  <Workbook.Column label="Antal betyg" value="Ratings" />
                  <Workbook.Column label="Kund ej hemma" value="Kundejhemma" />
                  <Workbook.Column label="Kund avbokade" value="Kundavbokade" />
                  <Workbook.Column label="Kom ej in i port" value="Komejin" />
                  <Workbook.Column label="Sen till pass (minuter)" value="sentillpass" />
                  <Workbook.Column label="Reklamationer" value="reklamation" />
                  <Workbook.Column label="Anmärkning" value="anmarkning" />
                  <Workbook.Column label="Gods ej klar för lastning (minuter)" value="godsejklart" />
                  <Workbook.Column label="Fordonsskada" value="fordonsskada" />
                  <Workbook.Column label="Exkludera, Gods ej klar för lastning" value="exkludera" />
                  <Workbook.Column label="Faktisk starttid" value="FaktiskSluttid" />
                  <Workbook.Column label="Premium-Gordoneer" value="isPremium" />
                </Workbook.Sheet>
              </Workbook>
              <br />
              <br />
              <ReactTable
                filterable={true}
                data={this.state.rows}
                defaultSorted={[
                  {
                    id: 'datum',
                    desc: false,
                  }, {
                    id: 'kund',
                    desc: false,
                  }, {
                    id: 'driver',
                    desc: false,
                  }]}
                columns={columns}
                defaultPageSize={500} />
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default Followup;

