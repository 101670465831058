import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import _ from "lodash";
import { addDays } from 'date-fns';
import Picker from 'react-month-picker'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import 'react-month-picker/css/month-picker.css';
import PropTypes from "prop-types";
import Workbook from 'react-excel-workbook';
import moment from 'moment';

class MonthBox extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      value: this.props.value || 'N/A',
    }

    this._handleClick = this._handleClick.bind(this)
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value || 'N/A',
    })
  }

  render() {

    return (
      <div className="box" onClick={this._handleClick}>
        <label>{this.state.value}</label>
      </div>
    )
  }

  _handleClick(e) {
    this.props.onClick && this.props.onClick(e)
  }
}

MonthBox.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
}

class MonthStats extends Component {
  constructor(props, context) {
    super(props, context)

    this.state = {
      mvalue: { year: 2020, month: 1 },
    }

    this.disableDiv = this.disableDiv.bind(this);
    this.enableDiv = this.enableDiv.bind(this);

    this.handleClickMonthBox = this.handleClickMonthBox.bind(this)
    this.handleAMonthChange = this.handleAMonthChange.bind(this)
    this.handleAMonthDissmis = this.handleAMonthDissmis.bind(this)
  }

  componentWillMount() {

    this.setState({
      disableDiv: false
    });
  }
  componentWillReceiveProps(nextProps) {
    this.setState({
      value: nextProps.value || 'N/A',
    })
  }

  getAbsoulteMonths(momentDate) {
    var months = Number(momentDate.format("MM"));
    var years = Number(momentDate.format("YYYY"));
    return months + (years * 12);
  }


  handleClickMonthBox(e) {
    this.refs.pickAMonth.show()
  }
  handleAMonthChange(value, text) {

    var startMonths = this.getAbsoulteMonths(moment().endOf('month'));
    var endMonths = this.getAbsoulteMonths(moment(value + "-" + text + "-01").endOf('month'));

    var monthDifference = endMonths - startMonths;
    this.setState({ mvalue: { year: value, month: text } })
    this.getStats(monthDifference);
  }
  handleAMonthDissmis(value) {
    this.setState({ mvalue: value })
  }
  login() {
    this.props.auth.login();
  }

  componentWillMount() {

    this.setState({ data: [] });
    this.setState({ isLoading: false });
  }
  componentDidMount() {
    this.getStats(0);
  }

  getStats(month) {

    this.disableDiv();
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {
      this.setState({ isLoading: true });
      fetch(process.env.REACT_APP_GORDON_API_URL + '/order/statsgroupedthismonth?month=' + month, {
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + access_token,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((dataJson) => {

          var data = dataJson;
          for (let i = 0; i < data.length; i++) {
            data[i].link = '/delivery?merchantname=' + data[i].kund + '&date=' + data[i].datum;
          };

          this.setState({ data: data });
          this.setState({ isLoading: false });
          this.enableDiv();
        })
        .catch((error) => {
          console.error(error);
          this.enableDiv();
        });

    } else {
      console.error("401 Unauthorized");
      this.enableDiv();
    }
  }

  disableDiv() {
    this.setState({
      disableDiv: true
    });
  }

  enableDiv() {
    this.setState({
      disableDiv: false
    });
  }


  render() {
    const { isAuthenticated } = this.props.auth;

    let pickerLang = {
      months: ['Jan', 'Feb', 'Mar', 'Apr', 'Maj', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dec']
      , from: 'Från', to: 'Till'
    }
    const mvalue = this.state.mvalue

    let makeText = m => {
      if (m && m.year && m.month) return (pickerLang.months[m.month - 1] + '. ' + m.year)
      return '?'
    }

    var divStyle = {
      display: this.state.disableDiv ? 'block' : 'none'
    };

    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Månadsuppföljning</h3>
              <div className="cover" style={divStyle}></div>
                <label>Välj månad</label>
                <div className="edit">
                  <Picker
                    ref="pickAMonth"
                    years={[2019, 2020]}
                    value={mvalue}
                    lang={pickerLang.months}
                    onChange={this.handleAMonthChange}
                    onDismiss={this.handleAMonthDissmis}
                  >
                    <MonthBox value={makeText(mvalue)} onClick={this.handleClickMonthBox} />
                  </Picker>
                </div>
                <Workbook filename="monthly.xlsx" element={<button className="btn btn-primary">Exportera till Excel</button>}>
                                <Workbook.Sheet data={() => this.state.data} name="Monthly stats">
                                    <Workbook.Column label="Butik" value="kund" />
                                    <Workbook.Column label="Leveransprecision" value="precision" />
                                    <Workbook.Column label="Antal" value="antal" />
                                </Workbook.Sheet>
                            </Workbook>
                            <br /><br />
                <ReactTable
                  noDataText="Oh Noes!"
                  data={this.state.data}
                  columns={[


                    {
                      Header: 'Butik',
                      accessor: 'kund',
                    },
                    {
                      Header: 'Antal',
                      accessor: 'antal'
                    },
                    {
                      Header: 'Leveransprecision',
                      accessor: 'precision',
                      Cell: row => (
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            backgroundColor: '#dadada',
                            borderRadius: '2px'
                          }}
                        >

                          <div
                            style={{
                              width: `${row.value}%`,
                              height: '100%',
                              backgroundColor: row.value > 90 ? '#228B22'
                                : row.value > 70 ? '#FFA500'
                                  : '#fe0400',
                              borderRadius: '2px',
                              transition: 'all .2s ease-out'
                            }}
                          >
                            <span style={{
                              color: '#ffffff',
                              paddingLeft: '10px'

                            }}>
                              {row.value}%
                  </span>
                          </div>
                        </div>

                      )
                    }

                  ]}
                  defaultPageSize={16}
                  filterable={true}
                  defaultSorted={[{
                    id: 'antal',
                    desc: true,
                  }]}
                  className="-striped -highlight"
                />
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default MonthStats;

