import React, { Component } from 'react';
import PropTypes from "prop-types";
import moment from 'moment';
import 'moment/locale/sv';
class Message extends Component {
  static propTypes = {
    author: PropTypes.string,
    content: PropTypes.string,
    createdAt: PropTypes.instanceOf(Date)
  };

  componentDidMount() {
    this.interval = setInterval(() => {
      this.forceUpdate();
    }, moment.duration(1, 'minute').asMilliseconds());
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    moment.locale('sv');

    var datum = moment(this.props.createdAt);
    if (datum > moment().add(-1, 'days')) {
      datum = moment(this.props.createdAt).fromNow();
    }
    else {
       datum = moment(this.props.createdAt).format('YYYY-MM-DD HH:mm:ss');
    }

      return (
        <div className="message">
          <div className="message__author-line">
            <div className="message__author">{this.props.author}</div>
            <div className="message__created-at" title={moment(this.props.createdAt).format('YYYY-MM-DD HH:mm:ss')}>{datum}</div>
          </div>
          <div className="message__content">{this.props.content}</div>
        </div>
      )
  }
}

export default Message;