import {
    BrowserRouter as Router,
    Route,
    Link
} from 'react-router-dom';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Gauge from 'react-svg-gauge';

import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
class SlStockholm extends Component {

    componentWillMount() {

        let donutrowssl = [];
        donutrowssl.push(['Typ', "Procent"]);
        this.setState({ donutrowssl: donutrowssl });

        this.setState({ summarysl: { LeveransPrecision: 0 } });

        this.setState({ slcolor: '#fe0400' });

        return { donutrowssl: donutrowssl };
    }

    componentDidMount() {

        let donutrowssl = this.state.donutrowssl;

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {



            var donutursl = 'https://api.gordondelivery.com/api/svartaladan/latest/';
            fetch(donutursl, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    var summarysl = JSON.parse(responseJson);
                    donutrowssl.push(["Lyckade", summarysl[0].Lyckade]);
                    donutrowssl.push(["Avvikelser", summarysl[0].TotalAvvikelser]);
                    var snitt = (((summarysl[0].Rating5 * 5) + (summarysl[0].Rating4 * 4) + (summarysl[0].Rating3 * 3) + (summarysl[0].Rating2 * 2) + (summarysl[0].Rating1 * 1)) / (summarysl[0].Ratings * 5)) * 5;

                    var slcolor = '#fe0400';
                    if (summarysl[0].LeveransPrecision > 90) {
                        slcolor = '#228B22';
                    }
                    else if (summarysl[0].LeveransPrecision > 70) {
                        slcolor = '#FFA500';
                    }

                    this.setState({ slcolor: slcolor });

                    this.state.summarysl = summarysl[0];
                    this.state.summarysl.AverageRating = Math.round(snitt * 100) / 100;


                    this.state.donutrowssl = donutrowssl;
                    this.setState({ donutrowssl: donutrowssl });

                    this.setState({ summarysl: summarysl[0] });


                })
                .catch((error) => {
                    console.error(error);
                });



        } else {
            console.error("401 Unauthorized");
        }
    }

    _buildLinkHrefSl() {
        return '/delivery?merchant=SL&date=' + this.state.summarysl.Datum;
    }

    render() {

        return (
            <div>
                <div className="dashboardleft">

                    <h5>Svarta Lådan - {this.state.summarysl.Datum}</h5>
                    <div className={'my-pretty-chart-container'}>
                    <div className="smallcircleDiv">
                        <CircularProgressbar percentage={this.state.summarysl.LeveransPrecision} 
                        text={`${this.state.summarysl.LeveransPrecision}%`}  
                         strokeWidth={5}
                        // You can override styles either by specifying this "styles" prop,
                        // or by overriding the default CSS here:
                        // https://github.com/iqnivek/react-circular-progressbar/blob/master/src/styles.css
                        styles={{
                          // Customize the root svg element
                          root: {},
                          // Customize the path, i.e. the part that's "complete"
                          path: {
                            // Tweak path color:
                            stroke: this.state.slcolor,
                            // Tweak path to use flat or rounded ends:
                            strokeLinecap: 'butt',
                            // Tweak transition animation:
                            transition: 'stroke-dashoffset 0.5s ease 0s',
                          },
                          // Customize the circle behind the path
                          trail: {
                            // Tweak the trail color:
                            stroke: '#d6d6d6',
                          },
                          // Customize the text
                          text: {
                            // Tweak text color:
                            fill: this.state.slcolor,
                            // Tweak text size:
                            fontSize: '30px',
                          },
                        }}
                      />
                    </div>

                        {/* <Gauge value={this.state.summarysl.LeveransPrecision} width={300} height={150} color={this.state.slcolor} label="" /> */}
                    </div>
                </div>
                {/* <ul>
                    <li>Vi levererade totalt <b>{this.state.summarysl.AntalLeveranser}</b> ordrar</li>
                    <li><b>{this.state.summarysl.Lyckade}</b> ordrar levererades helt enligt plan.</li>
                    <li><b>{this.state.summarysl.Utebliven}</b> ordrar avbröts och levererades inte.</li>
                    <li><b>{this.state.summarysl.Fore}</b> ordrar levererades för tidigt</li>
                    <li><b>{this.state.summarysl.Efter}</b> ordrar levererades för sent</li>
                    <li><b>{this.state.summarysl.Tidig}</b> ordrar levererades före leveransintervallet (- 45 min)</li>
                    <li><b>{this.state.summarysl.Sen}</b> ordrar levererades efter leveransintervallet (+ 45 min)</li>
                </ul>
                <p>   Gruppens totala leveransprecision var <b>{this.state.summarysl.LeveransPrecision}%</b>.</p>
                <p>   Snittbetyget från kunden för denna körning är <b>{this.state.summarysl.AverageRating} / 5</b>.<br />
                    ({this.state.summarysl.Rating5} st 5:or, {this.state.summarysl.Rating4} st 4:or, {this.state.summarysl.Rating3} st 3:or, {this.state.summarysl.Rating2} st 2:or, {this.state.summarysl.Rating1} st 1:or)</p>
                <div className="dashboardleft"><p>Det var {this.state.summarysl["Kunder Som Följde Leveransen"]} kunder som följde leveransen.</p></div> */}
                <div className="dashboardright">
                    <Button href={this._buildLinkHrefSl()}
                        bsStyle="primary"
                        className="btn-margin"
                    >
                        Detaljer...
          </Button>
                </div>
                <div className="dashboardtoprightsmall">
                    <p><strong>{this.state.summarysl.AntalLeveranser} st</strong></p>
                </div>
            </div>
        );
    }
}

export default SlStockholm;