import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Message from './message';
import MessageCreator from './message-creator';
import css from '../App.css'


class ClientComment extends Component {
  constructor(props) {
    super(props);


    var user = localStorage.getItem('userProfileName');
    var messages = [];

    //   {id: 1, author: "Pete Hunt", text: "This is one comment", createdAt : new Date("2018-01-01")},
    //   {id: 2, author: "Jordan Walke", text: "This is *another* comment", createdAt : new Date("2018-02-03 11:00:22")}
    // ];
    this.state = {
      author: user,
      messages: messages
    };
  }
  intervalID = 0;

  componentDidMount() {

    this.loadCommentsFromServer();
    this.intervalID = setInterval(this.loadCommentsFromServer.bind(this), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.intervalID);
  }

  loadCommentsFromServer() {
    var data = [];
    var user = localStorage.getItem('user');
    var clientName = localStorage.getItem('clientName');

    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + '/clientcomments/' + clientName, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((dataJson) => {

          var comments = dataJson;
          for (let i = 0; i < comments.length; i++) {
            data.push({ id: comments[i]._id, author: comments[i].user, content: comments[i].content, createdAt: comments[i].created_at });
          }
          this.state.messages = data;
          this.setState({ messages: data });

        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }
  addMessage(newContent) {
    const messages = this.state.messages;
    messages.unshift({
      id: this.state.messages.length,
      author: this.state.author,
      content: newContent,
      createdAt: new Date()
    });

    var clientName = localStorage.getItem('clientName');

    var postbody = {
      'clientName': clientName,
      'content': newContent,
      'user': this.state.author
    };
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + '/clientcomments', {
        method: 'POST',
        body: JSON.stringify(postbody),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => {
          this.loadCommentsFromServer();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }

  render() {

    var mes = this.state.messages;
    return (
      <div>
        <p><strong>Interna kommentarer:</strong></p>
        <MessageCreator create={this.addMessage.bind(this)} />
        {
          mes.map(message => {
            return <Message
              author={message.author}
              content={message.content}
              createdAt={message.createdAt}
              key={message.id} />;
          })
        }
      </div>
    );
  }
}

export default ClientComment;