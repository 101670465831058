import {
    BrowserRouter as Router,
    Route,
    Link
} from 'react-router-dom';
import React, { Component } from 'react';




import HemkopDjur from './widgets/hemkopdjur';
import WillysAlvsjo from './widgets/willysalvsjo';
import WillysUmea from './widgets/willysumea';
import WillysVaxjo from './widgets/willysvaxjo';
import WillysGavle from './widgets/willysgavle';
import WillysJonkoping from './widgets/willysjonkoping';
import SlStockholm from './widgets/slstockholm';
import LinasMatkasse from './widgets/linasmatkasse';
import HighlightGordoneer from './widgets/highlightgordoneer';
import GordonTotal from './widgets/gordontotal';

import IcaTaby from './widgets/icastoptaby';
import IcaSjostaden from './widgets/icasjostaden';
import Wrapsons from './widgets/wrapsons';
import BottomDrivers from './widgets/bottomdrivers';
import TopDrivers from './widgets/topdrivers';
import RookieDrivers from './widgets/rookiedrivers';
import TotalComplaints from './widgets/totalcomplaints';
import TotalDeliveries from './widgets/totaldeliveries';
import TotalPrecision from './widgets/totalprecision';
import TotalStoppTimme from './widgets/totalstopptimme';
import PointDrivers from './widgets/pointdrivers';

import TotalComplaintsLastMonth from './widgets/totalcomplaintslastmonth';
import TotalDeliveriesLastMonth from './widgets/totaldeliverieslastmonth';
import TotalPrecisionLastMonth from './widgets/totalprecisionlastmonth';
import TotalStoppTimmeLastMonth from './widgets/totalstopptimmelastmonth';

import { Responsive, WidthProvider } from 'react-grid-layout';

import './overriden.css';

import { Button, MenuItem, DropdownButton, Panel, ToggleButton, ToggleButtonGroup, ButtonToolbar } from 'react-bootstrap';
import fscreen from 'fscreen';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

class DashBoard extends Component {
    constructor() {
        super();

    }

    tick() {
        this.setState({ secondsElapsed: this.state.secondsElapsed + 1 });
    }
    login() {
        this.props.auth.login();
    }
    componentWillMount() {

        this.setState({ secondsElapsed: 0 });

        const { userProfile, getProfile, isAuthenticated } = this.props.auth;
        if (isAuthenticated()) {

            if (!userProfile) {
                getProfile((err, profile) => {
                    this.setState({ profile });

                    localStorage.setItem('userProfileName', profile.name);
                });
            } else {
                this.setState({ profile: userProfile });


                localStorage.setItem('userProfileName', userProfile.name);
            }
        }
        this.setState({ apikey: process.env.REACT_APP_API_KEY });
        return {};
    }

    
    componentDidMount() {
        this.interval = setInterval(this.tick.bind(this), 1000);
    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }
    fullscreen() {
        var object = this.refs.divcontainer;
        if (fscreen.fullscreenElement === null) {
            fscreen.requestFullscreen(object);
        }
        else {
            fscreen.exitFullscreen();
        }
    }

    render() {
        const { isAuthenticated } = this.props.auth;

        var divStyle7 = {
            display: fscreen.fullscreenElement === null == '' ? 'none' : 'block'
          };
        var layout = [
            { i: 'b', x: 0, y: 0, w: 3, h: 8, static: true },
            { i: 'c', x: 3, y: 0, w: 3, h: 8, static: true },
            { i: 'd', x: 6, y: 0, w: 3, h: 8, static: true },
            { i: 'a', x: 0, y: 8, w: 3, h: 8, static: true },
            { i: 'e', x: 3, y: 8, w: 3, h: 8, static: true },
            { i: 'f', x: 6, y: 8, w: 3, h: 10, static: true }
        ];
        var layouts = { lg: layout, md: layout }

        var apikey = this.state.apikey;
        return (
            <div className="full" ref="divcontainer">
                {
                    isAuthenticated() && (
                        <div>

                            <Button onClick={this.fullscreen.bind(this)}
                                bsStyle="primary" style={divStyle7}
                                className="btn-margin"
                            >
                                Helskärm
                            </Button>
                            <ResponsiveReactGridLayout className="layout" layouts={layouts} rowHeight={30}
                                breakpoints={{ lg: 1800, md: 1200, sm: 768, xs: 480, xxs: 0 }}
                                cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}>

                                <div className="precision" key={'b'}>
                                    <TotalPrecision />
                                </div>

                                <div className="antal" key={'c'}>
                                    <TotalDeliveries />
                                </div>

                                <div className="complaints" key={'d'}>
                                    <TotalComplaints />
                                </div>

                                <div key={'a'}>

                                
                                </div>

                                <div key={'e'}>
                                    <GordonTotal />
                                </div>
                                <div key={'f'}>
                                    <HighlightGordoneer />
                                </div>

                                {/* <div key={'s'}>
                  <TotalStoppTimme />
                </div>
                <div key={'t'}>
                  <TotalStoppTimmeLastMonth />
                </div> */}


                                {/* <div key={'p'}>
                  <TotalPrecisionLastMonth />
                </div> */}
                                {/* <div key={'q'}>
                  <TotalDeliveriesLastMonth />
                </div> */}
                                {/* <div  key={'r'}>
                  <TotalComplaintsLastMonth />
                </div> */}

                                {/* <div key={'e'}>
                                    <SlStockholm />
                                </div>
                                <div key={'f'}>
                                    <LinasMatkasse />
                                </div>
                                <div key={'n'}>
                                    <RookieDrivers />
                                </div> */}
                                {/* 
                <div key={'n'}>
                  <IcaSjostaden />
                </div>
                <div key={'u'}>
                  <Paradiset />
                </div> */}
                                {/* <div key={'m'}>
                                    <Wrapsons />
                                </div>
                                <div key={'g'}>
                                    <HemkopDjur />
                                </div>
                                <div key={'h'}>
                                    <WillysAlvsjo />
                                </div>
                                <div key={'l'}>
                                    <WillysUmea />
                                </div>
                                <div key={'o'}>
                                    <WillysVaxjo />
                                </div>
                                <div key={'v'}>
                                    <WillysGavle />
                                </div>
                                <div key={'y'}>
                                    <WillysJonkoping />
                                </div>

                                <div key={'i'}>
                                    <TopDrivers />
                                </div>
                                <div key={'j'}>
                                    <BottomDrivers />
                                </div>
                                <div key={'k'}>
                                    <HighlightGordoneer />
                                </div>
                                <div key={'z'}>
                                    <PointDrivers />
                                </div> */}
                            </ResponsiveReactGridLayout>
                        </div>
                    )
                }
                {
                    !isAuthenticated() && (
                        <h4>
                            You're not logged in! Click {' '}
                            <a
                                style={{ cursor: 'pointer' }}
                                onClick={this.login.bind(this)}
                            >
                                Login
                  </a>
                            {' '}to continue.
                </h4>
                    )
                }
            </div>

        );
    }
}

export default DashBoard;

