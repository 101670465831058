import React from 'react';
import Orderlist from './orderList';


class Dailyloadlist extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            routes: []
        }
    }


    componentDidMount() {
        let locationState = { ...this.props.location.state }

        if (locationState.routes) {
            this.setState({ ...this.state, routes: locationState.routes });
        }
    }

    render() {
        let { routes } = this.state;

        let orderLists = routes.map((r) =><Orderlist route={r} />);


        return (
            <div className="container">
                {orderLists}
            </div>
        )
    }
}

export default Dailyloadlist;