import React, { Component } from 'react';
import Tabs from 'react-responsive-tabs';
import ParadisetCity from './paradisetcity';
import ParadisetSickla from './paradisetsickla';

class Paradiset extends Component {
  login() {
    this.props.auth.login();
  }

  componentWillMount() {
    
  }
  componentDidMount() {

  }

  render() {
    const { isAuthenticated } = this.props.auth;

    const ordersTabs = [
      { name: 'City', content: <ParadisetCity /> },
      { name: 'E-handel (Sickla)', content: <ParadisetSickla /> }
    ];

    function getTabs() {
      return ordersTabs.map(ordertab => ({
        tabClassName: 'tab', // Optional
        panelClassName: 'panel', // Optional
        title: ordertab.name,
        getContent: () => ordertab.content,
      }));
    }

    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
                  <Tabs items={getTabs()} />
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default Paradiset;

