import React, { Component } from 'react';
import ReactTable from "react-table";
import 'react-table/react-table.css'
import Workbook from 'react-excel-workbook';
import _ from "lodash";
import { Button } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import Select from 'react-select';
import moment from 'moment';
import PropTypes from "prop-types";
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

var clientsList = [];

class CustomerNotes extends Component {
  static contextTypes = {
    router: PropTypes.object
  }
  constructor(props, context) {
    super(props, context);
    this.handleChange = this.handleChange.bind(this);

  }
  login() {
    this.props.auth.login();
  }

  componentWillMount() {
    this.setState({ formattedValue: null, selectedmerchant: null });
    this.setState({ value: '' });
    this.setState({ clients: [] });
    this.setState({ rows: [] });
    this.setState({ originalrows: [] });

    this.fetchData = this.fetchData.bind(this);
  }
  componentDidMount() {

    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {
      var url = process.env.REACT_APP_GORDON_API_URL + "/client/active";

      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((resp) => resp.json())
        .then((dataJson) => {
          var clients = dataJson;
          clientsList = [];
          for (let i = 0; i < clients.length; i++) {
            clientsList.push({ value: clients[i].external_ref, label: clients[i].name });
          }
          const sorter = (a, b) => {
            if (a.label > b.label) return 1;
            if (a.label < b.label) return -1;
            return 0;
          };

          clientsList = clientsList.sort(sorter);

          this.state.clients = clientsList;
          this.setState({ clients: clientsList });
        });
    } else {
      console.error("401 Unauthorized");
    }
  }

  getNotes() {
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + '/customernote?date=' + moment(this.state.formattedValue).format("YYYY-MM-DD") + "&merchant=" + this.state.selectedmerchant, {
        method: 'get',
        headers: {
          'Authorization': 'Bearer ' + access_token,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((responseJson) => {
          responseJson.forEach(row => {
            row.deliveryDate = moment(row.deliveryDate).format("YYYY-MM-DD");
          });
          let rows = responseJson;
          this.state.rows = rows;
          this.setState({ rows: rows });
          this.state.originalrows = responseJson;
          this.setState({ originalrows: responseJson });

        })
        .catch((error) => {
          console.error(error);
        });

    } else {
      console.error("401 Unauthorized");
    }
  }
  fetchData(state, instance) {
    let filteredData = this.state.originalrows;
    if (state.filtered.length) {
      filteredData = state.filtered.reduce((filteredSoFar, nextFilter) => {
        return filteredSoFar.filter(row => {
          return (row[nextFilter.id] + "").includes(nextFilter.value);
        });
      }, filteredData);
    }
    // You can also use the sorting in your request, but again, you are responsible for applying it.
    const sortedData = _.orderBy(
      filteredData,
      state.sorted.map(sort => {
        return row => {
          if (row[sort.id] === null || row[sort.id] === undefined) {
            return -Infinity;
          }
          return typeof row[sort.id] === "string"
            ? row[sort.id].toLowerCase()
            : row[sort.id];
        };
      }),
      state.sorted.map(d => (d.desc ? "desc" : "asc"))
    );

    let rows = sortedData;
    this.state.rows = rows;
    this.setState({ rows: rows });
  }

  handleChange(value, formattedValue) {
    this.setState({
      value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
    });

  }

  onMerchantSelect(merchant) {
    if (merchant != null) {
      this.setState({
        selectedmerchant: merchant.label
      })
    }
    else {
      this.setState({
        selectedmerchant: null
      })
    }
  }


  render() {
    const { formattedValue, selectedmerchant } = this.state;
    const isEnabled = formattedValue != null && selectedmerchant != null;

    const columns = [{
      Header: 'Datum',
      accessor: 'deliveryDate'
    }, {
      Header: 'Kund',
      accessor: 'merchant'
    }, {
      Header: 'Order Id',
      accessor: 'external_ref'
    }, {
      Header: 'Kundnamn',
      accessor: 'fullName'
    }, {
      Header: 'Notering',
      accessor: 'note'
    }]

    const { isAuthenticated } = this.props.auth;

    var { clients } = this.state;
    if (clients == null) {
      clients = [];
    }

    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Kunduppdateringar</h3>
              <div className="summarySearch">
                <div className="datePicker">Datum: <DatePicker autoFocus={false} showTodayButton={true} todayButtonLabel="Idag" id="example-datepicker" value={this.state.value} dateFormat='YYYY-MM-DD' weekStartsOn={1} onChange={this.handleChange.bind(this)}
                  monthLabels={['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']}
                  dayLabels={['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör']} />
                </div>

                <div className="dropdownSearch">

                  <Select placeholder="Välj kund/butik"
                    name="ddSelectMerchant"
                    onChange={this.onMerchantSelect.bind(this)}
                    options={clients}
                  />
                </div>

                <div className="buttonSearch">
                  <Button
                    bsStyle="primary"
                    className="btn-margin" disabled={!isEnabled}
                    onClick={this.getNotes.bind(this)}>Sök</Button>
                </div>
              </div>
              <br />
              <br />
              <Workbook filename="kunduppdateringar.xlsx" element={<button className="btn btn-primary">Exportera till Excel</button>}>
                <Workbook.Sheet data={() => this.state.rows} name="Kunduppdateringar">
                  <Workbook.Column label="Datum" value="deliveryDate" />
                  <Workbook.Column label="Kund" value="merchant" />
                  <Workbook.Column label="Order Id" value="external_ref" />
                  <Workbook.Column label="Kund namn" value="fullName" />
                  <Workbook.Column label="Notering" value="note" />
                </Workbook.Sheet>
              </Workbook>
              <br />
              <ReactTable
                filterable={true}
                data={this.state.rows}
                defaultSorted={[{
                  id: 'fullName',
                  desc: false,
                }]}
                onFetchData={this.fetchData}
                columns={columns}
                defaultPageSize={200} />
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default CustomerNotes;