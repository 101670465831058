import React, { Component } from 'react';
import { Chart } from 'react-google-charts';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Button, Modal, FormControl } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
import Select from 'react-select';
import PropTypes from "prop-types";
import Switch from "react-switch";
const queryString = require('query-string');

var data;
var complaints;

var categoryList = [];

class Matkasse extends Component {
  static contextTypes = {
    router: PropTypes.object
  }

  constructor(props, context) {
    super(props, context);

    this.state = {

      selected: [],
      donutrows: [['Typ', "Procent"]],
      author: '',
      selecteduser: null,
      data: [],
      complaints: {},

      showError: false,
      showInfo: false,

      summary: { Driver: '' },
      rubrik: '',

      formattedValue: null,
      formattedValueComplaint: null,
      value: '',
      valueComplaint: '',
      showModal: false,
      drivers: [],
      categories: [],
      clientname: [],

      comment: null,
      vehicle: null,
      routename: null,
      stopnumber: null,
      endcustomer: null,
      cost: null,
      time: null,
      drivercomment: null,

      driverinformed: false,
      visibleForClient: false,
      isPoint: false,
      gordonIsResponsible: true,
      merchant: '',

    }
    this.createComplaint = this.createComplaint.bind(this);
    this.cancel = this.cancel.bind(this);
    this.open = this.open.bind(this);
  }

  login() {
    this.props.auth.login();
  }
  componentWillMount() {

    var user = localStorage.getItem('userProfileName');

    this.setState({ ...this.state, author: user });
  }

  open() {
    this.setState({ ...this.state, showModal: true });
  }
  cancel() {

    this.setState({
      ...this.state,
      comment: null,
      vehicle: null,
      routename: null,
      stopnumber: null,
      endcustomer: null,
      cost: null,
      time: null,
      drivercomment: null,
      driverinformed: false,
      visibleForClient: false,
      isPoint: false,
      gordonIsResponsible: true,
      value: null,
      formattedValue: null,
      selectedCategory: null,
      selectedClient: null,
      selectedGordoneer: null,
      selectedId: null,
      showModal: false
    });

  }

  handleOnSelect = (row, isSelect) => {
    let selected = [];
    if (isSelect) {
      if (this.state.selected != null) {
        selected = this.state.selected;
      }
      this.setState(() => ({
        selected: [...selected, row.Driver]
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== row.Driver)
      }));
    }
  };

  handleOnSelectAll = (isSelect, rows) => {
    let ids = rows.map(r => r.Driver);

    if (isSelect) {
      return this.setState({ ...this.state, selected: ids });
    }

    this.setState({ ...this.state, selected: [] });

  }

  updateComplaint() {
    let updatebody = {
      'id': this.state.selectedId,
      'drivername': this.state.selecteduser,
      'deliverydate': this.state.formattedValue,
      'client': this.state.clientname,
      'category': this.state.selectedCategory,
      'comment': this.state.comment,
      'vehicle': this.state.vehicle,
      'routename': this.state.routename,
      'stopnumber': this.state.stopnumber == "" ? null : this.state.stopnumber,
      'endcustomer': this.state.endcustomer,
      'cost': this.state.cost == "" ? null : this.state.cost,
      'time': this.state.time == "" ? null : this.state.time,
      'driverinformed': this.state.driverinformed,
      'drivercomment': this.state.drivercomment,
      'gordonIsResponsible': this.state.gordonIsResponsible,
      'visibleForClient': this.state.visibleForClient,
      'isPoint': this.state.isPoint
    };

    let access_token = localStorage.getItem('access_token');

    if (!access_token) {
      return console.error("401 Unauthorized");
    }

    fetch(process.env.REACT_APP_GORDON_API_URL + '/complaints/update', {
      method: 'PUT',
      body: JSON.stringify(updatebody),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access_token,
      }
    })
      .then((response) => {

        this.setState({
          showModal: false,
          driverinformed: false,
          visibleForClient: false,
          isPoint: false,
          gordonIsResponsible: true,
          selectedCategory: null,
          selecteduser: null,
          selectedId: null,
          comment: null,
          vehicle: null,
          routename: null,
          stopnumber: null,
          endcustomer: null,
          cost: null,
          time: null,
          drivercomment: null
        });

        this.getStats(this.state.formattedValue);
      })
      .catch((error) => {
        console.error(error);
      });

  }

  createComplaint() {
    if (this.state.selectedId != null) {
      this.updateComplaint();
    }
    else {

      var postbody = {
        'drivername': this.state.selecteduser,
        'deliverydate': this.state.formattedValue,
        'client': this.state.clientname,
        'category': this.state.selectedCategory,
        'comment': this.state.comment,
        'vehicle': this.state.vehicle,
        'routename': this.state.routename,
        'stopnumber': this.state.stopnumber,
        'endcustomer': this.state.endcustomer,
        'cost': this.state.cost,
        'time': this.state.time,
        'driverinformed': this.state.driverinformed,
        'drivercomment': this.state.drivercomment,
        'gordonIsResponsible': this.state.gordonIsResponsible,
        'visibleForClient': this.state.visibleForClient,
        'isPoint': this.state.isPoint,
        'created_by': this.state.author
      };
      var access_token = localStorage.getItem('access_token');
      if (!access_token) {
        return console.error("401 Unauthorized");
      }

      fetch(process.env.REACT_APP_GORDON_API_URL + '/complaints', {
        method: 'POST',
        body: JSON.stringify(postbody),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => {

          this.setState({
            ...this.state,
            showModal: false,
            driverinformed: false,
            visibleForClient: false,
            isPoint: false,
            gordonIsResponsible: true,
            selectedCategory: null,
            selecteduser: null,
            selectedId: null,
            comment: null,
            vehicle: null,
            routename: null,
            stopnumber: null,
            endcustomer: null,
            cost: null,
            time: null,
            drivercomment: null
          });

          this.getStats(this.state.formattedValue);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  getStats(date) {
    let donutrows = [];
    donutrows.push(['Typ', "Procent"]);

    var access_token = localStorage.getItem('access_token');
 
    if (!access_token) {
      return console.error("401 Unauthorized");
    }

    let rubrik = "Matkasseflödet - " + date;

    let driverurl = process.env.REACT_APP_GORDON_API_URL + '/order/mkfstatsbydateanddriver?merchant=' + 'mkf' + '&date=' + date;
    let statsurl = process.env.REACT_APP_GORDON_API_URL + '/order/mkfstatsbydate?merchant=' + 'mkf' + '&date=' + date;

    this.setState({
      ...this.state,
      rubrik: rubrik
    })

    fetch(driverurl, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
    }).then((response) => response.json())
      .then((dataJson) => {

        data = dataJson;

        var drivers = [];
        for (let i = 0; i < data.length; i++) {
          drivers.push({ value: data[i].Driver, label: data[i].Driver });
        }

        this.setState({...this.state, drivers: drivers, data:data });

        fetch(statsurl, {
          method: 'GET',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + access_token,
          },
        })
          .then((resp) => resp.json())
          .then((dataJson) => {

            var summary = dataJson;

            var antalEjLyckade = 0;
            if (summary.length > 0) {
             
              antalEjLyckade = summary[0].avvikelser;
              summary[0].Antal = summary[0].antal;
          
              donutrows.push(["Inom leveransfönster", summary[0].Lyckade]);
              donutrows.push(["Utanför leveransfönster", antalEjLyckade]);

              this.setState({ ...this.state, summary: summary[0], donutrows: donutrows });
            }

          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  getStatsClick() {
    this.getStats( this.state.formattedValue);
  }

  toggleResponsibleChange = () => {
    this.setState({
      gordonIsResponsible: !this.state.gordonIsResponsible,
    });
  }

  toggleVisibleChange = () => {
    this.setState({
      visibleForClient: !this.state.visibleForClient,
    });
  }
  toggleInformedChange = () => {
    this.setState({
      driverinformed: !this.state.driverinformed,
    });
  }
  toggleIsPointChange = () => {
    this.setState({
      isPoint: !this.state.isPoint,
    });
  }

  componentDidMount() {

    var access_token = localStorage.getItem('access_token');

    if (!access_token) {
      return console.error("401 Unauthorized");
    }
    var url = process.env.REACT_APP_GORDON_API_URL + "/complaints/complaintcategories";

    fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + access_token,
      },
    })
    .then((resp) => resp.json())
    .then((dataJson) => {
      var categories = dataJson;
      categoryList = [];
      for (let i = 0; i < categories.length; i++) {
        categoryList.push({ value: categories[i].description, label: categories[i].description });
      }

      this.setState({ ...this.state, categories: categoryList });

    })
    .catch((error) => {
      console.error(error);
    });
  }

  handleChange(value, formattedValue) {
    this.setState({
      value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      formattedValue: formattedValue // Formatted String, ex: "11/19/2016"
    });

  }
  handleChangeComplaint(valueComplaint, formattedValueComplaint) {
    this.setState({
      valueComplaint: valueComplaint, // ISO String, ex: "2016-11-19T12:00:00.000Z"
      formattedValueComplaint: formattedValueComplaint // Formatted String, ex: "11/19/2016"
    });

  }

  openevent(row) {
    this.setState({
      selecteduser: row.drivername,
      formattedValue: row.deliverydate,
      value: new Date(row.deliverydate).toISOString(),
      selectedCategory: row.category,
      driverinformed: row.driverinformed,
      gordonIsResponsible: row.gordonIsResponsible == "Ja" ? true : row.gordonIsResponsible == "Nej" ? false : row.gordonIsResponsible,
      visibleForClient: row.visibleForClient,
      isPoint: row.isPoint,
      comment: row.comment,
      vehicle: row.vehicle,
      routename: row.routename,
      stopnumber: row.stopnumber,
      endcustomer: row.endcustomer,
      cost: row.cost,
      time: row.time,
      drivercomment: row.drivercomment,
      selectedId: row._id
    });
    this.setState({
      showModal: true
    });
  }

  gordoneerFormatter(cell, row) {
    const link = 'gordoneer?name=' + cell;
    return ' <a href="' + link + '">' + cell + '</a>';
  }

  eventFormatter(cell, row) {
    return <Button
      bsStyle="primary"
      className="btn-margin eventbtn"
      onClick={this.openevent.bind(this, row)}>Öppna...</Button>
  }

  


ratingFormatter(cell, row) {
  function renderDriverRating(rating) {
    let stars = [];
    let diff = 5 - rating;
    let style = {};
    if(rating >= 4) {
       style = { margin: "0 2px", color : "green" }
    }
    else if(rating >= 3) {
      style = { margin: "0 2px", color : "orange" }
   }
    else {
      style = { margin: "0 2px", color : "red" }
    }
   

    for (let i = 0; i < Math.floor(rating); i++) {
      stars = stars.concat([<i key={i} style={style} className="fas fa-star"></i>]);
    }
    if(rating % 1.0 > 0) {
      stars = stars.concat([<i key={rating.length} style={style} className="fas fa-star-half-alt"></i>]);
      diff = diff - (1 - (rating % 1.0));
    }
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        stars = stars.concat([<i key={(`diff_${i}`)} style={style} className="far fa-star"></i>]);
      }
    }

    return stars;
  }
  

      return   <span style={{ userSelect: "none" }} title={'Snittbetyg: ' + (cell != null ? cell : '-')}>
      {
        renderDriverRating(cell)
      }
    </span>;
  }
  gordoneerOrderFormatter(cell, row) {
    
    const link = 'orders?user=' + cell + "&date=" + (row.Datum != null ? row.Datum : row.datum) // + "&merchant=" + (row.Merchant != null ? row.Merchant : row.kund);
    return ' <a href="' + link + '">Visa ordrar</a>';
  }
  gordoneerAssignedOrderFormatter(cell, row) {
    const link = 'assignedorders?user=' + cell + "&date=" + (row.Datum != null ? row.Datum : row.datum) // + "&merchant=" + (row.Merchant != null ? row.Merchant : row.kund);
    return ' <a href="' + link + '">Visa ordrar</a>';
  }
  hideInfoModal() {
    this.setState({ showInfo: false });
  }
  hideModal() {
    this.setState({ showError: false });
  }

  feedback() {
    if (this.state.selected.length == 0) {
      this.setState({ showError: true });
    }
    else {
      for (let i = 0; i < this.state.selected.length; i++) {

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {
          var row = null;
          for (let j = 0; j < this.state.data.length; j++) {
            if (data[j].Driver == this.state.selected[i]) {
              row = data[j];
            }
          }
          if (row != null) {

            var snitt = (((row.Rating5 * 5) + (row.Rating4 * 4) + (row.Rating3 * 3) + (row.Rating2 * 2) + (row.Rating1 * 1)) / (row.Ratings * 5)) * 5;
            snitt = Math.round(snitt * 100) / 100;

            var totalsnitt = (((this.state.summary.Rating5 * 5) + (this.state.summary.Rating4 * 4) + (this.state.summary.Rating3 * 3)
              + (this.state.summary.Rating2 * 2) + (this.state.summary.Rating1 * 1)) / (this.state.summary.Ratings * 5)) * 5;
            totalsnitt = Math.round(totalsnitt * 100) / 100;

            var postbody = {
              'drivername': this.state.selected[i],
              'deliverydate': this.state.formattedValue,
              'client': this.state.clientname,
              'antal': row.Antal,
              'lyckade': row.Lyckade,
              'utebliven': row.Utebliven,
              'leveransprecision': row.LeveransPrecision,
              'dontcount': row.DontCount,
              'rating1': row.Rating1,
              'rating2': row.Rating2,
              'rating3': row.Rating3,
              'rating4': row.Rating4,
              'rating5': row.Rating5,
              'ratingssnitt': snitt,
              'totalantal': this.state.summary.Antal,
              'totallyckade': this.state.summary.Lyckade,
              'totalutebliven': this.state.summary.Utebliven,
              'totaldontcount': this.state.summary.DontCount,
              'totalleveransprecision': this.state.summary.precision,
              'totalrating1': this.state.summary.Rating1,
              'totalrating2': this.state.summary.Rating2,
              'totalrating3': this.state.summary.Rating3,
              'totalrating4': this.state.summary.Rating4,
              'totalrating5': this.state.summary.Rating5,
              'totalratingssnitt': totalsnitt,
            };

            var url = process.env.REACT_APP_GORDON_API_URL + '/order/feedback/';

            fetch(url, {
              method: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + access_token,
              },
              body: JSON.stringify(postbody)
            })
              .then((response) => {

                this.setState({ showInfo: true });
                this.setState({ selected: [] });

              })
              .catch((error) => {
                console.error(error);
              });

          }
        } else {
          console.error("401 Unauthorized");
        }
      }
    }
  }


  commentChanged(evt) {
    const comment = evt.target.value;
    this.setState({ comment });
  }
  vehicleChanged(evt) {
    const vehicle = evt.target.value;
    this.setState({ vehicle });
  }
  routenameChanged(evt) {
    const routename = evt.target.value;
    this.setState({ routename });
  }

  stopnumberChanged(evt) {
    const stopnumber = evt.target.value;
    this.setState({ stopnumber });
  }
  endcustomerChanged(evt) {
    const endcustomer = evt.target.value;
    this.setState({ endcustomer });
  }
  costChanged(evt) {
    const cost = evt.target.value;
    this.setState({ cost });
  }
  timeChanged(evt) {
    const time = evt.target.value;
    this.setState({ time });
  }
  drivercommentChanged(evt) {
    const drivercomment = evt.target.value;
    this.setState({ drivercomment });
  }

  onGordoneerSelect(gordoneer) {
    if (gordoneer != null) {
      this.setState({
        selecteduser: gordoneer.label
      });
    }
    else {
      this.setState({
        selecteduser: null
      })
    }
  }


  onCategorySelect(category) {
    if (category != null) {
      this.setState({
        selectedCategory: category.label
      });
    }
    else {
      this.setState({
        selectedCategory: null
      })
    }
  }

  onAfterDeleteRow(rowKeys) {
    for (let i = 0; i < rowKeys.length; i++) {
      var access_token = localStorage.getItem('access_token');
      if (access_token != null) {
        var url = process.env.REACT_APP_GORDON_API_URL + '/complaints/delete/' + rowKeys[i];
        fetch(url, {
          method: 'DELETE',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + access_token,
          },
        })
          .then((response) => {

            this.getStats(this.state.formattedValue);
          })
          .catch((error) => {
            console.error(error);
          });
      } else {

        console.error("401 Unauthorized");
      }
    }
  }

  render() {
    const { isAuthenticated } = this.props.auth;

    const isVisible = this.state.donutrows != null && this.state.donutrows.length > 1;
    const isGridVisible = this.state.data != null && this.state.data.length > 0;
    const { formattedValue } = this.state;
    const isEnabled = formattedValue != null;

    const isEnabledCompaint = formattedValue != null
      && this.state.selectedCategory != null && this.state.selecteduser != null;

    var { categories, drivers, data } = this.state;
    if (categories == null) {
      categories = [];
    }
    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: true
    };

    const options = {
      afterDeleteRow: this.onAfterDeleteRow.bind(this)
    };


    function format(cell, row) {
      return cell != null ? (cell + " kr") : "";
    }

    function timeformat(cell, row) {
      return cell != null ? (cell + " min") : "";
    }

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      selected: this.state.selected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll
    };

    
    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Matkasseflödet</h3>

              <div className="summarySearch">
                <h4>Hämta statistik för en specifik dag</h4>
                <div className="datePicker">Datum: <DatePicker autoFocus={false} showTodayButton={true} todayButtonLabel="Idag" id="example-datepicker" value={this.state.value} dateFormat='YYYY-MM-DD' weekStartsOn={1} onChange={this.handleChange.bind(this)}
                  monthLabels={['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']}
                  dayLabels={['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör']} />
                </div>
                <div className="buttonSearch">
                  <Button
                    bsStyle="primary"
                    className="btn-margin" disabled={!isEnabled}
                    onClick={this.getStatsClick.bind(this)}>Sök</Button>
                  <span>{this.state.errormessage}</span>
                </div>
              </div>

              <h3>{this.state.rubrik}</h3>
              {
                isVisible && (
                  <div>
                    <div>
                      <div className="deliveryleft">
                      
                            <div>
                              <p>Uppdraget var att leverera totalt <b>{this.state.summary.antal}</b> ordrar</p>
                              <p><b>{this.state.summary.Lyckade}</b> ordrar levererades helt enligt plan.</p>
                              <p><b>{this.state.summary.tidig}</b> ordrar levererades före leveransintervallet.</p>
                              <p><b>{this.state.summary.sen}</b> ordrar levererades efter leveransintervallet.</p>
                              <p><b>{this.state.summary.Utebliven}</b> ordrar avbröts och levererades inte.</p>
                              <p><b>{this.state.summary.Kundejhemma}</b> kunder var inte hemma vid leverans.</p>
                              <p><b>{this.state.summary.Komejin}</b> ordrar levererades inte eftersom föraren inte kom fram till dörr (BOM).</p>
                              <p><b>{this.state.summary.Kundavbokade}</b> kunder avbokade själv sin leverans.</p>
                              <p>Gruppens totala leveransprecision var <b>{this.state.summary.precision}%</b>.</p>
                              <p>Snittbetyget från kunden för denna körning är <b>{this.state.summary.averagerating} / 5</b>.
                              (Antal:{this.state.summary.Rating5 + this.state.summary.Rating4 + this.state.summary.Rating3 + this.state.summary.Rating2 + this.state.summary.Rating1}) <br />(
                                ({this.state.summary.Rating5} st 5:or, {this.state.summary.Rating4} st 4:or, {this.state.summary.Rating3} st 3:or, {this.state.summary.Rating2} st 2:or, {this.state.summary.Rating1} st 1:or)</p>

                            </div>
                         
                      </div>
                      <div className="deliveryright">
                        <Chart
                          chartType="PieChart"
                          data={this.state.donutrows}
                          options={{ is3D: true, pieHole: '0.7', backgroundColor: '#ffffff', colors: ['#04951E', '#1969CC', '#FE9900', '#9D1599', '#E33D0D'] }}
                          graph_id="PieChart"
                          width="100%"
                          legend_toggle
                        />

                      </div>
                    </div>
                    {
                      isGridVisible && (
                        <div>
                          <div className="deliverylefttable">
                            <h3>Gordoneerer</h3>
                            <div className="floatRight">

                              <Button
                                bsStyle="primary"
                                className="btn-margin btn-padding marginleft"
                                onClick={this.feedback.bind(this)}>Skicka feedback</Button>
                            </div>
                            
                            <BootstrapTable style={{'font-size' : '8px'}} data={data} striped={true} hover={true} selectRow={selectRow} >
                              <TableHeaderColumn width='220' dataField='Driver' dataFormat={this.gordoneerFormatter} isKey>Gordoneer</TableHeaderColumn>
                              <TableHeaderColumn width='250' dataField='RouteName'>Rutt</TableHeaderColumn>
                              <TableHeaderColumn dataField='LeveransPrecision'>Precision %</TableHeaderColumn>
                              <TableHeaderColumn dataField='AntalLeveranser'>Tilldelade</TableHeaderColumn>
                           
                                  <TableHeaderColumn dataField='Completed'>Genomförda</TableHeaderColumn>
                            
                                  <TableHeaderColumn dataField='Lyckade'>Lyckade</TableHeaderColumn>
                             
                                  <TableHeaderColumn dataField='Fore'>För tidigt</TableHeaderColumn>
                              
                                  <TableHeaderColumn dataField='Efter'>För sent</TableHeaderColumn>
                            
                              <TableHeaderColumn dataField='Utebliven'>Utebliven</TableHeaderColumn>

                                  {/* <TableHeaderColumn dataField='Rating5'>Betyg 5</TableHeaderColumn>
                             
                                  <TableHeaderColumn dataField='Rating4'>Betyg 4</TableHeaderColumn>
                             
                                  <TableHeaderColumn dataField='Rating3'>Betyg 3</TableHeaderColumn>
                             
                                  <TableHeaderColumn dataField='Rating2'>Betyg 2</TableHeaderColumn>
                              
                                  <TableHeaderColumn dataField='Rating1'>Betyg 1</TableHeaderColumn>*/}
                                  <TableHeaderColumn width='120' dataField='averagerating' dataFormat={this.ratingFormatter}>Snittbetyg</TableHeaderColumn> 
                           
                              <TableHeaderColumn dataField='DontCount'>Exkluderade, gods ej klart för lastning</TableHeaderColumn>
                              <TableHeaderColumn dataField='DontCountHelp'>Exkluderade, hjälpte Gordoneer</TableHeaderColumn>
                              <TableHeaderColumn dataField='Kundavbokning'>Kund avbokade</TableHeaderColumn>
                              <TableHeaderColumn dataField='Kundejhemma'>Kund inte hemma</TableHeaderColumn>
                              <TableHeaderColumn width='100' dataField='Driver' dataFormat={this.gordoneerOrderFormatter}>Ordrar</TableHeaderColumn>
                             
                                  <TableHeaderColumn width='200' dataField='Driver' dataFormat={this.gordoneerAssignedOrderFormatter}>Ännu ej levererade ordrar</TableHeaderColumn>
                            
                            </BootstrapTable>
                          </div>
                          <br />
                          <br />
                         
                          <div >
                            <h3>Händelselogg</h3>
                            <Button
                              bsStyle="primary"
                              className="btn-margin btn-padding"
                              onClick={this.open.bind(this)}>Lägg till...</Button>
                            <br /><br />
                            <BootstrapTable data={complaints} striped={true} hover={true} options={options} deleteRow search selectRow={selectRowProp} >
                              <TableHeaderColumn width='1' dataField='_id' hidden isKey>ID</TableHeaderColumn>
                              <TableHeaderColumn width='220' columnTitle dataField='drivername' dataFormat={this.gordoneerFormatter}>Gordoneer</TableHeaderColumn>
                              {/* <TableHeaderColumn dataField='vehicle' columnTitle>Fordon</TableHeaderColumn> */}
                              <TableHeaderColumn dataField='category' columnTitle>Orsak</TableHeaderColumn>
                              {/* <TableHeaderColumn dataField='routename' columnTitle>Rutt/Zon</TableHeaderColumn> */}
                              {/* <TableHeaderColumn dataField='stopnumber' columnTitle>Ordernr/Stoppnr</TableHeaderColumn> */}
                              {/* <TableHeaderColumn dataField='endcustomer' columnTitle>Slutkund</TableHeaderColumn> */}
                              <TableHeaderColumn dataField='comment' columnTitle>Beskrivning</TableHeaderColumn>
                              <TableHeaderColumn dataField='cost' dataAlign="center" dataFormat={format} >Kostnad</TableHeaderColumn>
                              <TableHeaderColumn dataField='time' dataAlign="center" dataFormat={timeformat} >Tid</TableHeaderColumn>
                              {/* <TableHeaderColumn dataField='drivercomment' columnTitle >Kommentar från Gordoneer</Tab leHeaderColumn> */}
                              <TableHeaderColumn dataField='created_at' columnTitle editable={false}>Skapad</TableHeaderColumn>
                              <TableHeaderColumn dataField='created_by' columnTitle editable={false}>Skapad av</TableHeaderColumn>
                              <TableHeaderColumn width='100' dataField='_id' dataFormat={this.eventFormatter.bind(this)}>Öppna</TableHeaderColumn>

                              {/* <TableHeaderColumn dataField='created_at' columnTitle editable={false}>Uppdaterad</TableHeaderColumn> */}
                              {/* <TableHeaderColumn dataField='updated_by' columnTitle editable={false}>Uppdaterad av</TableHeaderColumn> */}
                            </BootstrapTable>
                          </div>
                        </div>
                      )
                    }
                    <br />
                    <br />
                  </div>
                )
              }
              {
                !isVisible && (
                  <p>Leveranstillfälle saknas för vald kund och datum.</p>
                )
              }

              <Modal bsSize="large" show={
                this.state.showModal
              }
                onHide={
                  this.cancel.bind(this)
                } dimmer={"blurring"}>
                <Modal.Header >
                  <Modal.Title>{this.state.selectedId != null ? 'Uppdatera händelse' : 'Lägg till händelse'}</Modal.Title> </Modal.Header >
                <Modal.Body  >
                  <div className="row overview">
                    <div className="col-md-4 user-pad text-left">
                      <div className="datePicker paddingmore">
                        <p>Gordoneer: *</p>

                        <div className="dropdownEventSearch">

                          <Select placeholder="Välj Gordoneer"
                            name="ddSelectGordoneer"
                            value={this.state.selecteduser}
                            onChange={this.onGordoneerSelect.bind(this)}
                            options={drivers}
                          />
                        </div>
                      </div>

                    </div>
                    <div className="col-md-4 user-pad text-left">
                      <div className="datePicker paddingmore">
                        <p>Orsak: *</p>

                        <div className="dropdownEventSearch">

                          <Select placeholder="Välj orsak"
                            name="ddSelectKategori"
                            value={this.state.selectedCategory}
                            onChange={this.onCategorySelect.bind(this)}
                            options={categories}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <br />
                  <div className="row overview">
                    <div className="col-md-4 user-pad text-left">
                      <p>Tid i min ('Sen till pass', 'Gods ej klar' etc):</p>
                      <FormControl type="number" onInput={this.timeChanged.bind(this)} value={this.state.time}
                        placeholder='Tid i minuter' />
                    </div>
                    <div className="col-md-4 user-pad text-left">
                      <p>Fordon:</p>
                      <FormControl onInput={this.vehicleChanged.bind(this)} value={this.state.vehicle}
                        placeholder='t ex registreringsnummer' />
                    </div>
                    <div className="col-md-4 user-pad text-left">
                      <p>Namn på rutt,zon, etc:</p>
                      <FormControl onInput={this.routenameChanged.bind(this)} value={this.state.routename}
                        placeholder='Ruttnamn' />
                    </div>
                  </div>
                  <br />
                  <div className="row overview">
                    <div className="col-md-4 user-pad text-left">
                      <p>Kostnad för Gordon Delivery:</p>
                      <FormControl type="number" onInput={this.costChanged.bind(this)} value={this.state.cost}
                        placeholder='Kostnad i kr' />
                    </div>
                    <div className="col-md-4 user-pad text-left">
                      <p>Ordernr/Stoppnr:</p>
                      <FormControl type="number" onInput={this.stopnumberChanged.bind(this)} value={this.state.stopnumber}
                        placeholder='Ordernr/Stoppnr' />
                    </div>
                    <div className="col-md-4 user-pad text-left">
                      <p>Slutkundens namn:</p>
                      <FormControl onInput={this.endcustomerChanged.bind(this)} value={this.state.endcustomer}
                        placeholder='Slutkund/Stoppnumer' />
                    </div>
                  </div>

                  <p>Beskrivning:</p>
                  <FormControl onInput={this.commentChanged.bind(this)} value={this.state.comment}
                    placeholder="Beskrivning av händelsen" />
                  <br />
                  <div className="row overview">
                    <div className="col-md-4 user-pad text-left">
                      <p>Klassa som Prick (Point System):</p>
                      <Switch
                        onChange={this.toggleIsPointChange}
                        checked={this.state.isPoint}
                        id="normal-switch"
                      />
                    </div>
                    <div className="col-md-4 user-pad text-left">
                      <p>Är Gordon ansvarig för händelsen?:</p>
                      <Switch
                        onChange={this.toggleResponsibleChange}
                        checked={this.state.gordonIsResponsible}
                        id="normal-switch"
                      />
                    </div>
                    <div className="col-md-4 user-pad text-left">
                      <p>Synlig för kund:</p>
                      <Switch
                        onChange={this.toggleVisibleChange}
                        checked={this.state.visibleForClient}
                        id="normal-switch"
                      />
                    </div>
                  </div>
                  <br />
                  <p>Informerat Gordoneer:</p>

                  <Switch
                    onChange={this.toggleInformedChange}
                    checked={this.state.driverinformed}
                    id="normal-switch"
                  />
                  <br />
                  <p>Kommentar från Gordoneer:</p>
                  <FormControl onInput={this.drivercommentChanged.bind(this)} value={this.state.drivercomment}
                    placeholder="Kommentar från Gordoneer:" />
                  <br />
                </Modal.Body>
                <Modal.Footer>
                  <Button negative onClick={this.cancel.bind(this)}>
                    Avbryt</Button>
                  <Button positive
                    bsStyle="success"
                    onClick={this.createComplaint.bind(this)} disabled={!isEnabledCompaint} >{this.state.selectedId != null ? 'Uppdatera' : 'Spara'}</Button>
                </Modal.Footer>
              </Modal>

              <Modal
                {...this.props}
                show={this.state.showError}
                onHide={this.hideModal.bind(this)}
                dialogClassName="custom-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-lg">Ooops...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4>Du måste markera minst en order i listan</h4>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.hideModal.bind(this)}>Stäng</Button>
                </Modal.Footer>
              </Modal>

              <Modal
                {...this.props}
                show={this.state.showInfo}
                onHide={this.hideInfoModal.bind(this)}
                dialogClassName="custom-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-lg">Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4>Gordoneererna är informerade!</h4>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.hideInfoModal.bind(this)}>Stäng</Button>
                </Modal.Footer>
              </Modal>

            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default Matkasse;