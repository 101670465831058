import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import moment from 'moment';
import 'moment/locale/sv';

const queryString = require('query-string');
var categoryList = [];
var data;

class Orders extends Component {

  constructor() {
    super();
    this.state = {
      data: [],
      showSms: false,
      msg: null,
      selected: [],
      selectedstatus: 'Done',
      ismulti: false,
      title: '',
      showError: false,
    }
    this.handleDropDownChange = this.handleDropDownChange.bind(this);
  }

  handleDropDownChange(event) {
    this.setState({ selectedstatus: event.target.value });
  }

  login() {
    this.props.auth.login();
  }

  cancel() {
    this.setState({
      showSms: false
    });
  }

  msgChanged(evt) {
    const msg = evt.target.value;
    this.setState({ msg });
  }


  componentWillMount() {
    categoryList = [];
    categoryList.push('Done');
    categoryList.push('Cancelled');
    categoryList.push('Kund avbokade');
    categoryList.push('Kund var inte hemma');
    categoryList.push('Kom inte in i port');
    categoryList.push('Avbruten leverans pga gods saknas');
    categoryList.push('Exkludera, Gods ej klar för lastning');
    categoryList.push('Exkludera, hjälpte Gordoneer');
    categoryList.push('Tekniskt fel');
  }


  handleOnSelect = (row, isSelect) => {
    if (isSelect) {
      this.setState(() => ({
        selected: [...this.state.selected, row.OrderId]
      }));
    } else {
      this.setState(() => ({
        selected: this.state.selected.filter(x => x !== row.OrderId)
      }));
    }
  }

  handleOnSelectAll = (isSelect, rows) => {
    const ids = rows.map(r => r.OrderId);
    if (isSelect) {
      this.setState(() => ({
        selected: ids
      }));
    } else {
      this.setState(() => ({
        selected: []
      }));
    }
  }

  getOrders() {
    var parsed = queryString.parse(this.props.location.search);

    if (!parsed.date || !parsed.user) {
      return
    }

    let user = parsed.user;
    let merchant = parsed.merchant;
    let date = parsed.date;

    let title;


    let getURL = (client) => {
      if (!client) {
        return process.env.REACT_APP_GORDON_API_URL + '/order/ordersbydateanddriver?date=' + date + '&driver=' + user;
      }

      return process.env.REACT_APP_GORDON_API_URL + '/order/ordersbydateanddriver?merchant=' + client.name + '&date=' + date + '&driver=' + user;
    }

    let fetchOrders = (client) => {

      var url = '';
      if (client) {
        url = getURL(client);
      } else {
        url = getURL();
      }


      fetch(url, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((dataJson) => {
          let data;

          this.setState({ ismulti: true });
          data = dataJson;


          for (let i = 0; i < data.length; i++) {

            if (data[i].TimeArrived != undefined) {
              let PromisedTime = moment(data[i].PromisedTime);
              let TimeArrived = moment(data[i].TimeArrived);
              let TimeDelivered = moment(data[i].TimeDelivered);

              let NoEarlierThan = moment(data[i].NoEarlierThan);
              let NoLaterThan = moment(data[i].NoLaterThan);

              let merchant = data[i].Merchant;
              let arriveTime = moment(TimeArrived.format('LT'), 'HH:mm:ss');
              let startTIme = moment('17:00:00', 'HH:mm:ss');

              if (merchant == 'Svarta Lådan') {
                startTIme = moment('15:00:00', 'HH:mm:ss');
              }
              var endTime = moment('22:00:00', 'HH:mm:ss');
              if ((merchant == 'Svarta Lådan' || merchant == 'Linas Matkasse') && arriveTime.isBefore(startTIme)) {
                if (merchant == 'Svarta Lådan') {
                  data[i].Status = "Före 15:00";
                }
                else {
                  data[i].Status = "Före 17:00";
                }
              }
              else if ((merchant == 'Svarta Lådan' || merchant == 'Linas Matkasse') && arriveTime.isAfter(endTime)) {
                data[i].Status = "Efter 22:00";
              }
              else if (merchant == 'Svarta Lådan' || merchant == 'Linas Matkasse') {
                if (PromisedTime.diff(TimeArrived) > 2700000) {
                  data[i].Status = "Tidig";
                }
                else if (PromisedTime.diff(TimeArrived) < -2700000) {
                  data[i].Status = "Sen";
                }
                else {
                  data[i].Status = "OK!"
                }
              }
              else if (merchant.startsWith("Hemköp") || merchant.startsWith("Willys")) {
                if (PromisedTime.diff(TimeDelivered) > 960000) {
                  data[i].Status = "Tidig";
                }
                else if (PromisedTime.diff(TimeDelivered) < -960000) {
                  data[i].Status = "Sen";
                }
                else {
                  data[i].Status = "OK!"
                }
              }
              else if (merchant.startsWith("Coop") || merchant.startsWith("Stora Coop") || merchant.startsWith("outofhome.se")) {
                if (data[i].Earlier) {
                  data[i].Status = "Tidig";
                }
                else if (data[i].Late) {
                  data[i].Status = "Sen";
                }
                else {
                  data[i].Status = "OK!"
                }
              }
              else {
                if (PromisedTime.diff(TimeDelivered) > 3600000) {
                  data[i].Status = "Tidig";
                }
                else if (PromisedTime.diff(TimeDelivered) < -3600000) {
                  data[i].Status = "Sen";
                }
                else {
                  data[i].Status = "OK!"
                }
              }

              if (data[i].Rating == null) {
                data[i].Rating = '';
              }
              data[i].TimeArrived = moment(data[i].TimeArrived).format('YYYY-MM-DD HH:mm:ss');
              data[i].TimeDelivered = moment(data[i].TimeDelivered).format('YYYY-MM-DD HH:mm:ss');
            }
            else {
              // data[i].State = "Ej levererad";
              data[i].Status = "-";
              data[i].TimeArrived = "";
              data[i].TimeDelivered = "";
            }
            data[i].PromisedTime = moment(data[i].PromisedTime).format('YYYY-MM-DD HH:mm:ss');
          }

          this.setState({ ...this.state, data: data });
        })
        .catch((error) => {
          console.error(error);
        });
    }

    var access_token = localStorage.getItem('access_token');

    if (!access_token) {
      return console.error("401 Unauthorized");
    }

    if (!merchant) {
      title = "Ordrar levererade av " + user + " den " + moment(date).format('YYYY-MM-DD');
      this.setState({ ...this.state, title: title }, () => {
        return fetchOrders();
      });
    } else {

      title = "Ordrar levererade av " + user + " för " + merchant + " den " + moment(date).format('YYYY-MM-DD');

      this.setState({ ...this.state, title: title });
      moment.locale('sv');

      fetch(process.env.REACT_APP_GORDON_API_URL + "/client/name?name=" + merchant, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((dataJson) => {
          fetchOrders(dataJson)
        })
        .catch((error) => {
          console.error(error);
        });
    }

  }

  sendSms() {

    let { data } = this.state;
    var msg = this.state.msg;
    var merchant = this.state.merchant;
    if (msg == null || msg == "") {
      this.setState({ showError2: true });
    }
    else {
      var customers = [];
      for (let i = 0; i < this.state.selected.length; i++) {

        data.forEach(row => {
          if (row.OrderId == this.state.selected[i]) {
            customers.push({ 'name': row.CustomerName, 'phone': row.CustomerPhone })
          }
        });
      }
      var access_token = localStorage.getItem('access_token');
      if (access_token != null) {

        var url = process.env.REACT_APP_GORDON_API_URL + '/order/sms';
        var method = 'POST';

        var body = { 'msg': msg, 'customers': customers, 'merchant': merchant };
        fetch(url, {
          method: method,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + access_token,
          },
          body: JSON.stringify(body)
        })
          .then((response) => {

            this.setState({ showSms: false });
            alert("SMS skickades!");
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        console.error("401 Unauthorized");
      }

    }
  }

  showSmsDialog() {
    if (this.state.selected.length == 0) {
      this.setState({ showError: true });
    }
    else {

      this.setState({ showSms: true });
    }
  }

  componentDidMount() {
    this.getOrders();
  }
  hideModal() {
    this.setState({ showError: false });
  }
  hideModal2() {
    this.setState({ showError2: false });
  }
  add() {

    if (this.state.selected.length == 0) {
      this.setState({ showError: true });
    }
    else {
      for (let i = 0; i < this.state.selected.length; i++) {

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

          let url = process.env.REACT_APP_GORDON_API_URL + '/order/orders/' + this.state.selected[i] + '/' + this.state.selectedstatus;
          let method = 'PUT';
          fetch(url, {
            method: method,
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + access_token,
            },
          })
            .then((response) => {

              this.getOrders();
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          console.error("401 Unauthorized");
        }
      }
    }
  }
  onAfterSaveCell(row, cellName, cellValue) {

    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {


      let url = process.env.REACT_APP_GORDON_API_URL + '/order/orders/' + row.OrderId + '/' + cellValue;
      let method = 'PUT';

      fetch(url, {
        method: method,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => {

          this.getOrders();
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }

  statusFormatter(cell, row) {
    var color = "green";
    if (row.TimeArrived != null && row.TimeArrived != "") {
      var PromisedTime = moment(row.PromisedTime);
      var TimeArrived = moment(row.TimeArrived);
      var TimeDelivered = moment(row.TimeDelivered);
      var arriveTime = moment(TimeArrived.format('LT'), 'HH:mm:ss');
      var startTIme = moment('17:00:00', 'HH:mm:ss');
      var endTime = moment('22:00:00', 'HH:mm:ss');

      if (row.Merchant == 'Svarta Lådan') {
        startTIme = moment('15:00:00', 'HH:mm:ss');
      }
      var endTime = moment('22:00:00', 'HH:mm:ss');
      if ((row.Merchant == 'Svarta Lådan' || row.Merchant == 'Linas Matkasse') && arriveTime.isBefore(startTIme)) {
        if (row.Merchant == 'Svarta Lådan') {
          color = "red";
        }
        else {
          color = "red";
        }
      }
      else if ((row.Merchant == 'Svarta Lådan' || row.Merchant == 'Linas Matkasse') && arriveTime.isAfter(endTime)) {
        color = "red";
      }
      else if (row.Merchant == 'Svarta Lådan' || row.Merchant == 'Linas Matkasse') {
        if (PromisedTime.diff(TimeArrived) > 2700000) {
          color = "red";
        }
        else if (PromisedTime.diff(TimeArrived) < -2700000) {
          color = "red";
        }
      }
      else if (row.Merchant.startsWith("Hemköp") || row.Merchant.startsWith("Willys")) {
        if (PromisedTime.diff(TimeDelivered) > 960000) {
          color = "red";
        }
        else if (PromisedTime.diff(TimeDelivered) < -960000) {
          color = "red";
        }
      }
      else if (row.Merchant.startsWith("Coop") || row.Merchant.startsWith("Stora Coop") || row.Merchant.startsWith("outofhome.se")) {
        if (row.Earlier) {
          color = "red";
        }
        else if (row.Late) {
          color = "red";
        }
      }
      else {
        if (PromisedTime.diff(TimeDelivered) > 3600000) {
          color = "red";
        }
        else if (PromisedTime.diff(TimeDelivered) < -3600000) {
          color = "red";
        }
      }
    }
    else {
      color = "red";
    }


    return '<span style="color:' + color + '">' + cell + '</span>';
  }
  render() {
    const { isAuthenticated } = this.props.auth;
    let { data } = this.state;

    const selectRow = {
      mode: 'checkbox',
      clickToSelect: true,
      selected: this.state.selected,
      onSelect: this.handleOnSelect,
      onSelectAll: this.handleOnSelectAll
    };
    const cellEditProp = {
      mode: 'dbclick',
      blurToSave: true,
      afterSaveCell: this.onAfterSaveCell.bind(this)
    };

    let optionItems = categoryList.map((f) =>
      <option eventKey={f}>{f}</option>
    );

    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>{this.state.title}</h3>
              <div className="floatRight">
                <Button
                  bsStyle="primary"
                  className="btn-margin btn-padding marginleft"
                  onClick={this.showSmsDialog.bind(this)}>Skicka SMS...</Button>
                {}
                <select id="dropdown-size-medium" value={this.state.selectedstatus} onChange={this.handleDropDownChange}>
                  {optionItems}
                </select>
                <Button
                  bsStyle="primary"
                  className="btn-margin btn-padding marginleft"
                  onClick={this.add.bind(this)}>Ändra status på markerade ordrar...</Button>
              </div>
              <br /><br />
              <div className="divbreak">
                <BootstrapTable data={data} editable={false} striped={true} hover={true} selectRow={selectRow} cellEdit={cellEditProp} >
                  <TableHeaderColumn width='90' editable={false} dataFormat={this.statusFormatter} dataField='OrderId' isKey>Order ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='CustomerName' editable={false} dataFormat={this.statusFormatter} columnTitle>Kundens namn</TableHeaderColumn>
                  <TableHeaderColumn dataField='Address' editable={false} dataFormat={this.statusFormatter} columnTitle>Kundens adress</TableHeaderColumn>
                  <TableHeaderColumn dataField='PromisedTime' width='150' editable={false} dataFormat={this.statusFormatter} columnTitle>Utlovad tid</TableHeaderColumn>
                  <TableHeaderColumn dataField='TimeArrived' width='150' editable={false} dataFormat={this.statusFormatter} columnTitle>Ankommit till kund</TableHeaderColumn>
                  <TableHeaderColumn dataField='TimeDelivered' width='150' editable={false} dataFormat={this.statusFormatter} columnTitle>Levererat</TableHeaderColumn>
                  <TableHeaderColumn dataField='State' width='200' columnTitle editable={{ type: 'select', options: { values: categoryList } }}>Status</TableHeaderColumn>
                  <TableHeaderColumn dataField='Rating' width='70' editable={false} dataFormat={this.statusFormatter} columnTitle>Betyg</TableHeaderColumn>
                  <TableHeaderColumn dataField='Status' width='150' editable={false} dataFormat={this.statusFormatter} columnTitle>Leveransstatus</TableHeaderColumn>
                </BootstrapTable>
              </div>

              <Modal
                {...this.props}
                show={this.state.showSms}
                onHide={this.cancel.bind(this)}
                dialogClassName="custom-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-lg">Skicka SMS till kunder...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4>Ange meddelande</h4>
                  <br />
                  <p>Hej KUNDNAMN!</p>
                  <textarea className="message-creator__content" onInput={this.msgChanged.bind(this)} value={this.state.msg} />
                  <p>MVH Gordon Delivery</p>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.cancel.bind(this)}>Stäng</Button>
                  <Button bsStyle="success" onClick={this.sendSms.bind(this)}>Skicka</Button>
                </Modal.Footer>
              </Modal>

              <Modal
                {...this.props}
                show={this.state.showError2}
                onHide={this.hideModal2.bind(this)}
                dialogClassName="custom-modal"
              >
                <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title-lg">Ooops...</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h4>Du måste skriva in ett meddelande</h4>
                </Modal.Body>
                <Modal.Footer>
                  <Button onClick={this.hideModal2.bind(this)}>Stäng</Button>
                </Modal.Footer>
              </Modal>
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }

        <Modal
          {...this.props}
          show={this.state.showError}
          onHide={this.hideModal.bind(this)}
          dialogClassName="custom-modal"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-lg">Ooops...</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h4>Du måste markera minst en order i listan</h4>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.hideModal.bind(this)}>Stäng</Button>
          </Modal.Footer>
        </Modal>
      </div>

    );
  }
}

export default Orders;