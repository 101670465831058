import {
    BrowserRouter as Router,
    Route,
    Link
} from 'react-router-dom';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import Gauge from 'react-svg-gauge';

import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
class LinasMatkasse extends Component {

    componentWillMount() {
        let donutrowslinas = [];
        donutrowslinas.push(['Typ', "Procent"]);
        this.setState({ donutrowslinas: donutrowslinas });

        // this.setState({ summary: { Driver: '' } });
        this.setState({ summary: { LeveransPrecision: 0 } });
        this.setState({ linascolor: '#fe0400' });

        return { donutrowslinas: donutrowslinas };
    }

    componentDidMount() {

        let donutrowslinas = this.state.donutrowslinas;

        var access_token = localStorage.getItem('access_token');
        if (access_token != null) {

            var donuturlinas = 'https://api.gordondelivery.com/api/linasmatkasse/latest/';
            fetch(donuturlinas, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + access_token,
                },
            })
                .then((response) => response.json())
                .then((responseJson) => {
                    var summary = JSON.parse(responseJson);

                    var snitt = (((summary[0].Rating5 * 5) + (summary[0].Rating4 * 4) + (summary[0].Rating3 * 3) + (summary[0].Rating2 * 2) + (summary[0].Rating1 * 1)) / (summary[0].Ratings * 5)) * 5;

                    donutrowslinas.push(["Lyckade", summary[0].Lyckade]);
                    donutrowslinas.push(["Avvikelser", summary[0].TotalAvvikelser]);
                    this.state.summary = summary[0];
                    this.state.summary.AverageRating = Math.round(snitt * 100) / 100;

                    var linascolor = '#fe0400';
                    if (summary[0].LeveransPrecision > 90) {
                        linascolor = '#228B22';
                    }
                    else if (summary[0].LeveransPrecision > 70) {
                        linascolor = '#FFA500';
                    }

                    this.setState({ linascolor: linascolor });
                    this.state.donutrowslinas = donutrowslinas;
                    this.setState({ donutrowslinas: donutrowslinas });

                    this.setState({ summary: summary[0] });

                })
                .catch((error) => {
                    console.error(error);
                });

        } else {
            console.error("401 Unauthorized");
        }
    }

    _buildLinkHrefLinas() {
        return '/delivery?merchant=LM&date=' + this.state.summary.Datum;
    }

    render() {

        return (
            <div>
                <div className="dashboardleft">

                    <h5>Linas Matkasse {this.state.summary.Datum}</h5>
                    <div className={'my-pretty-chart-container'}>
                    <div className="smallcircleDiv">
                        <CircularProgressbar percentage={this.state.summary.LeveransPrecision} 
                        text={`${this.state.summary.LeveransPrecision}%`}  
                         strokeWidth={5}
                        // You can override styles either by specifying this "styles" prop,
                        // or by overriding the default CSS here:
                        // https://github.com/iqnivek/react-circular-progressbar/blob/master/src/styles.css
                        styles={{
                          // Customize the root svg element
                          root: {},
                          // Customize the path, i.e. the part that's "complete"
                          path: {
                            // Tweak path color:
                            stroke: this.state.linascolor,
                            // Tweak path to use flat or rounded ends:
                            strokeLinecap: 'butt',
                            // Tweak transition animation:
                            transition: 'stroke-dashoffset 0.5s ease 0s',
                          },
                          // Customize the circle behind the path
                          trail: {
                            // Tweak the trail color:
                            stroke: '#d6d6d6',
                          },
                          // Customize the text
                          text: {
                            // Tweak text color:
                            fill: this.state.linascolor,
                            // Tweak text size:
                            fontSize: '30px',
                          },
                        }}
                      />
                    </div>
                        {/* <Gauge value={this.state.summary.LeveransPrecision} width={300} height={150} color={this.state.linascolor} label="" /> */}

                    </div>
                </div>
                {/* <ul>
                    <li>Vi levererade totalt <b>{this.state.summary.AntalLeveranser}</b> ordrar</li>
                    <li><b>{this.state.summary.Lyckade}</b> ordrar levererades helt enligt plan.</li>
                    <li><b>{this.state.summary.Utebliven}</b> ordrar avbröts och levererades inte.</li>
                    <li><b>{this.state.summary.Fore17}</b> ordrar levererades före kl 17:00</li>
                    <li><b>{this.state.summary.Efter22}</b> ordrar levererades efter kl 22:00</li>
                    <li><b>{this.state.summary.Tidig}</b> ordrar levererades före leveransintervallet (- 45 min)</li>
                    <li><b>{this.state.summary.Sen}</b> ordrar levererades efter leveransintervallet (+ 45 min)</li>
                </ul>
                <p>   Gruppens totala leveransprecision var <b>{this.state.summary.LeveransPrecision}%</b>.</p>
                <p>   Snittbetyget från kunden för denna körning är <b>{this.state.summary.AverageRating} / 5</b>.<br />
                    ({this.state.summary.Rating5} st 5:or, {this.state.summary.Rating4} st 4:or, {this.state.summary.Rating3} st 3:or, {this.state.summary.Rating2} st 2:or, {this.state.summary.Rating1} st 1:or)</p>
                <div className="dashboardleft"><p>Det var {this.state.summary["Kunder Som Följde Leveransen"]} kunder som följde leveransen.</p></div> */}
                <div className="dashboardright">
                    <Button href={this._buildLinkHrefLinas()}
                        bsStyle="primary"
                        className="btn-margin"
                    >
                        Detaljer...
          </Button>

                </div>
                <div className="dashboardtoprightsmall">
                    <p><strong>{this.state.summary.AntalLeveranser} st</strong></p>
                </div>
            </div>
        );
    }
}

export default LinasMatkasse;