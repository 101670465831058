import {
  BrowserRouter as Router,
  Route,
  Link
} from 'react-router-dom';
import React, { Component } from 'react';
import { Button } from 'react-bootstrap';

import CircularProgressbar from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
class HemkopLinkoping extends Component {

  componentWillMount() {

    let donutrows = [];
    donutrows.push(['Typ', "Procent"]);
    this.setState({ donutrows: donutrows });
    this.setState({summaryaxfood: {LeveransPrecision : 0}});

    this.setState({ willyscolor: '#fe0400' });

    return { donutrows: donutrows };
  }

  componentDidMount() {

    let donutrows = this.state.donutrows;

    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      var donuturl = 'https://api.gordondelivery.com/api/hemkoplinkoping/latest/';
      fetch(donuturl, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((responseJson) => {
          var summary = JSON.parse(responseJson);
          donutrows.push(["Inom 15 min", summary[0].Lyckade]);
          donutrows.push(["Inom 30 min", summary[0].Inom1530]);
          donutrows.push(["Inom 60 min", summary[0].Inom3060]);
          donutrows.push(["Över 60 min", summary[0].MerAn60]);
          donutrows.push(["Uteblivna", summary[0].Utebliven]);

          this.state.summaryaxfood = summary[0];
          var willyscolor = '#fe0400';
          if (summary[0].LeveransPrecision > 90) {
            willyscolor = '#228B22';
          }
          else if (summary[0].LeveransPrecision > 70) {
            willyscolor = '#FFA500';
          }

          this.setState({ willyscolor: willyscolor });
          this.setState({ summaryaxfood: summary[0] });

          this.state.willysdatum = summary[0].Datum;
          this.setState({ willysdatum: summary[0].Datum });
          this.state.donutrows = donutrows;
          this.setState({ donutrows: donutrows });
        })
        .catch((error) => {
          console.error(error);
          var summary = [];
          summary.LeveransPrecision = 0;
          this.setState({ summaryaxfood: summary });
        });

    } else {
      console.error("401 Unauthorized");
    }
  }

  _buildLinkHrefWillys() {
    return '/delivery?merchant=AXLI&date=' + this.state.willysdatum;
  }

  render() {

    return (
      <div>
        <div className="dashboardleft">

          <h5>Hemköp Linköping - {this.state.willysdatum}</h5>
          <div >
          <div className="smallcircleDiv">
          <CircularProgressbar percentage={this.state.summaryaxfood.LeveransPrecision} 
                        text={`${this.state.summaryaxfood.LeveransPrecision}%`}  
                         strokeWidth={5}
                        // You can override styles either by specifying this "styles" prop,
                        // or by overriding the default CSS here:
                        // https://github.com/iqnivek/react-circular-progressbar/blob/master/src/styles.css
                        styles={{
                          // Customize the root svg element
                          root: {},
                          // Customize the path, i.e. the part that's "complete"
                          path: {
                            // Tweak path color:
                            stroke: this.state.willyscolor,
                            // Tweak path to use flat or rounded ends:
                            strokeLinecap: 'butt',
                            // Tweak transition animation:
                            transition: 'stroke-dashoffset 0.5s ease 0s',
                          },
                          // Customize the circle behind the path
                          trail: {
                            // Tweak the trail color:
                            stroke: '#d6d6d6',
                          },
                          // Customize the text
                          text: {
                            // Tweak text color:
                            fill: this.state.willyscolor,
                            // Tweak text size:
                            fontSize: '30px',
                          },
                        }}
                      />
                    </div>
          {/* <Gauge value={this.state.summaryaxfood.LeveransPrecision} width={300} height={150} color={this.state.willyscolor} label="" /> */}
            {/* <Gauge value='0' width={300} height={150} color={this.state.willyscolor} label="" /> */}
            {/*
          <ul>
            <li>Vi levererade totalt <b>{this.state.summaryaxfood.Antal}</b> ordrar</li>
            <li><b>{this.state.summaryaxfood.Lyckade}</b> ordrar levererades inom 15 minuter.</li>
            <li><b>{this.state.summaryaxfood.Utebliven}</b> ordrar avbröts och levererades inte.</li>
            <li><b>{this.state.summaryaxfood.Inom1530}</b> ordrar levererades inom 30 min.</li>
            <li><b>{this.state.summaryaxfood.Inom3060}</b> ordrar levererades inom 60 min.</li>
            <li><b>{this.state.summaryaxfood.MerAn60}</b> ordrar levererades över 60 minuter sent.</li> 
          </ul>*/}
            {/* 
          <div className="dashboardleft"> <p>   Gruppens totala leveransprecision var <b>{this.state.summaryaxfood.LeveransPrecision}%</b>.</p></div> */}
          </div>
          <div className="dashboardright">
            <Button href={this._buildLinkHrefWillys()}
              bsStyle="primary"
              className="btn-margin"
            >
              Detaljer...
          </Button>

          </div>
          <div className="dashboardtoprightsmall">
                    <p><strong>{this.state.summaryaxfood.Antal} st</strong></p>
                </div>
        </div>
      </div>

    );
  }
}

export default HemkopLinkoping;