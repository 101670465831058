import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/sv';
import {
  Button,
  Modal,
  FormControl
} from 'react-bootstrap';

class Sms extends Component {
  login() {
    this.props.auth.login();
  }

  componentWillMount() {
    this.setState({
      showModal: false
    });

    this.setState({
      phone: '',
      msg: '',
      status: null
    });
  }

  componentDidMount() {
  }

  phoneChanged(evt) {
    const phone = evt.target.value;
    this.setState({ phone });
  }
  msgChanged(evt) {
    const msg = evt.target.value;
    this.setState({ msg });
  }

  cancel() {
    this.setState({
      status: null,
      showModal: false,
      phone: '',
      msg: ''
    });
  }

  send() {
    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      var postbody = {
        'phone': this.state.phone,
        'msg': this.state.msg
      };
      fetch(process.env.REACT_APP_GORDON_API_URL + '/adminsms', {
        method: 'POST',
        body: JSON.stringify(postbody),
        headers: {
          'Authorization': 'Bearer ' + access_token,
          'Content-Type': 'application/json'
        }
      })
        .then((response) => response.json())
        .then((responseJson) => {

          if (responseJson.status == 'OK') {
            this.setState({
              status: 'SMS Skickades!',
              showModal: true,
              phone: '',
              msg: ''
            });
          }
          else {
            this.setState({
              status: 'Kunde inte skicka SMS, kontrollera telefonnumret och försök igen! Felmeddelande: ' + responseJson.status,
              showModal: true
            });
          }

        })
        .catch((error) => {
          console.error(error);
          this.setState({
            status: 'Kunde inte skicka SMS, kontrollera telefonnumret och försök igen!',
            showModal: true,
          });
        });

    } else {
      console.error("401 Unauthorized");
    }
  };
  render() {
    const { isAuthenticated } = this.props.auth;
    const btndisabled = !(this.state.phone != null && this.state.phone != '' && this.state.msg != null && this.state.msg != '' && this.state.msg.length > 0);
    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Skicka SMS</h3>
              <p>Lägg in telefonnummer till kunden och meddelande.<br />
                <strong> Om du vill skicka till flera kunder/telefonnummer samtidigt måste de vara separerade med kommatecken. T ex 0702286810,0702832684</strong><br /><br />
                OBS! Du måste ange hela meddelandet förutom "MVH Gordon Delivery", det läggs till automatiskt.</p><br />
              <div className="row overview">
                <div className="col-md-4 user-pad text-left">
                  <p>Mobilnummer:</p>
                  <FormControl componentClass="textarea" onInput={this.phoneChanged.bind(this)} value={this.state.phone}
                    placeholder='Telefonnummer' />
                </div>
                <div className="col-md-4 user-pad text-left">
                  <p>Text:</p>
                  <FormControl componentClass="textarea" onInput={this.msgChanged.bind(this)} value={this.state.msg}
                    placeholder='Meddelande' />
                  <p>MVH Gordon Delivery</p>
                </div>
                <div className="col-md-4 user-pad text-left">
                  <br />
                  <Button positive
                    bsStyle="success" disabled={btndisabled}
                    onClick={this.send.bind(this)}>Skicka SMS</Button>
                </div>
              </div>
              <br />
              <h3>SMS mallar</h3>
              <br />
              <h4>Sen leverans - när en rutt hamnar efter sin rutt, mer än 60 minuter, ska Gordons Trafikledning skicka ut SMS om att rutten är sen:</h4>
              <p>Hej, Vi vill meddela dig om att din leverans är försenad. Vi gör allt vi kan för att leverera till dig så snart som möjligt.</p>
              <br />
              <h4>Leverans efter 22.00 - Senast kl. 21:00 ska alla kunder som riskerar att få sin leverans efter 22:00 få sms enligt följande:</h4>
              <p>Din chaufför är dessvärre försenad, men vi gör vårt bästa för att leverera din order så fort som möjligt. Vänligen kontakta oss på 010-5518093, om du inte har möjlighet att ta emot din leverans efter 22.00.</p>
              <br />
              <h4>Avbryter leverans på grund av försening -  Om rutten inte kan fullföljas före 22:30 avbryts leveranserna. Slutkunden får följande SMS:</h4>
              <p><strong>SMS skickas automatiskt när man sätter order som "Cancel order" -> "Avbruten leverans pga utanför leveranstid".</strong> </p>
              <p>Vi behöver tyvärr avbryta er leverans pga försening. Vi ber om ursäkt för detta och levererar dina varor imorgon 17:00-22:00 istället. Dina varor  förvaras i kyl/frys med obruten kylkedja. Ni får ett SMS imorgon eftermiddag med ny tidpunkt för leveransen. Vid frågor, kontakta oss på 010-5518093.</p>
              <br />

              <Modal bsSize="small" show={
                this.state.showModal
              }
                onHide={
                  this.cancel.bind(this)
                } >
                <Modal.Header >
                  <Modal.Title>Status</Modal.Title> </Modal.Header >
                <Modal.Body  >
                  <p>{this.state.status}</p>
                </Modal.Body> <Modal.Footer>
                  <Button onClick={
                    this.cancel.bind(this)
                  } > OK </Button>
                </Modal.Footer> </Modal>
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                </a>
              {' '}to continue.
              </h4>
          )
        }
      </div>

    );
  }
}

export default Sms;