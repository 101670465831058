import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import _ from "lodash";
import { SyncLoader } from "react-spinners";
import matchSorter from "match-sorter";
import { Button, Modal, FormControl } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-16-bootstrap-date-picker";

const rows = [];
class LateStart extends Component {
  login() {
    this.props.auth.login();
  }

  state = { rows };

  componentWillMount() {
    this.setState({ formattedValue: null });
    this.setState({ value: "" });
    this.setState({ isloading: false, rows: [] });

    this.setState({ showModal: false });
    this.setState({ showTymezynkModal: false });
    var user = localStorage.getItem("userProfileName");

    this.setState({
      author: user
    });

    
  }
  componentDidMount() {}
  showTymezynkModal() {
    this.setState({ showTymezynkModal: true });
  }
  showModal(row) {
    var name = row.original.name;
    var timezynkId = row.original.timezynkId;
    var regnr = row.original.regnr;
    var late = row.original.late;
    var merchant = row.original.merchant;
    var date = moment(row.original.shiftstart).format("YYYY-MM-DD");
    this.setState({
      time: late,
      name: name,
      regnr: regnr,
      merchant: merchant,
      date: date,
      timezynkId: timezynkId
    });

    this.setState({ showModal: true });
  }
  cancel() {
    this.setState({
      time: null,
      name: null,
      regnr: null,
      merchant: null,
      date: null,
      timezynkId: null,
      gordonIsResponsible : false,
      visibleForClient : false,
      driverinformed : false,
      isPoint : false
    });
    this.setState({
      showModal: false
    });
  }
  cancelTz() {
    this.setState({
      showTymezynkModal: false
    });
    this.setState({
      showModal: false
    });
  }

  toggleResponsibleChange = () => {
    this.setState({
      gordonIsResponsible: !this.state.gordonIsResponsible
    });
  };

  toggleVisibleChange = () => {
    this.setState({
      visibleForClient: !this.state.visibleForClient
    });
  };
  toggleInformedChange = () => {
    this.setState({
      driverinformed: !this.state.driverinformed
    });
  };
  toggleIsPointChange = () => {
    this.setState({
      isPoint: !this.state.isPoint
    });
  };

  createComplaint(removetime) {
    var postbody = {
      drivername: this.state.name,
      deliverydate: moment(this.state.shiftstart).format(
        "YYYY-MM-DD"
      ),
      client: this.state.merchant,
      category: "Sen till pass",
      comment:
        this.comment != null && this.comment.value != null
          ? this.comment.value
          : null,
      vehicle: this.state.regnr,
      routename: this.routename.value,
      stopnumber: this.stopnumber.value,
      endcustomer: this.endcustomer.value,
      cost: this.cost.value,
      time: this.state.late,
      driverinformed: this.state.driverinformed,
      drivercomment: this.drivercomment.value,
      gordonIsResponsible: this.state.gordonIsResponsible,
      visibleForClient: this.state.visibleForClient,
      isPoint: this.state.isPoint,
      created_by: this.state.author
    };
    var access_token = localStorage.getItem("access_token");
    if (access_token != null) {
      fetch(process.env.REACT_APP_GORDON_API_URL + "/complaints", {
        method: "POST",
        body: JSON.stringify(postbody),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + access_token
        }
      })
        .then(response => {
          if (!removetime) {
            this.setState({
              showModal: false
            });
            this.setState({
              showTymezynkModal: false
            });

            alert("Händelse registrerad");
          } else {
            var url =
              process.env.REACT_APP_GORDON_API_URL +
              "/timezynkadmin/removetime/" +
              this.state.timezynkId +
              "/" +
              this.state.late +
              "/" +
              this.state.shiftstart;
            fetch(url, {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + access_token
              }
            })
              .then(response => {
                this.setState({
                  showModal: false
                });
                this.setState({
                  showTymezynkModal: false
                });
                alert("Händelse registrerad");
              })
              .catch(error => {
                console.error(error);
              });
          }
        })
        .catch(error => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }

  handleChange(value, formattedValue) {
    this.setState({
      value: value,
      formattedValue: formattedValue
    });
  }

  getLateStarts() {
    var rows = [];
    this.setState({ isloading: true });
    var access_token = localStorage.getItem("access_token");
    if (access_token != null) {
      fetch(
        process.env.REACT_APP_GORDON_API_URL +
          "/user/latestarts?datum=" +
          this.state.formattedValue,
        {
          method: "get",
          headers: {
            Authorization: "Bearer " + access_token,
            "Content-Type": "application/json"
          }
        }
      )
        .then(response => response.json())
        .then(responseJson => {
          for (let i = 0; i < responseJson.length; i++) {
            if (moment(responseJson[i].shiftstart) < moment()) {
              responseJson[i].shiftstart = moment(
                responseJson[i].shiftstart
              ).format("HH:mm");
              responseJson[i].unlock = moment(responseJson[i].unlock).format(
                "HH:mm"
              );

              rows.push({
                name: responseJson[i].name,
                regnr: responseJson[i].regnr,
                late:
                  responseJson[i].late != null && responseJson[i].late > 0
                    ? responseJson[i].late
                    : "",
                shiftstart: responseJson[i].shiftstart,
                unlock:
                  responseJson[i].late != null && responseJson[i].late > 0
                    ? responseJson[i].unlock
                    : null,
                merchant: responseJson[i].merchant,
                timezynkId: responseJson[i].timezynkId
              });
            }
          }
          this.rowsCount = rows.length;
          let originalRows = rows;
          let rowss = originalRows.slice(0);
          this.state.rows = rowss;
          this.state.originalRows = originalRows;
          this.setState({ rows: rows });
          this.setState({ originalRows: originalRows });

          this.handleGridSort("shiftstart", "ASC");
          this.setState({ isloading: false });
        })
        .catch(error => {
          console.error(error);
          this.setState({ isloading: false });
        });
    } else {
      console.error("401 Unauthorized");
      this.setState({ isloading: false });
    }
  }

  render() {
    const { isAuthenticated } = this.props.auth;

    const { formattedValue } = this.state;
    const text = this.state.isloading ? "Söker..." : "Sök";
    const isEnabled = formattedValue != null && !this.state.isloading;

    
  var divStyle = {
    display: this.state.isloading ? 'block' : 'none'
};


    const columns = [
      {
        id: "_id",
        Header: "ID",
        show: false
      },
      {
        Header: "Namn",
        accessor: "name",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["name"] }),
        filterAll: true,
        Cell: row => <a href={"/gordoneer?name=" + row.value}>{row.value}</a>
      },

      {
        Header: "Kund",
        accessor: "merchant",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["merchant"] }),
        filterAll: true
      },
      {
        Header: "Regnummer",
        accessor: "regnr",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["regnr"] }),
        filterAll: true
      },
      {
        Header: "Sen i minuter",
        accessor: "late",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ["late"]
          }),
        filterAll: true,
        Cell: row => (
          <span
            style={
              row.value > 15 ? { color: "#FF0000", "font-weight": "bold" } : {}
            }
          >
            {row.value}
          </span>
        )
      },
      {
        Header: "Starttid i TimeZynk",
        accessor: "shiftstart",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, {
            keys: ["shiftstart"]
          }),
        filterAll: true
      },
      {
        Header: "Upplåsningstid",
        accessor: "unlock",
        filterMethod: (filter, rows) =>
          matchSorter(rows, filter.value, { keys: ["unlock"] }),
        filterAll: true,
        Cell: row => (
          <span>
            {row.value != null ? (
              row.value
            ) : (
              <img src="./images/unlock.jpg" alt="Ej lås-app" width="25px" />
            )}
          </span>
        )
      },
      {
        Header: "Händelse",
        accessor: "id",
        Cell: row => (
          <div>
          <Button className="fas fa-exclamation-circle btn-margin latestartbutton" bsStyle="primary" onClick={this.showModal.bind(this, row)}></Button>
          </div>
        )
      },
      {
        accessor: "timezynkId",
        Header: "TimeZynkId",
        show: false
      }
    ];

    return (
      <div className="container">
        {isAuthenticated() && (
          <div>
            <h3>Sen till pass</h3>

            <div className="cover" style={divStyle}>
                                <div className="covercontent">
                                    <SyncLoader
                                        color={'#174b5F'}
                                        loading={this.state.isloading}
                                    />
                                </div>
                            </div>


            <div className="datePicker">
              Datum:
              <DatePicker
                id="example-datepicker"
                value={this.state.value}
                dateFormat="YYYY-MM-DD"
                weekStartsOn={1}
                onChange={this.handleChange.bind(this)}
                monthLabels={[
                  "Januari",
                  "Februari",
                  "Mars",
                  "April",
                  "Maj",
                  "Juni",
                  "Juli",
                  "Augusti",
                  "September",
                  "Oktober",
                  "November",
                  "December"
                ]}
                dayLabels={["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"]}
              />
            </div>
            <div className="buttonSearch">
              <Button
                bsStyle="primary"
                className="btn-margin"
                disabled={!isEnabled}
                onClick={this.getLateStarts.bind(this)}
              >
                {text}
              </Button>
            </div>
            <br />
            <br />
            <ReactTable
              filterable
              data={this.state.rows}
              defaultSorted={[
                {
                  id: "name",
                  desc: false
                }
              ]}
              defaultFilterMethod={(filter, row) =>
                String(row[filter.id]) === filter.value
              }
              columns={columns}
              pageSize={this.state.pageSize}
              defaultPageSize={50}
            />

<Modal bsSize="large" show={
          this.state.showModal
        }
          onHide={
            this.cancel.bind(this)
          } dimmer={"blurring"}>
          <Modal.Header >
            <Modal.Title>Registrera händelse</Modal.Title> </Modal.Header >
          <Modal.Body  >
            <table id="reklamationerTable">
              <tr>

                <td>
                  <div className="datePicker paddingmore">
                    <p>Gordoneer: *</p>
                    <span className="paddingleft"><strong>{this.state.name}</strong></span>
                  </div>
                </td>

                <td>
                  <div className="datePicker paddingmore">
                    <p>Orsak: *</p>
                    <span className="paddingleft"><strong>Sen till pass</strong></span>
                  </div>
                </td>
              </tr>
            </table>

            <br />
            <p>Tid i minuter (Används för 'Sen till pass', 'Gods ej klar' etc):</p>

            <span className="paddingleft"><strong>{this.state.late}</strong></span>
            <br />
            <p>Fordon:</p>
            <span className="paddingleft"><strong>{this.state.regnr}</strong></span>
            <br />  <br />
            <p>Namn på rutt,zon, etc:</p>
            <FormControl inputRef={
              (ref) => {
                this.routename = ref
              }
            } placeholder='Ruttnamn' />
            <br />
            <p>Ordernr/Stoppnr:</p>
            <FormControl inputRef={
              (ref) => {
                this.stopnumber = ref
              }
            } placeholder='Ordernr/Stoppnr' />
            <br />
            <p>Slutkundens namn:</p>
            <FormControl inputRef={
              (ref) => {
                this.endcustomer = ref
              }
            } placeholder='Slutkund/Stoppnumer' />
            <br />
            <p>Kostnad för Gordon Delivery:</p>
            <FormControl type="number" inputRef={
              (ref) => {
                this.cost = ref
              }
            } placeholder='Kostnad i kr' />
            <br />

            <p>Beskrivning:</p>
            <FormControl
              inputRef={
                (ref) => {
                  this.comment = ref
                }
              }
              componentClass="input"
              placeholder="Beskrivning av händelsen" />
            <br />
            <p>Informerat Gordoneer:</p>

            <input type='checkbox' checked={this.state.driverinformed} onChange={this.toggleInformedChange} />
            <br />
            <p>Kommentar från Gordoneer:</p>
            <FormControl
              inputRef={
                (ref) => {
                  this.drivercomment = ref
                }
              }
              componentClass="input"
              placeholder="Kommentar från Gordoneer:" />
            <br />
            <p>Är Gordon ansvarig för händelsen? Ja / Nej (kund ansvarig):</p>

            <input type='checkbox' checked={this.state.gordonIsResponsible} onChange={this.toggleResponsibleChange} />
            <br />
            <p>Synlig för kund:</p>

            <input type='checkbox' checked={this.state.visibleForClient} onChange={this.toggleVisibleChange} />
            <br />
            <p>Klassa som Prick (Professional Point System):</p>

            <input type='checkbox' checked={this.state.isPoint} onChange={this.toggleIsPointChange} />
          </Modal.Body>
          <Modal.Footer>

            <Button negative onClick={this.cancel.bind(this)}>
              Avbryt
        </Button>  <Button positive
              bsStyle="default" onClick={this.showTymezynkModal.bind(this)}>Spara</Button>
          </Modal.Footer>
        </Modal>

        <Modal bsSize="medium" show={
          this.state.showTymezynkModal
        }
          onHide={
            this.cancelTz.bind(this)
          } >
          <Modal.Header >
            <Modal.Title><i class="fas fa-exclamation-triangle"></i>{' '}Dra av tid i TimeZynk</Modal.Title> </Modal.Header >
          <Modal.Body  >
            <p>Vill du även att det automatiskt ska dras av <strong>{this.state.late}</strong> minuter från <strong>{this.state.name}</strong> pass?</p>
          </Modal.Body> <Modal.Footer>
            <Button bsStyle="default" onClick={
              this.cancelTz.bind(this)
            } > Avbryt </Button>
            <Button bsStyle="danger" onClick={this.createComplaint.bind(this, false)} > Nej </Button>
            <Button bsStyle="success" onClick={this.createComplaint.bind(this, true)} > Ja </Button>
          </Modal.Footer> </Modal>
          </div>
        )}
        {!isAuthenticated() && (
          <h4>
            You're not logged in! Click{" "}
            <a style={{ cursor: "pointer" }} onClick={this.login.bind(this)}>
              Login
            </a>{" "}
            to continue.
          </h4>
        )}
      </div>
    );
  }
}

export default LateStart;
