import React, { Component } from 'react';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import { Button, ToggleButton, ToggleButtonGroup, ButtonToolbar } from 'react-bootstrap';
import DatePicker from 'react-16-bootstrap-date-picker';
var moment = require("moment");
var momentDurationFormatSetup = require("moment-duration-format");

var data;

class Ekonomi extends Component {
  constructor(props) {
    super(props);
    this.getStats = this.getStats.bind(this);
    this.getStatsClick = this.getStatsClick.bind(this);
    this.updateInputValue = this.updateInputValue.bind(this);
    this.updateOrderValue = this.updateOrderValue.bind(this);
    this.updateCostValue = this.updateCostValue.bind(this);
  }

  login() {
    this.props.auth.login();
  }

  componentWillMount() {
    momentDurationFormatSetup(moment);

    this.setState({ inputValue: 0 });
    this.setState({ ordercount: 0 });
    this.setState({ win: 0 });
    this.setState({ data: {} });
    this.setState({ sum: 0 });
    this.setState({ cost: 0 });
    this.setState({ costpercent: 0 });
    this.setState({ sumNoRelocation: 0 });
    this.setState({ sumReported: 0 });
    this.setState({ formattedValue: null });
    let toggleValue = 1;
    this.setState({ toggleValue: toggleValue });
    this.setState({ value: '' });
    this.setState({ merchant: '' });
    this.setState({ message: '' });
    return { data: data, value: '', merchant: '', inputValue: 0, ordercount: 0, win: 0 };
  }

  getStatsClick() {
    var merchant = '';
    if (this.state.toggleValue == 1) {
      merchant = "Willys Älvsjö";
    }
    else if (this.state.toggleValue == 2) {
      merchant = "Hemköp Djurgårdsstaden";
    }
    else if (this.state.toggleValue == 3) {
      merchant = "Linas Matkasse";
    }
    else if (this.state.toggleValue == 4) {
      merchant = "Svarta Lådan";
    }
    else if (this.state.toggleValue == 5) {
      merchant = "Willys Umeå";
    }
    else if (this.state.toggleValue == 6) {
      merchant = "Willys Växjö";
    }
    else if (this.state.toggleValue == 7) {
      merchant = "Willys Gävle";
    }
    else if (this.state.toggleValue == 8) {
      merchant = "Willys Jönköping";
    }
    else if (this.state.toggleValue == 9) {
      merchant = "Wrapsons";
    }
    else if (this.state.toggleValue == 10) {
      merchant = "Willys Länna";
    }
    else if (this.state.toggleValue == 11) {
      merchant = "Hemköp Nacka";
    }
    this.setState({
      merchant: merchant
    });
    this.getStats(merchant, this.state.formattedValue);
  }

  getInitialState() {
    var value = new Date().toISOString();
    return {
      value: value
    }
  }

  handleChange(value, formattedValue) {
    this.setState({
      value: value, // ISO String, ex: "2016-11-19T12:00:00.000Z" 
      formattedValue: formattedValue // Formatted String, ex: "11/19/2016" 
    });
  }

  handleToggleChange(toggleValue) {
    this.setState({
      toggleValue: toggleValue
    });
  }

  handleSelect(evt, eventKey) {
    this.setState({ evt: evt });
  }

  getStats(merchant, date) {
    data = [];
    this.state.data = data;
    this.setState({ data: data });

    var access_token = localStorage.getItem('access_token');
    if (access_token != null) {

      fetch(process.env.REACT_APP_GORDON_API_URL + '/calculations?client=' + merchant + '&date=' + date, {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + access_token,
        },
      })
        .then((response) => response.json())
        .then((dataJson) => {

          data = dataJson.data;
          for (let i = 0; i < data.length; i++) {

            data[i].scheduledTime = moment.utc(moment(data[i].scheduledTime)).format("HH:mm");
            data[i].reportedTime = data[i].reportedTime > 0 ? moment.utc(moment(data[i].reportedTime)).format("HH:mm") : "-";

            data[i].scheduledTimeNoRelocation = moment.utc(moment(data[i].scheduledTimeNoRelocation)).format("HH:mm");
            data[i].reportedTimeNoRelocation = data[i].reportedTimeNoRelocation > 0 ? moment.utc(moment(data[i].reportedTimeNoRelocation)).format("HH:mm") : "-";

            if (data[i].overTime > 0) {
              data[i].overTime = "+" + moment.utc(moment(data[i].overTime)).format("HH:mm");
            } else if (data[i].overTime < 0) {
              data[i].overTime = "-" + moment.utc(Math.abs(moment(data[i].overTime))).add('h', 0).format("HH:mm");
            } else {
              data[i].overTime = "";
            }

            if (data[i].salaryhour == 0) {
              data[i].salaryhour = "UPPGIFT SAKNAS";
            } else {
              // data[i].salaryhour = data[i].salaryhour.format('0,0');
            }

            if (moment(date).isBefore(moment(), 'day')) {
              data[i].salary = data[i].salaryReportedTime.toFixed(2);
            } else {
              data[i].salary = data[i].salary.toFixed(2);
            }

          }

          this.state.data = data;
          this.setState({ data: data });

          this.state.antal = dataJson.antal;
          this.setState({ antal: dataJson.antal });

          var schedTime = moment.duration(dataJson.scheduledTime, "ms").format("h[h] mm[min]");
          this.state.scheduledTime = schedTime;
          this.setState({ scheduledTime: schedTime });

          var repTime = moment.duration(dataJson.reportedTime, "ms").format("h[h] mm[min]");
          this.state.reportedTime = repTime;
          this.setState({ reportedTime: repTime });

          var repNoLocationTime = moment.duration(dataJson.reportedTimeNoRelocation, "ms").format("h[h] mm[min]");
          this.state.reportedTimeNoRelocation = repNoLocationTime;
          this.setState({ reportedTimeNoRelocation: repNoLocationTime });

          var repRelocationTime = moment.duration(dataJson.reportedTime - dataJson.reportedTimeNoRelocation, "ms").format("h[h] mm[min]");
          this.state.repRelocationTime = repRelocationTime;
          this.setState({ repRelocationTime: repRelocationTime });

          var repRelocationTimePercent = (((dataJson.reportedTime - dataJson.reportedTimeNoRelocation) / dataJson.reportedTime) * 100).toFixed(0);
          this.state.repRelocationTimePercent = repRelocationTimePercent;
          this.setState({ repRelocationTimePercent: repRelocationTimePercent });

          var overTime = moment.duration(dataJson.overTime, "ms").format("h[h] mm[min]");
          this.state.overTime = overTime;
          this.setState({ overTime: overTime });

          var relTime = moment.duration(dataJson.relocationTime, "ms").format("h[h] mm[min]");
          this.state.relocationTime = relTime;
          this.setState({ relocationTime: relTime });

          var scheduledTimeNoRelocation = moment.duration(dataJson.scheduledTime - dataJson.relocationTime, "ms").format("h[h] mm[min]");
          this.state.scheduledTimeNoRelocation = scheduledTimeNoRelocation;
          this.setState({ scheduledTimeNoRelocation: scheduledTimeNoRelocation });

          var relocationTimePercent = ((dataJson.relocationTime / dataJson.scheduledTime) * 100).toFixed(0);
          this.state.relocationTimePercent = relocationTimePercent;
          this.setState({ relocationTimePercent: relocationTimePercent });

          this.state.sum = dataJson.sum;
          this.setState({ sum: dataJson.sum });

          this.state.sumReported = dataJson.sumReported;
          this.setState({ sumReported: dataJson.sumReported });

          this.state.sumNoRelocation = dataJson.sumNoRelocation;
          this.setState({ sumNoRelocation: dataJson.sumNoRelocation });

          var message = "";
          var kostnad = 0;

          if (moment(date).isBefore(moment(), 'day')) {

            message = "Beräkningarna baseras på rapporterad tid";

            kostnad = dataJson.sumReported;
            this.state.cost = kostnad.toFixed(2);
            this.setState({ cost: kostnad.toFixed(2) });

          } else {
            message = "Beräkningarna baseras på schemalagd tid";

            kostnad = dataJson.sum.toFixed(2);
            this.state.cost = kostnad;
            this.setState({ cost: kostnad });
          }
          this.setState({ message: message });

          var intakt = 0;
          if (merchant.startsWith("Willys") || merchant.startsWith("Hemköp")) {

            this.setState({ factor: 50 });

            if (moment(date) < (moment())) {
              intakt = 425 * (dataJson.reportedTimeNoRelocation / 3600000);
              this.state.inputValue = intakt.toFixed(2);
              this.setState({ inputValue: intakt.toFixed(2) });

            } else {
              intakt = 425 * (dataJson.scheduledTimeNoRelocation / 3600000);
              this.state.inputValue = intakt.toFixed(2);
              this.setState({ inputValue: intakt.toFixed(2) });
            }
            if (moment(date) < (moment())) {
              this.setState({
                win: (intakt * 0.50) - dataJson.sumReported
              });
            } else {
              this.setState({
                win: (intakt * 0.50) - dataJson.sum
              });
            }
          }
          else if (merchant == 'Linas Matkasse') {
            intakt = this.state.ordercount * 59;
            this.state.inputValue = intakt.toFixed(2);
            this.setState({ inputValue: intakt.toFixed(2) });

            this.setState({ factor: 40 });

            if (moment(date) < (moment())) {
              this.setState({
                win: (intakt * 0.40) - dataJson.sumReported
              });
            } else {
              this.setState({
                win: (intakt * 0.40) - dataJson.sum
              });
            }
          }
          else {
            this.setState({ inputValue: 0 });
            this.setState({
              win: 0
            });
          }
          if (intakt > 0) {
            var kostnadsprocent = ((kostnad / intakt) * 100).toFixed(0);
            this.setState({ costpercent: kostnadsprocent });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      console.error("401 Unauthorized");
    }
  }

  updateCostValue(evt) {
    this.setState({
      cost: evt.target.value
    });
    this.setState({
      win: (this.state.inputValue * 0.40) - evt.target.value
    });

    if (this.state.inputValue > 0) {
      var kostnadsprocent = ((evt.target.value / this.state.inputValue) * 100).toFixed(0);
      this.setState({ costpercent: kostnadsprocent });
    }
  }
  updateInputValue(evt) {
    this.setState({
      inputValue: evt.target.value
    });
    this.setState({
      win: (evt.target.value * 0.40) - this.state.cost
    });

    if (evt.target.value > 0) {
      var kostnadsprocent = ((this.state.cost / evt.target.value) * 100).toFixed(0);
      this.setState({ costpercent: kostnadsprocent });
    }
  }

  updateOrderValue(evt) {
    this.setState({
      ordercount: evt.target.value
    });
    if (this.state.merchant == 'Linas Matkasse') {
      var intakt = evt.target.value * 59;
      this.state.inputValue = intakt.toFixed(2);
      this.setState({ inputValue: intakt.toFixed(2) });

      if (moment(this.state.formattedValue) < (moment())) {
        this.setState({
          win: (intakt * 0.40) - this.state.sumReported
        });
      } else {
        this.setState({
          win: (intakt * 0.40) - this.state.sum
        });
      }
    }

    if (intakt > 0) {
      var kostnadsprocent = ((this.state.cost / intakt) * 100).toFixed(0);
      this.setState({ costpercent: kostnadsprocent });
    }
  }

  overtimeFormatter(cell, row) {
    var color = "black";
    var fontweight = "normal";

    if (cell[0] == "-") {
      //  color = "green";
      //  fontweight = "bold";
    } else if (cell[0] == "+") {
      color = "red";
      fontweight = "normal";
    }

    return '<span style="color:' + color + ';font-weight:' + fontweight + '">' + cell + '</span>';
  }

  salaryFormatter(cell, row) {
    var color = "black";
    var fontweight = "normal";

    if (row.salaryhour == "UPPGIFT SAKNAS") {
      color = "red";
      fontweight = "bold";
    }

    return '<span style="color:' + color + ';font-weight:' + fontweight + '">' + cell + '</span>';
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    return (
      <div className="container">
        {
          isAuthenticated() && (
            <div>
              <h3>Lönsamhet</h3>
              <div className="summarySearch">
                <h4>Välj datum och kund:</h4>
                <div className="datePicker">Datum: <DatePicker id="example-datepicker" value={this.state.value} dateFormat='YYYY-MM-DD' weekStartsOn={1} onChange={this.handleChange.bind(this)}
                  monthLabels={['Januari', 'Februari', 'Mars', 'April', 'Maj', 'Juni', 'Juli', 'Augusti', 'September', 'Oktober', 'November', 'December']}
                  dayLabels={['Sön', 'Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör']} />
                </div>

                <div className="togglebuttonSearch">
                  <ButtonToolbar>
                    <ToggleButtonGroup type="radio" name="options" value={this.state.toggleValue} onChange={this.handleToggleChange.bind(this)}>
                      <ToggleButton value={1}>Willys Älvsjö</ToggleButton>
                      <ToggleButton value={2}>Hemköp Djurgårdsstaden</ToggleButton>
                      <ToggleButton value={3}>Linas Matkasse</ToggleButton>
                      <ToggleButton value={4}>Svarta Lådan</ToggleButton>
                      <ToggleButton value={5}>Willys Umeå</ToggleButton>
                      <ToggleButton value={6}>Willys Växjö</ToggleButton>
                      <ToggleButton value={7}>Willys Gävle</ToggleButton>
                      <ToggleButton value={8}>Willys Jönköping</ToggleButton>
                      <ToggleButton value={9}>Wrapsons</ToggleButton>
                      <ToggleButton value={10}>Willys Länna</ToggleButton>
                      <ToggleButton value={11}>Hemköp Nacka</ToggleButton>
                    </ToggleButtonGroup>
                  </ButtonToolbar>
                </div>

                <div className="buttonSearch">
                  <Button
                    bsStyle="primary"
                    className="btn-margin"
                    onClick={this.getStatsClick.bind(this)}>Sök</Button>
                  <span>{this.state.errormessage}</span>
                </div>
              </div>
              <br />
              {
                this.state.data.length > 0 && (
                  <div className="detailsText">
                    <br />
                    <table>
                      <tr>
                        <td>
                          Kund:</td><td><strong>{this.state.merchant}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Datum:</td><td> <strong>{this.state.formattedValue}</strong>
                        </td>
                      </tr>

                      {/* <tr>
                    <td>
                    Antal ordrar:</td><td><strong>{this.state.antalordrar}</strong>
                    </td>
                    </tr> */}
                      <tr>
                        <td><br />
                          Antal pass:</td><td><br /><strong>{this.state.antal} st</strong>
                        </td>
                      </tr>
                      <tr>
                        <td><br />
                          Schemalagd tid:</td><td><br /><strong>{this.state.scheduledTime}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Schemalagd tid (exkl relocation):</td><td><strong>{this.state.scheduledTimeNoRelocation}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Schemalagd Relocation-tid:</td><td><strong>{this.state.relocationTime} ( {this.state.relocationTimePercent}% )</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Beräknad Gordoneerkostnad (inkl Relocation):&nbsp;&nbsp;&nbsp;<br /></td><td><strong>{this.state.sum.toFixed(2)} SEK</strong>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Beräknad Gordoneerkostnad (exkl Relocation):&nbsp;&nbsp;&nbsp;<br /></td><td><strong>{this.state.sumNoRelocation.toFixed(2)} SEK</strong>
                          <br />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <br />
                          Rapporterad tid:</td><td><br /><strong>{this.state.reportedTime}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Rapporterad tid (exkl relocation):</td><td><strong>{this.state.reportedTimeNoRelocation}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Rapporterad Relocation tid:</td><td><strong>{this.state.repRelocationTime} ( {this.state.repRelocationTimePercent}% )</strong>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Faktisk Gordoneerkostnad:<br /></td><td><strong>{this.state.sumReported.toFixed(2)} SEK</strong>
                          <br />
                        </td>
                      </tr>
                      {/* <tr>
                    <td>
                    Diff:</td><td><strong>{this.state.overTime} h</strong>
                    </td>
                    </tr> */}
                      {
                        this.state.merchant == 'Linas Matkasse' && (
                          <tr>
                            <td>
                              <br />
                              ANTAL ORDRAR:</td><td><br /><input value={this.state.ordercount} onChange={this.updateOrderValue} />
                            </td>
                          </tr>
                        )
                      }
                      <tr>
                        <td colspan="2">
                          <br />
                          <span style={{ color: 'green' }}><strong>{this.state.message}</strong></span>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <br />
                          INTÄKT:</td><td><br /><input value={this.state.inputValue} onChange={this.updateInputValue} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          KOSTNAD:</td><td><input onChange={this.updateCostValue} type="text" value={this.state.cost} /> {this.state.costpercent}%
                        </td>
                      </tr>
                      <tr>
                        <td>
                          LÖNSAMHET:</td><td><input value={this.state.win.toFixed(2)} disabled />
                        </td>
                      </tr>
                      <tr><td></td><td>LÖNSAMHET = (INTÄKT  x {this.state.factor} %) - KOSTNAD. Dvs {this.state.factor}% av intäkterna beräknas gå till arvode för Gordoneerer.</td></tr>
                    </table>
                    <br />
                  </div>
                )
              }
              <div >
                <BootstrapTable data={data} striped={true} hover={true}>
                  <TableHeaderColumn dataField='name'>Gordoneer</TableHeaderColumn>
                  <TableHeaderColumn dataField='gordoneerId' isKey>Gordoneer-ID</TableHeaderColumn>
                  <TableHeaderColumn dataField='scheduledTime'>Schemalagd tid</TableHeaderColumn>
                  <TableHeaderColumn dataField='scheduledTimeNoRelocation'>Schemalagd tid (utan relocation)</TableHeaderColumn>
                  <TableHeaderColumn dataField='reportedTime'>Rapporterad tid</TableHeaderColumn>
                  <TableHeaderColumn dataField='reportedTimeNoRelocation'>Rapporterad tid (utan relocation)</TableHeaderColumn>
                  <TableHeaderColumn dataField='overTime' dataFormat={this.overtimeFormatter}>Diff schemalagd vs rapporterad tid</TableHeaderColumn>
                  <TableHeaderColumn dataField='salary' dataFormat={this.salaryFormatter}>Summa Arvode (SEK)</TableHeaderColumn>
                  <TableHeaderColumn dataField='salaryhour' dataFormat={this.salaryFormatter}>Arvode/h (SEK)</TableHeaderColumn>
                </BootstrapTable>
              </div>
              <br />
              <br />
            </div>
          )
        }
        {
          !isAuthenticated() && (
            <h4>
              You're not logged in! Click {' '}
              <a
                style={{ cursor: 'pointer' }}
                onClick={this.login.bind(this)}
              >
                Login
                  </a>
              {' '}to continue.
                </h4>
          )
        }
      </div>
    );
  }
}

export default Ekonomi;

