import React, { Component } from 'react';
import PropTypes from "prop-types";
const { Button } = require('react-bootstrap');

class MessageCreator extends Component {
  static propTypes = {
    create: PropTypes.func
  };

  createMessage(e) {
    e.preventDefault();

    // const contentNode = ReactDOM.findDOMNode(this);

    this.props.create(this.node.value);
    this.node.value = '';
  }

  render() {
    return (
      <form className="message-creator" onSubmit={this.createMessage.bind(this)}>
        <textarea className="message-creator__content"  ref={(n) => this.node = n} required={true} />
        <Button positive
        type="Submit"
                                                className="btn-margin btn-padding"
                            bsStyle="default"> 
                            Lägg till
</Button>
        {/* <input className="message-creator__post-button" type="submit" value="Lägg till" /> */}
      </form>
    )
  }
}

export default MessageCreator;