import React, { Component } from "react";
import DatePicker from "react-16-bootstrap-date-picker";
import _ from "lodash";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { SyncLoader } from "react-spinners";
import { Button } from "react-bootstrap";

class Email extends Component {
  constructor() {
    super();

    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  handleTimeChange(time) {
    this.setState({ time: time });
  }
  login() {
    this.props.auth.login();
  }
  componentWillMount() {
    this.setState({
      isLoading: false
    });
    this.setState({ time: moment().format("HH:00") });
    this.setState({ formattedValue: null });
    this.setState({ value: "" });
  }
  componentDidMount() { }

  send(path) {
    this.setState({
      isLoading: true
    });
    var access_token = localStorage.getItem("access_token");
    if (access_token != null) {
      fetch(
        process.env.REACT_APP_GORDON_API_URL +
        "/" +
        path +
        "/gdp?datum=" +
        this.state.formattedValue +
        "&tid=" +
        this.state.time,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: "Bearer " + access_token
          }
        }
      )
        .then(resp => resp.json())
        .then(dataJson => {
          this.setState({
            isLoading: false
          });
        })
        .catch(error => {
          console.error(error);
          this.setState({
            isLoading: false
          });
        });
    }
  }

  handleDateChange(value, formattedValue) {
    this.setState({
      value: value,
      formattedValue: formattedValue
    });
  }

  render() {
    const { isAuthenticated } = this.props.auth;
    var divStyle = {
      display: this.state.isLoading ? "block" : "none"
    };

    const exportdisabled = !(
      this.state.formattedValue != null
    );

    return (
      <div className="container">
        {isAuthenticated() && (
          <div>
            <div className="cover" style={divStyle}>
              <div className="covercontent">
                <SyncLoader color={"#174b5F"} loading={this.state.isLoading} />
              </div>
            </div>

            <h3>Leveransavisering via e-post och sms</h3>
            <div className="dropdownSearch">
              <div className="datePicker">
                Datum:{" "}
                <DatePicker
                  autoFocus={false}
                  showTodayButton={true}
                  todayButtonLabel="Idag"
                  id="example-datepicker"
                  value={this.state.value}
                  dateFormat="YYYY-MM-DD"
                  weekStartsOn={1}
                  minDate={moment().format("YYYY-MM-DD")}
                  onChange={this.handleDateChange.bind(this)}
                  monthLabels={[
                    "Januari",
                    "Februari",
                    "Mars",
                    "April",
                    "Maj",
                    "Juni",
                    "Juli",
                    "Augusti",
                    "September",
                    "Oktober",
                    "November",
                    "December"
                  ]}
                  dayLabels={["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"]}
                />

                <br />
              </div>
            </div>

            {/* <br />
            <h4>Svarta Lådan</h4>
            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "slemail")}
            >
              Skicka e-post
            </Button> */}

            {/* <br />

            <h4>GI-Boxen</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "giboxensms")}
            >
              Skicka sms
            </Button> */}
            {/* <br />
            <h4>Ecoviva</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "ecovivaemail")}
            >
              Skicka e-post
            </Button> */}
            {/* <br />
            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "ecovivasms")}
            >
              Skicka SMS (NY!!)
            </Button> */}
            {/* <br />
            <h4>Smakbox</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "smakboxemail")}
            >
              Skicka e-post
            </Button> */}
            {/* <br />
            <h4>Min Farm</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "minfarmemail")}
            >
              Skicka e-post
            </Button> */}
            {/* <br />
            <h4>Kötthallen</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "kotthallenemail")}
            >
              Skicka e-post
</Button> */}
            {/* <br />
            <h4>Hello Fresh</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "hellofreshemail")}
            >
              Skicka e-post
            </Button> */}
            {/* <br />
            <h4>Green Deli</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "greendeliemail")}
            >
              Skicka e-post
            </Button> */}
            {/* <br />
            <h4>Mat Och Ro</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "matochroemail")}
            >
              Skicka e-post
</Button>
            <br />
            <h4>Bra Mat Hemma</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "bramathemmaemail")}
            >
              Skicka e-post
</Button>
            <br />
            <h4>Veckans Middag</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "veckansmiddagemail")}
            >
              Skicka e-post
</Button> */}
            {/* <br />
            <h4>Picard</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "picardemail")}
            >
              Skicka e-post
</Button>
            <br /> */}

            {/* <h4>Fresh Land</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "freshlandemail")}
            >
              Skicka e-post
</Button>
            <br />

            <h4>Marley Spoon</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "marleyspoonemail")}
            >
              Skicka e-post
</Button> */}
   {/* <br />

            <h4>Juica</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "juicaemail")}
            >
              Skicka e-post
</Button> */}
            {/* <br />

            <h4>Fior Di Latte</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "fiordilatteemail")}
            >
              Skicka e-post
</Button> */}
            {/* <br />

            <h4>Macro Meals</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "macromealsemail")}
            >
              Skicka e-post
</Button>
            <br /> */}
            {/* <hr />
            <h4>Simple Feast - SMS</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "sfsms")}
            >
              Skicka sms
            </Button>
            <br />
            <h4>Simple Feast DENMARK - SMS</h4>

            <Button
              disabled={exportdisabled}
              bsStyle="primary"
              className="btn-margin"
              onClick={this.send.bind(this, "sfdksms")}
            >
              Skicka sms
            </Button> */}
            <br />
          </div>
        )}
        {!isAuthenticated() && (
          <h4>
            You're not logged in! Click{" "}
            <a style={{ cursor: "pointer" }} onClick={this.login.bind(this)}>
              Login
            </a>{" "}
            to continue.
          </h4>
        )}
      </div>
    );
  }
}

export default Email;